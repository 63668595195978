import React, { useState, useContext } from "react";
import { AppContext } from "../../AppContext";

import { A, navigate } from "hookrouter";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import LoginLayout from "./LoginLayout";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: " 0 auto",
  },
  textField: {
    width: "100%",
    margin: "5px 0",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  },
  formContainer: {
    marginTop: "2rem",
    width: "80%",
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: ".5rem",
    },
  },
}));

const Request = () => {
  const log = window.log('Request');

  const [state, setState] = useState({
    email: "",
  });

  const initErrors = {
    email: false,
  };

  const [err, setErr] = useState(initErrors);

  const classes = useStyles();

  const { setLogoutState, snack } = useContext(AppContext);

  const resetEmail = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/reset",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state),
        }
      );

      const json = await response.json();

      if (json.success) {
        setErr(initErrors);
        snack(
          "A password reset link has been sent to your email address",
          "success"
        );
      } else {
        json.errors.forEach((error) => {
          setErr({ [error.type]: true });
          snack(error.msg, "error");
          if (error.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          error={err.email}
          label="Email"
          value={state.email}
          className={classes.textField}
          onChange={(e) => setState({ ...state, email: e.currentTarget.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.buttons}>
        <Button
          className={classes.button}
          id="resentEmail"
          variant="outlined"
          color="primary"
          onClick={() => navigate("/")}
          size="large"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.buttons}>
        <Button
          className={classes.button}
          id="resentEmail"
          variant="contained"
          color="primary"
          onClick={() => resetEmail()}
          size="large"
        >
          Send
        </Button>
      </Grid>
    </Grid>
  );
};

const NewPassword = (props) => {
  const log = window.log('NewPassword');
  const classes = useStyles();

  const { token } = props;

  const [state, setState] = useState({
    pw1: "",
    pw2: "",
    userToken: token,
  });

  const [err, setErr] = useState({
    pw1: false,
    pw2: false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant) => enqueueSnackbar(msg, { variant });

  const setPassword = async () => {
    try {
      const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (state.pw1 === "" || state.pw2 === "") {
        setErr({
          pw1: !state.pw1,
          pw2: !state.pw2,
        });
        return snack(
          "Something went wrong. Please double check all fields.",
          "error"
        );
      } else if (state.pw1 !== state.pw2) {
        setErr({
          pw1: true,
          pw2: true,
        });
        return snack("Password does not match.", "error");
      } else if (!pwRegex.exec(state.pw1)) {
        setErr({
          pw1: true,
          pw2: true,
        });
        return snack("Password requirements were not met.", "error");
      }

      try {
        const emailSend = await fetch(
          process.env.REACT_APP_API_URL + "/auth/reset-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(state),
          }
        );

        const json = await emailSend.json();

        if (json.success) {
          snack("Your password has been updated", "success");
          setTimeout(() => navigate("/", true), 750);
        } else {
          json.errors.forEach((error) => {
            setErr({ [error.type]: true });
            snack(error.msg, "error");
          });
        }
      } catch (err) {
        log(err);
        snack(
          "Something went wrong. Please refresh the page and try again.",
          "error"
        );
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <FormControl className={classes.textField} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            className={classes.textField}
            fullWidth
            variant="outlined"
            label="Password"
            placeholder="Password"
            value={state.pw1}
            name="password"
            onChange={(e) => setState({ ...state, pw1: e.currentTarget.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                document.getElementById("submit").focus();
                setPassword();
              }
            }}
            error={err.pw1}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <FormControl className={classes.textField} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password-confirmation">
            Password Confirmation
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password-confirmation"
            className={classes.textField}
            fullWidth
            variant="outlined"
            label="Password Confirmation"
            placeholder="Password Confirmation"
            value={state.pw2}
            name="password confirmation"
            onChange={(e) => setState({ ...state, pw2: e.currentTarget.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                document.getElementById("submit").focus();
                setPassword();
              }
            }}
            error={err.pw2}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="component-error-text">
            Requirements: at least 8 characters, at least 1 uppercase, at
            least 1 lowercase, at least 1 number, at least 1 special character (@$!%*?&)
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid container item xs={12} className={classes.buttons}>
        <Button
          variant="outlined"
          className={classes.button}
          size="large"
          color="primary"
          onClick={() => navigate("/")}
        >
          cancel
        </Button>

        <Button
          id="submit"
          variant="contained"
          className={classes.button}
          size="large"
          color="primary"
          onClick={() => setPassword()}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
};

const ForgotPassword = () => {
  const classes = useStyles();

  const url = new URL(window.location);
  const token = url.searchParams.get("token");

  return (
    <LoginLayout subtitle={"Enter your email to reset your password"}>
      <Grid container className={classes.formContainer}>
        {token ? <NewPassword token={token} /> : <Request />}
      </Grid>
    </LoginLayout>
  );
};

export default ForgotPassword;
