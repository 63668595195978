import React, { useState, useEffect } from "react";
import localforage from "localforage";
import getSubscriptionInfo from "./components/common/getSubscriptionInfo";
import { useSnackbar } from "notistack";
import { navigate } from "hookrouter";
import { Typography } from "@material-ui/core";
import socketIOClient from "socket.io-client";

export const AppContext = React.createContext({});

export const AppProvider = (props) => {
  const [state, setState] = useState({
    auth: {
      loggedIn: null,
      token: null,
    },
  });

  // loggedin state needs to change separately from state to allow app.js to execute logout()
  const [logoutState, setLogoutState] = useState(false);

  // settings ModalChart
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openOnboardModal, setOpenOnboardModal] = useState(false);
  // edit alert warning modal
  const [openAlertWarning, setOpenAlertWarning] = useState(false);
  //Used for adding entities
  const [openGatewayModal, setOpenGatewayModal] = useState(false);
  const [openSensorModal, setSensorModalState] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openAddingModal, setOpenAddingModal] = useState(false);
  const [openResellerModal, setOpenResellerModal] = useState(false);

  //Used for editing entities
  const [openEditGatewayModal, setOpenEditGatewayModal] = useState(false);
  const [openEditSensorModal, setOpenEditSensorModal] = useState(false);
  const [openEditAlertModal, setOpenEditAlertModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [openEditResellerModal, setOpenEditResellerModal] = useState(false);

  //Used for deleting entities
  const [openDeleteGatewayModal, setOpenDeleteGatewayModal] = useState(false);
  const [openDeleteSensorModal, setOpenDeleteSensorModal] = useState(false);
  const [openDeleteAlertModal, setOpenDeleteAlertModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openDeleteLocationModal, setOpenDeleteLocationModal] = useState(false);

  const log = window.log('App Provider');

  const { enqueueSnackbar } = useSnackbar();
  const snack = (msg, variant, action) =>
    enqueueSnackbar(msg, {
      action,
      variant,
      preventDuplicate: true,
      autoHideDuration: 5000,
    });

  const setAppState = async (state) => {
    try {
      await localforage.setItem("appState", state); // Save current state to local storage.
    } catch (err) {
      console.error(err);
    }

    // log("Looking at App Context state ", state);

    setState(state); // Update current state.
  };
  // If a modal open
  const modalOpenState = () => {
    const settingsModal = openOnboardModal || openSettingsModal;
    const addBoolean =
      openGatewayModal ||
      openSensorModal ||
      openAlertModal ||
      openUserModal ||
      openLocationModal ||
      openResellerModal ||
      openAddingModal;
    const editBoolean =
      openEditGatewayModal ||
      openEditSensorModal ||
      openEditAlertModal ||
      openEditUserModal ||
      openEditLocationModal;
    const deleteBoolean =
      openDeleteGatewayModal ||
      openDeleteSensorModal ||
      openDeleteAlertModal ||
      openDeleteUserModal ||
      openDeleteLocationModal;
    return (
      openAlertWarning ||
      settingsModal ||
      addBoolean ||
      editBoolean ||
      deleteBoolean
    );
  };

  return (
    <AppContext.Provider
      value={{
        appState: state,
        setAppState: setAppState,
        logoutState,
        setLogoutState,
        openGatewayModal,
        setOpenGatewayModal,
        openSensorModal,
        openEditSensorModal,
        setOpenEditSensorModal,
        openDeleteSensorModal,
        setOpenDeleteSensorModal,
        openAlertModal,
        setOpenAlertModal,
        openUserModal,
        setOpenUserModal,
        openEditUserModal,
        setOpenEditUserModal,
        openDeleteUserModal,
        setOpenDeleteUserModal,
        openLocationModal,
        setOpenLocationModal,
        openEditLocationModal,
        setOpenEditLocationModal,
        openDeleteLocationModal,
        setOpenDeleteLocationModal,
        openEditGatewayModal,
        setOpenEditGatewayModal,
        openEditAlertModal,
        setOpenEditAlertModal,
        openDeleteGatewayModal,
        setOpenDeleteGatewayModal,
        openDeleteAlertModal,
        setOpenDeleteAlertModal,
        openSettingsModal,
        setOpenSettingsModal,
        setOpenAlertWarning,
        modalOpenState,
        openOnboardModal,
        setOpenOnboardModal,
        openAddingModal,
        setOpenAddingModal,
        openResellerModal,
        setOpenResellerModal,
        openEditResellerModal,
        setOpenEditResellerModal,
        snack,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
