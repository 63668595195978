import React, { useState, useEffect, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import { Grid, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";
import { fetchOne, fetchAll, updateOne, deleteOne } from "../common/api";
// import EditableTextField from "../common/EditableTextField";
// import LocationForm from "../common/LocationForm";

import DetailsCard from "../Generic/DetailsCard";
// import LocationCard from "../Generic/LocationCard";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import Breadcrumb from "../Generic/BreadCrumb";
import LocationIcon from "@material-ui/icons/Room";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// import BluePressureIcon from "../../img/icons/bluePressureIcon.svg";
// import BlueMotionDetectionIcon from "../../img/icons/blueMotionDetectionIcon.svg";
// import BlueLightIcon from "../../img/icons/blueLightIcon.svg";
// import BluePanicIcon from "../../img/icons/bluePanicIcon.svg";
// import BlueProximityIcon from "../../img/icons/blueProximityIcon.svg";

import LastAlertsList from "./../Dashboard/LastAlertsList";
import GatewaysList from "../Generic/ItemList";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PrintIcon from "@material-ui/icons/Print";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardActions from "../Generic/CardActions";
import NotFoundPageLoggedIn from "../Auth/NotFoundPageLoggedIn";
import SensorImage from "../../img/icons/sensor.svg";

import MUIDataTable from "mui-datatables";

// import ShareIcon from "@material-ui/icons/Share";
// import SensorIcon from "../../img/icons/SensorIcon";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import GatewayIcon from "@material-ui/icons/Router";
import AlertIcon from "@material-ui/icons/Announcement";
import SensorIcon from "../../img/icons/SensorIcon";
import EditLocationFormModal from "./EditLocationFormModal";
import DeleteLocationModal from "./DeleteLocationFormModal";
import NotFoundPage from "../Auth/NotFoundPage";
import LoadingSpinner from "../Generic/LoadingSpinner";
import SingleLocationCard from "./SingleLocationCard";
import socketIOClient from "socket.io-client";
import DeviceCard from "../Devices/DeviceCard";
const useStyles = makeStyles((theme) => ({
  root: {},
  gridContainer: {
    marginTop: "5%",
  },
  backButton: {
    color: theme.palette.secondary.main,
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "1rem",
    width: "6rem",
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: "1rem",
    marginLeft: "auto",
  },
  gatewayButton: {
    marginTop: "1rem",
  },
  gatewayTableRoot: {
    height: "100%",
  },
  card: {
    marginTop: "5%",
    paddingLeft: "1rem",
    marginBottom: "1rem",
  },
  cardContent: {
    minHeight: "50vh",
  },
  titleName: {
    textAlign: "center",
    color: theme.palette.text,
    fontWeight: "bold",
    alignSelf: "center",
    margin: "auto",
  },
  text: {
    color: theme.palette.text,
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  nested: {
    maxWidth: 275,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  locationCardContainer: {
    height: 158,
    [theme.breakpoints.down("xs")]: {
      height: 251,
    },
  },
  sensorCardContainer: {
    height: 155,
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
}));

const SingleLocation = (props) => {
  const log = window.log('SingleLocation');

  const theme = useTheme();
  const classes = useStyles();
  const {
    appState,
    setAppState,
    setOpenEditLocationModal,
    setOpenDeleteLocationModal,
    alertCount: appContextAlertCount,
    snack,
  } = useContext(AppContext);

  const [name, setName] = useState();
  const [locationDetails, setLocationDetails] = useState();

  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();

  const [gatewayCount, setGatewayCount] = useState(0);
  const [sensorCount, setSensorCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);

  const [err, setErr] = useState();

  const [editForm, setEditForm] = useState(false);
  const [editName, setEditName] = useState();
  const [editLocationStreet, setEditLocationStreet] = useState();
  const [editLocationCity, setEditLocationCity] = useState();
  const [editLocationState, setEditLocationState] = useState();
  const [editLocationZip, setEditLocationZip] = useState();
  const [editLocationName, setEditLocationName] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [locationGateways, setLocationGateways] = useState([]);

  const [devices, setDevices] = useState([]);
  const [alertsArray, setAlertsArray] = useState([]);
  const [locationId, setLocationId] = useState();
  const [location, setLocation] = useState({});
  const [reload, setReload] = useState(false);

  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  let sockets = [];

  const { id } = props;

  const updateAlertCount = async () => {
    try {
      const json = await fetchOne(
        "location",
        id,
        appState.auth.userInfo.companyId,
        appState.auth.token
      );

      if (json.success) {
        log("Looking at the json ", json);
        if (json.data && json.data.locations.length) {
          setAlertCount(json.data.locations[0].alertCount);
          setAlertsArray(json.data.locations[0].alertsArray);
        }
      } else {
        throw new Error("Request failed");
      }
    } catch (err) {
      log("Unable to update alert count");
    }
  };

  useEffect(() => {
    updateAlertCount();
  }, [appContextAlertCount]);

  const getLocation = async () => {
    try {
      const json = await fetchOne(
        "location",
        id,
        appState.auth.userInfo.companyId,
        appState.auth.token
      );

      log("Single_Location: Looking at json ", json);

      if (json.success) {
        setLocation(json.data.locations[0]);
        setStreet(json.data.locations[0].street);
        setCity(json.data.locations[0].city);
        setState(json.data.locations[0].state);
        setZipcode(json.data.locations[0].zip);
        setName(json.data.locations[0].name);
        setEditLocationName(json.data.locations[0].name);
        setEditLocationStreet(json.data.locations[0].street);
        setEditLocationCity(json.data.locations[0].city);
        setEditLocationState(json.data.locations[0].state);
        setEditLocationZip(json.data.locations[0].zip);
        setLocationDetails(json.data.locations[0]);
        setGatewayCount(json.data.locations[0].gatewayCount);
        setSensorCount(json.data.locations[0].sensorCount);
        setAlertCount(json.data.locations[0].alertCount);
        setAlertsArray(json.data.locations[0].alertsArray);
        setLocationId(json.data.locations[0].id);
        //Set the gateway information
        let locationGatewayData = [];

        const asyncForEach = async (array, callback) => {
          for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
          }
        };

        let sensorArray = [];
        const getGatewaySensors = async () => {
          try {
            await asyncForEach(
              json.data.locations[0].gateways,
              async (gateway) => {
                log( "Single_Location: Showing gateway values\t", gateway );
                

                locationGatewayData.push({
                  name: gateway.gateway_name,
                  MAC: gateway.gateway_id,
                });

                log(
                  "Single location: Running a fetch all on sensors with gateway entry ID " +
                    gateway.gateway_entry_id
                );
                const sensorJson = await fetchAll(
                  "sensor",
                  appState.auth.userInfo.companyId,
                  appState.auth.token,
                  gateway.gateway_entry_id
                );

                log("Single location: Looking at sensor json ", sensorJson.data.sensors);

                if (sensorJson.data.sensors !== undefined) {
                  sensorJson.data.sensors.forEach((sensor) => {
                    sensorArray.push(sensor);
                  });
                }
              }
            );
          } catch (error) {
            log(error);
          }
        };

        await getGatewaySensors();

        setReload(!reload);
        for (var i in sensorArray) {
          const timer = 300 - sensorArray[i].lastMessageTime
          sensorArray[i].lastMessageTime = new Date().getTime() / 1000 - timer; 
        }
        setDevices(sensorArray);
        setLocationGateways(locationGatewayData);
      } else {
        setLocation(null);
        snack(json.errors[0].msg, "error");
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getLocation();
  }, []);

  //Use for the sensor message report time
  useEffect(() => {
    // const interval = setInterval(() => {
    //   setDevices((prevState) => {
    //     let newState = [];
    //     for (let i = 0; i < prevState.length; i++) {
    //       let stateObj = prevState[i];
    //       if (stateObj.lastMessageTime > 0) {
    //         stateObj.lastMessageTime -= 1;
    //       }
    //       newState.push(stateObj);
    //     }
    //     log("Looking at the new state ",newState);
    //     return newState;
    //   });
    // }, 1000);
    // return () => clearInterval(interval);
  }, [reload]);

  useEffect(() => {
    if (devices.length === 0) return;
    //Set up the live message traffic

    //For every gateway MAC create a socket
    log("Looking at the state " + state);
    for (let i = 0; i < devices.length; i++) {
      log(devices[i]);
      log(devices[i].gatewayId);
      log(devices[i].sensorId);
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        upgrade: false,
        transports: ["websocket"],
        query: {
          sensorMAC: devices[i].sensorId.toLowerCase(),
        },
      });
      sockets.push(socket);
      // connectSocket(socket);
      log("The id is " + devices[i].sensorId);
      socket.on(devices[i].sensorId.toLowerCase(), (data) => {
        log("Sensor data: " + devices[i].sensorId + ":" + data);
        let newState = [];
        for (let j = 0; j < devices.length; j++) {
          if (devices[i].sensorId === devices[j].sensorId) {
            let stateObj = devices[i];

            // //Panic
            // if (data.doorPanicSource === 5) {
            //   data.panic = data.doorPanicAlert;
            //   if (
            //     stateObj.lastSensorMessage.door &&
            //     stateObj.lastSensorMessage.door !== 0
            //   ) {
            //     data.door = stateObj.lastSensorMessage.door;
            //   }
            // }

            // //Door
            // if (
            //   data.doorPanicSource === 4 ||
            //   stateObj.lastSensorMessage.doorPanicSource === 4 ||
            //   stateObj.lastSensorMessage.last_door_alert ||
            //   (stateObj.lastSensorMessage.door &&
            //     stateObj.lastSensorMessage.door !== 0)
            // ) {
            //   if (
            //     data.doorPanicSource === 0 &&
            //     (stateObj.lastSensorMessage.last_door_alert == 3 || stateObj.lastSensorMessage.door === 3)
            //   ) {
            //     data.door = 3;
            //   } else if (
            //     data.doorPanicSource === 0 &&
            //     (stateObj.lastSensorMessage.last_door_alert == 2 || stateObj.lastSensorMessage.door === 2)
            //   ) {
            //     data.door = 2;
            //   } else if (data.doorPanicSource === 4) {
            //     data.door = data.doorPanicAlert;
            //   }
            // }

            stateObj.lastSensorMessage = data;
            newState.push(stateObj);
          } else {
            newState.push(devices[j]);
          }
        }
        log("Looking at the new state ", newState);
        setDevices(newState);
      });
    }

    return () => {
      for (let i = 0; i < sockets.length; i++) {
        log("Leaving page");
        sockets[i].disconnect();
      }
    };
  }, [locationGateways]);

  const onEditClick = () => {
    // Set input values and change to edit form
    setEditName(name);
    // setEditForm(!editForm);
    // setEditGatewayId(state.gatewayId);
  };

  const onSubmitClick = async () => {
    // Submit changes to API and revert to plain form
    setEditForm(!editForm);

    try {
      const data = {
        name: editName,
        street,
        city,
        state,
        zipcode,
        id,
        companyId: appState.auth.userInfo.companyId,
      };
      const json = await updateOne("location", data, appState.auth.token);
      if (json.success) {
        snack("Location information successfully updated", "success");
        getLocation();
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const rowOptions = {
    selectableRows: "none",
    responsive: "stacked",
    tableBodyHeight: "100%",
  };

  const userAuthButtons = (
    <Grid container>
      {editForm ? (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onSubmitClick()}
        >
          Submit
        </Button>
      ) : (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onEditClick()}
        >
          Edit
        </Button>
      )}
      <Button
        disabled={appState.auth.userInfo.perm === 1}
        className={classes.deleteButtons}
        variant="outlined"
        onClick={() => handleOpenDeleteLocationModal()}
      >
        Delete
      </Button>
    </Grid>
  );

  const handleOpenEditLocationModal = () => {
    log("open edit dialog");
    setOpenEditLocationModal(true);
  };

  const handleOpenDeleteLocationModal = () => {
    log("Open Delete Modal 1");
    setOpenDeleteLocationModal(true);
    log("Open Delete Modal 2");
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/location/all">
        Locations
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );
  const convertTempForUserPref = (value) => {
    let unitType = " °C";
    //Convert the Fahrenheit
    if (appState.auth.userInfo.themePreferences.measurement === "imperial") {
      value = value * (9 / 5) + 32;
      unitType = " °F";
    }
    value = value.toFixed(2);
    value = value + unitType;
    return value;
  };

  return location ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div className={classes.root}>
        <DeleteLocationModal locationId={locationId} />
        <EditLocationFormModal
          getLocation={getLocation}
          locationId={locationId}
          editLocationName={editLocationName}
          setEditLocationName={setEditLocationName}
          editLocationStreet={editLocationStreet}
          setEditLocationStreet={setEditLocationStreet}
          editLocationCity={editLocationCity}
          setEditLocationCity={setEditLocationCity}
          editLocationState={editLocationState}
          setEditLocationState={setEditLocationState}
          editLocationZip={editLocationZip}
          setEditLocationZip={setEditLocationZip}
        />
        {location ? (
          <Grid container spacing={2}>
            <Grid item className={classes.title}>
              <Breadcrumb
                breadcrumbs={breadcrumbs}
                leadingIcon={
                  <LocationIcon style={{ fontSize: 32 }} color="primary" />
                }
                title={`${name}`}
              />
            </Grid>

            <Grid item xs={12} className={classes.locationCardContainer}>
              <SingleLocationCard
                singleLocationCard={true}
                enableZero={true}
                showAvatar={false}
                name={name ? `${name}` : "No Location Name"}
                subtitle=""
                subtitle2={`${street || ""}`}
                subtitle3={
                  city || state || zipcode
                    ? `${city || ""}, ${state || ""} ${zipcode || ""}`
                    : ""
                }
                buttons={
                  appState.auth.userInfo.perm !== 1 && (
                    <CardActions
                      smallDevice={smallDevice}
                      editEvent={handleOpenEditLocationModal}
                      deleteEvent={handleOpenDeleteLocationModal}
                    />
                  )
                }
                trailingItems={[
                  {
                    value: alertCount,
                    icon: (
                      <AlertIcon style={{ fontSize: 24, color: "#FF6A6A" }} />
                    ),
                  },
                  {
                    value: gatewayCount,
                    icon: (
                      <GatewayIcon style={{ fontSize: 24, color: "#59C754" }} />
                    ),
                  },

                  {
                    value: sensorCount,
                    icon: (
                      <SensorIcon
                        style={{
                          fontSize: 24,
                          color: theme.palette.primary.main,
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LastAlertsList title="Active Alerts" alerts={alertsArray} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <GatewaysList
                title="Gateways"
                // action={
                //   <Box>
                //     <IconButton>
                //       <AddCircleOutlineIcon />
                //     </IconButton>
                //     <IconButton>
                //       <PrintIcon />
                //     </IconButton>
                //     <IconButton>
                //       <CloudDownloadIcon />
                //     </IconButton>
                //     <IconButton>
                //       <FilterListIcon />
                //     </IconButton>
                //   </Box>
                // }
                columns={[
                  {
                    label: "Name",
                    id: "name",
                    minWidth: 170,
                  },
                  {
                    label: "ID",
                    id: "MAC",
                    minWidth: 170,
                  },
                ]}
                items={locationGateways}
              />
            </Grid>
            {devices.map((device) => (
              <Grid
                item
                xs={12}
                key={device.sensorId}
                className={classes.sensorCardContainer}
              >
                <DeviceCard
                  sensorCard={true}
                  showStatus={true}
                  lastMessageTime={device.lastMessageTime}
                  // id={device.sensorId}
                  onClick={() => {
                    navigate(`/device/${device.sensorId}`);
                  }}
                  name={`${device.sensorName}`}
                  subtitle={`Sensor ID: ${device.sensorId}`}
                  subtitle2={`Sensor Location: ${device.sensorLocation}`}
                  defaultAvatar={device.sensorImageLocation ? false : true}
                  avatar={
                    device.sensorImageLocation
                      ? device.sensorImageLocation
                      : SensorImage
                  }
                  isItemsDict={true}
                  // showOnlyItems={["temperature", "humidity", "pressure"]}
                  showOnlyItems={device.sensorProfileArray}
                  trailingItems={{
                    temperature: {
                      title: "Temperature",
                      value:
                        device.lastSensorMessage.temperature ||
                        device.lastSensorMessage.temperature === 0
                          ? convertTempForUserPref(
                              device.lastSensorMessage.temperature
                            )
                          : "-",
                      icon: (
                        <TemperatureSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    humidity: {
                      title: "Humidity",
                      value:
                        device.lastSensorMessage.humidity ||
                        device.lastSensorMessage.humidity === 0
                          ? device.lastSensorMessage.humidity.toFixed(1) + " %"
                          : "-",
                      icon: (
                        <HumiditySVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    pressure: {
                      title: "Pressure",
                      value:
                        device.lastSensorMessage.pressure ||
                        device.lastSensorMessage.pressure === 0
                          ? device.lastSensorMessage.pressure.toFixed(1) +
                            " hPa"
                          : "-",
                      icon: (
                        <PressureSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    motionDetection: {
                      title: "Motion",
                      value:
                        device.lastSensorMessage.acceleration ||
                        device.lastSensorMessage.acceleration === 0
                          ? device.lastSensorMessage.acceleration.toFixed(1) +
                            ""
                          : "-",
                      icon: (
                        <MotionDetectionSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    light: {
                      title: "Light",
                      value:
                        device.lastSensorMessage.light ||
                        device.lastSensorMessage.light === 0
                          ? Number(device.lastSensorMessage.light).toFixed(2) + " lux"
                          : "-",
                      icon: (
                        <LightSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    panicButton: {
                      title: "Panic",
                      value: device.lastSensorMessage.currentPanicState
                        ? "Active"
                        : "Inactive",
                      icon: (
                        <PanicSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    proximity: {
                      title: "Proximity",
                      value:
                        device.lastSensorMessage.rssi ||
                        device.lastSensorMessage.rssi === 0
                          ? device.lastSensorMessage.rssi
                          : "-",
                      icon: (
                        <ProximitySVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    doorOpen: {
                      title: "Door",
                      value: device.lastSensorMessage.currentDoorState
                        ? "Open"
                        : "Closed",
                      icon: (
                        <DoorSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <NotFoundPageLoggedIn />
        )}
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default SingleLocation;
