import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import CardInfoIcons from "../Generic/CardInfoIcons";
import grey from "@material-ui/core/colors/grey";
import { navigate } from "hookrouter";
import GenericListCard from "../Generic/GenericListCard";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      justifyContent: "flex-start",
    },
  },
  trailingItems: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "100%",
    },
  },
  leadingContainer: {
    width: "50%",
    marginTop: 7,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.type === "light" ? grey[600] : "#fff",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  },
  messageStreet: {
    color: theme.palette.type === "light" ? grey[500] : "#fff",
    margin: 0,
    whiteSpace: "nowrap",
    fontSize: 12,
  },
  messageCityStateZip: {
    color: theme.palette.type === "light" ? grey[500] : "#fff",
    margin: 0,
    whiteSpace: "nowrap",
    fontSize: 12,
  },
}));

/**
 * CustomerCard
 * @param {Object} props
 * @props
 * id: Integer
 * trailingItems: Object Array [{ icon: React Component , value: String }]
 * title: String,
 * message: String,
 */
export default function CustomerCard(props) {
  const {
    id, // Id of the location
    trailingItems, // Trailing Items for the CardInfoIcons
    title, // Title of the Card
    street, // Address variable of the Card
    city, // Address variable of the Card
    state, // Address variable of the Card
    zip, // Address variable of the Card
    phone, // Phone number of the Card
  } = props;
  /**
   * Event to send the browser to the user page
   */
  const showReseller = () => {
    navigate(`/customer/${id}`);
  };
  const classes = useStyles();

  return (
    <GenericListCard>
      <Box
        className={classes.cardContainer}
        onClick={showReseller}
        style={{ cursor: "pointer" }}
      >
        <Box className={classes.leadingContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <Box>
            <Typography className={classes.messageStreet}>{street}</Typography>
          </Box>
          <Box>
            <Typography className={classes.messageCityStateZip}>
              {city}, {state} {zip}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.messageCityStateZip}>
              {phone}
            </Typography>
          </Box>
        </Box>
        <div className={classes.trailingItems}>
          <CardInfoIcons
            locationCard={true}
            wrapItems="nowrap"
            items={trailingItems}
            isBold={true}
            enableZero={true}
          />
        </div>
      </Box>
    </GenericListCard>
  );
}
