import _ from "lodash";
import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  imageButtonSize: {
    width: 150,
    [theme.breakpoints.down("sm")]: {
      width: 140,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 150,
      marginBottom: 10,
    },
  },
}));

/**
 * Common Complete Screen for Modal
 * @param {Object} props
 * @props
 * setDefaultObject: () => {}
 */
export default function Complete(props) {
  const classes = useStyles();

  const { header, desc, completeButtons } = props;

  const mapCompleteButtons = () => {
    return _.map(completeButtons, (button, index) => (
      <Grid className={classes.imageButtonSize} item key={index}>
        {button}
      </Grid>
    ));
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader header={header} desc={desc} />
      <Grid item xs={12}>
        {!_.isEmpty(completeButtons) && (
          <Grid container justify="space-around" display="flex">
            {mapCompleteButtons()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
