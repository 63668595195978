import MUIDataTable from "mui-datatables";
import React, { useEffect, useRef } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  tableSelection: {
    paddingTop: theme.spacing(2),
  },
  tableBody: {
    maxHeight: 350,
    minHeight: 350,
  },
}));

/**
 * Select Baseline Sensor
 * @param {*} props
 */
export default function SelectBaselineSensor(props) {
  const classes = useStyles();
  const {
    data,
    desc,
    header,
    columns,
    options,
    tableTitle,
    baselineSensorSel = null,
  } = props;

  const selectSensorTableOptions = {
    rowsPerPage: 6,
    pagination: false,
    rowsPerPageOptions: [],
    selectableRowsOnClick: true,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    selectToolbarPlacement: "above",
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: "No matching sensors",
      },
    },
  };

  const sensorTableColumns = [
    {
      name: "sensorName",
      label: "Sensor Name",
    },
    {
      name: "sensorLocation",
      label: "Location",
    },
  ];

  const usersTableColumns = [
    {
      name: "fname",
      label: "First Name",
    },
    {
      name: "lname",
      label: "Last Name",
    },
  ];

  // We want to hijack the table for baseline sensors and rewrite the text that shows when a sensor is selected
  const sensorTableRef = useRef(null);

  const containerRef = useRef(null);

  const baselineSensorSelName = baselineSensorSel?.sensorName;

  useEffect(() => {
    if (
      sensorTableRef?.current?.tableContent?.current?.firstChild?.firstChild
        ?.firstChild?.innerHTML &&
      baselineSensorSelName
    ) {
      sensorTableRef.current.tableContent.current.firstChild.firstChild.firstChild.innerHTML = `${baselineSensorSelName} Selected`;
    }
  }, [baselineSensorSel]);

  return (
    <Grid item xs={12} ref={containerRef}>
      <ModalFormHeader header={header} desc={desc} />
      <Grid item xs={12} className={classes.tableSelection}>
        <MUIDataTable
          classes={{ responsiveBase: classes.tableBody }}
          title={
            <Grid container>
              <Typography style={{ fontWeight: "bold" }}>
                {tableTitle}
              </Typography>
            </Grid>
          }
          options={{
            ...selectSensorTableOptions,
            ...options,
          }}
          columns={columns === "users" ? usersTableColumns : sensorTableColumns}
          data={data}
          ref={sensorTableRef}
        />
      </Grid>
    </Grid>
  );
}
