import _ from "lodash";
import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import SensorValueCard from "../../Generic/SensorValueCard";
import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";
import { defaultSensorProfile } from "../../common/ModalComponents/DefaultSensorProfile";

const useStyles = makeStyles((theme) => ({
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Set Alert Name
 * @param {name: string, nameCallback: ()=>void, nameError: bool} props
 */
export default function PanicButtonSettingsStep() {
  const theme = useTheme();
  const classes = useStyles();

  const sensorProfile = defaultSensorProfile(theme);

  return (
    <Grid item xs={12}>
      <ModalFormHeader
        header="Alert Range Settings - Panic Button"
        desc="You will receive alerts when the panic button is pressed."
      />

      <Grid item xs={12}>
        <SensorValueCard
          icon={_.find(sensorProfile, { name: "panic_button" }).icon}
          sensorName={"Panic Button"}
        />
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.fontColor}>No configuration needed.</Box>
      </Grid>
    </Grid>
  );
}
