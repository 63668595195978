import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  batteryRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const BatteryThresholds = (props) => {
  const theme = useTheme();

  const {
    batteryThresholdLow,
    setBatteryThresholdLow,
    batteryThresholdHigh,
    setBatteryThresholdHigh,
    enableBatteryAlert,
    setEnableBatteryAlert,
    batteryRangeType,
    setBatteryRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: 0,
      label: "0%",
    },

    {
      value: 100,
      label: "100%",
    },
  ];

  const valuetext = (value) => {
    return `${value}%`;
  };

  const handleToggleEnable = (e) => {
    setEnableBatteryAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (batteryRangeType === 1 || batteryRangeType === 2) {
      setBatteryThresholdHigh(v);
    } else {
      setBatteryThresholdLow(v[0]);
      setBatteryThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableBatteryAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.batteryRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="battery-range-slider">
                Battery
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableBatteryAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={batteryRangeType}
                setRangeType={setBatteryRangeType}
                enabled={enableBatteryAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                aria-labelledby="battery-range-slider"
                onChange={handleOnChange}
                value={
                  batteryRangeType === 1 || batteryRangeType === 2
                    ? batteryThresholdHigh
                      ? batteryThresholdHigh
                      : 50
                    : [
                        batteryThresholdLow ? batteryThresholdLow : 0,
                        batteryThresholdHigh ? batteryThresholdHigh : 100,
                      ]
                }
                track={
                  batteryRangeType === 1 || batteryRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelFormat={(value) => valuetext(value)}
                valueLabelDisplay="on"
                marks={Marks}
                min={0}
                max={100}
                disabled={!enableBatteryAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BatteryThresholds;
