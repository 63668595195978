import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "./../../AppContext";
import {
  Box,
  Grid,
  LinearProgress,
  IconButton,
  makeStyles,
  TextField,
  FormControl,
  InputLabel,
  useTheme,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ResponsiveDialog from "../Generic/GenericDialog";
import ImageButton from "../Generic/ImageButton";
import { Braintree, HostedField } from "react-braintree-fields";
import grey from "@material-ui/core/colors/grey";
import LocationForm from "../common/LocationForm";
import uploadImage from "../common/api/uploadImage";
import { navigate } from "hookrouter";
import MuiPhoneNumber from "material-ui-phone-number";
import TermAndConditions from "../Generic/TermAndConditions";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  inputNormal: {
    height: "50px",
    marginTop: "50px",
    background: "white",
    border: "1px solid grey",
    padding: "4px",
    borderRadius: "5px",
    paddingLeft: "15px",
  },
  inputFocus: {
    height: "50px",
    marginTop: "50px",
    background: "white",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "4px",
    borderRadius: "5px",
    paddingLeft: "15px",
    width: "100%",
    color: theme.palette.type === "light" ? grey[600] : grey[400],
  },
  removeImageButton: {
    marginLeft: "1rem",
  },
  error: {
    borderColor: "#f44336",
  },
}));

/**
 * Onboard Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function OnboardFormModal(props) {
  const log = window.log("OnboardFormModal");

  const theme = useTheme();
  const { appState, setAppState, setLogoutState, snack } = useContext(
    AppContext
  );
  const { openState, handleClose, setOpenOnboardModal } = props;

  const url = new URL(window.location);
  const [token, setToken] = useState(url.searchParams.get("token"));
  const [userInfo, setUserInfo] = useState(url.searchParams.get({}));

  const classes = useStyles();
  const [tokenize, setTokenizeFunc] = useState();
  const [cardType, setCardType] = useState("");
  const [focusedFieldName, setFocusedField] = useState("");
  const numberField = useRef();
  const cvvField = useRef({ field: "CVC" });
  const postalCodeField = useRef();

  const [onboardObject, setOnboardObject] = useState({
    activationCode: "",
    resellerId: null,
    activationState: false,
    discount: false,
    companyName: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    firstName: "",
    lastName: "",
    phoneNum: "",
    cardNumber: "",
    cardExpirationDate: "",
    cardCVC: "CVC",
    zipCode: "",
    platformImageLocation: "",
    faviconImageLocation: "",
    lat: "",
    lng: "",
  });

  const [errorState, setErrorState] = useState({
    activationCode: false,
    street: false,
    city: false,
    state: false,
    zip: false,
    companyName: false,
    firstName: false,
    lastName: false,
    number: false,
    expirationDate: false,
    cvv: false,
    postalCode: false,
  });

  const [clientToken, setClientToken] = useState("");
  const [isBtReady, setIsBtReady] = useState(false);

  const onAuthorizationSuccess = () => {
    setIsBtReady(true);
    numberField.current.focus();
  };

  const onDataCollectorInstanceReady = (collector) => {
    // DO SOMETHING with Braintree collector as needed
    log("COLLECTOR", collector);
  };

  const handleError = (error = {}) => {
    log("Handling braintree error: ", error);
    let fieldError = {
      number: false,
      expirationDate: false,
      cvv: false,
      postalCode: false,
    };
    if (error.details && error.details.invalidFieldKeys) {
      error.details.invalidFieldKeys.forEach((field) => {
        fieldError[field] = true;
      });
    } else if (
      error.message ===
        "All fields are empty. Cannot tokenize empty card fields." &&
      error.code === "HOSTED_FIELDS_FIELDS_EMPTY"
    ) {
      let fields = ["number", "expirationDate", "cvv", "postalCode"];
      fields.forEach((field) => {
        fieldError[field] = true;
      });
    }

    setErrorState((prevState) => {
      return {
        ...prevState,
        ...fieldError,
        firstName: !onboardObject.firstName,
        lastName: !onboardObject.lastName,
      };
    });
    snack("Something went wrong. Please double check all fields.", "error");
  };

  const onFieldBlur = (field, event) => setFocusedField("");
  const onFieldFocus = (field, event) => setFocusedField(event.emittedBy);

  const onCardTypeChange = ({ cards }) => {
    let cardCode = "CVV";
    if (1 === cards.length) {
      const [card] = cards;

      setCardType(card.type);

      if (card.code && card.code.name) {
        cardCode = card.code.name;
      }
    } else {
      setCardType("");
    }
    setOnboardObject((prevState) => {
      return { ...prevState, cardCVC: cardCode };
    });
  };

  const handleChange = (e) => {
    if (!e) return; //FOR TESTING

    const object = {
      ...onboardObject,
    };

    if (e.currentTarget.name === "activationCode") {
      if (e.currentTarget.value.length <= 11) {
        object[e.currentTarget.name] = checkActivationDashes(
          e.currentTarget.value
        );
      }
    } else object[e.currentTarget.name] = e.currentTarget.value;

    setOnboardObject(object);
  };

  //  Adds dashes even when pasting in code
  const checkActivationDashes = (code) => {
    let dashedCode = "";
    const strippedCode = code.split("-").join("");
    const codeArr = strippedCode.split("");
    codeArr.forEach((codeNo, index) => {
      if (index === 3 || index === 6) dashedCode += "-";
      if (index !== 3 || index !== 7) {
        dashedCode += codeNo.toUpperCase();
      }
    });
    return dashedCode;
  };

  const handleChangeActivationState = async () => {
    if (onboardObject.activationCode === "") {
      snack("Activation code is required.", "error");
      return setErrorState({ ...errorState, activationCode: true });
    }

    try {
      const actCode = onboardObject.activationCode.split("-").join("");
      const isValidResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-activation?code=${actCode}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const json = await isValidResponse.json();

      // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      // await delay(1500);

      let activationErrorState = false;
      let discount = false;
      if (!json.success) {
        activationErrorState = true;
        snack("No activation code in our system", "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      } else {
        activationErrorState = false;
        if (json.data.discount) {
          discount = true;
        }
      }
      setOnboardObject({
        ...onboardObject,
        resellerId: json.data.reseller_id,
        discount,
      });
      setErrorState({ ...errorState, activationCode: activationErrorState });
      return json.success;
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  const handleCompanyInfo = async () => {
    log("About to run handleCompanyInfo");
    setErrorState({
      ...errorState,
      companyName: !onboardObject.companyName,
      street: !onboardObject.street,
      city: !onboardObject.city,
      state: !onboardObject.state,
      zip: !onboardObject.zip,
    });
    // Don't continue if there are missing info
    if (
      !onboardObject.street ||
      !onboardObject.city ||
      !onboardObject.state ||
      !onboardObject.zip ||
      !onboardObject.companyName
    ) {
      snack("Something went wrong. Please double check all fields.", "error");
      return false;
    } else if (onboardObject.state.length !== 2) {
      snack("Please Use Two Letter State Code", "error");
      return false;
    } else {
      const checked = await checkLatLng();
      if (checked) return true;
      else return false;
    }
  };

  const checkLatLng = async () => {
    try {
      log("Runnning the here api request");

      let street = onboardObject.street;
      if (onboardObject.street.includes("#")) {
        street = onboardObject.street.split("#").join("%23");
      }

      const response = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&searchtext=${street}+${onboardObject.city}+${onboardObject.state}+${onboardObject.zip}`
      );

      const json = await response.json();

      if (json.Response.View.length === 0) {
        snack("Not a valid address", "error");
        return false;
      } else {
        let lat =
          json.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        let lng =
          json.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        setOnboardObject({
          ...onboardObject,
          lat,
          lng,
        });
        return true;
      }
    } catch (hereApiError) {
      snack("Network error", "error");
      return false;
    }
  };
  const platformImageUploadHandler = async (e) => {
    try {
      const renameText = `platformImage-${onboardObject.companyName}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = "Platform Image";
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          token,
          destinationFolder
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            log(response);
            snack("Error Loading Image", "error");
          }
        } else {
          // Success
          setOnboardObject({
            ...onboardObject,
            platformImageLocation: response.location,
          });
          snack("Image successfully uploaded", "success");
        }
      } else {
        snack("Please upload file", "error");
      }
    } catch (error) {
      log(error);
    }
  };

  const faviconImageUploadHandler = async (e) => {
    try {
      const renameText = `favicon-${onboardObject.companyName}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = "Favicon Image";
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          token,
          destinationFolder
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            snack("Error Loading Image. Must be a SVG file.", "error");
          }
        } else {
          // Success
          setOnboardObject({
            ...onboardObject,
            faviconImageLocation: response.location,
          });
          snack("Image successfully uploaded", "success");
        }
      } else {
        snack("Please upload SVG file", "error");
      }
    } catch (error) {
      log(error);
    }
  };

  const verifyPayment = async () => {
    try {
      const payload = await tokenize();
      if (!onboardObject.firstName || !onboardObject.lastName) {
        throw new Error("Error found with verifying payment");
      }

      if (payload.nonce) {
        return payload.nonce;
      }
    } catch (err) {
      handleError(err);
    }
    return false;
  };

  const handlePaymentSubmit = async (nonce) => {
    try {
      if (nonce) {
        //Submit to braintree
        const formattedPhoneNum = onboardObject.phoneNum
          .split("(")
          .join("")
          .split(")")
          .join("")
          .split(" ")
          .join("")
          .split("-")
          .join("");

        const actCode = onboardObject.activationCode.split("-").join("");

        const response = await fetch(
          process.env.REACT_APP_API_URL + "/payment/create-account",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fname: onboardObject.firstName,
              lname: onboardObject.lastName,
              userToken: token,
              nonce: nonce,
              street: onboardObject.street,
              city: onboardObject.city,
              state: onboardObject.state,
              zipcode: onboardObject.zip,
              resellerCode: actCode,
              resellerId: onboardObject.resellerId,
              company: onboardObject.companyName,
              logo: onboardObject.platformImageLocation,
              favicon: onboardObject.faviconImageLocation,
              phoneNum: formattedPhoneNum,
              discount: onboardObject.discount,
            }),
          }
        );

        const json = await response.json();

        // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        // await delay(3000);

        log("Looking at json response ", json);
        if (json.success) {
          const { token, userInfo } = json.data;
          setToken(token);
          setUserInfo(userInfo);

          snack("Account created successfully", "success");

          // //Add the user location into the system
          try {
            const data = {
              street: onboardObject.street,
              city: onboardObject.city,
              state: onboardObject.state,
              zipcode: onboardObject.zip,
              name: onboardObject.companyName,
              primary: false,
              lat: onboardObject.lat,
              lng: onboardObject.lng,
            };

            log("Running the location add api reqeust");
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/location/add`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: token,
                },
                body: JSON.stringify(data),
              }
            );
            const json = await response.json();

            log("Looking at json ", json);

            if (json.success) {
              log("Returning true");
              return true;
            } else {
              json.errors.forEach((err) => {
                snack(err.msg, "error");
                if (err.type === "token") setLogoutState(true);
              });
              log("Returning false");
              return false;
            }
          } catch (apiError) {
            snack("Unable to complete request", "error");
          }
        } else {
          snack(json.errors[0].msg || json.errors[0], "error");
        }
      }
      // Reset error state;
      setErrorState({
        ...errorState,
        firstName: false,
        lastName: false,
        number: false,
        expirationDate: false,
        cvv: false,
        postalCode: false,
      });
      return false;
    } catch (err) {
      handleError(err);
      return false;
    }
  };

  useEffect(() => {
    if (clientToken) return;
    let isCancelled = false;
    let count = 0;
    const getToken = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/payment/token",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        if (json.success) {
          !isCancelled && setClientToken(json.data.token);
        } else {
          count++;
          if (count <= 3) {
            getToken();
          } else {
            snack(
              "Unable able to get token. Please refresh the page.",
              "error"
            );
            json.errors.forEach((err) => {
              if (err.type === "token") {
                snack(err.msg, "error");
                setLogoutState(true);
              }
            });
          }
        }
      } catch (err) {
        log("Something went wrong getting client token", err);
      }
    };

    getToken();

    return () => {
      isCancelled = true;
    };
  }, []);

  const navigateAndOpenOnboard = () => {
    setAppState({
      ...appState,
      auth: {
        loggedIn: true,
        token,
        userInfo,
      },
    });
    setOpenOnboardModal(true);
    handleClose();
    navigate("/");
  };

  const [agreeButtonState, setAgreeButtonState] = useState(false)

  const reachBottomFunction = () => {
    setAgreeButtonState(true);
  }
  return (
    <div>
      <ResponsiveDialog
        openState={openState}
        handleCancel={handleClose}
        handleFinish={() => {
          navigateAndOpenOnboard();
        }}
        title="WELCOME TO MISENSORS"
        stepsArray={[
          {
            label: "Terms and conditions",
            child: <TermAndConditions reachBottomFunction={reachBottomFunction}/>,
            nextButtonName: "I agree",
            nextButtonActive: agreeButtonState
          },
          {
            label: "ACTIVATION CODE",
            nextFunction: handleChangeActivationState,
            backFunction: handleClose,
            showProgress: true,
            validator: !onboardObject.activationState,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Enter your activation code
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    Locate your activation code on the insert card of your
                    MiSensors Package.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="activationCode"
                    value={onboardObject.activationCode}
                    className={classes.textField}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder="Activation Code *"
                    error={errorState.activationCode}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Activating your account
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    MiSensors is currently configuring your activation code into
                    the platform. This should take less than a minute.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "COMPANY INFO",
            nextFunction: handleCompanyInfo,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Enter company information
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    Enter the Name, Street, City, State and Zipcode information
                    for your company below.
                  </Box>
                </Grid>
                <LocationForm
                  addCompanyField={true}
                  companyName={onboardObject.companyName}
                  setCompanyName={(value) => {
                    setOnboardObject((prevState) => {
                      return { ...prevState, companyName: value };
                    });
                  }}
                  companyNameError={errorState.companyName}
                  street={onboardObject.street}
                  setStreet={(value) => {
                    setOnboardObject((prevState) => {
                      return { ...prevState, street: value };
                    });
                  }}
                  streetError={errorState.street}
                  city={onboardObject.city}
                  setCity={(value) => {
                    setOnboardObject((prevState) => {
                      return { ...prevState, city: value };
                    });
                  }}
                  cityError={errorState.city}
                  state={onboardObject.state}
                  setState={(value) => {
                    setOnboardObject((prevState) => {
                      return { ...prevState, state: value };
                    });
                  }}
                  stateError={errorState.state}
                  zipcode={onboardObject.zip}
                  setZipcode={(value) => {
                    setOnboardObject((prevState) => {
                      return { ...prevState, zip: value };
                    });
                  }}
                  zipcodeError={errorState.zip}
                  classes={classes}
                />
              </Grid>
            ),
          },
          {
            label: "COMPANY LOGO", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Upload Company Logos
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    Upload your main logo and logo icon to customize the
                    MiSensors software to your brand.
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" style={{ position: "relative" }}>
                    {!onboardObject.platformImageLocation ? (
                      <ImageButton
                        image={
                          theme.palette.type === "light"
                            ? "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg"
                            : "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg"
                        }
                        action="UPLOAD"
                        text="MAIN LOGO"
                        component="label"
                        uploadHandler={platformImageUploadHandler}
                      />
                    ) : (
                      <>
                        <ImageButton
                          image={onboardObject.platformImageLocation}
                          action="UPLOAD"
                          text="MAIN LOGO"
                          component="label"
                          uploadedImg={false}
                          uploadHandler={platformImageUploadHandler}
                        />
                        <IconButton
                          aria-label="delete"
                          style={{ position: "absolute", right: 0 }}
                          size="small"
                          onClick={() =>
                            setOnboardObject({
                              ...onboardObject,
                              platformImageLocation: "",
                            })
                          }
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="flex" style={{ position: "relative" }}>
                    {!onboardObject.faviconImageLocation ? (
                      <ImageButton
                        image={
                          theme.palette.type === "light"
                            ? "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon.svg"
                            : "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon+-+DarkMode.svg"
                        }
                        action="UPLOAD"
                        text="FAVICON"
                        component="label"
                        uploadHandler={faviconImageUploadHandler}
                      />
                    ) : (
                      <>
                        <ImageButton
                          image={onboardObject.faviconImageLocation}
                          action="UPLOAD"
                          text="FAVICON"
                          component="label"
                          uploadedImg={false}
                          uploadHandler={faviconImageUploadHandler}
                        />
                        <IconButton
                          aria-label="delete"
                          style={{ position: "absolute", right: 0 }}
                          size="small"
                          onClick={() =>
                            setOnboardObject({
                              ...onboardObject,
                              faviconImageLocation: "",
                            })
                          }
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            ),
          },
          {
            label: "PAYMENT INFORMATION",
            nextFunction: handlePaymentSubmit,
            verifyBeforeNextPageRender: verifyPayment,
            showProgress: true,
            validator: true,
            nextButtonName: "SUBMIT",
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Enter payment information
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    Enter your payment information for future billing charges
                    and subscription renewals.
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="firstName"
                    value={onboardObject.firstName}
                    className={classes.textField}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder="First name"
                    error={errorState.firstName}
                    inputRef={numberField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lastName"
                    value={onboardObject.lastName}
                    className={classes.textField}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder="Last name"
                    error={errorState.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    value={onboardObject.phoneNum}
                    placeholder="Phone Number"
                    label="Phone Number"
                    defaultCountry={"us"}
                    preferredCountries={["us"]}
                    disableAreaCodes={true}
                    countryCodeEditable={false}
                    autoFormat={true}
                    onChange={(e) =>
                      setOnboardObject({ ...onboardObject, phoneNum: e })
                    }
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Braintree
                    className={isBtReady ? "" : "disabled"}
                    authorization={clientToken}
                    onAuthorizationSuccess={onAuthorizationSuccess}
                    onDataCollectorInstanceReady={onDataCollectorInstanceReady}
                    onError={handleError}
                    onCardTypeChange={onCardTypeChange}
                    getTokenRef={(ref) => setTokenizeFunc(() => ref)}
                    styles={{
                      input: {
                        "font-size": "inherit",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="credit-card-number">
                            Card Number
                          </InputLabel>
                          <HostedField
                            type="number"
                            className={`${
                              focusedFieldName === "number"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${errorState.number ? classes.error : ""} `}
                            placeholder="xxxx xxxx xxxx xxxx"
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="expirationDate">
                            Expiration date
                          </InputLabel>
                          <HostedField
                            type="expirationDate"
                            placeholder="MM/YY"
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            className={`${
                              focusedFieldName === "expirationDate"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${
                              errorState.expirationDate ? classes.error : ""
                            }`}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="cvv">Security Code</InputLabel>
                          <HostedField
                            type="cvv"
                            ref={cvvField}
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            className={`${
                              focusedFieldName === "cvv"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${errorState.cvv ? classes.error : ""}`}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="expirationDate">
                            Zip Code
                          </InputLabel>
                          <HostedField
                            type="postalCode"
                            placeholder="Zip Code"
                            ref={postalCodeField}
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            onValidityChange={(e) => log(e)}
                            className={`${
                              focusedFieldName === "postalCode"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${errorState.postalCode ? classes.error : ""}`}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Braintree>
                </Grid>
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Activating your account
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    Misensors is currently configuring your account into the
                    platform. This should take less than a minute.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "COMPLETE", // Because is the finish
            // nextFunction: navigateAndOpenOnboard,

            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Success! You have completed your account setup
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box>
                    {/* You may now begin to setup your locations, gateways and
                    sensors. Select “Add Location” below to begin setting up
                    your MiSensors. */}
                    Please click Finish and login to your account.
                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                  <Box display="flex">
                    <ImageButton image={marker} action="ADD" text="LOCATION" />
                  </Box>
                </Grid> */}
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
