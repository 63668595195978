import React, { useState, useContext, useEffect } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import {
  Button,
  Box,
  Grid,
  TextField,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import GenericDialog from "../Generic/GenericDialog";

import LocationSelect from "../common/LocationSelect";
import LocationFormModal from "../Locations/LocationFormModal";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  locationSelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Gateway Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function EditGatewayFormModal(props) {
  const log = window.log('EditGatewayFormModal');

  const {
    appState,
    setLogoutState,
    setOpenEditGatewayModal,
    openEditGatewayModal,
    setOpenLocationModal,
    snack,
  } = useContext(AppContext);
  const {
    getGateway,
    gatewayEntryId,
    editName,
    setEditName,
    gatewayName,
    locationEntryId,
  } = props;
  log("Looking at the edit name " + editName);
  const classes = useStyles();
  //Gateway name state hooks
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  //Gateway MAC state hooks
  const [gatewayMac, setGatewayMac] = useState(false);
  const [gatewayMacError, setGatewayMacError] = useState(false);
  //Location ID state hook
  const [locationId, setLocationId] = useState();
  //Submit gateway state hook
  const [submitState, setSubmitState] = useState(false);

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  log("User info", appState.auth.userInfo);

  // const handleCheckNameValue = () => {
  //   if (name) {
  //     return true;
  //   } else {
  //     setNameError(true);
  //     snack("Required Field", "error");
  //     return false;
  //   }
  // };

  const handleChangeGatewayMac = (e) => {
    setGatewayMac(e.target.value);
  };

  useEffect(() => {
    setName(gatewayName);
  }, [gatewayName]);

  useEffect(() => {
    if (!locationEntryId) return;
    setLocationId(locationEntryId);
  }, [locationEntryId]);

  const handleSubmitGateway = async () => {

      log("Gateway_Submit: Inside of handle gateway");
      log("Gateway_Submit: Looking at gatewayEntryID " + gatewayEntryId);
    
    try {
      const data = {
        gatewayMAC: gatewayMac,
        editName,
        locationId,
        userId: appState.auth.userInfo.id,
        gatewayEntryId: gatewayEntryId,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/edit`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      log("Gateway_Submit: Looking at the json response ", json);
      
      if (json.success) {
        setNameError(false);
        setGatewayMacError(false);
        getGateway();
        return true;
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          if (error.type === "gatewayMac") {
            setGatewayMacError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleClose = () => {
    setOpenEditGatewayModal(false);
    getGateway();
  };

  return (
    <div>
      <LocationFormModal />
      <GenericDialog
        openState={openEditGatewayModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title="EDIT GATEWAY"
        stepsArray={[
          {
            label: "GATEWAY NAME",
            // nextFunction: handleCheckNameValue,
            // showProgress: false,
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Edit your gateway name
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight="fontWeightLight"
                  fontSize={16}
                  style={{ marginTop: 10 }}
                >
                  <Box className={classes.fontColor}>
                    Giving your gateway a name will help you locate and manage
                    all of your gateways and devices.
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 25 }}>
                  <TextField
                    className={classes.textField}
                    error={nameError}
                    fullWidth
                    name="name"
                    value={editName}
                    variant="outlined"
                    placeholder="Gateway Name"
                    onChange={(e) => handleNameChange(e)}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "GATEWAY LOCATION",
            nextFunction: handleSubmitGateway,
            validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Select the Location
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    Select the location where you have installed your gateway.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => setOpenLocationModal(true)}
                    variant="outlined"
                    color="primary"
                  >
                    NO LOCATION CREATED? ADD A NEW LOCATION HERE
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <LocationSelect
                    returnValue={locationId}
                    setReturnValue={setLocationId}
                    width={"100%"}
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightBold"
                    fontSize={20}
                    className={classes.fieldPadding}
                  >
                    Updating gateway into platform
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight="fontWeightLight"
                  fontSize={16}
                  style={{ marginTop: 10 }}
                  className={classes.fieldPadding}
                >
                  <Box className={classes.fontColor}>
                    MiSensors is currently configuring your gateway into the
                    platform. This should take less than a minute.
                  </Box>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10 }}
                    className={classes.fieldPadding}
                  >
                    <LinearProgress />
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            label: "EDIT COMPLETE", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Success! Your gateway has been updated.
                  </Box>
                  <Box
                    className={classes.fontColor}
                    style={{ marginTop: "1rem" }}
                  >
                    Select close to return to the gateway page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
