import React from "react";
import "./range_alert_styles/alert_range.css";

import { makeStyles } from "@material-ui/core";

export default function RangeAlertDisplay(props) {
  const log = window.log('RangeAlertDisplay');

  const markerWidth = 30;

  const alertPointDiameter = 20;

  const {
    minValue,
    maxValue,
    minRange,
    maxRange,
    alertValue,
    barWidth = 350, // float , barWidth in Pixels
    measurementUnit,
    rangeType,
  } = props;
  log(props);
  const barMaxOffset = barWidth - alertPointDiameter;

  // let rangeAreaWidth = 190;

  const calculatePositions = (
    fullbarWidth,
    _minValue,
    _maxValue,
    _minRange,
    _maxRange,
    _alertValue
  ) => {
    const valueRangeMax = Math.abs(_minValue) + Math.abs(_maxValue);

    const valueToPercentage = (value) =>
      ((value + Math.abs(_minValue)) * 100) / valueRangeMax;

    const barOnePercent = fullbarWidth / 100;

    const minRangePosition = parseFloat(
      (valueToPercentage(_minRange) * barOnePercent).toFixed(2)
    );

    const maxRangePosition = parseFloat(
      (valueToPercentage(_maxRange) * barOnePercent).toFixed(2)
    );

    const alertValuePosition = parseFloat(
      (valueToPercentage(_alertValue) * barOnePercent).toFixed(2)
    );

    return {
      minRangePosition,
      maxRangePosition,
      alertValuePosition,
      rangeAreaWidth: maxRangePosition - minRangePosition,
    };
  };

  const {
    minRangePosition,
    maxRangePosition,
    alertValuePosition,
    rangeAreaWidth,
  } = calculatePositions(
    barWidth,
    minValue,
    maxValue,
    minRange,
    maxRange,
    alertValue
  );

  const useStyles = makeStyles((theme) => ({
    rangeMinValue: {
      transform: `translateX(${minRangePosition}px)`,
    },
    rangeMaxValue: {
      transform: `translateX(${maxRangePosition}px)`,
    },
    rangeArea:
      rangeAreaWidth === 0
        ? {
            width: `5px`,
            transform: `translateX(${minRangePosition + markerWidth / 2}px)`,
          }
        : {
            width: `${rangeAreaWidth}px`,
            transform: `translateX(${minRangePosition + markerWidth / 2}px)`,
          },
    alertValue: {
      transform: `translateX(${alertValuePosition}px)`,
    },
    rangeComponent: {
      width: `${barWidth}px`,
      minWidth: `${barWidth}px`,
      backgroundColor: theme.palette.background.paper,
    },
    barMaxValue: {
      transform: `translateX(${barMaxOffset - markerWidth / 2}px)`,
    },
    alertPointValue: {
      top: -(alertPointDiameter / 2),
      height: alertPointDiameter,
      width: alertPointDiameter,
    },
    markerWidth: {
      width: `${markerWidth}px`,
    },
  }));

  const classes = useStyles();

  return (
    <div className={`out_of_range_alert_component ${classes.rangeComponent}`}>
      <div className="range_container">
        <span className="bar"></span>
        <span className={`range_values bar--min_value ${classes.markerWidth}`}>
          {/* {minValue}
          {measurementUnit} */}
        </span>
        <span
          className={`range_values bar--max_value ${classes.barMaxValue} ${classes.markerWidth}`}
        >
          {/* {maxValue}
          {measurementUnit} */}
        </span>
        <span className={`range_area ${classes.rangeArea}`}></span>
        <span
          className={`range_values area_marker ${classes.rangeMinValue} ${classes.markerWidth}`}
        >
          {minRange}
          {measurementUnit}
        </span>
        <span
          className={`range_values area_marker ${classes.rangeMaxValue} ${classes.markerWidth}`}
        >
          {maxRange}
          {measurementUnit}
        </span>
        <div className={`value--container ${classes.alertValue}`}>
          <span className={`alert_point ${classes.alertPointValue}`}></span>
          <div className="tooltip_value">
            {alertValue}
            {measurementUnit}
          </div>
        </div>
      </div>
      <div className="alert_message">{rangeType}</div>
    </div>
  );
}
