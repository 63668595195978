import React, { useState, useEffect, useContext } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

// Generic Imports
import BreadCrumb from "../Generic/BreadCrumb";

// Icon import
import SettingsIcon from "@material-ui/icons/Settings";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// Component imports
import {
  Button,
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Switch,
  IconButton,
  Tooltip,
  Link,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import ChangeSubscription from "./ChangeSubscription";
import RenewSubscription from "./RenewSubscription";
import SkeletonForm from "../common/SkeletonForm";

import { AppContext } from "../../AppContext";

import LoadingSpinner from "../Generic/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.75, 4),
  },
  card: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  cardSubtitle: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "17px",
  },
  cardElement: {
    minWidth: "100px",
  },
  table: {
    margin: theme.spacing(4, 0),
    "& tr:nth-of-type(odd)": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor:
        theme.palette.type === "light" ? "white" : theme.palette.grey[800],
    },
  },
}));

const SquareSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(24px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
    borderRadius: 8,
    marginTop: "2px",
  },
  track: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  edgeStart: {},
  edgeEnd: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      disabled={props.disabled}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        edgeStart: classes.edgeStart,
        edgeEnd: classes.edgeEnd,
      }}
      {...props}
    />
  );
});

const AccountBilling = () => {
  const log = window.log("AccountBilling");

  const classes = useStyles();
  const { appState, setLogoutState, snack } = useContext(AppContext);

  const [
    changeSubscriptionOpenState,
    setchangeSubscriptionOpenState,
  ] = useState(false);
  const [autoRenew, setAutoRenew] = useState(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [subscriptionInfoLoadState, setSubscriptionInfoLoadState] = useState(
    false
  );

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const openChangeSubscriptionModal = () => {
    setchangeSubscriptionOpenState(true);
  };

  const closeChangeSubscriptionModal = () => {
    setchangeSubscriptionOpenState(false);
  };

  /**
   * table options
   */
  const tableOptions = {
    // onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    selectableRows: "none",
    print: false,
    download: true,
    responsive: "scrollMaxHeight",
    onDownload: (buildHead, buildBody, columns, data) => {
      let head = [];
      for (const header of columns) {
        if (header.label === "Document Download") {
          continue;
        }
        head.push(header);
      }

      let body = [];
      for (const row of data) {
        row.data.pop();
        body.push(row);
      }

      return buildHead(head) + buildBody(body);
    },
    downloadOptions: {
      filename: "misensors_invoice.csv",
      separator: ",",
    },
  };

  const tableColumns = [
    {
      name: "invoiceNumber",
      label: "Invoice Number",
      options: {
        filter: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
      },
    },
    {
      name: "purchaseDate",
      label: "Purchase Date",
      options: {
        filter: true,
      },
    },
    {
      name: "invoiceTotal",
      label: "Invoice Total",
      options: {
        filter: true,
      },
    },
    {
      name: "documentDownload",
      label: "Document Download",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              style={{
                margin: -10,
              }}
            >
              <IconButton
                aria-label="download"
                color="primary"
                component={Link}
                href={value.downloadLink}
                download={value.label + ".csv"}
              >
                <CloudDownloadIcon />
              </IconButton>

              <Typography>{`${value.label}`}</Typography>
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    reloadPageData();
  }, []);

  //Load or Reload Subscription info on load and after updating subscription plan
  const reloadPageData = async () => {
    await getSubscriptionInfo();
    await getTransactions();
    setInitialPageLoad(false);
  };

  const getSubscriptionInfo = async () => {
    setDisableSwitch(true);
    setSubscriptionInfoLoadState(true);
    try {
      const fetchSubscription = await fetch(
        process.env.REACT_APP_API_URL + "/payment/get-subscription",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await fetchSubscription.json();
      if (json.success) {
        setSubscriptionInfo(json.data);
        if (json.data.pendingSubscription) {
          setAutoRenew(true);
        } else {
          setAutoRenew(json.data.neverExpires);
        }
      } else {
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
        throw new Error(json.errors[0]);
      }
    } catch (err) {
      snack(
        "Unable to find subscription info at this time. Please refresh the page and try again.",
        "error"
      );
    }
    setDisableSwitch(false);
    setSubscriptionInfoLoadState(false);
  };

  const getTransactions = async () => {
    try {
      const fetchTransactions = await fetch(
        process.env.REACT_APP_API_URL + "/transaction/list",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await fetchTransactions.json();
      if (json.success) {
        const planPrices = listOfPlans.map((plan) => plan.price);
        const transactionList = json.data.transactions.map((transaction) => {
          let type = null;
          if (planPrices.includes(Number(transaction.amount))) {
            type = "Annual";
          } else {
            type = "Upgrade";
          }

          let csvRows = [
            ["Invoice Number", "Type", "Purchase Date", "Invoice Total"],
          ];
          csvRows.push([
            transaction.id,
            type,
            new Date(transaction.createdAt).toDateString(),
            "$" + transaction.amount,
          ]);
          let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
          csvRows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\n";
          });

          const encodedUri = encodeURI(csvContent);

          return {
            invoiceNumber: transaction.id,
            purchaseDate: new Date(transaction.createdAt).toLocaleDateString(
              undefined,
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            ),
            invoiceTotal: "$" + transaction.amount,
            type,
            documentDownload: {
              label: transaction.id,
              downloadLink: encodedUri,
            },
          };
        });
        setTransactions(transactionList);
      } else {
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
        throw new Error(json.errors[0]);
      }
    } catch (err) {
      log(err);
      snack(err.message, "error");
    }
  };

  const handleAutoRenew = async () => {
    if (!subscriptionInfo.planInfo) {
      snack(
        "Could not find your subscription info. Unable to process request at this time.",
        "error"
      );
      return;
    }
    setDisableSwitch(true);
    try {
      const autoRenewResponse = await fetch(
        process.env.REACT_APP_API_URL + "/payment/auto-renew",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify({
            status: !autoRenew,
          }),
        }
      );

      const json = await autoRenewResponse.json();

      if (json.success) {
        if (!json.data.renewStatus) {
          getSubscriptionInfo();
        }
        setAutoRenew(json.data.renewStatus);
        snack("Successfully updated the subscription", "success");
      } else {
        throw new Error(json.errors[0]);
      }
    } catch (err) {
      log(err);
      snack("Unable to process request at this time.", "error");
    }
    setDisableSwitch(false);
  };

  const renderSubscriptionEndDate = () => {
    if (autoRenew) {
      if (!subscriptionInfo.nextBillingDate) return "";
      const day = new Date(subscriptionInfo.nextBillingDate).getDate() + 1;
      const month = new Date(subscriptionInfo.nextBillingDate).getMonth() + 1;
      const year = new Date(subscriptionInfo.nextBillingDate).getFullYear();
      const formattedDate = new Date(
        `${year}-${month}-${day}`
      ).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      let billingAmount = subscriptionInfo.nextBillAmount;
      if (
        subscriptionInfo.pendingSubscription &&
        subscriptionInfo.pendingSubscription.price
      ) {
        billingAmount = subscriptionInfo.pendingSubscription.price;
      }
      return `${formattedDate} ($${billingAmount})`;
    } else {
      if (!subscriptionInfo.paidThroughDate) return "";
      const day = new Date(subscriptionInfo.paidThroughDate).getDate() + 1;
      const month = new Date(subscriptionInfo.paidThroughDate).getMonth() + 1;
      const year = new Date(subscriptionInfo.paidThroughDate).getFullYear();
      return new Date(`${year}-${month}-${day}`).toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    return "N/A";
  };

  const renderPendingSubscriptionInfo = () => {
    if (!subscriptionInfo.pendingSubscription) return;

    let pendingPlan = null;
    for (const plan of listOfPlans) {
      if (plan.id === subscriptionInfo.pendingSubscription.planId) {
        pendingPlan = plan;
        break;
      }
    }

    if (!pendingPlan) return;
    return <Typography>{pendingPlan.text}</Typography>;
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <React.Fragment>
      <ChangeSubscription
        openState={changeSubscriptionOpenState}
        handleClose={closeChangeSubscriptionModal}
        currentSubscription={subscriptionInfo}
        reload={reloadPageData}
        token={appState.auth.token}
        listOfPlans={listOfPlans}
      />
      {/* <RenewSubscription /> */}
      <div className={classes.topBar}>
        <BreadCrumb
          title="Account Settings - Billing"
          leadingIcon={
            <SettingsIcon style={{ fontSize: 32 }} color="primary" />
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={openChangeSubscriptionModal}
          className={classes.button}
        >
          CHANGE SUBSCRIPTION
        </Button>
      </div>
      <Card className={classes.card}>
        <CardContent>
          <Grid container direction="row" justify="space-between">
            <Grid item className={classes.cardElement}>
              <Typography className={classes.cardSubtitle}>
                Subscription
              </Typography>
              {subscriptionInfo.planInfo && !subscriptionInfoLoadState ? (
                <Typography>
                  {subscriptionInfo.planInfo.description || "N/A"}
                </Typography>
              ) : (
                <SkeletonForm styles={{ width: 250, margin: "5px 0 10px" }} />
              )}
              {/* <Typography>
                  1 Year
                </Typography> */}
            </Grid>
            <Grid item className={classes.cardElement}>
              <Typography className={classes.cardSubtitle}>
                Number Of Sensor
              </Typography>
              {subscriptionInfo.numOfSensors ||
              (subscriptionInfo.numOfSensors === 0 &&
                !subscriptionInfoLoadState) ? (
                <Typography>
                  {subscriptionInfo.numOfSensors ||
                  subscriptionInfo.numOfSensors === 0
                    ? subscriptionInfo.numOfSensors
                    : "N/A"}
                </Typography>
              ) : (
                <SkeletonForm styles={{ width: 250, margin: "5px 0 10px" }} />
              )}
            </Grid>
            <Grid item className={classes.cardElement}>
              <Typography className={classes.cardSubtitle}>
                {autoRenew ? "Next Billing" : "Expires"}
              </Typography>
              {subscriptionInfo.nextBillingDate &&
              !subscriptionInfoLoadState ? (
                <Typography>{renderSubscriptionEndDate()}</Typography>
              ) : (
                <SkeletonForm styles={{ width: 250, margin: "5px 0 10px" }} />
              )}
              {subscriptionInfo.pendingSubscription && autoRenew
                ? renderPendingSubscriptionInfo()
                : null}
            </Grid>
            <Grid item className={classes.cardElement}>
              <Grid style={{ display: "flex" }}>
                <Typography className={classes.cardSubtitle}>
                  Auto-Renew
                </Typography>
                <Tooltip
                  placement="top"
                  title="Pending subscriptions will be cancelled when turning off auto renewal."
                >
                  <HelpOutlineIcon style={{ marginLeft: 5 }} />
                </Tooltip>
              </Grid>
              <SquareSwitch
                checked={autoRenew}
                onChange={handleAutoRenew}
                name="autoRenew"
                disabled={disableSwitch}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <MUIDataTable
        className={classes.table}
        options={tableOptions}
        data={transactions}
        columns={tableColumns}
        title={
          <Grid container>
            <Typography style={{ fontWeight: "bold" }}>
              YOUR INVOICES
            </Typography>
          </Grid>
        }
      ></MUIDataTable>
    </React.Fragment>
  );
};

export default AccountBilling;

const listOfPlans = [
  {
    tier: 1,
    id: "MSLIC0612",
    price: 49,
    sensors: 6,
    name: "MS-LIC-06-12",
    description: "MiSensors Annual License (Up to 6 Sensors)",
    text: "(Up to 6 Sensors)",
  },
  {
    tier: 2,
    id: "MSLIC1212",
    price: 69,
    sensors: 12,
    name: "MS-LIC-12-12",
    description: "MiSensors Annual License (Up to 12 Sensors)",
    text: "(Up to 12 Sensors)",
  },
  {
    tier: 3,
    id: "MSLIC2512",
    price: 99,
    sensors: 25,
    name: "MS-LIC-25-12",
    description: "MiSensors Annual License (Up to 25 Sensors)",
    text: "(Up to 25 Sensors)",
  },
  {
    tier: 4,
    id: "MSLIC5012",
    price: 149,
    sensors: 50,
    name: "MS-LIC-50-12",
    description: "MiSensors Annual License (Up to 50 Sensors)",
    text: "(Up to 50 Sensors)",
  },
  {
    tier: 5,
    id: "MSLIC10012",
    price: 249,
    sensors: 100,
    name: "MS-LIC-100-12",
    description: "MiSensors Annual License (Up to 100 Sensors)",
    text: "(Up to 100 Sensors)",
  },
];
