import React from "react";
import moment from "moment";
import { Box, Grid, Typography } from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PowerOffIcon from "../../img/icons/powerIcon.svg";
import CheckCircleIcon from "../../img/icons/checkCircleIcon.svg";
import { Power, PowerOff } from "@material-ui/icons";
import { grey, red, green } from "@material-ui/core/colors";

/**
 * returns the bacjground color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int} theme, lightTone=700, darkTone=300
 */
const rowColor = ({ theme, index }) => {
  if (theme.palette.type === "light") {
    return index % 2 === 0 ? grey[100] : "white";
  } else {
    return index % 2 === 0 ? grey[900] : grey[800];
  }
};
/**
 * Device Container with alertStatus
 *
 * @props
 * index: integer
 * deviceName: String
 * locationName: String
 * gatewayName: String
 * alertStatus: Bool
 * lastMessageTime: integer (Unix TimeStamp)
 */
function DeviceContainer(props) {
  const theme = useTheme();
  const {
    index, // Index use for the color
    deviceName, // Name of the device
    locationName, // Name of the location for the device
    gatewayName, // Gateway name of the device
    alertStatus, // Alert status that define the icon and the format of the date
    lastMessageTime, //How many days since the last message
    entity,
    alertName,
    noResolvedAlerts,
    noOfflineSensors,
  } = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundColor: rowColor({ theme, index }),
      paddingLeft: 35,
      paddingRight: 40,
      paddingTop: 10,
      paddingBottom: 10,
    },
    titleText: {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    subTitleText: {
      fontSize: 15,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
      textAlign: "right",
    },
    descriptionText: {
      fontSize: 12,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    timeText: {
      fontSize: 14,
      color: alertStatus ? green[400] : red[400],
      textAlign: "right",
    },
    icon: {
      margin: theme.spacing(0, 0, 1.5, 0),
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(3, 0, 1.5, -1.5),
      },
    },
  }));

  const parseTime = () => {
    let date = Date.now() / 1000 - lastMessageTime;
    if (date < 3600) {
      const minutes = Math.round(date % 60);
      return `${minutes} Minutes`;
    } else if (date < 86400) {
      const hours = Math.round(date % 3600);
      return `${hours} Hours`;
    } else {
      const days = Math.round(date % 86400);
      return `${days} Days`;
    }
  };

  const parseIntToDate = () => {
    return moment(lastMessageTime * 1000).format("MMMM Do - h:mm a");
  };

  const classes = useStyles();

  return noResolvedAlerts || noOfflineSensors ? (
    <Box
      style={{
        backgroundColor: rowColor({ theme, index }),
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Grid container justify="center">
        <Grid item xs={12}>
          {entity === "alerts" ? (
            <Box
              className={classes.titleText}
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {alertName}
            </Box>
          ) : (
            <Box
              className={classes.titleText}
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              {deviceName}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Grid container>
        <Grid style={{ maxWidth: 50 }} item xs={2}>
          {alertStatus ? (
            <img
              src={CheckCircleIcon}
              style={{ height: 32, width: 32 }}
              className={classes.icon}
              alt="alert icon"
            />
          ) : (
            <img
              src={PowerOffIcon}
              style={{ height: 32, width: 32 }}
              className={classes.icon}
              alt="alert icon"
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {entity === "alerts" ? (
            <Box className={classes.titleText}>{alertName}</Box>
          ) : (
            <Box className={classes.titleText}>{deviceName}</Box>
          )}
          {entity === "alerts" ? (
            <Box className={classes.descriptionText}>
              {locationName} - {deviceName}
            </Box>
          ) : (
            <Box className={classes.descriptionText}>
              {locationName} - {gatewayName}
            </Box>
          )}
        </Grid>
        <Grid style={{ marginLeft: "auto" }} item xs={4}>
          <Box className={classes.subTitleText}>
            Time {alertStatus ? "Resolved" : "Offline"}
          </Box>
          <Box className={classes.timeText}>{`${lastMessageTime} Days`}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DeviceContainer;
