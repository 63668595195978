const path = require("path");

const uploadImage = async (
  selectedFile,
  data,
  text,
  token,
  folderDestination
) => {
  const log = window.log('uploadImage');

  //rename file
  const newFileName = text + path.extname(selectedFile.name);

  data.append("folderDestination", folderDestination);

  data.append("image", selectedFile, newFileName);

  log(
    "Upload image: Looking at data values " +
      selectedFile +
      ":" +
      data +
      ":" +
      text +
      ":" +
      folderDestination
  );
  const request = await fetch(
    process.env.REACT_APP_API_URL + `/image/uploadImage`,
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        authorization: token,
      },
      body: data,
    }
  );
  const json = await request.json();

  return json;
};

export default uploadImage;
