import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import fetchAll from "./api/fetchAll";
import FirmwareSelectSkeleton from "./FirmwareSelectSkeleton";
import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: " 0 auto",
  },
  textField: {
    margin: "5px 0",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: 200,
  },
  gatewayButton: {
    marginTop: "1rem",
  },
  formControl: {
    marginTop: 8,
    minWidth: 200,
    width: "80%",
  },
  select: {
    padding: theme.spacing(3, 2),
  },
  selectMenu: {
    width: "105%",
  },
}));

const FirmwareSelect = (props) => {
  const { returnValue, setReturnValue, onClick, disabled } = props;
  const { setLogoutState, snack } = useContext(AppContext);

  const inputLabel = React.useRef(null);

  const classes = useStyles();

  const [firmwareNames, setFirmwareNames] = useState();
  const [firmwareValue, setFirmwareValue] = useState();

  const getFirmwareNames = async () => {
    const json = await fetchAll("firmware", null, null);

    if (json.success) {
      setFirmwareNames(json);
    } else {
      json.errors.forEach((err) => {
        snack(err.msg, "error");
        if (err.type === "token") setLogoutState(true);
      });
    }
  };

  useEffect(() => {
    getFirmwareNames();
  }, []);

  return (
    <div>
      {firmwareNames ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
            Select Firmware
          </InputLabel>
          <Select
            className={classes.selectMenu}
            labelid="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={firmwareValue}
            onChange={(e) => {
              setReturnValue(e.currentTarget.innerText);
              setFirmwareValue(e.currentTarget.value);
            }}
            labelWidth={125}
          >
            {firmwareNames.map((item, index) => (
              <MenuItem value={index} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            className={classes.gatewayButton}
            disabled={disabled}
            variant={"contained"}
            onClick={onClick}
          >
            Flash to gateway
          </Button>
        </FormControl>
      ) : (
        <FirmwareSelectSkeleton />
      )}
    </div>
  );
};

export default FirmwareSelect;
