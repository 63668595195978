import React, { forwardRef, useState, useImperativeHandle } from "react";
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  MobileStepper,
  withWidth,
} from "@material-ui/core/";

import logo from "../../img/MiSensorsLogo.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// import withWidth from "@material-ui/core/withWidth";

const isSmall = (_width) => {
  switch (_width) {
    case "sm":
      return true;
    case "xs":
      return true;
    default:
      return false;
  }
};

/**
 * 
 * @param {Object} props
 * @props
 * stepsArray: Array of Objects, [{
  label: String,
  child: ReactComponent,
  nextFunction: Function,
  backFunction: Function,
  isCountlessStep: Boolean, if this is true the child will be shown but it wont be counted in the stepper
  validator: Boolean,
  skipStep: Boolean,
  nextStepCallback: Function, a function that executes after nextFunction , and before the next step
}]
* title: String
* finishCallback: Function
*/
const StepperForm = forwardRef((props, ref) => {
  const log = window.log("StepperForm");

  useImperativeHandle(ref, () => ({
    nextStep() {
      setActiveStep(activeStep + 1);
    },
  }));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);

  const [stepperVisualStep, setStepperVisualStep] = useState(0);

  const {
    width, // withWidth MaterialUI property
    stepsArray, // Array of objects that have te label and the components for the step
    title, // Title of the form
    finishCallback, // Callback when finished
    cancelCallback, // Callback when cancel
    handleFinish,
  } = props;
  // log(activeStep);
  // log(stepsArray[activeStep]);
  const buttonContainerHeight = stepsArray[activeStep]?.showSkipButton
    ? 110
    : 60;

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      height: "85vh",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        height: "100vh",
      },
    },
    stepperContainer: {
      backgroundColor: theme.palette.background.paper,
      minWidth: "280px",
      height: "100%",
      marginLeft: 30,
      [theme.breakpoints.down("sm")]: {
        height: "130px",
        marginLeft: 0,
      },
    },
    formContainer: {
      backgroundColor:
        theme.palette.type === "light"
          ? "#F4F5F5"
          : theme.palette.background.default,
      overflow: "auto",
      // minWidth: "50vw",
      width: "100vw",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100% - 175px)",
      },
    },
    mobileStepper: {
      backgroundColor: theme.palette.background.paper,
    },
    mobileStepTitle: {},
    stepperWrapper: {
      height: "80%",
      maxHeight: "550px",
      overflow: "auto",
      [theme.breakpoints.down("sm")]: {
        height: "60px !important",
      },
    },
    childContent: {
      width: "100%",
      height: `calc(100% - ${buttonContainerHeight}px - 58px)`,
      padding: 70,
      overflowY: "auto",
      transform: "translateY(-33px)",
      [theme.breakpoints.down("sm")]: {
        height: "inherit",
      },
      [theme.breakpoints.down("xs")]: {
        padding: 29,
        transform: "translateY(-12px)",
      },
    },
    buttonsContainer: {
      padding: theme.spacing(1, 0),
      maxHeight: `${buttonContainerHeight}px`,
      width: "99%",
      transform: activeStep !== 0 ? "translateY(-30px)" : "translateY(22px)",
      [theme.breakpoints.down("xs")]: {
        transform: "translateY(0px)",
      },
    },
    stepButtons: {
      padding: theme.spacing(0, 4),
      width: "100%",
      [theme.breakpoints.down("xs")]: {},
    },
    skipStepButtons: {
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(1),
    },
    title: {
      marginTop: 30,
      marginLeft: theme.spacing(2),
      color:
        theme.palette.type === "light"
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        marginLeft: theme.spacing(0),
      },
    },
    subtitle1: {
      color:
        theme.palette.type === "light"
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
    },
    imageContainer: {
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
      },
    },
    imageLogo: {
      objectFit: "contain",
      width: "160px",
      margin: theme.spacing(2),
      marginBottom: 49,
      marginRight: 37,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 45,
      },
    },
    button: {
      padding: theme.spacing(1, 3),
    },
    stepperLabel: {
      color: theme.palette.primary.main,
      fontSize: 14,
    },
    rightButton: {
      width: 114,
      marginRight: 30,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    leftButton: {
      width: 114,
      marginLeft: 36,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    cancelButton: {
      color: theme.palette.error.main,
      width: 114,
      marginLeft: 36,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
      padding: theme.spacing(1, 3),
    },
  }));

  const classes = useStyles();

  /**
   * Function to handle the change of a Step so it can have steps that dont appear in the
   * stepper side bar but are still shown in the view
   * @param {integer} stepNumber, value of the next step
   */
  const handleActiveStep = (stepNumber) => {
    //  calculates if we are adding or substracting steps
    const difference = stepNumber - activeStep;
    setActiveStep(activeStep + difference);

    if (difference > 0) {
      if (!stepsArray[stepNumber].isCountlessStep) {
        setStepperVisualStep(stepperVisualStep + difference);
      }
    } else if (difference < 0) {
      if (!stepsArray[stepNumber + 1].isCountlessStep) {
        setStepperVisualStep(stepperVisualStep + difference);
      }
    }
  };

  const handleNext = async () => {
    log(
      "StepperForm: ActiveStep:StepArrayLength\t" +
        activeStep +
        ":" +
        stepsArray.length
    );

    //If you're on the last step
    if (activeStep === stepsArray.length - 1) {
      log("StepperForm: Last step");
      if (handleFinish) {
        //only for onboard modal
        handleFinish();
      } else if (finishCallback) {
        finishCallback();
      }
    } else {
      log("StepperForm: Not the last step");
      // log(
      //   "StepperForm: Showing condtional value nextFunction\t" +
      //     stepsArray[activeStep].nextFunction
      // );
      log(
        "StepperForm: Showing condtional value validator\t" +
          stepsArray[activeStep].validator
      );
      log(
        "StepperForm: Showing condtional value show progress\t" +
          stepsArray[activeStep].showProgress
      );
      if (
        stepsArray[activeStep].nextFunction &&
        stepsArray[activeStep].validator &&
        stepsArray[activeStep].showProgress
      ) {
        let verifyResult;
        //Do something before page renders to next page
        if (stepsArray[activeStep].verifyBeforeNextPageRender) {
          verifyResult = await stepsArray[
            activeStep
          ].verifyBeforeNextPageRender();
        }

        handleActiveStep(activeStep + 1);
        log("StepperForm: Inside of if statement Active Step:\t" + activeStep);

        let result;
        if (verifyResult === undefined || verifyResult) {
          result = await stepsArray[activeStep].nextFunction(verifyResult);
        }
        log("StepperForm: Looking at the result\t", result);

        // Check if api call was successful, move to next step if true else go back
        if (result) {
          log("StepperForm: Result true incrementing step");
          // setTimeout(() => {
          handleActiveStep(activeStep + 2);
          // handleActiveStep(activeStep + 1);
          // }, 3000);
        } else {
          log("StepperForm: Result false decrementing step");
          // setTimeout(() => {
          handleActiveStep(activeStep);
          setStepperVisualStep(activeStep);

          // handleActiveStep(activeStep - 1);
          // }, 3000);
        }
      } else if (
        stepsArray[activeStep].nextFunction &&
        !stepsArray[activeStep].showProgress
      ) {
        log("StepperForm: Inside of else if Active Step:\t" + activeStep);

        let result = await stepsArray[activeStep].nextFunction();
        // Check if api call was successful, move to next step if true else go back
        if (result) {
          if (stepsArray[activeStep + 1].skipStep) {
            // setActiveStep((prevActiveStep) => prevActiveStep + 2);
            handleActiveStep(activeStep + 2);
          } else {
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            handleActiveStep(activeStep + 1);
          }
        } else {
          log("test failure");
        }
      } else {
        log("StepperForm: Inside of else Active Step:\t" + activeStep);
        if (stepsArray[activeStep + 1].skipStep) {
          // setActiveStep((prevActiveStep) => prevActiveStep + 2);
          handleActiveStep(activeStep + 2);
        } else {
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleActiveStep(activeStep + 1);
        }
      }
    }
    //To allow steps to have a callback method
    if (stepsArray[activeStep].nextStepCallback != null) {
      log("StepperForm: Inside of callback if");
      stepsArray[activeStep].nextStepCallback();
    }
    log("StepperForm: Exiting handleNext function. ActiveStep\t" + activeStep);
  };

  const handleBack = () => {
    log(
      "StepperForm: start handleBack----------------------------------------------"
    );

    if (
      stepsArray[activeStep].backFunction &&
      !stepsArray[activeStep].validator
    ) {
      stepsArray[activeStep].backFunction();
    } else {
      if (activeStep === 0) {
        if (cancelCallback) {
          cancelCallback();
        }
      } else if (stepsArray[activeStep - 1].label === "LOADING") {
        // setActiveStep((prevActiveStep) => prevActiveStep - 2);
        handleActiveStep(activeStep - 2);
      } else if (stepsArray[activeStep - 1].skipStep) {
        // setActiveStep((prevActiveStep) => prevActiveStep - 2);
        handleActiveStep(activeStep - 2);
      } else {
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);
        handleActiveStep(activeStep - 1);
      }

      if (stepsArray[activeStep].callback) {
        stepsArray[activeStep].callback();
      }
    }
  };

  const stepLabels = stepsArray.reduce(function (filtered, step) {
    if (step.label !== "" && step.isCountlessStep !== true) {
      filtered.push(step);
    }
    return filtered;
  }, []);
  return (
    // Left section with steps and app logo
    <Box className={classes.container} spacing={0}>
      {/* Left section with steps and app logo */}
      <Box className={classes.stepperContainer}>
        <Box
          display="flex"
          flexDirection="column"
          style={{
            height: "100%",
          }}
        >
          <Typography variant="h6" component="div" className={classes.title}>
            {title}
          </Typography>
          <Box className={classes.stepperWrapper}>
            {fullScreen ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {/* Stepper when in small screens */}

                <MobileStepper
                  variant="dots"
                  steps={stepsArray.length}
                  position="static"
                  activeStep={stepperVisualStep}
                  className={classes.mobileStepper}
                />
                <Typography
                  component="div"
                  variant="subtitle1"
                  className={classes.subtitle1}
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {stepsArray[activeStep].label}
                </Typography>
              </Box>
            ) : (
              <Stepper activeStep={stepperVisualStep} orientation="vertical">
                {stepLabels.map(({ label }) => {
                  if (label !== "LOADING") {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  }
                  return null;
                })}
              </Stepper>
            )}
          </Box>

          <Box
            justifyContent="center"
            display="flex"
            alignItems="flex-end"
            style={{ width: "100%" }}
            className={classes.imageContainer}
          >
            {isSmall ? null : <div></div>}
          </Box>
        </Box>
      </Box>{" "}
      {/* End Left section with steps and app logo */}
      {/* Right section of the stepper with the steps of the form */}
      <Box className={classes.formContainer}>
        {/* Current step child component */}
        <Box className={classes.childContent}>
          {stepsArray[activeStep]?.child}
        </Box>

        {/* Container of buttons  */}
        <Box
          // item container xs={12}
          className={classes.buttonsContainer}
        >
          {/* Optional skip step button */}
          {/* {stepsArray[activeStep].showSkipButton ? (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justify="start"
              className={classes.skipStepButtons}
            >
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                SKIP THIS STEP
              </Button>
            </Grid>
          ) : null} */}
          {/* Cancel Button after first step */}
          {activeStep === 0 ||
          stepsArray[activeStep]?.label === "LOADING" ||
          stepsArray[activeStep]?.label === "COMPLETE" ||
          stepsArray[activeStep]?.label === "EDIT COMPLETE" ? null : (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justify="flex-start"
              className={classes.skipStepButtons}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={() => cancelCallback()}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </Grid>
          )}
          {/* navigation buttons */}
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            className={classes.stepButtons}
          >
            {stepsArray[activeStep]?.label === "LOADING" ||
            stepsArray[activeStep]?.label === "COMPLETE" ||
            activeStep === stepsArray.length - 1 ? null : (
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleBack}
                className={classes.leftButton}
              >
                {activeStep === 0 ? "Cancel" : "Previous"}
              </Button>
            )}
            {stepsArray[activeStep]?.label === "LOADING" 
            || (stepsArray[activeStep]?.nextButtonActive !== undefined && !stepsArray[activeStep]?.nextButtonActive) 
            ? null : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.rightButton}
              >
                {stepsArray[activeStep]?.nextButtonName
                  ? stepsArray[activeStep]?.nextButtonName
                  : stepsArray[activeStep]?.label === "EDIT COMPLETE"
                  ? "Close"
                  : activeStep === stepsArray.length - 1 ||
                    stepsArray[activeStep]?.label === "COMPLETE"
                  ? "Finish"
                  : "Next"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>{" "}
      {/* End Right section of the stepper with the steps of the form */}
    </Box>
  );
});

export default StepperForm;
