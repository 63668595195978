import React, { useState, useEffect, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import socketIOClient from "socket.io-client";
import fetchOne from "./api/fetchOne";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Snackbar,
} from "@material-ui/core";

import {
  TempGauge,
  HumidityGauge,
  PressureGauge,
  BatteryVoltageGauge,
  RSSIGauge,
  LightLuxGauge,
  Accel,
} from "../Gauges";

// import { Octagon } from "../../icons"
import octagon from "../../img/oct.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  // [theme.breakpoints.down("sm")]: {
  //   cardContent: { width: "25rem" },
  // },

  p: {
    textAlign:"center"
  }
}));

const SingleLiveSensor = (props) => {
  const log = window.log('SingleLiveSensor');

  const classes = useStyles();

  const { appState, setAppState, snack} = useContext(AppContext);
  const { macAddress, refreshTable } = props;

  const [state, setState] = useState({
    temp: 0,
    humi: 0,
    pres: 0,
  });

  const [live, setLive] = useState(false);

  const processData = (data) => {
    setState(data);
    refreshTable();
    if (live === false) {
      setLive(true);
    }
  };
  const socketListener = () => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      upgrade: false,
      transports: ['websocket']
    });
    socket.on(macAddress, (data) => processData(data));
  };

  const lastReported = async () => {
    try {
      const lastMessage = await fetchOne(
        "last-reported",
        macAddress,
        appState.auth.userInfo.companyId,
        appState.auth.token
      );
  
      log(lastMessage);
  
      if (lastMessage.success) {
        setState({
          temp: lastMessage.data.temperature,
          humi: lastMessage.data.humidity,
          pres: lastMessage.data.pressure,
        });
      } else {
        log(lastMessage.errors);
      }
    } catch (err) {
      log("------: lastReported -> err", err);
      snack('Network Error', 'error');
    }
  };

  useEffect(() => {
    // fetch last reported initally
    lastReported();

    // set up socketIO listener
    socketListener();
  }, []);

  return (
    <>
      <Grid className={classes.root} container>
        <Grid item md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              
              <Grid justify={'center'} alignItems={'center'} container>
                <Grid item xs={12}>
                 <p className={classes.p}>{live ? "Live Data" : "Last reported message"}</p>
                </Grid>
                <Grid item xs={12} style={{marginBottom: -50}}>
                  <p className={classes.p}>top</p>
                  <HumidityGauge
                    fontSize={30}
                    direction={"column"}
                    data={state.humi}
                    live={live}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className={classes.p}>top left</p>
                  {/* <TempGauge
                    data={state.temp}
                    live={live}
                    fontSize={40}
                    barSize={150}
                    bottomFontSpace={-9}
                    bottomBarSpace={-65}
                    leftBarSpace={-35}
                  /> */}
                  
                </Grid>
                <Grid item xs={2}>
                  {/* <p className={classes.p}></p> */}
                </Grid>
                <Grid item xs={5}>
                  <p className={classes.p}>top right</p>
                  <PressureGauge
                    fontSize={35}
                    direction={"column"}
                    data={state.pres}
                    live={live}
                  />
                </Grid>
                <Grid item xs={4}>
                  <p className={classes.p}>left</p>
                </Grid>
                <Grid item xs={4}>
                  <img src={octagon} width={"100%"} style={{transform: "scale(1.25)"}} />
                </Grid>
                <Grid item xs={4}>
                  <BatteryVoltageGauge data={90} fontSize={45} direction={"row"} />
                </Grid>
                <Grid item xs={5}>

                  <p className={classes.p}>bottom left</p>
                </Grid>
                <Grid item xs={2}>
                  {/* <p className={classes.p}></p> */}
                </Grid>
                <Grid item xs={5}>
                  <RSSIGauge translateY={-10} data={3} fontSize={55} direction={"row"} />
                </Grid>
                <Grid item xs={12} style={{display: "block", margin:"0 auto"}}>
                  <LightLuxGauge data={false} fontSize={55} />
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SingleLiveSensor;
