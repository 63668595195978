import React, { useState, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AppContext } from "../../AppContext";
import { stateInfo } from "../../resources/infoData";

const LocationForm = (props) => {
  const log = window.log('LocationForm');

  const {
    addCompanyField,
    removeUseCurrentLocation,
    setCompanyName,
    companyNameError,
    companyName,
    setStreet,
    setCity,
    setState,
    setZipcode,
    city,
    state,
    zipcode,
    street,
    zipcodeError,
    streetError,
    cityError,
    stateError,
    classes,
  } = props;

  const { setLogoutState, snack } = useContext(AppContext);

  //lists
  const [streets, setStreets] = useState([]);
  const [states, setStates] = useState(stateInfo);
  const [cities, setCities] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);

  const useStyles = makeStyles((theme) => ({
    locationListItem: {
      color: theme.palette.primary.main,
      "&:hover": {
        fontWeight: "bold",
        cursor: "pointer",
      },
    },
    titleText: {
      fontWeight: "bold",
      color: "#747474",
    },
  }));

  //current location hooks

  const [guessList, setGuessList] = useState([]);

  // current location functions

  const getCurrentLocation = (e) => {
    e.preventDefault();

    const locationCallback = async (position) => {
      const { latitude, longitude, accuracy } = position.coords;
      try {
        if (accuracy > 0 && accuracy < 10000) {
          let json = await fetch(
            `${process.env.REACT_APP_API_URL}/location/current?lat=${latitude}&long=${longitude}&acc=${accuracy}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          json = await json.json();
          if (json.success) {
            log(json);
            if (json.data.result.length === 0) {
              setGuessList(false);
            } else {
              setGuessList(json.data);
            }
          } else {
            json.errors.forEach((err) => {
              snack(err.msg, "error");
              if (err.type === "token") setLogoutState(true);
            });
          }
        } else {
          setGuessList(false);
        }
      } catch (err) {
        log("------: locationCallback -> err", err);
        snack("Network Error", "error");
      }
    };

    const error = () => {
      setGuessList(false);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationCallback, error);
    } else {
      log("Geolocation is not supported by this browser.");
    }
  };

  const CurrentLocationGuessList = (props) => {
    const classes = useStyles();
    const { items } = props;
    return (
      <>
        {items.result && (
          <ul>
            <Typography
              className={classes.titleText}
              variant="h6"
              component="h6"
            >
              Is one of these your current address?
            </Typography>
            {items.result.map((item, index) => (
              <li
                className={classes.locationListItem}
                key={index}
                onClick={() => {
                  // setState({ ...state, address: item.label });
                  setStreet(item.HouseNumber + " " + item.Street);
                  setCity(item.City);
                  setState(item.State);
                  setZipcode(item.PostalCode);
                  setGuessList([]);
                }}
              >
                {item.Label}
              </li>
            ))}
          </ul>
        )}
      </>
    );
  };

  // Autocomplete functions

  const handleSetStreetChange = async (e, v) => {
    let arr = [];
    if (e) {
      try {
        setStreet(e.currentTarget.value);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/location/suggestion?query=${e.currentTarget.value}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          const suggestions = data.data.result.suggestions;
          suggestions.forEach((item) =>
            arr.push({
              ...(item.address.houseNumber
                ? { houseNumber: item.address.houseNumber }
                : {}),
              ...(item.address.street ? { street: item.address.street } : {}),
              ...(item.address.city ? { city: item.address.city } : {}),
              ...(item.address.state ? { state: item.address.state } : {}),
              ...(item.address.postalCode
                ? { postalCode: item.address.postalCode }
                : {}),
              ...(item.address.country
                ? { country: item.address.country }
                : {}),
              ...(item.address.county ? { county: item.address.county } : {}),
            })
          );
        } else {
          log("handle err");
        }
      } catch (error) {
        log(error);
      }
    }
    setStreets(arr);
  };

  const handleStreetSelect = (e, v) => {
    if (v) {
      setStreet(v.displayText);
      setCity(v.city);
      setState(v.state);
      setZipcode(v.postalCode);
    }
  };

  const handleStateSelect = (e, v) => {
    if (v) {
      setState(v.abbr);
    }
  };

  const handleSetStateChange = (e, v) => {
    if (e) {
      setState(e.currentTarget.value);
    }
  };

  const handleSetCityChange = async (e, v) => {
    let arr = [];
    if (e) {
      try {
        setCity(e.currentTarget.value);
        const response = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${e.currentTarget.value}&country=USA&resultType=city`
        );
        const data = await response.json();
        if (data.suggestions) {
          data.suggestions.forEach((item) => arr.push(item.address.city));
        }
      } catch (error) {
        log(error);
      }
    }
    setCities(arr);
  };

  const handleCitySelect = (e, v) => {
    if (v) {
      setCity(v);
    }
  };

  const handleSetZipcodeChange = async (e, v) => {
    let arr = [];
    if (e) {
      try {
        setZipcode(e.currentTarget.value);
        const response = await fetch(
          `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&query=${e.currentTarget.value}&country=USA&resultType=postalCode`
        );
        const data = await response.json();
        if (data.suggestions) {
          data.suggestions.forEach((item) =>
            arr.push(item.label.match(/(\d+)/)[0])
          );
        }
      } catch (error) {
        log(error);
      }
    }
    setZipcodes(arr);
  };

  const handleZipcodeSelect = (e, v) => {
    if (v) {
      setZipcode(v);
    }
  };

  const options = states.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const streetOptions = streets.map((option) => {
    let text = `${option.houseNumber ? option.houseNumber + " " : ""}${
      option.street ? option.street + " " : ""
    }`;

    return {
      displayText: text,
      ...option,
    };
  });

  return (
    <>
      {!removeUseCurrentLocation ? (
        <>
          <Grid item xs={12}>
            <Button
              id="currentLocation"
              variant="outlined"
              color="secondary"
              onClick={getCurrentLocation}
            >
              Use Current Location
            </Button>
          </Grid>
          <Grid item xs={12}>
            {guessList ? (
              <CurrentLocationGuessList items={guessList} />
            ) : (
              <p>Unable to retrieve your location.</p>
            )}
          </Grid>
        </>
      ) : null}
      {addCompanyField ? (
        <Grid item xs={12}>
          <TextField
            name="companyName"
            value={companyName}
            className={classes.textField}
            onChange={(e) => setCompanyName(e.currentTarget.value)}
            fullWidth
            required
            variant="outlined"
            label="Company Name"
            placeholder="Company Name*"
            error={companyNameError}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Autocomplete
          id="street"
          options={streetOptions}
          getOptionLabel={(option) =>
            `${option.displayText} ${option.city}, ${option.state} ${option.postalCode}`
          }
          inputValue={street}
          onChange={(e, v) => handleStreetSelect(e, v)}
          onInputChange={(e, v) => handleSetStreetChange(e, v)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              autoComplete="street"
              label="Street"
              name="street"
              variant="outlined"
              required
              fullWidth
              error={streetError}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="city"
          options={cities}
          getOptionLabel={(option) => `${option}`}
          inputValue={city}
          onChange={(e, v) => handleCitySelect(e, v)}
          onInputChange={(e, v) => handleSetCityChange(e, v)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              autoComplete="city"
              label="City"
              name="city"
              variant="outlined"
              required
              fullWidth
              error={cityError}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="state"
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => `(${option.abbr}) ${option.name}`}
          inputValue={state}
          onChange={(e, v) => handleStateSelect(e, v)}
          onInputChange={(e, v) => handleSetStateChange(e, v)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              autoComplete="state"
              label="State"
              name="state"
              variant="outlined"
              required
              fullWidth
              error={stateError}
              inputProps={{ ...params.inputProps, maxLength: 2 }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="zipcode"
          options={zipcodes}
          getOptionLabel={(option) => `${option}`}
          inputValue={zipcode}
          onChange={(e, v) => handleZipcodeSelect(e, v)}
          onInputChange={(e, v) => handleSetZipcodeChange(e, v)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.textField}
              autoComplete="zipcode"
              label="Zipcode"
              name="zipcode"
              variant="outlined"
              required
              fullWidth
              error={zipcodeError}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default LocationForm;
