import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "./../../AppContext";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Generic Imports
import BreadCrumb from "../Generic/BreadCrumb";
// Users import
import SingleUserCard from "./SingleUserCard";
import EditCurrentUserDialog from "./EditCurrentUserDialog";
import EditUserProfileDialog from "./EditUserProfileDialog";
import EditPasswordDialog from "./EditPasswordDialog";
import EditUserImageDialog from "./EditUserImageDialog";
import EditPaymentDialog from "./EditPaymentDialog";
import ChangeCompanyLogos from "./ChangeCompanyLogos";
import EditSubUserFormModal from "./EditSubUserFormModal";
import fetchOne from "../common/api/fetchOne";
import { navigate } from "hookrouter";

// Icon import
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "../Generic/IconButton";
import ImageIcon from "@material-ui/icons/Image";
import LockIcon from "@material-ui/icons/Lock";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import uploadImage from "../common/api/uploadImage";

const useStyles = makeStyles((theme) => ({
  MuiContainer: {
    root: {
      padding: "0",
    },
  },
  bar: {
    margin: theme.spacing(1, 1, 3, 1),
  },
  root: {
    // width: "100%",
  },
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
}));

const AccountSettings = (props) => {
  const log = window.log('AccountSettings');

  const {
    platformImageLocation,
    setPlatformImageLocation,
    faviconImageLocation,
    setFaviconImageLocation,
  } = props;
  const classes = useStyles();
  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenEditUserModal,
    snack,
  } = useContext(AppContext);
  const [userInfo, setUserInfo] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [networkErr, setNetworkErr] = useState(false);

  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
    if (
      appState.auth.userInfo.profile &&
      appState.auth.userInfo.profile.profileImageLocation
    ) {
      setProfileImageLocation(
        appState.auth.userInfo.profile.profileImageLocation
      );
    }
    if (
      appState.auth.userInfo.themePreferences &&
      appState.auth.userInfo.themePreferences.platformImageLocation
    ) {
      setPlatformImageLocation(
        appState.auth.userInfo.themePreferences.platformImageLocation
      );
    }
    if (
      appState.auth.userInfo.themePreferences &&
      appState.auth.userInfo.themePreferences.faviconImageLocation
    ) {
      setFaviconImageLocation(
        appState.auth.userInfo.themePreferences.faviconImageLocation
      );
    }
  }, [appState.auth.userInfo]);

  const [profileModalState, setProfileModalState] = useState(false);
  const [profileImageLocation, setProfileImageLocation] = useState("");
  // const [platformImageLocation, setPlatformImageLocation] = useState("");
  // const [faviconImageLocation, setFaviconImageLocation] = useState("");
  const [imgErr, setImgErr] = useState(false);
  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();

  const handleRemoveProfileImageLocation = () => {
    setProfileImageLocation("");
  };

  const profileOpenModal = () => {
    setProfileModalState(true);
  };

  const profileCloseModal = () => {
    setProfileModalState(false);
  };

  const profileSaveModal = () => {
    setProfileModalState(false);
  };

  const [passwordModalState, setpasswordModalState] = useState(false);

  const passwordOpenModal = () => {
    setpasswordModalState(true);
  };

  const passwordCloseModal = () => {
    setpasswordModalState(false);
  };

  const [userImageModalState, setUserImageModalState] = useState(false);

  const userImageOpenModal = () => {
    setUserImageModalState(true);
  };

  const userImageCloseModal = () => {
    setUserImageModalState(false);
  };

  const userImageSaveModal = () => {
    setUserImageModalState(false);
  };

  const [userLogosModalState, setUserLogosModalState] = useState(false);

  const userLogosOpenModal = () => {
    setUserLogosModalState(true);
  };

  const userLogosCloseModal = () => {
    setUserLogosModalState(false);
  };

  const userLogosSaveModal = () => {
    setUserLogosModalState(false);
  };

  const [userPaymentModalState, setUserPaymentModalState] = useState(false);

  const userPaymentOpenModal = () => {
    setUserPaymentModalState(true);
  };

  const userPaymentCloseModal = () => {
    setUserPaymentModalState(false);
  };

  const userPaymentSaveModal = () => {
    setUserPaymentModalState(false);
  };

  const [state, setState] = useState([]);

  const renderCompanyButton = () => {
    if (userInfo.perm === 0 || userInfo.perm ===  2 || userInfo.perm === 99) {
      return (
        <Grid item xs={12} md={6}>
          <IconButton
            icon={<ImageIcon style={{ fontSize: 40 }} color="primary" />}
            title="Company Logo"
            subtitle="Custom Main Logo and Logo Icon"
            onClick={userLogosOpenModal}
          />
        </Grid>
      );
    }

    return null;
  };

  const renderPaymentButton = () => {
    if (userInfo.perm === 0 || userInfo.perm === 99) {
      return (
        <Grid item xs={12} md={6}>
          <IconButton
            icon={<CreditCardIcon style={{ fontSize: 40 }} color="primary" />}
            title="Payment Methods"
            subtitle="Credit card information"
            onClick={userPaymentOpenModal}
          />
        </Grid>
      );
    }

    return null;
  };

  const navigateToBillingPlan = () => {
    navigate("/current/user/billing");
  };
  const renderBillingButton = () => {
    if (userInfo.perm === 0 || userInfo.perm === 99) {
      return (
        <Grid item xs={12} md={6}>
          <IconButton
            icon={<LocalAtmIcon style={{ fontSize: 40 }} color="primary" />}
            title="Billing"
            subtitle="Subscriptions, billing dates, and recurring payments"
            onClick={navigateToBillingPlan}
          />
        </Grid>
      );
    }

    return null;
  };

  const getUser = async () => {
    try {
      const json = await fetchOne(
        "user",
        appState.auth.userInfo.id,
        appState.auth.userInfo.companyId,
        appState.auth.token
      );

      log("JSON", json);
      if (json.success) {
        setState(json.data.users[0]);
        if (
          json.data.users[0].profile &&
          json.data.users[0].profile.profileImageLocation
        ) {
          setProfileImageLocation(
            json.data.users[0].profile.profileImageLocation
          );
        }
      } else {
        setState([]);
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (error) {
      snack("Network Error", "error");
      setNetworkErr(true);
    }
  };

  const getCompany = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/company/get`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          // body: JSON.stringify({ company, entityId }),
        }
      );

      const json = await request.json();

      if (json.success) {
        setCompanyInfo(json.data.company[0]);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  useEffect(() => {
    getUser();
    getCompany();
  }, []);

  const handleOpenEditUserModal = async () => {
    setOpenEditUserModal(true);
  };

  const singleFileUploadHandler = async (e) => {
    try {
      const renameText = `userImage-${appState.auth.userInfo.id}-${Date.now()}`;
      const data = new FormData();

      const folderDestination = "Profile Image";
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          // Success
          setProfileImageLocation(response.location);
          snack("Image successfully uploaded", "success");
          handleSaveProfileImage(response.location);
        }
      } else if (imgFile) {
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          // Success
          setProfileImageLocation(response.location);
          handleSaveProfileImage(response.location);
        }
      } else if (profileImageLocation) {
        //PASS
      } else {
        handleSaveProfileImage("");

        snack("Image Removed", "success");
      }
    } catch (error) {
      log(error);
    }
  };

  // Save Profile Image
  const handleSaveProfileImage = async (location) => {
    const changes = {
      profileImageLocation: location,
    };

    try {
      const data = {
        ...userInfo,
        profile: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/user/editUserImage",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await response.json();

      if (json.success) {
        // snack("Image successfully updated", "success");
        let cp = { ...appState };
        cp.auth.userInfo.profile = JSON.parse(json.data.profile);
        setAppState(cp);
        // getUser();
        userImageCloseModal();
      } else {
        //handle errs
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
  };
  // test
  const platformImageUploadHandler = async (e) => {
    try {
      const renameText = `platformImage-${
        appState.auth.userInfo.companyId
      }-${Date.now()}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = "Platform Image";
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          destinationFolder
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            log(response);
            snack(response.error, "error");
          }
        } else {
          // Success
          setPlatformImageLocation(response.location);
          snack("Image successfully uploaded", "success");
        }
      } else {
        snack("Please upload file", "error");
      }
    } catch (error) {
      log(error);
    }
  };

  const faviconImageUploadHandler = async (e) => {
    try {
      const renameText = `favicon-${
        appState.auth.userInfo.companyId
      }-${Date.now()}`;
      const data = new FormData();
      const file = await e.target.files[0];
      const destinationFolder = "Favicon Image";
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          destinationFolder
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          // Success
          setFaviconImageLocation(response.location);
          snack("Image successfully uploaded", "success");
        }
      } else {
        snack("Please upload file", "error");
      }
    } catch (error) {
      log(error);
    }
  };
  // Save company logos
  const handleSaveCompanyLogos = async () => {
    let changes = {};

    if (appState.auth.userInfo.themePreferences) {
      changes = {
        ...appState.auth.userInfo.themePreferences,
        platformImageLocation,
        faviconImageLocation,
      };
    } else {
      changes = {
        platformImageLocation,
        faviconImageLocation,
      };
    }

    try {
      const data = {
        themePreferences: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        snack("Settings successfully updated", "success");
        let cp = { ...appState };
        cp.auth.userInfo.themePreferences = JSON.parse(
          json.data.themePreferences
        );
        setAppState(cp);
        userLogosCloseModal();
      } else {
        snack(json.errors[0], "error");
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
  };

  return networkErr ? null : (
    <React.Fragment>
      <EditSubUserFormModal getUser={getUser} userInfo={state} />
      <div className={classes.root}>
        <BreadCrumb
          className={classes.bar}
          title="Account Settings"
          leadingIcon={
            <SettingsIcon style={{ fontSize: 32 }} color="primary" />
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SingleUserCard
              id={state.id}
              name={`${state.fname || ""} ${state.lname || ""}`}
              email={state.email || ""}
              companyName={userInfo.companyName || ""}
              phoneNumber={state.phoneNumber || ""}
              userSettingsPage={true}
              permissionLevel={userInfo.perm}
              avatar={
                userInfo.profile && userInfo.profile.profileImageLocation
                  ? userInfo.profile.profileImageLocation
                  : ""
              }
              handleOpenEditUserModal={handleOpenEditUserModal}
              disableDelete={true}
              handleEditImage={userImageOpenModal}
            />
          </Grid>
          {renderCompanyButton()}
          <Grid
            item
            xs={12}
            md={
              userInfo.perm === 0 || userInfo.perm === 2 || userInfo.perm === 99
                ? 6
                : 12
            }
          >
            <IconButton
              icon={<LockIcon style={{ fontSize: 40 }} color="primary" />}
              title="Password"
              subtitle="Update Password"
              onClick={passwordOpenModal}
            />
          </Grid>
        </Grid>

        {userInfo.perm === 0 || userInfo.perm === 2 || userInfo.perm === 99 ? (
          <EditCurrentUserDialog
            openState={profileModalState}
            handleClose={profileCloseModal}
            handleSave={profileSaveModal}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
            appState={appState}
          />
        ) : (
          <EditUserProfileDialog
            openState={profileModalState}
            handleClose={profileCloseModal}
            handleSave={profileSaveModal}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            appState={appState}
          />
        )}

        <EditPasswordDialog
          openState={passwordModalState}
          handleClose={passwordCloseModal}
          appState={appState}
        />
        <EditUserImageDialog
          openState={userImageModalState}
          handleClose={userImageCloseModal}
          handleSave={singleFileUploadHandler}
          setImgLocation={setProfileImageLocation}
          handleRemoveImg={handleRemoveProfileImageLocation}
          imgLocation={profileImageLocation}
          setImgErr={setImgErr}
          setFile={setFile}
          setImgFile={setImgFile}
        />
        <ChangeCompanyLogos
          openState={userLogosModalState}
          handleClose={userLogosCloseModal}
          handleSave={handleSaveCompanyLogos}
          platformImageUploadHandler={platformImageUploadHandler}
          faviconImageUploadHandler={faviconImageUploadHandler}
          platformImageLocation={platformImageLocation}
          setPlatformImageLocation={setPlatformImageLocation}
          faviconImageLocation={faviconImageLocation}
          setFaviconImageLocation={setFaviconImageLocation}
        />
      </div>
    </React.Fragment>
  );
};

export default AccountSettings;
