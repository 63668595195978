import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  lightRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
  mark: {
    height: "5px",
  },
}));

const LightThresholds = (props) => {
  const theme = useTheme();

  const {
    lightThresholdLow,
    setLightThresholdLow,
    lightThresholdHigh,
    setLightThresholdHigh,
    enableLightAlert,
    setEnableLightAlert,
    lightRangeType,
    setLightRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: 0,
      label: "Off",
    },
    {
      value: 1,
      label: "Low",
    },
    {
      value: 2,
      label: "Med",
    },
    {
      value: 3,
      label: "High",
    },
  ];

  const valuetext = (value) => {
    if (value === 0) {
      return "Off";
    }
    if (value === 1) {
      return "Low";
    }
    if (value === 2) {
      return "Med";
    }
    if (value === 3) {
      return "High";
    }
  };

  const handleToggleEnable = (e) => {
    setEnableLightAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (lightRangeType === 1 || lightRangeType === 2) {
      setLightThresholdHigh(v);
    } else {
      setLightThresholdLow(v[0]);
      setLightThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableLightAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.lightRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="light-range-slider">
                Light
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableLightAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={lightRangeType}
                setRangeType={setLightRangeType}
                enabled={enableLightAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                classes={{ mark: classes.mark }}
                aria-labelledby="light-range-slider"
                valueLabelFormat={(value) => valuetext(value)}
                onChange={handleOnChange}
                value={
                  lightRangeType === 1 || lightRangeType === 2
                    ? lightThresholdHigh
                      ? lightThresholdHigh
                      : 1
                    : [
                        lightThresholdLow ? lightThresholdLow : 0,
                        lightThresholdHigh ? lightThresholdHigh : 3,
                      ]
                }
                track={
                  lightRangeType === 1 || lightRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                min={0}
                max={3}
                disabled={!enableLightAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LightThresholds;
