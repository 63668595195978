import React from "react";
import { SvgIcon } from "@material-ui/core";

const DoorSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="35.013"
      height="35.013"
      viewBox="0 0 35.013 35.013"
      {...props}
    >
      <path fill="none" d="M0,0H35.013V35.013H0Z" />
      <g transform="translate(8.753 2.918)">
        <g transform="translate(0 1.49)">
          <path
            fill={outlinecolor}
            d="M1173.27,120.8v1.071h7.445l-2.759-1.071Z"
            transform="translate(-1168.149 -120.801)"
          />
          <path
            fill={outlinecolor}
            d="M1180.431,120.8h-2.146l2.226.864.608.236v.48a.723.723,0,0,1,.031.21v22.843a.326.326,0,0,0,.327.325h.419a.326.326,0,0,0,.325-.325V122.591A1.79,1.79,0,0,0,1180.431,120.8Z"
            transform="translate(-1165.848 -120.801)"
          />
          <path
            fill={outlinecolor}
            d="M1173.93,120.8h-2.379a1.789,1.789,0,0,0-1.79,1.79v22.843a.326.326,0,0,0,.325.325h.419a.326.326,0,0,0,.327-.325V122.591a.72.72,0,0,1,.719-.719h2.379Z"
            transform="translate(-1169.76 -120.801)"
          />
        </g>
        <path
          fill={filledcolor}
          d="M1174.242,119.828a.714.714,0,0,0-.972.665v27.4a.888.888,0,0,0,1.112.859l8.086-2.092V123.02Zm1.088,15.133c-.273,0-.493-.372-.493-.83s.22-.83.493-.83.493.372.493.83S1175.6,134.961,1175.33,134.961Z"
          transform="translate(-1168.149 -119.78)"
        />
      </g>
    </SvgIcon>
  );
};

export default DoorSVG;
