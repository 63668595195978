import { Card, Dialog } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { forwardRef, useRef, useImperativeHandle } from "react";

import StepperForm from "./StepperForm";

/**
 *
 * @param {Object} props
 * @props
 * stepsArray: Array of Objects for the StepperForm, [{
      label: String,
      child: ReactComponent
    }]
 * title: String
 * handleClose: Function
 * handleCancel: Function
 * openState: Boolean
 */

const ResponsiveDialog = forwardRef((props, ref) => {
  const log = window.log("ResponsiveDialog");

  useImperativeHandle(ref, () => ({
    nextStep() {
      log(stepperFormRef);
      stepperFormRef.current.nextStep();
    },
  }));

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    title, // Title of the form
    openState, // Open state of the dialog
    stepsArray, // Array of objects that have te label and the components for the step
    handleClose, // Handle the close event of the dialog
    handleCancel, // Handle the cancel event of the dialog
    handleFinish,
  } = props;

  const stepperFormRef = useRef();

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        fullWidth={false}
        onClose={handleClose}
        fullScreen={fullScreen}
        open={Boolean(openState)}
        aria-labelledby="responsive-dialog-title"
      >
        <Card>
          <StepperForm
            title={title}
            ref={stepperFormRef}
            stepsArray={stepsArray}
            handleFinish={handleFinish} // For onboard modal
            finishCallback={handleClose}
            cancelCallback={handleCancel}
          />
        </Card>
      </Dialog>
    </div>
  );
});

export default ResponsiveDialog;
