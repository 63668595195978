import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

import grey from "@material-ui/core/colors/grey";

const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[400];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  imageButtonSize: {
    width: 175,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 150,
      marginBottom: 10,
    },
  },
  textContainer: {
    maxHeight: "45vh",
    overflowY: "scroll",
    padding: 15,
    border: "0.5px solid",
    borderColor: textColor({ theme })
  },
  text: {
    fontSize: 14,
    fontWeight: "600",
    color: textColor({ theme })
  },
}));

const TermAndConditions = (props) => {
  const {
    reachBottomFunction
  } = props
  const classes = useStyles();

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - (e.target.scrollTop + 15) <= e.target.clientHeight;
    if (bottom) { 
      reachBottomFunction && reachBottomFunction()
    }
  }
  return (<Grid container item xs={12} spacing={2}>
    <Grid item xs={12}>
      <Box
        className={classes.fontColor}
        fontWeight="fontWeightBold"
        fontSize={20}
      >
        TERMS AND CONDITIONS
      </Box>
    </Grid>
    
    <Grid item xs={12}>
      <Paper variant="outlined" className={classes.textContainer} onScroll={handleScroll}>
        <div className={classes.text}>
          <p><span>End User License Agreement</span></p>
          <p><span>Last updated: September 11/17/2020</span></p>
          <p><span>This Web Application End User License Agreement ( &ldquo;Agreement &ldquo;) is a binding agreement between you ( &ldquo;Licensee &ldquo; or &ldquo;you &ldquo;) and Direct Communication Solutions, Inc. ( &ldquo;DCS&ldquo; or &ldquo;Company&rdquo;). This Agreement governs your use of DCS&rsquo;s website and web applications (referred to herein as the &ldquo;Application &ldquo;). The Application is licensed, not sold, to you.</span></p>
          <p><span>BY &ldquo;CREATING YOUR ACCOUNT &ldquo; YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION.</span></p>
          <ol>
            <li><span>License Grant. Subject to the terms of this Agreement and your subscription in accordance with Section 2 of this Agreement, DCS grants you a limited, nonexclusive and nontransferable license to use the Application.</span></li>
            <li><span>Subscription Services</span></li>
          <ol>
          <ol>
            <li><span>Annual Subscription: If you subscribe to the Application on an annual subscription basis, you must pay the annual fee in advance to access and use the Application. Fees are described Company&rsquo;s website or from within the Application. The first annual fee will start to accrue as of the first day you create your account The fees do not include any taxes or duties of any kind, which may be imposed by any governmental entity on the transactions contemplated by this Agreement, and you will be solely responsible for all such taxes. All amounts are quoted in and payable in US dollars. Company reserves the right to suspend your access to and use of the Application if you fail to pay any undisputed amount owed on or before its due date. You may cancel your subscription at any time; upon cancellation, you (i) will not receive a refund for the billing period during which you cancel your subscription; and (ii) will be able to access your subscription and receive updates of the relevant subscription until the subscription termination date.</span></li>
            <li><span>Refunds: Company may provide you a refund of the partial unused subscription period if: (i) the Application does not function properly; or (ii) you request a refund from Company within 30 days of the charge.</span></li>
          </ol>
          </ol>
            <li><span>Return Policy: You may return purchased products within 30 days of the original order date, with our authorization. After 30 days, all transactions are final. We will pay the return shipping costs if the product is defective or the return is a result of our error. If the item is returned for any other reason you are responsible for the return shipping costs and may be charged a restocking fee of 30 percent. No returned merchandise will be accepted without a Return Material Authorization (RMA) number. RMA numbers are issued by Customer Service (support@misensors.com). We will credit you in the same manner as your original payment within 30 days of receiving the returned item.</span></li>
            <li><span>License Restrictions. Licensee shall not:</span></li>
          <ol>
            <li><span>copy the Application, except as expressly permitted by this license;&nbsp;</span></li>
            <li><span>modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Application;&nbsp;</span></li>
            <li><span>reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;&nbsp;</span></li>
            <li><span>remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Application, including any copy thereof; or,</span></li>
            <li><span>rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Application or any features or functionality of the Application, to any third party for any reason.</span></li>
          </ol>
            <li><span>Reservation of Rights. You acknowledge and agree that the Application is provided under license on a subscription basis, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions and restrictions, under this Agreement. Company reserves and shall retain its entire right, title and interest in and to the Application, including all copyrights, trademarks and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.</span></li>
            <li><span>Collection and Use of Information</span></li>
          <ol>
            <li><span>You acknowledge that when you use the Application, Company may use automatic means (including, for example, cookies and web beacons) to collect information about your Devices and about your use of the Application. You also may be required to provide certain information about yourself as a condition to using the Application or certain of its features or functionality. All information we collect through or in connection with this Application is subject to our Privacy Policy. By downloading, installing, using and providing information to or through this Application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</span></li>
            <li><span>If you provide us with information relating to personal contact information (the &ldquo;Data&rdquo;) of third parties (e.g. email, mail, telephone, fax), such information shall be provided by you free of any charge or fee. You warrant that: (i) you make no claim of copyright or other intellectual property rights in the Data; (ii) you have all necessary rights to provide the Data to us and warrant that the Data was not collected, generated, compiled, obtained and/or being supplied to us in any manner that would subject us to legal or regulatory liability for the use as contemplated herein; (iii) none of the individuals included in the Data have &ldquo;opted out&rdquo; of receiving future messages; (iv) your providing the Data to us does not infringe any rights of any third party; (v) there are no material suits, claims, charges or proceedings currently pending or threatened against you relating to the Data.</span></li>
            <li><span>You agree that the Company may collect and use technical data and related information&mdash;including but not limited to technical information about your device, system and application software, and peripherals&mdash;that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Application. Company may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</span></li>
          </ol>
            <li><span>Geographic Restrictions. The Content and Services are based in the state of California in the United States and provided for access and use only by persons located in the United States. You acknowledge that you may not be able to access all or some of the Content and Services outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Content and Services from outside the United States, you are responsible for compliance with local laws.</span></li>
            <li><span>Updates. DCS may from time to time in its sole discretion develop and provide Application updates, which may include upgrades, bug fixes, patches and other error corrections and/or new features (collectively, including related documentation, &ldquo;Updates &ldquo;). Updates may also modify or delete in their entirety certain features and functionality. You agree that Company has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality.&nbsp;</span></li>
            <li><span>Term and Termination.&nbsp;</span></li>
          <ol>
            <li><span>The term of Agreement commences when you acknowledge your acceptance and will continue in effect until terminated by you or Company</span></li>
            <li><span>You may terminate this Agreement by disabling auto-pay in your billing settings.&nbsp;</span></li>
            <li><span>Company may terminate this Agreement at any time with reasonable advance notice if Company ceases to support the Application, which Company may do in its sole discretion. Other reasons for app removal include: (i) an allegation or actual infringement of any intellectual property right or right of publicity or privacy of any third party; (ii) an allegation of actual defamation; (iii) an allegation or determination that an app does not comply with applicable law; (iv) Company ceasing to do business; or (v) Company filing a petition in bankruptcy, dissolving, or otherwise finding itself unable to pay its debts as they come due.</span></li>
            <li><span>This Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.</span></li>
            <li><span>The Application may be unpublished at any time.</span></li>
            <li><span>Upon termination: (i) all rights granted to you under this Agreement will also terminate; and (ii) you must cease all use of the Application.&nbsp;</span></li>
            <li><span>Termination will not limit any of Company's rights or remedies at law or in equity.</span></li>
          </ol>
            <li><span>Disclaimer of Warranties. THE APPLICATION IS PROVIDED TO LICENSEE &ldquo;AS IS &ldquo; AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</span><span><br /></span><span><br /></span><span>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</span></li>
          </ol>
            <p><span><br /></span><span>You may not use or otherwise export or re-export the Application except as authorized by United States law and the laws of the jurisdiction in which the Application was obtained. In particular, but without limitation, the Application may not be exported or re-exported (a) into any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture, or production of nuclear, missile, or chemical or biological weapons</span><span>.</span></p>
            <p>&nbsp;</p>
          <ol>
            <li><span>Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:</span></li>
          <ol>
            <li><span>PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES&nbsp;</span></li>
            <li><span>DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</span></li>
          </ol>
            <li><span>Indemnification. You agree to indemnify, defend and hold harmless Company and its officers, directors, employees, agents, affiliates, successors and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement. Furthermore, you agree that Direct Communication Solutions, Inc. assumes no responsibility for the content you submit or make available through this Application.</span></li>
            <li><span>Severability. If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</span></li>
            <li><span>Governing Law. This Agreement is governed by and construed in accordance with the internal laws of the California without giving effect to any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of or related to this Agreement or the Application shall be instituted exclusively in the federal courts of the United States or the courts of California in each case located in San Diego County, California. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span></li>
            <li><span>Entire Agreement. This Agreement and our Privacy Policy constitute the entire agreement between you and Company with respect to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application.&nbsp;</span></li>
            <li><span>Waiver. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</span></li>
          </ol>
        </div>
      </Paper>
    </Grid>
    
  </Grid>)
}


export default TermAndConditions