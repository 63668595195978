import React, { useState } from "react";
import { TextField, Typography } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const EditableTextField = (props) => {
  const classes = useStyles();

  const { setEditForm, text, edit, variant, error, phoneField } = props;

  const [editText, setEditText] = useState(text);

  const handleChange = (e) => {
    setEditText(e.currentTarget.value);
    setEditForm(e.currentTarget.value);
  };

  const handlePhoneChange = (e) => {
    setEditText(e);
    setEditForm(e);
  };

  return (
    <>
      {edit ? (
        <>
          {phoneField ? (
            <MuiPhoneNumber
              style={{ width: 210 }}
              variant="outlined"
              value={editText}
              defaultCountry={"us"}
              preferredCountries={["us"]}
              disableAreaCodes={true}
              onChange={(e) => handlePhoneChange(e)}
              countryCodeEditable={false}
              inputProps={{
                autoComplete: 'new-password'
              }}
            />
          ) : (
            <TextField
              variant={variant}
              value={editText}
              onChange={(e) => handleChange(e)}
              error={error}
            />
          )}
        </>
      ) : (
        <>
          <Typography className={classes.text} variant="h6">
            {text}
          </Typography>
        </>
      )}
    </>
  );
};

export default EditableTextField;
