/**
 * 
 * @param {string} date YYYY-MM-DD
 * @return {string} formatted date - MM/DD/YYYY
 */
export const formatBraintreeDate = (date) => {
  //expecting format to be YYYY-MM-DD (ex. 2020-12-01)
  if ( typeof date === 'string' && date.length === 10 ) {
    const splitDate = date.split('-');
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];
    return `${month}/${day}/${year}`;
  }
  return 'N/A';
}