import React from "react";
import { SvgIcon } from "@material-ui/core";

const AdminDashboardIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs></defs><path fill="none" className="a" d="M0,0H24V24H0Z"/>
        <g transform="translate(-643.178 -255.338)"><g transform="translate(646.178 258.338)">
          <path className="b" d="M653.91,258.338a.6.6,0,0,0-.564.406l-.6,1.812a7.131,7.131,0,0,0-.715.3L650.318,260a.6.6,0,0,0-.686.111l-1.682,1.681a.6.6,0,0,0-.111.686l.852,1.7a7.14,7.14,0,0,0-.3.719l-1.809.6a.6.6,0,0,0-.406.564v2.378a.6.6,0,0,0,.406.564l1.815.605a7.086,7.086,0,0,0,.295.711l-.856,1.712a.6.6,0,0,0,.111.686l1.682,1.683a.6.6,0,0,0,.686.111l1.708-.855a7.154,7.154,0,0,0,.714.295l.6,1.814a.6.6,0,0,0,.564.406h2.378a.6.6,0,0,0,.564-.406l.6-1.817a7.125,7.125,0,0,0,.71-.294l1.713.857a.6.6,0,0,0,.686-.111l1.682-1.683a.594.594,0,0,0,.111-.686l-.854-1.707a7.128,7.128,0,0,0,.3-.716l1.812-.6a.594.594,0,0,0,.406-.564V266.07a.6.6,0,0,0-.406-.564l-1.813-.6a7.177,7.177,0,0,0-.3-.714l.854-1.709a.594.594,0,0,0-.111-.686l-1.682-1.681a.594.594,0,0,0-.686-.111l-1.7.851a7.151,7.151,0,0,0-.721-.3l-.6-1.808a.6.6,0,0,0-.564-.406Zm1.186,3.093a5.826,5.826,0,1,1-5.822,5.829A5.845,5.845,0,0,1,655.1,261.431Z" transform="translate(-646.178 -258.338)"/>
          </g><path className="b" d="M756,355.882c-.845-.6-2.11-.7-2.372-1.4a9.236,9.236,0,0,1-.02-.941,1.842,1.842,0,0,0,.505-1.093c.128-.01.331-.136.39-.63a.461.461,0,0,0-.172-.462c.209-.628.642-2.571-.8-2.771a1.136,1.136,0,0,0-1.024-.393c-1.979.036-2.218,1.495-1.784,3.165a.462.462,0,0,0-.172.462c.059.494.262.62.39.63a1.838,1.838,0,0,0,.517,1.093,9.239,9.239,0,0,1-.02.941c-.262.706-1.536.806-2.384,1.406a4.949,4.949,0,0,0,2.815,1.382h1.314A4.959,4.959,0,0,0,756,355.882Z" transform="translate(-97.431 -85.094)"/></g></svg>
    </SvgIcon>
  );
};

export default AdminDashboardIcon;
