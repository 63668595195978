import React, { useState, useContext } from "react";
import { AppContext } from "../../AppContext";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Typography,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import ResponsiveDialog from "../Generic/GenericDialog";
import ImageButton from "../Generic/ImageButton";
import marker from "../../img/place-24px.svg";
import Loading from "../common/ModalComponents/Loading";
import Complete from "../common/ModalComponents/Complete";
import TermAndConditions from "../Generic/TermAndConditions";
import EnterUserInfo from "./SubUserFormModalComponents/EnterUserInfo";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  imageButtonSize: {
    width: 175,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 150,
      marginBottom: 10,
    },
  },
  selectMenu: {
    maxHeight: 58,
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function SubUserFormModal(props) {
  const log = window.log("SubUserFormModal");

  const {
    appState,
    setAppState,
    setLogoutState,
    openUserModal,
    setOpenUserModal,
    setFeatureCount,
    snack,
  } = useContext(AppContext);
  const { getUsers } = props;

  const classes = useStyles();

  const [userInfo, setUserInfo] = useState({
    fname: "",
    lname: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    fnameError: false,
    lnameError: false,
    emailError: false,
  });

  const setDefaultObject = () => {
    setUserInfo({
      fname: "",
      lname: "",
      email: "",
    });
    setErrors({
      fnameError: false,
      lnameError: false,
      emailError: false,
    });
  };

  // Marks Add User as complete for onboard
  const handleUserFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addUser: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter((item) => item === false)
          .length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      log(err);
    }
  };

  // Submit function to api
  const handleSubmitUser = async () => {
    const { fname, lname, email } = userInfo;

    if ( !fname.length || !lname.length || !email.length ) {
      setErrors({
        fnameError: !fname.length,
        lnameError: !lname.length,
        emailError: !email.length,
      });

      snack("Please Fill Out All Fields", "error");
      return false;
    }


    try {
      const data = {
        fname,
        lname,
        email,
        perm: 51, //51 - Reseller Demo Account
      };

      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1500);

    // Double check reseller email domain and the new user email domain matches
      if (appState?.auth?.userInfo?.resellerId ) {
        const verifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/reseller/verify-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': appState.auth.token,
          },
          body: JSON.stringify({
            id: appState.auth.userInfo.resellerId,
            domain: email.split('@')[1],
          }),
        });

        const json = await verifyResponse.json();

        if ( !json.success ) {
          json.errors.length && json.errors[0].type !== 'email' 
            ? snack(json.errors[0].msg, 'error') 
            : snack("Unable to send an invitation. User's email domain does not match the company's email domain on the system.", 'error');

          return false;
        }
      } else {
        snack("Unable to send invitation, please re-login and try again. If the problem persists, please contact support.", 'error')
        return false;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/addUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        setDefaultObject();
        getUsers && getUsers();
        handleUserFeatureComplete();
        return true;
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          switch (error.type) {
            case "fname":
              handleErrorsChange("fnameError", true);
              break;
            case "lname":
              handleErrorsChange("lnameError", true);
              break;
            case "email":
              handleErrorsChange("emailError", true);
              break;
          }

          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleUserInfoChange = (key, value) => {
    setUserInfo({ ...userInfo, [key]: value });
  };

  const handleErrorsChange = (key, value) => {
    setErrors({ ...errors, key, value });
  };

  const handleClose = () => {
    setOpenUserModal(false);
    setDefaultObject();
  };

  const handleRestart = async () => {
    setOpenUserModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenUserModal(true);
    }, 500);
  };

  return (
    <div>
      <ResponsiveDialog
        openState={openUserModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title="ADD A NEW DEMO ACCOUNT"
        stepsArray={[
          {
            label: "Enter Name and Email of the new demo account user.",
            nextFunction: handleSubmitUser,
            validator: true,
            showProgress: true,
            child: (
              <EnterUserInfo
                userInfo={userInfo}
                errors={errors}
                handleUserInfoChange={handleUserInfoChange}
              />
            ),
          },
          {
            label: "LOADING",
            child: (
              <Loading
                header="Creating Invitation"
                desc="MiSensors is currently creating email invitation for your new demo account user."
              />
            ),
          },
          {
            label: "COMPLETE", // Because is the finish
            child: (
              <Complete
                header="Success! Your new demo account user invitation has been sent."
                desc=" You may now add additional demo account user by selecting the option below."
                completeButtons={[
                  <ImageButton
                    onClick={() => {
                      handleRestart();
                    }}
                    image={marker}
                    action="ADD"
                    text="DEMO ACCOUNT"
                  />,
                ]}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
