// react imports
import React, { useContext, useEffect, useState } from "react";

// component imports

import ManageAlertSensorCell from "./ManageAlertSensorCell";
// import fetchAll from "../common/api/fetchAll";
import Tile from "../Dashboard/Tile.js";

import ActiveAlertsList from "./ActiveAlertsList";

import HeaderBar from "../Generic/HeaderBar";
import { AppContext } from "./../../AppContext";
import IconSelector from "./IconSelector";
import LoadingSpinner from "../Generic/LoadingSpinner";
// import ManagerAlertList from "./ManagerAlertList";

// Libraries imports
import red from "@material-ui/core/colors/red";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { navigate, A } from "hookrouter";
import MUIDataTable from "mui-datatables";
import { Grid, Tooltip, Typography, Box, FormControl, Select, MenuItem, Card } from "@material-ui/core";

// Icon imports
import SensorIcon from "../../img/icons/SensorIcon";
import AlertIcon from "@material-ui/icons/Announcement";
import LocationIcon from "@material-ui/icons/Room";

import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  MuiContainer: {
    root: {
      padding: "0",
    },
  },
  devices: {
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    maxWidth: 200,
    marginTop: -10,
    marginBottom: -10,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      bottom: 12,
      marginTop: 0,
      marginBottom: 0,
      whiteSpace: "wrap",
      overflow: "visible",
      maxWidth: "auto",
      margin: "auto",
    },
  },
  alertNameText: {
    marginLeft: 25,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  root: {
    // width: "100%",
    // marginTop: "5%",
    // marginBottom: "5%",
    // padding: "10px",
  },
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  alertsCards: {
    marginBottom: theme.spacing(2),
  },
  currentAlertsTable: {
    margin: theme.spacing(2, 0),
  },
  table: {
    margin: theme.spacing(4, 0),
    "& tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.type === "light" ? grey[50] : grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: theme.palette.type === "light" ? "white" : grey[800],
    },
  },
  breadcrumbs: {
    color:
      theme.palette.type === "light" ? "#051F34" : theme.palette.text.primary,
  },
  formSelect: {
    width: "100%",
  },
}));

const AllAlerts = (props) => {
  const log = window.log('AllAlerts');
  const { getAlertCount } = props;
  const classes = useStyles();

  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    appState,
    setLogoutState,
    setOpenAlertModal,
    setOpenAddingModal,
    openLocationModal,
    setAlertCount,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
    snack,
  } = useContext(AppContext);

  const [state, setState] = useState();
  const [activeAlerts, setActiveAlerts] = useState("-");
  const [locationsWithAlerts, setLocationsWithAlerts] = useState("-");
  const [sensorsWithAlerts, setSensorsWithAlerts] = useState("-");
  const [temperatureAlerts, setTemperatureAlerts] = useState("-");
  const [humidityAlerts, setHumidityAlerts] = useState("-");
  const [pressureAlerts, setPressureAlerts] = useState("-");
  const [motionAlerts, setMotionAlerts] = useState("-");
  const [lightAlerts, setLightAlerts] = useState("-");
  const [panicButtonAlert, setPanicButtonAlerts] = useState("-");
  const [proximityAlerts, setProximityAlerts] = useState("-");
  const [doorAlerts, setDoorAlerts] = useState("-");
  const [alertData, setAlertData] = useState([]);
  const [alertCounts, setAlertCounts] = useState({
    tempAlertCount: 0,
    humidAlertCount: 0,
    pressAlertCount: 0,
    motionAlertCount: 0,
    lightAlertCount: 0,
    panicButtonAlertCount: 0,
    proximityAlertCount: 0,
    doorOpenAlertCount: 0,
  });
  const [alertsArray, setAlertsArray] = useState([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);

  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [networkErr, setNetworkErr] = useState(false);

  let icons = [
    <TemperatureSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <HumiditySVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <PressureSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <MotionDetectionSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <LightSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <PanicSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <ProximitySVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <DoorSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
  ];

  const [columns, setColumns] = useState([
    { name: "alertDescription", label: "Name" },
    { name: "alertId", label: "ID" },
    {
      name: "values",
      label: "Temperature",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "temperature") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "Pressure",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "pressure") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "Humidity",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "humidity") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "Battery",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "battery") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "X-Accel",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "xAccel") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "Y-Accel",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "yAccel") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "Z-Accel",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "zAccel") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "Light",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "light") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    {
      name: "values",
      label: "RSSI",
      options: {
        customBodyRender: (values, tableMeta) => {
          const result = values.map((value) => {
            let str = "";
            if (value.alertValueMeasurementType === "rssi") {
              if (value.alertValueRangeType === "1") {
                str = `Above ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "2") {
                str = `Below ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "3") {
                str = `Outside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
              if (value.alertValueRangeType === "4") {
                str = `Inside of ${value.alertValueThresholdLow} and ${value.alertValueThresholdHigh}`;
              }
            }
            return str;
          });
          return result;
        },
        display: "false",
      },
    },
    { name: "countOfOccurrences", label: "Count of Occurrences" },
    { name: "lastOccurrence", label: "Last Occurrence" },
    {
      name: "sensors",
      label: "Assigned Devices",
      options: {
        customBodyRender: (sensors, tableMeta) => {
          if (sensors.length === 0) {
            return "Not assigned";
          } else {
            let devices = "";
            sensors.forEach((sensor) => {
              if (sensor.sensorName) {
                devices = devices + `, ${sensor.sensorName}`;
              } else {
                devices = devices + `, ${sensor.sensorId}`;
              }
            });
            devices = devices.replace(/(^,)|(,$)/g, "");
            return (
              <Tooltip title={devices} placement="top">
                <Typography noWrap className={classes.devices}>
                  {devices}
                </Typography>
              </Tooltip>
            );
          }
        },
      },
    },
  ]);

  const setSelectedAlertsArray = (index, alertsArray) => {
    log(alertsArray);
    let tmpAlerts = [];
    alertsArray.map((alert) => {
      //Grab all alerts
      if (index == 8) {
        tmpAlerts.push(alert);
        //Move the leading Icon back by 1 to accomodate for the all seletor
      } else if (index == alert.info.leadingIcon) {
        tmpAlerts.push(alert);
      }
    });
    setSelectedAlerts(tmpAlerts);
  };

  const fetchAllAlertsPage = async () => {
    log("fetchAlertCounts: Inside of fetchAlertCounts");

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/alert/allPage",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await response.json();
      log("json:", json);

      log("Looking at json ", json);
      if (json.success) {
        setAlertData(json.data.alerts);
        setActiveAlerts(json.data.activeAlertCount);
        setLocationsWithAlerts(json.data.locationsWithActiveAlerts);
        setSensorsWithAlerts(json.data.sensorsWithActiveAlerts);
        //This is for the app bar
        setAlertCount(json.data.activeAlertCount);
        //This is for the alert selector bar
        setAlertCounts(json.data.alertCounts);
        setAlertsArray(json.data.alertsArray);
        setSelectedAlertsArray(selectedAlert, json.data.alertsArray);
        log("Looking at the alert counts\n", json.data.alertCounts);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
      setNetworkErr(true);
    }
    setInitialPageLoad(false);
  };

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
  }, [appState.auth.userInfo]);

  useEffect(() => {
    fetchAllAlertsPage();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
  ]);

  // useEffect(() => {
  //   getAlerts();
  // }, []);

  const options = {
    onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    selectableRows: "none",
    print: false,
    download: false,
  };

  /**
   * open dialog to add an alert
   */
  const openDialog = () => {
    setOpenAlertModal(true);
  };

  /**
   * close dialog to add an alert
   */
  const handleClose = () => {
    setOpenAlertModal(false);
  };

  const [selectedAlert, setSelectedAlert] = useState(8);

  const alertTiles = [
    {
      id: 1,
      entityName: "ACTIVE ALERTS",
      underlineColor: theme.palette.primary.main,
      numberOfEntities: activeAlerts,
      icon: (
        <AlertIcon
          style={{
            fontSize: 48,
            color: red[500],
          }}
        />
      ),
    },
    {
      id: 2,
      entityName: "LOCATIONS WITH ALERTS",
      underlineColor: theme.palette.primary.main,
      numberOfEntities: locationsWithAlerts,
      icon: (
        <LocationIcon
          color="primary"
          style={{
            fontSize: 48,
          }}
        />
      ),
    },
    {
      id: 1,
      entityName: "SENSORS WITH ALERTS",
      underlineColor: theme.palette.primary.main,
      numberOfEntities: sensorsWithAlerts,
      icon: (
        <SensorIcon
          style={{
            fontSize: 48,
            color: theme.palette.primary.main,
          }}
        />
      ),
    },
  ];

  /**
   * Alert types
   */

  const [alertTypes, setAlertTypes] = useState();

  useEffect(() => {
    setAlertTypes([
      {
        label: "Temperature",
        icon: icons[0],
        text: alertCounts.tempAlertCount,
      },
      {
        label: "Humidity",
        icon: icons[1],
        text: alertCounts.humidAlertCount,
      },
      {
        label: "Pressure",
        icon: icons[2],
        text: alertCounts.pressAlertCount,
      },
      {
        label: "Motion Detection",
        icon: icons[3],
        text: alertCounts.motionAlertCount,
      },
      {
        label: "Light Detection",
        icon: icons[4],
        text: alertCounts.lightAlertCount,
      },
      {
        label: "Panic Button",
        icon: icons[5],
        text: alertCounts.panicButtonAlertCount,
      },
      {
        label: "Proximity",
        icon: icons[6],
        text: alertCounts.proximityAlertCount,
      },
      {
        label: "Open Door",
        icon: icons[7],
        text: alertCounts.doorOpenAlertCount,
      },
      {
        label: smallDevice ? "All Alerts" : "All",
        icon: <Typography variant="h5">All</Typography>,
        text: "",
      },
    ]);
  }, [alertCounts]);

  /**
   * manage alerts table
   */
  const onRowClick = (rowData, rowMeta) => {
    // log("Looking at rowMeta ", rowMeta);
    const element = alertData[rowMeta.dataIndex];
    // log("Looking at element ", element);
    // log(element["alert"]);
    navigate(`/alert/${element["alert"]["alert_id"]}`);
  };
  const optionsManageAlerts = {
    // onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    selectableRows: "none",
    print: false,
    download: false,
    responsive: "standard",
    filter: false,
    jumpToPage: true,
    viewColumns: false,
    // responsive: 'vertical',
    onRowClick: onRowClick,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (
          col.alert_name &&
          col.alert_name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          isFound = true;
        }
      });

      return isFound;
    },
  };

  const columnsManageAlerts = [
    {
      name: "alert_name",
      label: "Alert Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              key={tableMeta.rowIndex}
              className={classes.alertNameText}
              style={{
                cursor: "pointer",
                display: "flex",
              }}
            >
              {value}
            </Typography>
          );
        },
        customHeadRender: (columnMeta) => (
          <th
            key={`alerts__manager__col__${columnMeta.index}`}
            style={{
              // borderBottom: "1px solid rgba(224, 224, 224, 1)",
              // backgroundColor: theme.palette.background.paper,
              display: "none",
            }}
          />
        ),
      },
    },
    {
      name: "alert",
      label: " ",

      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          log(value.sensorProfile);
          return (
            <Box key={tableMeta.rowIndex} style={{ cursor: "pointer" }}>
              <ManageAlertSensorCell
                sensorIcons={smallDevice ? {} : value.sensorProfile}
                isSmall={smallDevice}
                numberOfAlerts={value.numberOfAlerts}
              ></ManageAlertSensorCell>
            </Box>
          );
        },
        customHeadRender: (columnMeta) => (
          <th
            key={`alerts__manager__col__${columnMeta.index}`}
            style={{
              // borderBottom: "1px solid rgba(224, 224, 224, 1)",
              // backgroundColor: theme.palette.background.paper,
              display: "none",
            }}
          />
        ),
      },
    },
  ];

  const dataManageAlerts = [
    {
      alert: {
        sensorProfile: {
          temperature: true,
          humidity: true,
          pressure: true,
          motionDetection: true,
          lightDetection: true,
          panicButton: true,
          proximity: true,
          openDoor: true,
        },
        numberOfAlerts: "9",
      },
      alert_name: "Lorem Alert",
    },
    {
      alert: {
        sensorProfile: {
          temperature: true,
          humidity: true,
          pressure: true,
          motionDetection: true,
          lightDetection: true,
          panicButton: true,
          proximity: true,
          openDoor: true,
        },
        numberOfAlerts: "9",
      },
      alert_name: "Lorem Alert",
    },
  ];

  const renderManageTable = () => {
    if (userInfo.perm === 0 || userInfo.perm === 2 || userInfo.perm === 99) {
      return (
        <MUIDataTable
          className={classes.table}
          options={optionsManageAlerts}
          columns={columnsManageAlerts}
          data={alertData}
          title={
            <Grid container>
              <Typography className={classes.titleText}>
                MANAGE ALERTS
              </Typography>
            </Grid>
          }
        />
      );
    }
    return null;
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/">
        Dashboard
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );
  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : networkErr ? null : (
    <div className={classes.root}>
      {/* <AlertsFormModal
          handleClose={handleClose}
          fetchAllAlertsPage={fetchAllAlertsPage}
          getAlertCount={getAlertCount}
        /> */}
      <HeaderBar
        leadingIcon={<AlertIcon style={{ fontSize: 32 }} color="primary" />}
        // breadcrumbs={breadcrumbs}
        title="Alerts"
        searchInput={false}
        addButton={userInfo.perm === 0 || userInfo.perm === 2 || userInfo.perm === 99}
        buttonAddTitle="ADD ALERT"
        buttonAddEvent={openAddModal}
        searchCallback={(value) => {}}
      />
      <Grid
        container
        spacing={2}
        justify="center"
        className={classes.alertsCards}
      >
        {alertTiles.map((u, index) => (
          <Grid item xs={12} md={4} key={`alerts__tiles__${index}`}>
            <Tile
              entityName={u.entityName}
              underlineColor={u.underlineColor}
              numberOfEntities={u.numberOfEntities}
              icon={u.icon}
            />
          </Grid>
        ))}
      </Grid>
      {smallDevice ? (
        <Grid item xs={12}>
        <Card>
          <FormControl variant="outlined" className={classes.formSelect}>
            <Select
              labelId="alert-profile-range-type"
              id="alert-profile-range-select"
              className={classes.select}
              inputProps={{
                name: 'Alert type',
                id: "range-type-selector",
              }}
              onChange={(event) => {
                log("Looking at index " + event.target.value);
                setSelectedAlert(event.target.value);
                setSelectedAlertsArray(event.target.value, alertsArray);
              }}
              value={selectedAlert}
            >
              {alertTypes.map(
                ({ label }, index) => {
                  
                  return (
                    <MenuItem
                      key={`alert__range__setting__option__${index}`}
                      value={index}
                    >
                      {<em>{label}</em>}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        </Card>
      </Grid>
      ) : 
      
      (<IconSelector
        items={alertTypes}
        selectedIndexCallback={(index) => {
          log("Looking at index " + index);
          setSelectedAlert(index);
          setSelectedAlertsArray(index, alertsArray);
        }}
      ></IconSelector>)}
      
      
      {selectedAlert !== null ? (
        <ActiveAlertsList
          getAlertCount={getAlertCount}
          title={`${alertTypes[selectedAlert].label.toUpperCase()} ALERTS`}
          // alerts={selectedAlertsArray}
          icons={icons}
          alerts={selectedAlerts}
          fetchAllAlertsPage={fetchAllAlertsPage}
        />
      ) : (
        /* Table when an alert is selected in the icon selector */
        /* Table when there are no alerts selected in the icon selector */
        <ActiveAlertsList
          getAlertCount={getAlertCount}
          title="ACTIVE ALERTS"
          icons={icons}
          alerts={alertsArray}
          fetchAllAlertsPage={fetchAllAlertsPage}
        />
      )}
      {renderManageTable()}
    </div>
  );
};

export default AllAlerts;
