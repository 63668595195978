import React from "react";

import GenericList from "../Dashboard/GenericList";

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  tableBody: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.type === "light" ? grey[100] : grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: theme.palette.type === "light" ? "white" : grey[800],
    },
  },
}));

/**
 * ItemList for
 * the list in the location
 *
 * @props
 * items: Array[Object]
 * collumns
 * actions
 * checklist: boolean
 * title: String
 * 
 * 
 */
export default function ItemList(props) {
  const { title, items, columns, action, checklist } = props;

  const classes = useStyles();

  return (
    <GenericList
      title={title}
      onClick={() => {}}
      showActions={false}
      action={action}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {
                checklist ? (
                <TableCell  
                  align="left" 
                  className={classes.columnHeader}>
                </TableCell>) : null
              }
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth }}
                  className={classes.columnHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {items.map((row, index) => {
              return (
                <TableRow
                  key={`item__list__row__${index}`}
                >
                    {
                      checklist ? (
                      <TableCell  align="left">
                        <Checkbox
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </TableCell>) : null
                    }
                  {columns.map((column, j) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        // key={column.id}
                        key={`item__list__row__${index}__col__${j}`}
                        align="left"
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </GenericList>
  );
}
