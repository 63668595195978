import React from "react";
import { SvgIcon } from "@material-ui/core";

const ListViewIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23">
        <path className="a" d="M27.578,15.858a1.859,1.859,0,0,1-1.858,1.858H10.858a1.858,1.858,0,0,1,0-3.716H25.72A1.859,1.859,0,0,1,27.578,15.858Z" transform="translate(-2.578 -4.358)"/>
        <path className="a" d="M27.578,5.858A1.859,1.859,0,0,1,25.72,7.716H10.858a1.858,1.858,0,0,1,0-3.716H25.72A1.859,1.859,0,0,1,27.578,5.858Z" transform="translate(-2.578 -4)"/>
        <path className="a" d="M27.578,25.858a1.859,1.859,0,0,1-1.858,1.858H10.858a1.858,1.858,0,1,1,0-3.716H25.72A1.859,1.859,0,0,1,27.578,25.858Z" transform="translate(-2.578 -4.716)"/>
        <path className="a" d="M3,5.858A1.858,1.858,0,1,0,4.858,4,1.86,1.86,0,0,0,3,5.858Z" transform="translate(-3 -4)"/>
        <path className="a" d="M3,15.858A1.858,1.858,0,1,0,4.858,14,1.86,1.86,0,0,0,3,15.858Z" transform="translate(-3 -4.358)"/>
        <path className="a" d="M3,25.858A1.858,1.858,0,1,0,4.858,24,1.86,1.86,0,0,0,3,25.858Z" transform="translate(-3 -4.716)"/>
      </svg>
    </SvgIcon>
  );
};

export default ListViewIcon;
