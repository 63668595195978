import React, { useContext } from "react";
import { AppContext } from "../../../AppContext";
import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  selectMenu: {
    maxHeight: 58,
  },
}));

/**
 * Enter User Info
 * @param {Object} props
 * @props
 * userInfo: {
 *  fname: string,
 *  lname: string,
 *  email: string,
 *  permissions: number
 * }
 * errors: {
 *  fnameError: bool,
 *  lnameError: bool,
 *  emailError: bool,
 *  permissionsError: bool
 * }
 * handleUserInfoChange: () => {}
 */
export default function EnterUserInfo(props) {
  const {
    userInfo: { fname, lname, email },
    errors: { fnameError, lnameError, emailError },
    handleUserInfoChange,
  } = props;

  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header="Enter Name and Email of the new demo account user."
        desc="Once completed your new demo account user will receive and invitation email for them to finish the sign up process."
      />
      <Grid item xs={12}>
        <TextField
          name="firstName"
          error={fnameError}
          className={classes.textField}
          fullWidth
          variant="outlined"
          placeholder="First Name*"
          label="First Name*"
          value={fname}
          onChange={(e) => handleUserInfoChange("fname", e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="lastName"
          error={lnameError}
          className={classes.textField}
          fullWidth
          variant="outlined"
          placeholder="Last Name*"
          label="Last Name*"
          value={lname}
          onChange={(e) => handleUserInfoChange("lname", e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="email"
          error={emailError}
          className={classes.textField}
          fullWidth
          variant="outlined"
          placeholder="Email*"
          label="Email*"
          value={email}
          onChange={(e) => handleUserInfoChange("email", e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
