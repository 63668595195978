import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AlertIcon from "@material-ui/icons/Announcement";
import InformationTile from "./InformationTile";
import CardActions from "../Generic/CardActions";
import SliderTile from "../Generic/SliderTile";
import clsx from "clsx";
import DeleteAlertFormModal from "./DeleteAlertFormModal";
import AlertsFormModal from "./AlertsFormModal";
import NotFoundPageLoggedIn from "../Auth/NotFoundPageLoggedIn";
import { A } from "hookrouter";

import React, { useContext, useEffect, useState, useRef } from "react";

import SensorIcon from "../../img/icons/SensorIcon";

import BreadCrumb from "../Generic/BreadCrumb";

import { AppContext } from "./../../AppContext";

import DetailsCard from "../Generic/DetailsCard";
import grey from "@material-ui/core/colors/grey";

import GenericList from "../Dashboard/GenericList";
import ListElement from "../Generic/ListElement";

// Icons Import

import TemperatureSVG from "../../icons/TemperatureSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import PressureSVG from "../../icons/PressureSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import LightSVG from "../../icons/LightSVG";
import PanicSVG from "../../icons/PanicSVG";
// import ProximitySVG from "../../icons/ProximitySVG";
import DoorSVG from "../../icons/DoorSVG";
import BatterySVG from "../../icons/BatterySVG";
import moment from "moment-timezone";

import LoadingSpinner from "../Generic/LoadingSpinner";

const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles((theme) => ({
  MuiContainer: {
    root: {
      padding: "0",
    },
  },
  centerTextColor: {
    textTransform: "uppercase",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  devices: {
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    maxWidth: 200,
    marginTop: -10,
    marginBottom: -10,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      bottom: 12,
      marginTop: 0,
      marginBottom: 0,
      whiteSpace: "wrap",
      overflow: "visible",
      maxWidth: "auto",
      margin: "auto",
    },
  },
  center: {
    justifyContent: "center",
  },
  root: {
    // width: "100%",
    // marginTop: "5%",
    // marginBottom: "5%",
    // padding: "10px",
  },
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  alertsCards: {
    marginBottom: theme.spacing(2),
  },
  currentAlertsTable: {
    margin: theme.spacing(2, 0),
  },
  table: {
    margin: theme.spacing(2, 0),
    "& tr:nth-of-type(odd)": {
      backgroundColor: theme.palette.type === "light" ? grey[50] : grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: theme.palette.type === "light" ? "white" : grey[800],
    },
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  alertCardContainer: {
    height: 143,
    [theme.breakpoints.down("xs")]: {
      height: 225,
    },
  },
  sensorTableContainer: {
    marginTop: "1.5rem",
    marginLeft: "15px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "revert",
      marginTop: 0,
    },
  },
  cardContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
    },
  },
  singleCardContainer: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px !important",
      marginBottom: 18,
    },
  },
}));

const ManageAlertsPage = (props) => {
  const log = window.log("ManageAlertsPage");
  const { id, drawer } = props;

  const classes = useStyles();

  const theme = useTheme();

  const {
    appState,
    setLogoutState,
    setOpenEditAlertModal,
    setOpenDeleteAlertModal,
    setOpenAlertWarning,
    openAlertModal,
    setOpenAlertModal,
    snack,
    getAlertCount,
    openEditAlertModal,
    setAlertCount,
  } = useContext(AppContext);

  const [sensorProfile, setSensorProfile] = useState({
    temperature: false,
    humidity: false,
    pressure: false,
    motion_detection: false,
    motion_detection_advanced_X: false,
    motion_detection_advanced_Y: false,
    motion_detection_advanced_Z: false,
    light_detection: false,
    panic_button: false,
    proximity: false,
    open_door: false,
  });
  const [tempSlider, setTempSlider] = useState({
    subtitle: "-",
    minimumValue: -100,
    maximumValue: 100,
    unitMeasure: "°C",
    isRangeType: false,
    value: [0, 0],
  });
  const [humidSlider, setHumidSlider] = useState({
    subtitle: "-",
    minimumValue: 0,
    maximumValue: 100,
    unitMeasure: "%",
    isRangeType: false,
    value: [0, 0],
  });
  const [pressureSlider, setPressureSlider] = useState({
    subtitle: "-",
    minimumValue: 0,
    maximumValue: 2000,
    unitMeasure: "hPa",
    isRangeType: false,
    value: [0, 0],
  });
  const [lightSlider, setLightSlider] = useState({
    subtitle: "-",
    minimumValue: 0.01,
    maximumValue: 64000,
    unitMeasure: "lux",
    isRangeType: false,
    value: [0, 0],
  });
  const [motionSlider, setMotionSlider] = useState({
    subtitle: "-",
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: "g",
    isRangeType: false,
    value: [0, 0],
  });
  const [motionXSlider, setMotionXSlider] = useState({
    subtitle: "-",
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: "g",
    isRangeType: false,
    value: [0, 0],
  });
  const [motionYSlider, setMotionYSlider] = useState({
    subtitle: "-",
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: "g",
    isRangeType: false,
    value: [0, 0],
  });
  const [motionZSlider, setMotionZSlider] = useState({
    subtitle: "-",
    minimumValue: -50,
    maximumValue: 50,
    unitMeasure: "g",
    isRangeType: false,
    value: [0, 0],
  });
  const [doorSlider, setDoorSlider] = useState({
    value: 0,
  });

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    setUserInfo(appState.auth.userInfo);
    fetchAlert();
  }, [
    appState.auth.userInfo,
    appState.auth.userInfo.themePreferences.measurement,
  ]);
  const [alertName, setAlertName] = useState("-");
  const [editAlertName, setEditAlertName] = useState("");
  const [lastUpdated, setLastUpdated] = useState("-");
  const [sensorCount, setSensorCount] = useState("-");
  const [alert, setAlert] = useState({});
  const [openEditAlertWarning, setOpenEditAlertWarning] = useState(false);
  const [alertCondition, setAlertCondition] = useState({});
  const [pairedSensors, setPairedSensors] = useState([]);

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const devicesDummy = Array(5).fill({
    sensorName: "Lorem Sensor",
    locationName: "Location ipsum",
  });

  useEffect(() => {
    if (initialPageLoad) return;
    setTimeout(() => {
      fetchAlert();
    }, 500);
  }, [drawer]);

  useEffect(() => {
    fetchAlert();
  }, [openEditAlertModal]);

  const fetchAlert = async () => {
    try {
      //Get the UTC offset
      let date = new Date();
      let UTCOffset = date.getTimezoneOffset();

      log("Looking at id " + id);
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/alert/get?alertId=${id}&Offset=${UTCOffset}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await request.json();
      log("json:", json);

      if (json.success) {
        log("Looking at sensor profile ", json.data.alert[0].sensorProfile);

        try {
          const parsedAlertCondition = JSON.parse(json.data.alert[0].alert);
          setAlertCondition(parsedAlertCondition);
        } catch (err) {
          log("Error parsing alert condition");
        }

        setAlert(json.data.alert[0]);
        setSensorProfile(json.data.alert[0].sensorProfile);
        if (json.data.alert[0].sliders.tempSlider) {
          // setTempSlider(json.data.alert[0].sliders.tempSlider);
          let tempSlider = json.data.alert[0].sliders.tempSlider;

          //Convert the Fahrenheit and set maxTemp slider to 85 Cels
          if (
            appState.auth.userInfo.themePreferences.measurement === "imperial"
          ) {
            tempSlider.unitMeasure = "°F";
            tempSlider.maximumValue = 185;
            tempSlider.value[0] = tempSlider.value[0] * (9 / 5) + 32;
            tempSlider.value[1] = tempSlider.value[1] * (9 / 5) + 32;
            tempSlider.value[0] = tempSlider.value[0].toFixed(2);
            tempSlider.value[1] = tempSlider.value[1].toFixed(2);
          }
          setTempSlider(tempSlider);
        }
        if (json.data.alert[0].sliders.humidSlider) {
          // if (
          //   json.data.alert[0].sliders.humidSlider.subtitle === "Below Value" ||
          //   json.data.alert[0].sliders.humidSlider.subtitle === "Above Value"
          // ) {
          //   setHumidSlider({
          //     ...json.data.alert[0].sliders.humidSlider,
          //     isRangeType: false,
          //   });
          // } else {
          setHumidSlider(json.data.alert[0].sliders.humidSlider);
          // }
        }
        if (json.data.alert[0].sliders.pressureSlider) {
          setPressureSlider(json.data.alert[0].sliders.pressureSlider);
        }
        if (json.data.alert[0].sliders.lightSlider) {
          setLightSlider(json.data.alert[0].sliders.lightSlider);
        }
        if (json.data.alert[0].sliders.doorSlider) {
          setDoorSlider(json.data.alert[0].sliders.doorSlider);
        }
        if (json.data.alert[0].sliders.motionSlider) {
          const motionObj = json.data.alert[0].sliders.motionSlider;
          const motionX = motionObj["motion_detection_advanced_X"];
          const motionY = motionObj["motion_detection_advanced_Y"];
          const motionZ = motionObj["motion_detection_advanced_Z"];
          let motion = null;
          //Check if we should toggle basic or advanced setting
          if (
            motionX.subtitle === motionY.subtitle &&
            motionX.subtitle === motionZ.subtitle &&
            motionX.value[0] === motionY.value[0] &&
            motionX.value[1] === motionZ.value[1] &&
            motionX.value[0] === motionZ.value[0] &&
            motionX.value[1] === motionZ.value[1]
          ) {
            //Pass
            motion = motionX;
          } else {
            motion = {
              subtitle: "-",
              minimumValue: -50,
              maximumValue: 50,
              unitMeasure: "g",
              isRangeType: false,
              value: [0, 0],
            };
            setSensorProfile((prevState) => {
              return {
                ...prevState,
                motion_detection_advanced_X: true,
                motion_detection_advanced_Y: true,
                motion_detection_advanced_Z: true,
              };
            });
          }
          setMotionSlider(motion);
          setMotionXSlider(motionX);
          setMotionYSlider(motionY);
          setMotionZSlider(motionZ);
        }

        setAlertName(json.data.alert[0].alertName);
        setEditAlertName(json.data.alert[0].alertName);
        setLastUpdated(json.data.alert[0].lastUpdatedTime);
        setSensorCount(json.data.alert[0].sensorCount);
        setPairedSensors(json.data.alert[0].sensorsAssignedToAlert);
        getAlertCount();
        setAlertCount(json.data.alert[0].alertCount);
      } else {
        setAlert(null);
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }

    setInitialPageLoad(false);
  };

  const renderLastUpdated = () => {
    if (!lastUpdated || lastUpdated === "-") return "-";
    let userTimeZone = "";
    let time = "";
    if (
      Intl &&
      Intl.DateTimeFormat() &&
      Intl.DateTimeFormat().resolvedOptions()
    ) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (
      appState.auth.userInfo.themePreferences &&
      appState.auth.userInfo.themePreferences.timeFormat === "12hr"
    ) {
      time = moment
        .tz(lastUpdated, userTimeZone)
        .format("ddd MMM DD YYYY hh:mm:ssa z");
    } else {
      time = moment
        .tz(lastUpdated, userTimeZone)
        .format("ddd MMM DD YYYY kk:mm:ss z");
    }

    return time;
  };

  const rangeCards = [
    {
      title: "TEMPERATURE",
      subtitle: tempSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: tempSlider,
      enabled: sensorProfile.temperature,
    },
    {
      title: "HUMIDITY",
      subtitle: humidSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: humidSlider,
      enabled: sensorProfile.humidity,
    },
    {
      title: "PRESSURE",
      subtitle: pressureSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: pressureSlider,
      enabled: sensorProfile.pressure,
    },
    {
      title: "IMPACT DETECTION",
      subtitle: motionSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: motionSlider,
      enabled:
        sensorProfile.motion_detection_advanced_X ||
        sensorProfile.motion_detection_advanced_Y ||
        sensorProfile.motion_detection_advanced_Z
          ? false
          : sensorProfile.motion_detection,
    },
    {
      title: "IMPACT DETECTION X",
      subtitle: motionXSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: motionXSlider,
      enabled: sensorProfile.motion_detection_advanced_X,
    },
    {
      title: "IMPACT DETECTION Y",
      subtitle: motionYSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: motionYSlider,
      enabled: sensorProfile.motion_detection_advanced_Y,
    },
    {
      title: "IMPACT DETECTION Z",
      subtitle: motionZSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: motionZSlider,
      enabled: sensorProfile.motion_detection_advanced_Z,
    },
    {
      title: "LIGHT DETECTION",
      subtitle: lightSlider.subtitle,
      underlineColor: theme.palette.primary.main,
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      slider: lightSlider,
      enabled: sensorProfile.light_detection,
    },
  ];
  const informationCards = [
    {
      title: "PANIC BUTTON",
      centerText: "You will receive alerts when button is pressed",
      underlineColor: theme.palette.primary.main,
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      enabled: sensorProfile.panic_button,
    },
    {
      title: "DOOR OPEN/CLOSED",
      centerText:
        doorSlider.value === 1
          ? "You will receive alerts when door is opened"
          : doorSlider.value === 2
          ? "You will receive alerts when door is closed"
          : "", //0 - Not enabled, 1 - Opened, 2 - Closed
      underlineColor: theme.palette.primary.main,
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
      enabled: sensorProfile.open_door,
    },
    {
      title: "Battery",
      centerText: "You will receive alerts when battery is low",
      underlineColor: theme.palette.primary.main,
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 28, width: 28 }}
        />
      ),
      name: sensorProfile.battery,
    },
  ];
  const editModalRef = useRef();
  const handleOpenEditAlertModal = () => {
    // editModalRef.current.initState();
    // setOpenEditAlertModal(true);
    setOpenAlertModal(true);
    // setOpenAlertWarning(true);
    // setOpenEditAlertWarning(true);
  };

  const handleOpenDeleteAlertModal = () => {
    log("Delete click");
    setOpenDeleteAlertModal(true);
  };

  const handleCancelEditWarning = () => {
    setOpenEditAlertWarning(false);
  };

  const handleContinueEditWarning = () => {
    return true;
  };

  const alertSliders = () => {
    return {
      temperature: tempSlider,
      humidity: humidSlider,
      pressure: pressureSlider,
      light_detection: lightSlider,
      motion_detection: motionSlider,
      motion_detection_advanced_X: motionXSlider,
      motion_detection_advanced_Y: motionYSlider,
      motion_detection_advanced_Z: motionZSlider,
    };
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/alert/all">
        Alerts
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const cards = () => {
    const cards = [];
    rangeCards.forEach((card, index) => {
      if (card.enabled) {
        cards.push({
          item: card,
          type: "range",
        });
      }
    });
    informationCards.forEach((card, index) => {
      if (card.enabled) {
        cards.push({
          item: card,
          type: "information",
        });
      }
    });

    if ( cards.length ) {
      cards[cards.length - 1].lastOne = cards.length % 2 !== 0;
    }

    return cards;
  };
  return alert ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div className={classes.root}>
        <AlertsFormModal
          alertId={id}
          isEdit={true}
          alertName={alertName}
          fetchAlert={fetchAlert}
          alertSliders={alertSliders()}
          editAlertName={editAlertName}
          pairedSensors={pairedSensors}
          alertCondition={alertCondition}
          editAlertProfiles={sensorProfile}
          setEditAlertName={setEditAlertName}
          openEditAlertWarning={openEditAlertWarning}
          handleCancelEditWarning={handleCancelEditWarning}
          setOpenEditAlertWarning={setOpenEditAlertWarning}
          type="edit"
        />
        <DeleteAlertFormModal alertId={id} />
        <BreadCrumb
          breadcrumbs={breadcrumbs}
          leadingIcon={<AlertIcon style={{ fontSize: 32 }} color="primary" />}
          title={`${alertName}`}
        />

        <Grid
          style={{ marginTop: "1rem" }}
          container
          // spacing={2}
        >
          <Grid item xs={12} className={classes.alertCardContainer}>
            <DetailsCard
              singleAlertCard={true}
              enableZero={true}
              showAvatar={false}
              name={`Alert Name: ${alertName}`}
              // displayDivider="none"
              buttons={
                appState.auth.userInfo.perm !== 1 && (
                  <CardActions
                    editEvent={() => {
                      handleOpenEditAlertModal();
                    }}
                    deleteEvent={() => {
                      handleOpenDeleteAlertModal();
                    }}
                  />
                )
              }
              subtitle={`Last Updated:`}
              subtitle2={`${renderLastUpdated()}`}
              // locationName={"Location Name"}
              trailingItems={[
                {
                  icon: "",
                },
                {
                  value: sensorCount,
                  icon: <SensorIcon color="primary" style={{ fontSize: 32 }} />,
                },
              ]}
            />
          </Grid>
          <Grid
            className={classes.cardContainer}
            style={{ marginTop: "1rem" }}
            container
            item
            spacing={2}
            xs={12}
            sm={8}
          >
            {cards().map((card, index) => {
              return card.type === "range" ? (
                <Grid
                  className={classes.singleCardContainer}
                  key={index}
                  item
                  xs={12}
                  sm={12}
                  md={card.lastOne ? 12 : 6}
                >
                  <InformationTile
                    entityName={card.item.title}
                    entitySubtitle={card.item.subtitle}
                    underlineColor={card.item.underlineColor}
                    icon={card.item.icon}
                  >
                    <Box
                      fontWeight="fontWeightMedium"
                      className={clsx(classes.centerTextColor, classes.center)}
                      display="flex"
                      justifyContent="center"
                    >
                      <SliderTile
                        drawer={drawer}
                        unitMeasure={card.item.slider.unitMeasure}
                        values={card.item.slider.value}
                        isRangeType={card.item.slider.isRangeType}
                        maximumValue={card.item.slider.maximumValue}
                        minimumValue={card.item.slider.minimumValue}
                        type={card.item.subtitle}
                      />
                    </Box>
                  </InformationTile>
                </Grid>
              ) : (
                <Grid
                  className={classes.singleCardContainer}
                  key={index}
                  item
                  xs={12}
                  sm={12}
                  md={card.lastOne ? 12 : 6}
                >
                  <InformationTile
                    entityName={card.item.title}
                    entitySubtitle=""
                    underlineColor={card.item.underlineColor}
                    icon={card.item.icon}
                  >
                    <Box
                      fontWeight="fontWeightMedium"
                      className={clsx(classes.centerTextColor, classes.center)}
                      display="flex"
                      justifyContent="center"
                    >
                      <p>{card.item.centerText}</p>
                    </Box>
                  </InformationTile>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={4}
            spacing={2}
            className={classes.sensorTableContainer}
          >
            <Grid item xs={12}>
              <GenericList
                showActions={false}
                title="ASSIGNED SENSORS"
                data={pairedSensors}
                emptyBuilder={() => (
                  <ListElement
                    index={0}
                    title={
                      <Typography
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          fontWeight: "bold",
                          fontSize: 16,
                          color:
                            theme.palette.type === "light" ? grey[500] : "#FFF",
                        }}
                      >
                        No Matching Sensors
                      </Typography>
                    }
                  />
                )}
                itemBuilder={(item, index) => {
                  return (
                    <ListElement
                      index={index}
                      title={item.sensorName}
                      subtitle={item.locationName}
                    />
                  );
                }}
                useItemBuilder={true}
                // actionTitle="View All Alerts"
                // action={
                //   <IconButton
                //     aria-label="sort"
                //     onClick={() => {
                //       log("TODO: implement sorting of sensors");
                //     }}
                //   >
                //     <SortIcon />
                //   </IconButton>
                // }
              >
                {/* {pairedSensors.length === 0 ? (
                <ListElement
                  index={0}
                  title={
                    <Typography
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        fontWeight: "bold",
                        fontSize: 16,
                        color: grey[500],
                      }}
                    >
                      No Matching Sensors
                    </Typography>
                  }
                />
              ) : (
                pairedSensors.map((item, index) => {
                  return (
                    <ListElement
                      index={index}
                      title={item.sensorName}
                      subtitle={item.locationName}
                    />
                  );
                })
              )} */}
              </GenericList>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default ManageAlertsPage;
