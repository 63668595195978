import React from "react";
import { navigate } from "hookrouter";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Box } from "@material-ui/core";

import LoggedInLayout from "./LoggedInLayout";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  formContainer: {
    width: "80%",
  },
  linkForgotPassword: {
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    textDecoration: "none",
    fontSize: 16,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "2rem",
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: ".5rem",
    },
  },
}));

const NotFoundPage = (props) => {
  const classes = useStyles();

  const { subtitle } = props;
  const goToHomePage = async () => {
    navigate("/");
  };

  return (
    <LoggedInLayout subtitle={ subtitle ? subtitle : "This page isn't available. Sorry about that."}>
      <Box className={classes.formContainer}>
        <Grid container spacing={1}>
          <Grid container item xs={12} className={classes.buttons}>
            <Button
              id="submit"
              variant="outlined"
              className={classes.button}
              size="large"
              color="primary"
              onClick={() => window.history.back()}
            >
              Go Back
            </Button>
            <Button
              id="submit"
              variant="contained"
              className={classes.button}
              size="large"
              color="primary"
              onClick={() => goToHomePage("/")}
            >
              Dashboard
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LoggedInLayout>
  );
};

export default NotFoundPage;
