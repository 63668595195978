import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
/**
 *  103px is the height of the navbar and the padding of the container
 * , so it is fully centered in the screen with half of it 51.5
 */

const useStyles = makeStyles((theme) => ({
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  pageWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20%",
    height: "100%",
    width: "100%",
  },
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#08b3ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default function LoadingSpinner() {
  const classes = useStyles();

  return (
    <Box className={classes.pageWrapper}>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={8}
        className={classes.rightContainer}
      >
        <Paper style={{ height: 100, width: 100 }}>
          <Grid
            style={{ justifyContent: "center", display: "flex", marginTop: 28 }}
            item
          >
            <FacebookCircularProgress />
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}
