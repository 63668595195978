import React from "react";
import { SvgIcon } from "@material-ui/core";

const BatterySVG = (props) => {
  const { outlinecolor, filledcolor, volts } = props;

  /**
   * determines level of the battery and the color of the meter
   */
  const batteryLevel = () => {
    let level = "39"; // full level
    let color = "#59C754"; // full and green

    // mid level
    if (volts <= 2.9) {
      level = "30";
      color = "#59C754";
    }

    // low level
    if (volts <= 2.76) {
      level = "15";
      color = "#FF6A6A";
    }

    // if volts aren't being sent in
    if (!volts) {
      level = "33";
      color = filledcolor;
    }
    return [level, color];
  };

  return (
    <SvgIcon width="69.62" height="69.62" viewBox="0 0 69.62 69.62" {...props}>
      <path fill="none" d="M0,0H69.62V69.62H0Z" />
      <g transform="translate(17.226 63.844) rotate(-90)">
        <g transform="translate(0 4)">
          <path
            fill={outlinecolor}
            fillRule="evenodd"
            d="M819.547,55.547V76.829h46.11V55.547ZM819.574,52h46.057a3.55,3.55,0,0,1,3.573,3.536v3.557h3.547V73.282H869.2v3.557a3.57,3.57,0,0,1-3.573,3.536H819.574A3.55,3.55,0,0,1,816,76.839v-21.3A3.57,3.57,0,0,1,819.574,52Z"
            transform="translate(-816 -52)"
          />
          <rect
            fill={batteryLevel()[1]}
            width={batteryLevel()[0]}
            height="15"
            transform="translate(6.852 6.766)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default BatterySVG;
