import React from "react";
import { SvgIcon } from "@material-ui/core";

const PanicSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <path fill="none" d="M0,0H34V34H0Z" />
      <g transform="translate(5.667 1.417)">
        <g transform="translate(0 5.606)">
          <path
            fillRule="evenodd"
            fill={outlinecolor}
            d="M1079.165,148.565a7.319,7.319,0,0,0,7.177-7.175h0V135.65a1.435,1.435,0,1,0-2.87,0v.476h-.956v-2.386a1.435,1.435,0,1,0-2.87,0v1.429h-.958v-2.386a1.435,1.435,0,1,0-2.87,0v3.342h-.956V126.085a1.435,1.435,0,1,0-2.87,0v10.808c-1.969-2.107-4.563-4.421-5.625-3.353-1.04,1.046,1.641,3.927,5.383,10.226,1.687,2.838,3.821,4.8,7.416,4.8Zm8.134-7.175a8.132,8.132,0,0,1-8.133,8.132c-2.978,0-6.121-1.388-8.285-5.331-3.122-5.688-7.116-9.419-5.215-11.321,1.36-1.36,3.5-.055,5.366,1.694h0v-8.476a2.392,2.392,0,1,1,4.784,0v4.776a4.37,4.37,0,0,0,5.263.479,2.385,2.385,0,0,1,2.391,2.39v.013a2.4,2.4,0,0,1,3.828,1.917v5.728Z"
            transform="translate(-1064.893 -123.41)"
          />
          <path
            fill={outlinecolor}
            d="M1079.249,149.882c-2.52,0-6.045-.951-8.528-5.475-.99-1.8-2.068-3.41-3.02-4.828-2.093-3.115-3.6-5.366-2.146-6.824,1.187-1.187,2.964-.762,5.284,1.264v-7.847a2.67,2.67,0,1,1,5.341,0v4.28a2.646,2.646,0,0,1,1.156-.262,2.687,2.687,0,0,1,2.355,1.4,2.669,2.669,0,0,1,4.1,1.762,2.674,2.674,0,0,1,3.866,2.39v5.728A8.42,8.42,0,0,1,1079.249,149.882Zm-12.142-17.208a1.573,1.573,0,0,0-1.159.475c-1.133,1.133.271,3.224,2.214,6.12.958,1.428,2.044,3.046,3.046,4.87,2.353,4.287,5.672,5.186,8.041,5.186a7.863,7.863,0,0,0,7.854-7.854v-5.728a2.115,2.115,0,0,0-3.381-1.693l-.445.333v-.557a2.111,2.111,0,0,0-3.512-1.592l-.3.269-.14-.381a2.121,2.121,0,0,0-1.985-1.376,2.094,2.094,0,0,0-1.268.421l-.445.334v-5.331a2.114,2.114,0,1,0-4.227,0v9.116l-.469-.438C1069.769,133.766,1068.3,132.673,1067.107,132.673Zm12.141,16.252h0c-3.266,0-5.627-1.523-7.656-4.937-1.183-1.989-2.271-3.656-3.145-5-2.041-3.125-3.066-4.695-2.2-5.569a1.193,1.193,0,0,1,.9-.351c1.033.017,2.593,1.094,4.641,3.2V126.167a1.714,1.714,0,1,1,3.428,0v9.764h.4v-3.064a1.714,1.714,0,1,1,3.427,0v2.107h.4v-1.15a1.714,1.714,0,1,1,3.428,0v2.108h.4v-.2a1.713,1.713,0,1,1,3.427,0v6.722h-.068A7.573,7.573,0,0,1,1079.248,148.925Zm-12.117-15.3a.656.656,0,0,0-.486.187c-.551.555.569,2.272,2.268,4.872.877,1.343,1.969,3.016,3.158,5.015,1.917,3.226,4.131,4.664,7.177,4.665a7.08,7.08,0,0,0,6.9-6.9v-5.739a1.157,1.157,0,1,0-2.313,0v.755h-1.514v-2.665a1.157,1.157,0,1,0-2.313,0v1.707h-1.513v-2.663a1.157,1.157,0,1,0-2.313,0v3.621h-1.515v-10.32a1.156,1.156,0,1,0-2.312,0v11.512l-.483-.514c-2.118-2.265-3.8-3.52-4.725-3.535Z"
            transform="translate(-1064.975 -123.492)"
          />
        </g>
        <path
          fill={filledcolor}
          d="M1068.8,127.122a3.613,3.613,0,1,1,7.017.047v2.05a4.578,4.578,0,1,0-7.017-.013Z"
          transform="translate(-1063.826 -118.633)"
        />
        <path
          fill={filledcolor}
          d="M1073.214,120.5a6.678,6.678,0,0,1,5.255,10.8,2.865,2.865,0,0,1,.854.465,7.642,7.642,0,1,0-9.622,2.2v-1.105a6.68,6.68,0,0,1,3.513-12.362Z"
          transform="translate(-1064.728 -119.535)"
        />
      </g>
    </SvgIcon>
  );
};

export default PanicSVG;
