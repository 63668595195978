import React, { useState, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ResponsiveDialog from "../Generic/GenericDialog";
import ImageButton from "../Generic/ImageButton";
import marker from "../../img/place-24px.svg";
import gateway from "../../img/router-24px.svg";
import LocationForm from "../common/LocationForm";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function EditLocationFormModal(props) {
  const log = window.log("EditLocationFormModal");

  const {
    appState,
    setLogoutState,
    openEditLocationModal,
    setOpenEditLocationModal,
    snack,
  } = useContext(AppContext);
  const {
    getLocation,
    locationId,
    editLocationName,
    setEditLocationName,
    editLocationStreet,
    setEditLocationStreet,
    editLocationCity,
    setEditLocationCity,
    editLocationState,
    setEditLocationState,
    editLocationZip,
    setEditLocationZip,
  } = props;

  const classes = useStyles();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [street, setStreet] = useState("");
  const [streetError, setStreetError] = useState(false);

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);

  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);

  const [submitState, setSubmitState] = useState(false);

  // Submit function to api
  const handleSubmitLocation = async () => {
    let foundError = false;
    if (editLocationStreet.length === 0) {
      setStreetError(true);
      foundError = true;
    }
    if (editLocationCity.length === 0) {
      setCityError(true);
      foundError = true;
    }
    if (editLocationState.length === 0) {
      setStateError(true);
      foundError = true;
    }
    if (editLocationZip.length === 0) {
      setZipcodeError(true);
      foundError = true;
    }
    if (foundError) {
      snack("Please Fill Out All Fields", "error");
      return false;
    }
    if (editLocationState.length !== 2) {
      snack("Please Use Two Letter State Code", "error");
      return false;
    }
    try {
      const data = {
        street: editLocationStreet,
        city: editLocationCity,
        state: editLocationState,
        zipcode: editLocationZip,
        editLocationName,
        primary: false,
        locationId,
      };
      log(data);

      let street = data.street;
      if (street.includes("#")) street = street.split("#").join("%23");

      const locationResponse = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&searchtext=${street}+${data.city}+${data.state}+${data.zipcode}`
      );
      const locationJson = await locationResponse.json();

      log("Looking at the location json ", locationJson);

      if (locationJson.Response.View.length === 0) {
        snack("Not a valid address", "error");
      } else {
        let lat =
          locationJson.Response.View[0].Result[0].Location.DisplayPosition
            .Latitude;
        let lng =
          locationJson.Response.View[0].Result[0].Location.DisplayPosition
            .Longitude;
        data.lat = lat;
        data.lng = lng;

        log("Looking at the data object ", data);

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/location/edit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: appState.auth.token,
            },
            body: JSON.stringify(data),
          }
        );
        const json = await response.json();
        log("Looking at JSON ", json);
        if (json.success) {
          getLocation && getLocation();
          return true;
        } else {
          try {
            json.errors.forEach((error) => {
              snack(error.msg, "error");
              if (error.type === "token") setLogoutState(true);
            });
          } catch (err) {
            snack("System error", "error");
            log("Error ", err);
          }
          return false;
        }
      }
    } catch (err) {
      log("Error " + err);
      snack("Error contacting server", "error");
    }
  };

  const verifyLocationName = async () => {
    if (editLocationName === undefined || editLocationName === "") {
      setNameError(true);
      snack("A Location name is required", "error");
      return false;
    }
    return true;
  };

  const handleClose = () => {
    getLocation();
    setOpenEditLocationModal(false);
  };
  return (
    <div>
      <ResponsiveDialog
        openState={openEditLocationModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title="EDIT LOCATION"
        stepsArray={[
          {
            label: "LOCATION NAME",
            nextFunction: verifyLocationName,
            // showProgress: false,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Edit your location a name
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    Giving your location a name will you help you locate and
                    manage all of your gateways and devices.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    error={nameError}
                    value={editLocationName}
                    className={classes.textField}
                    onChange={(e) => setEditLocationName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Location Name"
                    label="Location Name"
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "ADDRESS",
            nextFunction: handleSubmitLocation,
            validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Enter the address of your location
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter the Street, City, State and Zipcode information for
                    your location below.
                  </Box>
                </Grid>
                <LocationForm
                  street={editLocationStreet}
                  setStreet={setEditLocationStreet}
                  streetError={streetError}
                  city={editLocationCity}
                  setCity={setEditLocationCity}
                  cityError={cityError}
                  state={editLocationState}
                  setState={setEditLocationState}
                  stateError={stateError}
                  zipcode={editLocationZip}
                  setZipcode={setEditLocationZip}
                  zipcodeError={zipcodeError}
                  classes={classes}
                />
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Updating Location into Platform
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    MiSensors is currently configuring your location into the
                    platform. This should take less than a minute.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "EDIT COMPLETE", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Success! Your location has been updated.
                  </Box>
                  <Box
                    className={classes.fontColor}
                    style={{ marginTop: "1rem" }}
                  >
                    Select close to return to the location page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
