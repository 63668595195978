import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  rssiRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
  mark: {
    height: "5px",
  },
}));

const RSSIThresholds = (props) => {
  const theme = useTheme();

  const {
    rssiThresholdLow,
    setRSSIThresholdLow,
    rssiThresholdHigh,
    setRSSIThresholdHigh,
    enableRSSIAlert,
    setEnableRSSIAlert,
    rssiRangeType,
    setRSSIRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 20,
    },
    {
      value: 40,
    },
    {
      value: 60,
    },
    {
      value: 80,
    },
    {
      value: 100,
      label: "100",
    },
  ];

  const valuetext = (value) => {
    return `${value}`;
  };

  const handleToggleEnable = (e) => {
    setEnableRSSIAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (rssiRangeType === 1 || rssiRangeType === 2) {
      setRSSIThresholdHigh(v);
    } else {
      setRSSIThresholdLow(v[0]);
      setRSSIThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableRSSIAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.rssiRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="rssi-range-slider">
                RSSI Signal
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableRSSIAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={rssiRangeType}
                setRangeType={setRSSIRangeType}
                enabled={enableRSSIAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                classes={{ mark: classes.mark }}
                aria-labelledby="rssi-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  rssiRangeType === 1 || rssiRangeType === 2
                    ? rssiThresholdHigh
                      ? rssiThresholdHigh
                      : 40
                    : [
                        rssiThresholdLow ? rssiThresholdLow : 0,
                        rssiThresholdHigh ? rssiThresholdHigh : 100,
                      ]
                }
                track={
                  rssiRangeType === 1 || rssiRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                step={20}
                min={0}
                max={100}
                disabled={!enableRSSIAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RSSIThresholds;
