import React from "react";
import { SvgIcon } from "@material-ui/core";

const LightSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="34.992"
      height="34.992"
      viewBox="0 0 34.992 34.992"
      {...props}
    >
      <path fill="none" d="M0,0H34.992V34.992H0Z" />
      <g transform="translate(2.916 1.458)">
        <path
          fill={outlinecolor}
          d="M1025.28,123.726a8.217,8.217,0,0,0-5.816,13.952,4.644,4.644,0,0,1,1.742,3.361,1.019,1.019,0,0,0,1.018,1.019h6.111a1.018,1.018,0,0,0,1.018-1.019,4.547,4.547,0,0,1,1.722-3.34,8.009,8.009,0,0,0,2.352-5.825,8.324,8.324,0,0,0-2.484-5.937A7.814,7.814,0,0,0,1025.28,123.726Zm4.419,12.505a7.007,7.007,0,0,0-2.292,3.789h-4.256a7.139,7.139,0,0,0-2.241-3.779,6.53,6.53,0,0,1-1.741-4.368,6.405,6.405,0,0,1,6.11-6.111,5.731,5.731,0,0,1,4.2,1.63,6.265,6.265,0,0,1,1.9,4.48,6.035,6.035,0,0,1-1.72,4.358Z"
          transform="translate(-1011.022 -117.617)"
        />
        <path
          fill={outlinecolor}
          d="M1027.055,137h-6.111a1.018,1.018,0,0,0,0,2.037h6.111a1.018,1.018,0,0,0,0-2.037Z"
          transform="translate(-1009.743 -111.538)"
        />
        <path
          fill={outlinecolor}
          d="M1025.718,139.091h-4.074a1.018,1.018,0,1,0,0,2.037h4.074a1.018,1.018,0,0,0,0-2.037Z"
          transform="translate(-1009.423 -110.578)"
        />
        <path
          fill={filledcolor}
          d="M1023.041,123.607a1.018,1.018,0,0,0,1.018-1.018v-2.037a1.018,1.018,0,0,0-2.037,0v2.037A1.018,1.018,0,0,0,1023.041,123.607Z"
          transform="translate(-1008.783 -119.535)"
        />
        <path
          fill={filledcolor}
          d="M1016,128.615h-2.037a1.018,1.018,0,1,0,0,2.035H1016a1.018,1.018,0,1,0,0-2.035Z"
          transform="translate(-1012.942 -115.376)"
        />
        <path
          fill={filledcolor}
          d="M1032.761,128.615h-2.037a1.018,1.018,0,1,0,0,2.035h2.037a1.018,1.018,0,1,0,0-2.035Z"
          transform="translate(-1005.264 -115.376)"
        />
        <path
          fill={filledcolor}
          d="M1030.635,122.491a1.016,1.016,0,0,0-1.435,0l-1.446,1.436a1.018,1.018,0,0,0-.006,1.441l.006.006a1.035,1.035,0,0,0,1.446,0l1.435-1.446A1.018,1.018,0,0,0,1030.635,122.491Z"
          transform="translate(-1006.296 -118.317)"
        />
        <path
          fill={filledcolor}
          d="M1018.765,125.357a1.021,1.021,0,0,0,.006-1.441l-.006-.006-1.446-1.436a1.019,1.019,0,0,0-1.436,1.436l1.436,1.446a1.035,1.035,0,0,0,1.446,0Z"
          transform="translate(-1011.707 -118.301)"
        />
      </g>
    </SvgIcon>
  );
};

export default LightSVG;
