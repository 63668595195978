import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";

import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Set Alert Name
 * @param {name: string, nameCallback: ()=>void, nameError: bool} props
 */
export default function AlertName(props) {
  const classes = useStyles();
  const { name, nameCallback, nameError, isEdit } = props;

  return (
    <Grid item xs={12} style={{ height: "100%" }}>
      <ModalFormHeader
        header={isEdit ? "Edit your alert name" : "Give your alert a name"}
        desc="Giving your alert a name will help you locate and manage all of your
          devices connected to this alert."
      />
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <TextField
          className={classes.textField}
          fullWidth
          variant="outlined"
          placeholder="Alert Name"
          label="Alert Name"
          value={name}
          error={nameError}
          onChange={(event) => {
            nameCallback("name", event.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
}
