import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  humidityRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const HumidityThresholds = (props) => {
  const theme = useTheme();

  const {
    humidityThresholdLow,
    setHumidityThresholdLow,
    humidityThresholdHigh,
    setHumidityThresholdHigh,
    enableHumidityAlert,
    setEnableHumidityAlert,
    humidityRangeType,
    setHumidityRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: 0,
      label: "0%",
    },

    {
      value: 100,
      label: "100%",
    },
  ];

  const valuetext = (value) => {
    return `${value}%`;
  };

  const handleToggleEnable = (e) => {
    setEnableHumidityAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (humidityRangeType === 1 || humidityRangeType === 2) {
      setHumidityThresholdHigh(v);
    } else {
      setHumidityThresholdLow(v[0]);
      setHumidityThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableHumidityAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.humidityRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="humidity-range-slider">
                Humidity
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableHumidityAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={humidityRangeType}
                setRangeType={setHumidityRangeType}
                enabled={enableHumidityAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                aria-labelledby="humidity-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  humidityRangeType === 1 || humidityRangeType === 2
                    ? humidityThresholdHigh
                      ? humidityThresholdHigh
                      : 50
                    : [
                        humidityThresholdLow ? humidityThresholdLow : 0,
                        humidityThresholdHigh ? humidityThresholdHigh : 100,
                      ]
                }
                track={
                  humidityRangeType === 1 || humidityRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                min={0}
                max={100}
                disabled={!enableHumidityAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default HumidityThresholds;
