import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//Components
import { AppContext } from "../../AppContext";

const BarGraphContainer = ({ data, sellerInfo }) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const { appState } = useContext(AppContext);

  const [formattedData, setFormattedData] = useState([]);
  const [buttonState, setButtonState] = useState("current");

  const formatBarData = (data) => {
    let upRate; //upgrade rate
    let reRate; //renewal rate

    if (sellerInfo.upgrade_commission_rate) {
      upRate = parseInt(sellerInfo.upgrade_commission_rate) / 100;
    } else {
      upRate = 1;
    }

    if (sellerInfo.renewal_commission_rate) {
      reRate = parseInt(sellerInfo.renewal_commission_rate) / 100;
    } else {
      reRate = 1;
    }

    const { previous, current, forecast } = data;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const formattedBarGraphData = [];
    if (buttonState === "current") {
      for (const key in previous) {
        const splitDate = key.split("-");
        const formattedYear = splitDate[1].slice(2, 4);
        const formattedDate = splitDate[0] + "/1/" + formattedYear;
        const obj = previous[key];
        formattedBarGraphData.unshift({
          date: formattedDate,
          UPGRADES: (obj.totalProrated * upRate).toFixed(2),
          "COMPLETED RENEWALS": (obj.totalCompletedAnnual * reRate).toFixed(2),
          "INCOMPLETE RENEWALS": (obj.totalPendingAnnual * reRate).toFixed(2),
          "Total Annual": (
            obj.totalProrated * upRate +
            obj.totalCompletedAnnual * reRate
          ).toFixed(2),
        });
      }
      if (current) {
        formattedBarGraphData.push({
          date: `${currentMonth}/1/${String(currentYear).slice(2, 4)}`,
          UPGRADES: current.totalProrated * upRate,
          "COMPLETED RENEWALS": (current.totalCompletedAnnual * reRate).toFixed(
            2
          ),
          "INCOMPLETE RENEWALS": (current.totalPendingAnnual * reRate).toFixed(
            2
          ),
          "Total Annual": (
            current.totalProrated * upRate +
            current.totalCompletedAnnual * reRate
          ).toFixed(2),
        });
      }
    } else {
      for (const key in forecast) {
        const splitDate = key.split("-");
        const formattedYear = splitDate[1].slice(2, 4);
        const formattedDate = splitDate[0] + "/1/" + formattedYear;
        const obj = forecast[key];
        formattedBarGraphData.push({
          date: formattedDate,
          "Total Annual": (obj.totalAnnual * reRate).toFixed(2),
        });
      }
    }
    setFormattedData(formattedBarGraphData);
  };

  useEffect(() => {
    formatBarData(data);
  }, [data, buttonState]);

  const handleButton = (e) => {
    setButtonState(e.currentTarget.name);
  };

  const renderTooltip = (item) => {
    const isHovered = (type) =>
      type === item.id
        ? { fontWeight: "bolder", color: "black" }
        : { color: "black" };

    return buttonState === "current" ? (
      <>
        <Typography style={isHovered("INCOMPLETE RENEWALS")}>
          Incomplete Renewals: ${item.data["INCOMPLETE RENEWALS"]}
        </Typography>
        <Typography style={isHovered("COMPLETED RENEWALS")}>
          Completed Renewals: ${item.data["COMPLETED RENEWALS"]}
        </Typography>
        <Typography style={isHovered("UPGRADES")}>
          Upgrades: ${item.data["UPGRADES"]}
        </Typography>
        <Typography style={isHovered("Total Annual")}>
          Total: ${item.data["Total Annual"]}
        </Typography>
      </>
    ) : (
      <Typography style={isHovered("Total Annual")}>
        Total Annual: ${item.data["Total Annual"]}
      </Typography>
    );
  };

  return (
    <Grid item xs={12} md={9} style={{ maxHeight: 550, minHeight: "100%" }}>
      <Card style={{ height: "100%" }}>
        <Grid
          style={{
            paddingTop: 19,
            position: "relative",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography style={{ display: "inline" }}>
            FORECASTED COMMISSION
          </Typography>
          <Grid
            md={12}
            style={
              !isSmallDevice
                ? { position: "absolute", right: 19, top: 15 }
                : { marginTop: 10 }
            }
          >
            <Button
              style={{ marginRight: 10 }}
              variant={buttonState === "current" ? "contained" : "outlined"}
              color="primary"
              size="small"
              name="current"
              onClick={handleButton}
            >
              THIS YEAR
            </Button>
            <Button
              variant={buttonState === "forecast" ? "contained" : "outlined"}
              color="primary"
              size="small"
              name="forecast"
              onClick={handleButton}
            >
              NEXT YEAR
            </Button>
          </Grid>
        </Grid>
        <CardContent style={{ height: 350 }}>
          <ResponsiveBar
            data={formattedData}
            keys={
              buttonState === "current"
                ? ["UPGRADES", "COMPLETED RENEWALS", "INCOMPLETE RENEWALS"]
                : ["Total Annual"]
            }
            indexBy="date"
            margin={{ top: 25, right: 30, bottom: 50, left: 40 }}
            padding={0.1}
            colors={
              buttonState === "current"
                ? ["#051F34", "#08B3FF", "white"]
                : "#08B3FF"
            }
            borderWidth={1}
            borderColor="#08B3FF"
            axisTop={null}
            axisRight={null}
            label={(val) => ""}
            tooltip={(item) => renderTooltip(item)}
            xScale={{
              type: "time",
              format: "%Y-%m-%d",
              useUTC: false,
              precision: "month",
            }}
            xFormat="time:%Y-%m-%d"
            axisBottom={{
              format: (d) => moment(d).format("MMM YY"),
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              // legend: "Months",
              legendPosition: "middle",
              legendOffset: 70,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            enableArea={false}
            useMesh={false}
            defs={[
              linearGradientDef("gradientA", [
                { offset: 0, color: "inherit" },
                { offset: 100, color: "inherit", opacity: 0.3 },
              ]),
            ]}
            fill={[{ match: "*", id: "gradientA" }]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            theme={
              appState?.auth?.userInfo?.themePreferences?.darkMode && {
                axis: {
                  ticks: {
                    text: {
                      fill: "white",
                    },
                  },
                  legend: {
                    text: {
                      fill: "white",
                    },
                  },
                },
                tooltip: {
                  container: {
                    color: "black",
                  },
                },
              }
            }
          />
        </CardContent>
        <Grid
          container
          md={12}
          style={{ justifyContent: "center", paddingBottom: 18 }}
        >
          {buttonState === "current" ? (
            <>
              <LegendItem
                style={{ border: "1px solid #08B3FF" }}
                color="white"
                label="INCOMPLETE RENEWALS"
              />
              <LegendItem color="#08B3FF" label="COMPLETED RENEWALS" />
              <LegendItem color="#051F34" label="UPGRADES" />
            </>
          ) : (
            <LegendItem color="#08B3FF" label="TOTAL ANNUAL" />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

const LegendItem = ({ style, color, label }) => {
  return (
    <Grid item xs={12} md={4} lg={3} xl={2}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            ...style,
            backgroundColor: color,
            width: 30,
            height: 8,
            marginRight: 6,
          }}
        ></div>
        <Typography style={{ display: "inline", cursor: "default" }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BarGraphContainer;
