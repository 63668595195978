import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  searchInput: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  leadingIcon: {
    marginBottom: theme.spacing(-0.5),
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

/**
 * Generic HeaderBar for the multiple modules
 * @param {Object} props
 * @props
 * leadingIcon: React component to render for the Icon
 * title: String
 * buttonAddTitle: String
 * buttonAddEvent: Callback
 * searchCallback: Callback event to handle input writting
 * to implement search behavior
 */
export default function BreadCrumb(props) {
  const { leadingIcon, title, className, breadcrumbs } = props;

  const classes = useStyles();

  return (
    <Box
      display="flex"
      className={className}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="flex-end">
        <Box className={classes.leadingIcon}>{leadingIcon}</Box>
        <Typography variant="h6" component="h6" className={classes.title}>
          {breadcrumbs}
          {title}
        </Typography>
      </Box>
    </Box>
  );
}
