import React from 'react'

import {
  Box,
  makeStyles,
  Divider
} from '@material-ui/core'

import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    width: '100%',
    padding: theme.spacing(1),
    border: `1px solid ${grey[500]}`,
    margin: theme.spacing(1.5, 0)
  },
  content: {
    color: theme.palette.type === "light" ? grey[600] : grey[400],
    fontSize: '15px',
    margin: theme.spacing(0,1)
  },
  sensorValue: {
    color: theme.palette.type === "light" ? grey[600] : grey[400],
    fontSize: '18px',
    margin: theme.spacing(0, 1)
  }
}));

/**
 * Small input like card to show sensor value in add alert form
 */
export default function SensorValueCard(props) {

  const {
    icon,
    sensorName,
    readingName,
    sensorValue
  } = props;

  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.card}
    >
      {/* icon ,sensorName section */}
      <Box
        display="flex"
        alignItems="center"
      >
        <Box>
          { icon }
        </Box>
        <Box className={classes.content}>
          { sensorName }
        </Box>
      </Box>

      {/* readingName ,sensorValue  section*/}
      <Box
        display="flex"
        alignItems="center"
      >
        <Box className={classes.content}>
          {readingName}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box className={classes.sensorValue}>
          {sensorValue}
        </Box>
      </Box>
    </Box>
  )
};

