import React, { useState, useEffect, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import { Grid, Card, CardContent, Typography, Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";
import { fetchOne, fetchAll, updateOne, deleteOne } from "../common/api";
import EditableTextField from "../common/EditableTextField";
import FirmwareSelect from "../common/FirmwareSelect";
import { useSnackbar } from "notistack";
import DeleteDialog from "../common/DeleteDialog";
import DevicesAccordian from "../common/DevicesAccordian";
import LocationSelect from "../common/LocationSelect";
import Breadcrumb from "../Generic/BreadCrumb";
import GatewayIcon from "@material-ui/icons/Router";
import SensorIcon from "../../img/icons/SensorIcon";
import DetailsCard from "../Generic/DetailsCard";
import CardActions from "../Generic/CardActions";
import EditGatewayFormModal from "./EditGatewayFormModal";
import DeleteGatewayFormModal from "./DeleteGatewayFormModal";
import SensorImage from "../../img/icons/sensor.svg";

import ShareIcon from "@material-ui/icons/Share";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import NotFoundPageLoggedIn from "../Auth/NotFoundPageLoggedIn";
import LoadingSpinner from "../Generic/LoadingSpinner";
import GatewayImage from "../../img/icons/router-24px.svg";
import EditGatewayImageDialog from "./EditGatewayImageDialog";
import uploadImage from "../common/api/uploadImage";
import moment from "moment-timezone";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import socketIOClient from "socket.io-client";
import GatewayCard from "./GatewayCard";
import DeviceCard from "../Devices/DeviceCard";
const useStyles = makeStyles((theme) => ({
  root: {},
  backButton: {
    color: theme.palette.secondary.main,
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "1rem",
    width: "6rem",
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: "1rem",
    marginLeft: "auto",
  },
  gatewayButton: {
    marginTop: "1rem",
  },
  card: {
    marginTop: "5%",
    paddingLeft: "1rem",
    marginBottom: "1rem",
  },
  cardContent: {
    minHeight: "50vh",
  },
  titleName: {
    textAlign: "center",
    color: theme.palette.text,
    fontWeight: "bold",
    alignSelf: "center",
    margin: "auto",
  },
  text: {
    color: theme.palette.text,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  nested: {
    maxWidth: 275,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  sensorCardContainer: {
    height: 155,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  gatewayCardContainer: {
    height: 175,
    [theme.breakpoints.down("xs")]: {
      height: 310,
    },
  },
}));

const SingleGateway = (props) => {
  const log = window.log("SingleGateway");

  const theme = useTheme();
  const classes = useStyles();
  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenEditGatewayModal,
    setOpenDeleteGatewayModal,
    snack,
  } = useContext(AppContext);
  const [state, setState] = useState({});

  const [locationId, setLocationId] = useState();
  const [locationName, setLocationName] = useState();
  const [locationStreet, setLocationStreet] = useState();

  const [err, setErr] = useState();

  const [editForm, setEditForm] = useState(false);
  const [editName, setEditName] = useState("");
  const [editGatewayId, setEditGatewayId] = useState("");

  const [selectedFirmware, setSelectedFirmware] = useState();
  const [gatewayDevices, setGatewayDevices] = useState([]);
  const [deviceCount, setDeviceCount] = useState(0);
  const [gatewayEntryId, setGatewayEntryId] = useState();

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const [gatewayImageModalState, setGatewayImageModalState] = useState(false);
  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();
  const [imgErr, setImgErr] = useState(false);
  const [gatewayImageLocation, setGatewayImageLocation] = useState("");

  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  let [reload, setReload] = useState(false);
  let sockets = [];

  const { id } = props;
  const deviceArray = [];

  const getGateway = async () => {
    try {
      const json = await fetchOne(
        "gateway",
        id,
        appState.auth.userInfo.companyId,
        appState.auth.token,
        true
      );

      log("JSON", json);
      log("Looking at the gateway name " + json.data.gateways[0].gatewayName);
      if (json.success) {
        log("JSON", json.data);
        const gatewayDevices = await getDevices(json.data.gateways[0].entryId);

        log("Looking at gateway devices ", gatewayDevices);

        try {
          if (
            gatewayDevices.success &&
            gatewayDevices.data.sensors &&
            gatewayDevices.data.sensors.length
          ) {
            for (let i in gatewayDevices.data.sensors) {
              const timer = 300 - gatewayDevices.data.sensors[i].lastMessageTime
              gatewayDevices.data.sensors[i].lastMessageTime = new Date().getTime() / 1000 - timer; 
            }
            setGatewayDevices(gatewayDevices.data.sensors);
          }
        } catch (err) {
          log("err", err);
        }

        setState(json.data.gateways[0]);
        setEditName(json.data.gateways[0].gatewayName);
        setLocationId(json.data.gateways[0].locationId);
        setLocationName(json.data.gateways[0].locationName);
        setLocationStreet(json.data.gateways[0].street);
        setGatewayEntryId(json.data.gateways[0].entryId);
        if (json.data.gateways[0].gatewayImageLocation) {
          setGatewayImageLocation(json.data.gateways[0].gatewayImageLocation);
        }
        setReload(!reload);
      } else {
        snack(json.errors[0].msg, "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    // debugger;
    getGateway();
  }, []);

  const getDevices = async (gatewayEntryId) => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get?gatewayEntryId=${gatewayEntryId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await request.json();

      if (json.success) {
        log("Single_Gateway: Looking at json\t", json);
        log(json.data.sensors.length);
        setDeviceCount(json.data.sensors.length);
        for (let i = 0; i < json.data.sensors.length; i++) {
          log("json:" + json.data.sensors[i].sensorId);
          log("json:" + json.data.sensors[i].sensorName);
          const timer = 300 - json.data.sensors[i].lastMessageTime 
          deviceArray.push({
            sensorId: json.data.sensors[i].sensorId,
            sensorLocation: json.data.sensors[i].sensorLocation,
            sensorName: json.data.sensors[i].sensorName,
            sensorProfileArray: json.data.sensors[i].sensorProfileArray,
            lastSensorMessage: json.data.sensors[i].lastSensorMessage,
            lastMessageTime: new Date().getTime() / 1000 - timer,
          });
        }
        log("Looking at the device array ", deviceArray);
        setGatewayDevices(deviceArray);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
      return json;
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    if (!state.lastMessageTime) return;
    const interval = setInterval(() => {
      //This is for the gateways
      setState((prevState) => {
        log("Looking at the previous gateway state ", prevState);
        let stateObj = prevState;
        stateObj.lastMessageTime = prevState.lastMessageTime - 1;
        return stateObj;
      });
      //This is for sensors
      // setGatewayDevices((prevState) => {
      //   log("Looking at the previous sensor state ", prevState);
      //   let newState = [];
      //   for (let i = 0; i < prevState.length; i++) {
      //     let stateObj = prevState[i];
      //     stateObj.lastMessageTime -= 1;
      //     newState.push(stateObj);
      //   }
      //   log("Looking at the new state ", prevState);
      //   return newState;
      // });
    }, 1000);

    return () => clearInterval(interval);
  }, [reload]);

  //Gateway timer reset
  const [reloadInterval1, setReloadInterval1] = useState(null);
  const [reloadInterval2, setReloadInterval2] = useState(null);
  useEffect(() => {
    log("Use effect looking at the state ", state);
    if (state === undefined) {
      const interval = setInterval(() => {
        setReload(!reload);
      }, 2000);
      setReloadInterval1(interval)
      return;
    }
    if (Object.keys(state).length === 0) {
      const interval = setInterval(() => {
        setReload(!reload);
      }, 2000);
      setReloadInterval2(interval)
      return;
    }
    //Clear out any existing sockets
    sockets = [];
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      query: {
        sensorMAC: state.gatewayMAC.toLowerCase(),
      },
    });
    sockets.push(socket);
    socket.on(state.gatewayMAC.toLowerCase(), (data) => {
      log("Gateway message received ", data);
      log(state);
      if (state.lastMessageTime < 100) {
        let stateObj = state;
        stateObj.lastMessageTime = 300;
        setState(stateObj);
      }
    });
    return () => {
      clearInterval(reloadInterval1);
      clearInterval(reloadInterval2);
      for (let i = 0; i < sockets.length; i++) {
        log("Leaving the page");
        sockets[i].disconnect();
      }
    };
  }, [reload]);

  //Sensor timer reset
  useEffect(() => {
    if (gatewayDevices.length === 0) return;
    for (let i = 0; i < gatewayDevices.length; i++) {
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        upgrade: false,
        transports: ["websocket"],
        query: {
          sensorMAC: gatewayDevices[i].sensorId.toLowerCase(),
        },
      });
      sockets.push(socket);
      socket.on(gatewayDevices[i].sensorId.toLowerCase(), (data) => {
        let newState = [];
        for (let j = 0; j < gatewayDevices.length; j++) {
          if (gatewayDevices[i].sensorId === gatewayDevices[j].sensorId) {
            log("Message received for " + gatewayDevices[i].sensorId);
            let stateObj = gatewayDevices[i];

            // // Panic
            // if (data.doorPanicSource === 5) {
            //   data.panic = data.doorPanicAlert;
            //   if (
            //     stateObj.lastSensorMessage.door &&
            //     stateObj.lastSensorMessage.door !== 0
            //   ) {
            //     data.door = stateObj.lastSensorMessage.door;
            //   }
            // }

            // //Door
            // if (
            //   data.doorPanicSource === 4 ||
            //   stateObj.lastSensorMessage.doorPanicSource === 4 ||
            //   stateObj.lastSensorMessage.last_door_alert ||
            //   (stateObj.lastSensorMessage.door &&
            //     stateObj.lastSensorMessage.door !== 0)
            // ) {
            //   if (
            //     data.doorPanicSource === 0 &&
            //     (stateObj.lastSensorMessage.last_door_alert == 3 || stateObj.lastSensorMessage.door === 3)
            //   ) {
            //     data.last_door_alert = 3;
            //     data.door = 3;
            //   } else if (
            //     data.doorPanicSource === 0 &&
            //     (stateObj.lastSensorMessage.last_door_alert == 2 || stateObj.lastSensorMessage.door === 2)
            //   ) {
            //     data.last_door_alert = 2;
            //     data.door = 2;
            //   } else if (data.doorPanicSource === 4) {
            //     data.door = data.doorPanicAlert;
            //   }
            // }
            stateObj.lastMessageTime = new Date().getTime() / 1000;
            stateObj.lastSensorMessage = data;
            newState.push(stateObj);
          } else {
            newState.push(gatewayDevices[j]);
          }
        }
        log("Looking at the new state ", newState);
        setGatewayDevices(newState);
      });
    }
    return () => {
      for (let i = 0; i < sockets.length; i++) {
        log("Leaving the page");
        sockets[i].disconnect();
      }
    };
  }, [state]);

  const onEditClick = () => {
    // Set input values and change to edit form
    setEditForm(!editForm);
    setEditName(state.name);
    setEditGatewayId(state.gatewayId);
  };

  const onSubmitClick = async () => {
    // Submit changes to API and revert to plain form
    setEditForm(!editForm);

    try {
      const data = {
        ...state,
        name: editName,
        gatewayId: editGatewayId,
        companyId: appState.auth.userInfo.companyId,
        locationId,
      };
      const json = await updateOne("gateway", data, appState.auth.token);
      if (json.success) {
        snack("Gateway information successfully updated", "success");
        getGateway();
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const deleteEntity = async () => {
    const allGatewayDevicesDeleted = await deleteDevices();

    if (allGatewayDevicesDeleted) {
      const data = {
        ...state,
        sensorId: state.sensorId,
        companyId: appState.auth.userInfo.companyId,
      };

      const json = await deleteOne("gateway", data, appState.auth.token);

      if (json.success) {
        snack("Gateway successfully removed from the system", "success");
        setTimeout(() => navigate("/gateway/all", true), 750);
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          errorObj = { ...errorObj, [error.type]: true };
          snack(error.msg, "error");
        });
        setErr(errorObj);
      }
    }
  };

  const deleteDevices = async () => {
    const gatewayDevices = await getDevices(state.entryId);
    const { data: devices } = gatewayDevices;
    let allDevicesDeleted = true;
    if (devices !== undefined) {
      devices.map(async (device) => {
        const data = {
          sensorEntryId: device.sensorEntryId,
          companyId: appState.auth.userInfo.companyId,
        };

        const json = await deleteOne("device", data, appState.auth.token);

        if (json.success) {
          snack("Device successfully removed from the system", "success");
        } else {
          allDevicesDeleted = false;
          let errorObj = {};
          json.errors.forEach((error) => {
            errorObj = { ...errorObj, [error.type]: true };
            snack(error.msg, "error");
          });
          setErr(errorObj);
        }
      });
    }
    return allDevicesDeleted;
  };

  const onClick = () => {
    alert("send firmware update msg to device via MQTT");
  };

  const userAuthButtons = (
    <Grid container>
      {editForm ? (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onSubmitClick()}
        >
          Submit
        </Button>
      ) : (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onEditClick()}
        >
          Edit
        </Button>
      )}
      <Button
        disabled={appState.auth.userInfo.perm === 1}
        className={classes.deleteButtons}
        variant="outlined"
        onClick={() => handleOpenDeleteGatewayModal()}
      >
        Delete
      </Button>
    </Grid>
  );

  //Stub
  // for (let i = 0; i < 3; i++) {
  //   deviceArray.push({
  //     sensorId: i,
  //     name: "Sensor Name",
  //   });
  // }

  const handleOpenEditGatewayModal = () => {
    setOpenEditGatewayModal(true);
  };

  const handleOpenDeleteGatewayModal = () => {
    log("Open Delete Modal");
    setOpenDeleteGatewayModal(true);
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/gateway/all">
        Gateways
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );
  const convertTempForUserPref = (value) => {
    let unitType = " °C";
    //Convert the Fahrenheit
    if (appState.auth.userInfo.themePreferences.measurement === "imperial") {
      value = value * (9 / 5) + 32;
      unitType = " °F";
    }
    value = value.toFixed(2);
    value = value + unitType;
    return value;
  };

  const singleFileUploadHandler = async (e) => {
    log("Inside of the single file upload handler");
    try {
      const renameText = `location-${state.locationId}-gateway-${
        state.gatewayId
      }-${Date.now()}`;
      const data = new FormData();

      const folderDestination = "Gateway Image";
      log("Looking at the file " + file);

      if (file) {
        log("File control structure 1");
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        log("Looking at response ", response);
        if (response.error) {
          log("File control structure 2");
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            log("File control structure 3");
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          log("File control structure 4");
          // Success
          setGatewayImageLocation(response.location);
          handleSaveGatewayImage(response.location);
        }
      } else if (imgFile) {
        log("File control structure 5");
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        log("Looking at the response ", response);
        if (response.error) {
          log("File control structure 6");
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            log("File control structure 7");
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          log("File control structure 8");
          // Success
          setGatewayImageLocation(response.location);
          handleSaveGatewayImage(response.location);
        }
      } else if (gatewayImageLocation) {
        //PASS
      } else {
        //No image detected
        // log("File control structure 9");
        handleSaveGatewayImage("");
        // snack("Image Removed", "success");
      }
    } catch (error) {
      log("File control structure 1");
      log(error);
    }
  };

  const updateGatewayImage = async (entity, data, token) => {
    log("Inside of the updateOne function");
    log("Looking at the data ", data);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/${entity}/editImage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await request.json();
      if (json.success) {
        log("Looking at the response ", json);
        return json;
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
    log("Do nothing");
  };

  // Save Device Image
  const handleSaveGatewayImage = async (location) => {
    log("Save clicked");
    log(state);
    log(location);
    let imgLocation = location;
    try {
      const data = {
        ...state,
        name: state.gatewayName,
        gatewayId: state.gatewayMAC,
        gatewayImageLocation: imgLocation,
        companyId: appState.auth.userInfo.companyId,
        userId: appState.auth.userInfo.id,
      };
      log("data:", data);
      log("Calling update gateway image 1");
      const json = await updateGatewayImage(
        "gateway",
        data,
        appState.auth.token
      );
      log("Looking at the response ", json);
      if (json.success) {
        log(json);
        snack("Image successfully updated", "success");
        getGateway();
        gatewayImageCloseModal();
      } else {
        //handle errs
        log(json);
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
  };

  const handleEditGatewayImage = () => {
    setGatewayImageModalState(true);
  };

  const gatewayImageCloseModal = () => {
    setGatewayImageModalState(false);
  };

  const handleRemoveGatewayImageLocation = () => {
    setFile(false);
    setImgFile(null);
    setGatewayImageLocation("");
  };

  return state ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div className={classes.root}>
        <EditGatewayFormModal
          gatewayEntryId={gatewayEntryId}
          getGateway={getGateway}
          gatewayName={state.gatewayName}
          editName={editName}
          setEditName={setEditName}
          locationEntryId={locationId}
        />
        <EditGatewayImageDialog
          openState={gatewayImageModalState}
          handleClose={gatewayImageCloseModal}
          handleSave={singleFileUploadHandler}
          setImgLocation={setGatewayImageLocation}
          handleRemoveImg={handleRemoveGatewayImageLocation}
          imgLocation={gatewayImageLocation}
          setImgErr={setImgErr}
          setFile={setFile}
          setImgFile={setImgFile}
        />
        <DeleteGatewayFormModal gatewayEntryId={gatewayEntryId} />
        {state ? (
          <Grid className={classes.root} container spacing={2}>
            <Grid item className={classes.title}>
              <Breadcrumb
                leadingIcon={
                  <GatewayIcon style={{ fontSize: 32 }} color="primary" />
                }
                breadcrumbs={breadcrumbs}
                title={`${state.gatewayName}`}
              />
            </Grid>
            <Grid item xs={12} className={classes.gatewayCardContainer}>
              <GatewayCard
                handleEditImage={handleEditGatewayImage}
                singleGatewayCard={true}
                lastMessageTime={state.lastMessageTime}
                singleGateway={true}
                gatewayCard={true}
                enableZero={true}
                showAvatar={true}
                defaultAvatar={state.gatewayImageLocation ? false : true}
                avatar={
                  state.gatewayImageLocation
                    ? state.gatewayImageLocation
                    : GatewayImage
                }
                showStatus={true}
                onlineStatus={true}
                name={
                  !smallDevice
                    ? state.gatewayName
                      ? `Gateway Name: ${state.gatewayName}`
                      : "No Gateway Name"
                    : state.gatewayName
                    ? `${state.gatewayName}`
                    : "No Gateway Name"
                }
                id={state.gatewayMAC}
                subtitle={
                  state.gatewayMAC
                    ? `Gateway ID: ${state.gatewayMAC}`
                    : "Gateway ID:"
                }
                subtitle2={
                  state.locationName
                    ? `Location: ${state.locationName}`
                    : "Location: No location provided"
                }
                subtitle3={
                  state.latestMessage !== null
                    ? `${
                        appState.auth.userInfo &&
                        appState.auth.userInfo.themePreferences &&
                        appState.auth.userInfo.themePreferences.timeFormat ===
                          "12hr"
                          ? `Last Reported: ${moment(
                              state.latestMessage
                            ).format("MM/DD/YYYY hh:mm a")}`
                          : `Last Reported: ${moment(
                              state.latestMessage
                            ).format("MM/DD/YYYY kk:mm")}`
                      }`
                    : "Last Reported: No messages"
                }
                // wrap="wrap"
                // displayDivider="none"
                buttons={
                  appState.auth.userInfo.perm !== 1 && (
                    <CardActions
                      editEvent={handleOpenEditGatewayModal}
                      deleteEvent={handleOpenDeleteGatewayModal}
                      smallDevice={smallDevice}
                    />
                  )
                }
                trailingItems={[
                  {
                    icon: "",
                  },
                  {
                    value: deviceCount,
                    icon: (
                      <SensorIcon color="primary" style={{ fontSize: 32 }} />
                    ),
                  },
                ]}
              />
            </Grid>
            {gatewayDevices.map((device) => (
              <Grid
                item
                xs={12}
                key={device.sensorId}
                className={classes.sensorCardContainer}
              >
                <DeviceCard
                  sensorCard={true}
                  showStatus={true}
                  lastMessageTime={device.lastMessageTime}
                  id={device.sensorId}
                  onClick={() => {
                    navigate(`/device/${device.sensorId}`);
                  }}
                  // wrap="wrap"
                  // displayDivider="none"
                  name={`${device.sensorName}`}
                  subtitle={`Sensor ID: ${device.sensorId}`}
                  subtitle2={`Sensor Location: ${device.sensorLocation}`}
                  defaultAvatar={device.sensorImageLocation ? false : true}
                  avatar={
                    device.sensorImageLocation
                      ? device.sensorImageLocation
                      : SensorImage
                  }
                  isItemsDict={true}
                  // showOnlyItems={["temperature", "humidity", "pressure"]}
                  showOnlyItems={device.sensorProfileArray}
                  trailingItems={{
                    temperature: {
                      title: "Temperature",
                      value:
                        device.lastSensorMessage.temperature ||
                        device.lastSensorMessage.temperature === 0
                          ? convertTempForUserPref(
                              device.lastSensorMessage.temperature
                            )
                          : "-",
                      icon: (
                        <TemperatureSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    humidity: {
                      title: "Humidity",
                      value:
                        device.lastSensorMessage.humidity ||
                        device.lastSensorMessage.humidity === 0
                          ? device.lastSensorMessage.humidity.toFixed(1) + " %"
                          : "-",
                      icon: (
                        <HumiditySVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    pressure: {
                      title: "Pressure",
                      value:
                        device.lastSensorMessage.pressure ||
                        device.lastSensorMessage.pressure === 0
                          ? device.lastSensorMessage.pressure.toFixed(1) +
                            " hPa"
                          : "-",
                      icon: (
                        <PressureSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    motionDetection: {
                      title: "Motion",
                      value:
                        device.lastSensorMessage.acceleration ||
                        device.lastSensorMessage.acceleration === 0
                          ? device.lastSensorMessage.acceleration.toFixed(1)
                          : "-",
                      icon: (
                        <MotionDetectionSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    light: {
                      title: "Light",
                      value:
                        device.lastSensorMessage.light ||
                        device.lastSensorMessage.light === 0
                          ? Number(device.lastSensorMessage.light).toFixed(2) +
                            " lux"
                          : "-",
                      icon: (
                        <LightSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    panicButton: {
                      title: "Panic",
                      value: device.lastSensorMessage.currentPanicState
                        ? "Active"
                        : "Inactive",
                      icon: (
                        <PanicSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    proximity: {
                      title: "Proximity",
                      value:
                        device.lastSensorMessage.rssi ||
                        device.lastSensorMessage.rssi === 0
                          ? device.lastSensorMessage.rssi
                          : "-",
                      icon: (
                        <ProximitySVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                    doorOpen: {
                      title: "Door",
                      value: device.lastSensorMessage.currentDoorState
                        ? "Open"
                        : "Closed",
                      icon: (
                        <DoorSVG
                          outlinecolor={
                            theme.palette.type === "light"
                              ? theme.palette.iconColor.outline
                              : theme.palette.iconColorDarkMode.outline
                          }
                          filledcolor={theme.palette.iconColor.filled}
                          style={{ height: 28, width: 28 }}
                        />
                      ),
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default SingleGateway;
