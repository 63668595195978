import _ from "lodash";
import React from "react";
import {
  Grid,
  Select,
  Checkbox,
  MenuItem,
  makeStyles,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";

import SliderCard from "../../Generic/SliderCard";
import SensorValueCard from "../../Generic/SensorValueCard";
import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

/**
 * Range Settings Step
 * @param {*} props
 */
export default function RangeSettingsStep(props) {
  const {
    showMarkers,
    belowChildren,
    rangeCallback,
    rangeSelError,
    checkboxObject,
    setShowMarkers,
    objectDefinition,
    baselineSensorSel,
    changeRangeCallback,
    isSettingAlertRange,
    checkboxValuesObject,
    invalidRangeTypeError,
  } = props;
  const classes = useStyles();
  /**
   * Possible types of alerts in the range
   */
  const rangeTypesOptions = [
    {
      name: "Select a Range Type",
      isDefault: true,
      value: "none",
    },
    {
      name: "Below Value",
      value: "below_value",
    },
    {
      name: "Above Value",
      value: "above_value",
    },
    {
      name: "Inside of Range",
      value: "inside_range",
    },
    {
      name: "Outside of Range",
      value: "outside_range",
    },
  ];

  return (
    <Grid item xs={12}>
      <ModalFormHeader
        header={`Alert Range Settings - ${objectDefinition.title}`}
        desc={`Select your ${objectDefinition.type || objectDefinition.name} range settings ${objectDefinition.extraDescription} below. You will receive alerts from the system based off these settings.`}
      />
      {!_.isEmpty(baselineSensorSel) &&
        baselineSensorSel?.lastSensorMessage[objectDefinition?.name] && (
          <Grid item xs={12}>
            <SensorValueCard
              icon={objectDefinition.icon}
              sensorName={objectDefinition.title}
              readingName={"Baseline Reading"}
              sensorValue={
                baselineSensorSel.lastSensorMessage[objectDefinition.name]
              }
            />
          </Grid>
        )}
      <Grid item container xs={12}>
        {!_.isEmpty(checkboxObject) ? (
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxValuesObject.checked}
                  onChange={(event) => {
                    checkboxObject.onChange(event);
                  }}
                  name={checkboxObject.name}
                  color="primary"
                />
              }
              label={checkboxObject.title}
            />
          </Grid>
        ) : null}
        <Grid item xs={!_.isEmpty(checkboxObject) ? 8 : 12}>
          <FormControl
            variant="outlined"
            className={classes.formSelect}
            error={invalidRangeTypeError}
          >
            <Select
              labelId="alert-profile-range-type"
              id="alert-profile-range-select"
              className={classes.select}
              inputProps={{
                name: objectDefinition.name,
                id: "range-type-selector",
              }}
              defaultValue={0}
              onChange={changeRangeCallback}
              onOpen={() => setShowMarkers(false)}
              onClose={() => setShowMarkers(true)}
              value={objectDefinition.rangeType}
              error={rangeSelError.rangeSelDropdown}
            >
              {rangeTypesOptions.map(({ name, value, isDefault }, index) => {
                const label = isDefault ? <em>{name}</em> : name;
                return (
                  <MenuItem
                    key={`alert__range__setting__option__${index}`}
                    value={value}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <SliderCard
          error={rangeSelError}
          minimumValue={objectDefinition.minimum}
          maximumValue={objectDefinition.maximum}
          light_markers={objectDefinition.marks}
          unitMeasure={objectDefinition.unit}
          values={objectDefinition.rangeValues}
          rangeType={objectDefinition.rangeType}
          isRangeType={isSettingAlertRange(objectDefinition.rangeType)}
          onChange={(newValue) => {
            rangeCallback(objectDefinition.name, "rangeValues", newValue);
          }}
          showMarkers={showMarkers}
        />
      </Grid>
      {belowChildren}
    </Grid>
  );
}
