import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { navigate } from "hookrouter";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";

import LoginLayout from "../Auth/LoginLayout";
import MuiPhoneNumber from "material-ui-phone-number";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  formContainer: {
    width: "80%",
  },
  linkForgotPassword: {
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    textDecoration: "none",
    fontSize: 16,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: ".5rem",
    },
  },
}));

const ResellerSignup = () => {
  const log = window.log('UserInvitation');

  const { appState, setAppState, setLogoutState, snack } = useContext(
    AppContext
  );
  let url = window.location.href;
  let urlobj = new URL(url);
  let urlEmail = urlobj.searchParams.get("email");
  let userToken = urlobj.searchParams.get("token");

  const [state, setState] = useState({
    email: urlEmail,
    phoneNumber: "",
    pw: "",
    pwConfirmation: "",
  });

  const [err, setErr] = useState({
    email: false,
    phoneNumber: false,
    pw: false,
    pwConfirmation: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const attemptSignUp = async () => {
    // log("Looking at user token: " + userToken);
    const formattedPhoneNum = state.phoneNumber
      .split("(")
      .join("")
      .split(")")
      .join("")
      .split(" ")
      .join("")
      .split("-")
      .join("");
    const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (state.pw === "" || state.pwConfirmation === "") {
      setErr({
        pw: !state.pw1,
        pwConfirmation: !state.pw2,
      });
      setIsSubmitting(false);
      return snack(
        "Something went wrong. Please double check all fields.",
        "error"
      );
    } else if (state.pw !== state.pwConfirmation) {
      setErr({
        pw: true,
        pwConfirmation: true,
      });
      setIsSubmitting(false);
      return snack("Password does not match.", "error");
    } else if (!pwRegex.exec(state.pw)) {
      setErr({
        pw: true,
        pwConfirmation: true,
      });
      setIsSubmitting(false);
      return snack("Password requirements were not met.", "error");
    }
    
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/reseller/create-account",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: userToken,
          },
          body: JSON.stringify({
            email: state.email,
            password: state.pw,
            passwordConfirmation: state.pwConfirmation,
            phoneNum: formattedPhoneNum,
          }),
        }
      );
      const json = await response.json();
      log("Looking at JSON ", json);
      if (json.success) {
        snack("User account created", "success");
        const { token, userInfo } = json.data;

        setAppState({
          ...appState,
          auth: {
            loggedIn: true,
            token,
            userInfo,
          },
        });
        setErr({
          pw: false,
          pwConfirmation: false,
        });
        navigate("/");
      } else {
        setErr({ [json.errors[0].errType]: true });
        snack(json.errors[0].msg, "error");
        if (err.type === "token") {
          snack(err.msg, "error");
          setLogoutState(true);
        }
      }
    } catch (err) {
      log(err);
      snack(
        "Something went wrong. Please refresh the page and try again.",
        "error"
      );
    }
    setIsSubmitting(false);
  };

  const goToHomePage = async () => {
    navigate("/");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <LoginLayout subtitle="You’ve been invited to join our organization..">
      <Box className={classes.formContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              disabled
              variant="outlined"
              id="email"
              className={classes.textField}
              label="Email Address"
              onChange={(e) =>
                setState({ ...state, email: e.currentTarget.value })
              }
              value={state.email}
              error={err.email}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("password").focus();
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPhoneNumber
              className={classes.textField}
              id="phoneNumber"
              fullWidth
              variant="outlined"
              value={state.phoneNumber}
              placeholder="Phone Number"
              label="Phone Number"
              defaultCountry={"us"}
              preferredCountries={["us"]}
              disableAreaCodes={true}
              autoFormat={true}
              onChange={(e) => setState({ ...state, phoneNumber: e })}
              countryCodeEditable={false}
              inputProps={{
                autocomplete: 'new-password'
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                variant="outlined"
                label="Password"
                placeholder="Password"
                value={state.pw}
                name="password"
                onChange={(e) =>
                  setState({ ...state, pw: e.currentTarget.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attemptSignUp();
                  }
                }}
                error={err.pw}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password-confirmation">
                Password Confirmation
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-confirmation"
                className={classes.textField}
                fullWidth
                variant="outlined"
                label="Password Confirmation"
                placeholder="Password Confirmation"
                value={state.pwConfirmation}
                name="password confirmation"
                onChange={(e) =>
                  setState({ ...state, pwConfirmation: e.currentTarget.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attemptSignUp();
                  }
                }}
                error={err.pwConfirmation}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-error-text">
                Requirements: at least 8 characters, at least 1 uppercase, at
                least 1 lowercase, at least 1 number, at least 1 special character (@$!%*?&)
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid container item xs={12} className={classes.buttons}>
            <Button
              id="cancel"
              variant="outlined"
              className={classes.button}
              size="large"
              color="primary"
              onClick={() => goToHomePage()}
            >
              cancel
            </Button>
            <Button
              id="submit"
              variant="contained"
              className={classes.button}
              size="large"
              color="primary"
              disabled={isSubmitting}
              onClick={() => attemptSignUp()}
            >
              Join
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LoginLayout>
  );
};

export default ResellerSignup;
