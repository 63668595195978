import React from "react";
import { SvgIcon } from "@material-ui/core";

const CustomersIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path  fill="none" className="a" d="M0,0H24V24H0Z"/><g transform="translate(16209.115 -7920.759)">
        <path className="b" d="M6.992,11.853H3V8.6a4.446,4.446,0,0,0-.82-1.924c-.1-.118-.2-.236-.306-.349.08,0,.155-.006.224-.006a6.207,6.207,0,0,1,1.452.2,7.32,7.32,0,0,1,1.606.606,4.2,4.2,0,0,1,1.3,1.009,2.187,2.187,0,0,1,.536,1.411v2.3ZM2.1,5.532A1.869,1.869,0,0,1,.613,4.723,3.246,3.246,0,0,1,0,2.766,3.246,3.246,0,0,1,.613.809,1.869,1.869,0,0,1,2.1,0a1.861,1.861,0,0,1,1.48.809,3.256,3.256,0,0,1,.61,1.957,3.256,3.256,0,0,1-.61,1.957A1.861,1.861,0,0,1,2.1,5.532Z" transform="translate(-16193.106 7928.018)"/>
        <path className="b" d="M2.1,12.064a1.869,1.869,0,0,1-1.484-.809A3.246,3.246,0,0,1,0,9.3,3.246,3.246,0,0,1,.613,7.341,1.869,1.869,0,0,1,2.1,6.532a1.861,1.861,0,0,1,1.481.809A3.256,3.256,0,0,1,4.188,9.3a3.256,3.256,0,0,1-.61,1.957A1.861,1.861,0,0,1,2.1,12.064Zm0-6.532c-.071,0-.147,0-.224-.006.1-.112.207-.229.306-.349A4.448,4.448,0,0,0,3,3.253V0H6.992V2.305a2.187,2.187,0,0,1-.536,1.411,4.2,4.2,0,0,1-1.3,1.009,7.32,7.32,0,0,1-1.606.606A6.207,6.207,0,0,1,2.1,5.532Z" transform="translate(-16201.326 7939.87) rotate(180)"/><path className="b" d="M6.847,14.609c1.387,0,2.5-1.476,2.5-3.3S8.233,8,6.847,8,4.341,9.476,4.341,11.3,5.46,14.609,6.847,14.609Zm0,1.57C4.9,16.179,1,17.468,1,20.034v2.754H12.693V20.034C12.693,17.468,8.793,16.179,6.847,16.179Z" transform="translate(-16204.109 7917.082)"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default CustomersIcon;
