import React, { useContext, useState } from "react";
import moment from "moment";

import {
  makeStyles,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Box,
  Button,
  withWidth,
  MenuItem,
  FormControl,
  // Menu,
  Select,
  Avatar,
} from "@material-ui/core";

import SensorImage from "../../img/icons/sensor.svg";

import green from "@material-ui/core/colors/green";

import RangeAlertDisplay from "./RangeAlertDisplay";
import { AppContext } from "./../../AppContext";

// icon imports

import CheckIcon from "@material-ui/icons/Check";
import SnoozeIcon from "@material-ui/icons/Snooze";

const ResolveAlertModal = (props) => {
  const log = window.log('ResolveAlertModal');

  const isSmall = (_width) => {
    switch (_width) {
      case "sm":
        return true;
      case "xs":
        return true;
      default:
        return false;
    }
  };

  let {
    width,
    openState,
    handleClose,
    alertIcon,
    alertName,
    device,
    minValue,
    maxValue,
    minRange,
    maxRange,
    rangeType,
    sensorValue,
    currentAlert,
    updateAlertPage,
    measurementUnit,
    getAlertCount,
    alertType,
    messageReceivedDate,
    timeFormat,
  } = props;
  log("device", device);

  const useStyles = makeStyles((theme) => ({
    titleContainer: {
      padding: theme.spacing(1),
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.background.paper
          : theme.palette.background.paper,
      height: "60px",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    dialog: {
      width: "100%",
      height: "100%",
      padding: "0 !important",
      [theme.breakpoints.up("sm")]: {
        width: "50vw",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    devicePicture: {
      border: "2px solid white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      width: "65px",
      boxSizing: "border-box",
      height: "65px",
      borderRadius: "50%",
    },
    deviceContainer: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
    },
    deviceInfo: {
      marginLeft: theme.spacing(2),
      color: "white",
      fontSize: 14,
    },
    rangeDisplayContainer: {
      padding: theme.spacing(3, 4, 4, 2),
      overflow: "auto",
    },
    subsectionContainer: {
      padding: theme.spacing(3),
      backgroundColor:
        theme.palette.type === "light"
          ? "#F4F5F5"
          : theme.palette.background.default,
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 140px - 116px - 60px)",
      },
    },
    subsectionText: {
      fontWeight: "bold",
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    optionButtonsContainer: {
      padding: theme.spacing(3),
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    selectContainer: {
      padding: theme.spacing(0, 3),
    },
    actionButtonsContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      justifyContent: "space-between",
      marginLeft: 53,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginLeft: 0,
      },
    },
    actionButtons: {
      padding: theme.spacing(1, 5),
      fontWeight: "bold",
      margin: theme.spacing(1),
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    optionButtons: {
      padding: theme.spacing(1, 5),
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(1),
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    snoozeSelected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "white",
    },
    snoozeIcon: {
      fontSize: 32,
      color: theme.palette.warning.main,
    },
    snoozeSelectedIcon: {
      color: "white",
    },
    checkIcon: {
      fontSize: 32,
      color: green[500],
    },
    avatarLarge: {
      width: "15%",
      height: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    select: {
      backgroundColor: theme.palette.background.paper,
    },
    formSelect: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    dialogContent: {
      inlineSize: "fit-content",
    },
    cardContainer: {
      width: 650,
      [theme.breakpoints.down("sm")]: {
        width: "auto",
      },
    },
    doorOrPanicText: {
      fontWeight: "bold",
      color: "#F44336",
      fontSize: 20,
    },
    avatarImage: {
      objectFit: !device.deviceImageUrl && "none",
    },
  }));
  log("device", device);

  log(device.deviceImageUrl);
  const classes = useStyles();

  const { appState, setLogoutState, setAlertCount, snack } = useContext(
    AppContext
  );
  log("appState:", appState);
  log("Looking at the alertType " + alertType);
  log("CurrentAlert", currentAlert);
  if (alertType === "temperature") {
    //Update the temperature value based on user preference
    if (appState.auth.userInfo.themePreferences.measurement === "imperial") {
      minRange = minRange * (9 / 5) + 32;
      maxRange = maxRange * (9 / 5) + 32;
      minRange = parseInt(minRange.toFixed(2));
      maxRange = parseInt(maxRange.toFixed(2));
      log("Looking at the min:max range " + minRange + ":" + maxRange);
    }
  }

  const snoozeOptions = [
    {
      label: "5 minutes",
      value: "5",
    },
    {
      label: "10 minutes",
      value: "10",
    },
    {
      label: "15 minutes",
      value: "15",
    },
    {
      label: "30 minutes",
      value: "30",
    },
    {
      label: "45 minutes",
      value: "45",
    },
    {
      label: "60 minutes",
      value: "60",
    },
  ];

  // const [snoozeMenuAnchor, setSnoozeMenuAnchor] = useState(null);

  const [showSnoozeSelect, setShowSnoozeSelect] = useState(false);

  const [selectedSnoozeTime, setSelectedSnoozeTime] = useState(
    snoozeOptions[0].value
  );

  const onClickSnooze = (event) => {
    setShowSnoozeSelect(!showSnoozeSelect);
  };

  // const onCloseSnooze = () => {
  //   setSnoozeMenuAnchor(null);
  // };

  const handleSnoozeSelectChange = (event) => {
    const value = event.target.value;
    setSelectedSnoozeTime(value);
  };

  const barSize = () => {
    if (isSmall(width)) {
      return 300;
    } else {
      return 450;
    }
  };

  const resolveAlert = async () => {
    log("Resolve alert clicked");
    log("Looking at the app state\n", appState);
    let data = {
      userId: appState.auth.userInfo.id,
      currentAlertId: currentAlert.info.id,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/alert/resolveAlert",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      log("Looking at the response ", response);
      const json = await response.json();
      log("Looking at json ", json);
      if (json.success) {
        snack("Alert acknowledged", "success");
        getAlertCount();
        updateAlertPage && updateAlertPage();
        handleClose();
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  const snoozeAlert = async () => {
    log("Snooze alert clicked with " + selectedSnoozeTime);
    log("Looking at the app state\n", appState);

    let data = {
      userId: appState.auth.userInfo.id,
      currentAlertId: currentAlert.info.id,
      selectedSnoozeTime: selectedSnoozeTime,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/alert/snoozeAlert",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      log("Looking at the response ", response);
      let json = await response.json();
      log("Looking at json ", json);
      if (json.success) {
        snack("Alert snoozed", "success");
        getAlertCount();
        updateAlertPage && updateAlertPage();
        handleClose();
        //Updated the alert count
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/alert/getNotificationCount",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: appState.auth.token,
            },
          }
        );
        json = await response.json();
        if (json.success) {
          setAlertCount(json.data.notificationCount);
        }
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  return (
    <Dialog
      open={openState}
      onClose={handleClose}
      aria-labelledby="resolve-alert-modal-title"
      aria-describedby="resolve-alert-modal-description"
      fullScreen={isSmall(width)}
      fullWidth={false}
      maxWidth={"md"}
    >
      <DialogContent
        classes={{ root: classes.dialogContent }}
        className={classes.dialog}
      >
        <Grid className={classes.cardContainer} container alignItems="stretch">
          <Grid
            item
            container
            xs={12}
            justify="center"
            alignItems="center"
            className={classes.titleContainer}
          >
            <Box>{alertIcon}</Box>
            <Box>
              <Typography className={classes.title}>{alertName}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            alignItems="center"
            className={classes.deviceContainer}
          >
            <Avatar
              classes={{ img: classes.avatarImage }}
              alt="avatar"
              src={device.deviceImageUrl ? device.deviceImageUrl : SensorImage}
              className={classes.avatarLarge}
            />
            <Box className={classes.deviceInfo}>
              <Box fontWeight="bold">{device.deviceName}</Box>
              <Box>{`Sensor ID: ${device.sensorId}`}</Box>
              <Box>{device.location}</Box>
              <Box>
                {appState.auth.userInfo &&
                appState.auth.userInfo.themePreferences &&
                appState.auth.userInfo.themePreferences.timeFormat === "12hr"
                  ? `Date Received: ${moment(messageReceivedDate).format(
                      "MM/DD/YYYY hh:mm a"
                    )}`
                  : `Date Received: ${moment(messageReceivedDate).format(
                      "MM/DD/YYYY kk:mm"
                    )}`}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="center"
            alignItems="center"
            className={classes.rangeDisplayContainer}
          >
            {alertType === "door" || alertType === "panic" ? (
              <Typography className={classes.doorOrPanicText}>
                {currentAlert.info.lastSensorValue}
              </Typography>
            ) : (
              <RangeAlertDisplay
                minValue={minValue}
                maxValue={maxValue}
                minRange={minRange}
                maxRange={maxRange}
                alertValue={sensorValue}
                barWidth={barSize()}
                rangeType={rangeType}
                measurementUnit={measurementUnit}
              />
            )}
          </Grid>
          <Grid item container xs={12} className={classes.subsectionContainer}>
            <Grid item container xs={12} justify="center">
              <Box className={classes.subsectionText}>
                Would you like to Snooze or mark this alert as Resolved?
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              className={classes.optionButtonsContainer}
            >
              <Button
                variant="contained"
                aria-controls="snooze-menu"
                aria-haspopup="true"
                className={`${classes.optionButtons} ${
                  showSnoozeSelect ? classes.snoozeSelected : ""
                }`}
                startIcon={
                  <SnoozeIcon
                    className={`${classes.snoozeIcon}  ${
                      showSnoozeSelect ? classes.snoozeSelectedIcon : ""
                    }`}
                  />
                }
                onClick={onClickSnooze}
              >
                Snooze Alert
              </Button>
              <Button
                variant="contained"
                className={classes.optionButtons}
                startIcon={<CheckIcon className={classes.checkIcon} />}
                onClick={() => resolveAlert()}
              >
                Resolve Alert
              </Button>
            </Grid>
            {showSnoozeSelect ? (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justify="center"
                className={classes.selectContainer}
              >
                <FormControl variant="outlined" className={classes.formSelect}>
                  <Select
                    className={classes.select}
                    value={selectedSnoozeTime}
                    onChange={handleSnoozeSelectChange}
                  >
                    {snoozeOptions.map(({ label, value }) => {
                      return <MenuItem value={value}>{label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              className={classes.actionButtonsContainer}
            >
              <Button
                // variant="contained"
                className={classes.actionButtons}
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleClose}
              >
                Exit
              </Button>
              {showSnoozeSelect ? (
                <Button
                  className={classes.actionButtons}
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => snoozeAlert()}
                >
                  Snooze
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withWidth()(ResolveAlertModal);
