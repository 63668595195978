// entities are top level UI items: Gateways, Devies, Alerts, Users
const insertOne = async (entity, data, token) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/${entity}/add`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(data),
    }
  );

  const json = await request.json();

  return json;
};

export default insertOne;