import React from "react";
import TemperatureThresholds from "./TemperatureThresholds";
import PressureThresholds from "./PressureThresholds";
import HumidityThresholds from "./HumidityThresholds";
import BatteryThresholds from "./BatteryThresholds";
import XAccelThresholds from "./XAccelThresholds";
import YAccelThresholds from "./YAccelThresholds";
import ZAccelThresholds from "./ZAccelThresholds";
import LightThresholds from "./LightThresholds";
import RSSIThresholds from "./RSSIThresholds";

import { Grid, Card, CardContent } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: { margin: "auto" },
  alertCard: {
    marginTop: "1rem",
  },
}));

const GaugeThresholdDash = (props) => {
  const {
    temperatureThresholdLow,
    setTemperatureThresholdLow,
    temperatureThresholdHigh,
    setTemperatureThresholdHigh,
    temperatureScaleType,
    setTemperatureScaleType,
    enableTemperatureAlert,
    setEnableTemperatureAlert,
    temperatureRangeType,
    setTemperatureRangeType,
    pressureThresholdLow,
    setPressureThresholdLow,
    pressureThresholdHigh,
    setPressureThresholdHigh,
    enablePressureAlert,
    setEnablePressureAlert,
    pressureRangeType,
    setPressureRangeType,
    humidityThresholdLow,
    setHumidityThresholdLow,
    humidityThresholdHigh,
    setHumidityThresholdHigh,
    enableHumidityAlert,
    setEnableHumidityAlert,
    humidityRangeType,
    setHumidityRangeType,
    batteryThresholdLow,
    setBatteryThresholdLow,
    batteryThresholdHigh,
    setBatteryThresholdHigh,
    enableBatteryAlert,
    setEnableBatteryAlert,
    batteryRangeType,
    setBatteryRangeType,
    xAccelThresholdLow,
    setXAccelThresholdLow,
    xAccelThresholdHigh,
    setXAccelThresholdHigh,
    enableXAccelAlert,
    setEnableXAccelAlert,
    xAccelRangeType,
    setXAccelRangeType,
    yAccelThresholdLow,
    setYAccelThresholdLow,
    yAccelThresholdHigh,
    setYAccelThresholdHigh,
    enableYAccelAlert,
    setEnableYAccelAlert,
    yAccelRangeType,
    setYAccelRangeType,
    zAccelThresholdLow,
    setZAccelThresholdLow,
    zAccelThresholdHigh,
    setZAccelThresholdHigh,
    enableZAccelAlert,
    setEnableZAccelAlert,
    zAccelRangeType,
    setZAccelRangeType,
    lightThresholdLow,
    setLightThresholdLow,
    lightThresholdHigh,
    setLightThresholdHigh,
    enableLightAlert,
    setEnableLightAlert,
    lightRangeType,
    setLightRangeType,
    rssiThresholdLow,
    setRSSIThresholdLow,
    rssiThresholdHigh,
    setRSSIThresholdHigh,
    enableRSSIAlert,
    setEnableRSSIAlert,
    rssiRangeType,
    setRSSIRangeType,
  } = props;
  const classes = useStyles();

  return (
    <Card elevation={3}>
      <CardContent className={classes.content}>
        <Grid container justify="space-evenly">
          <Grid className={classes.alertCard} item>
            <TemperatureThresholds
              temperatureThresholdLow={temperatureThresholdLow}
              setTemperatureThresholdLow={setTemperatureThresholdLow}
              temperatureThresholdHigh={temperatureThresholdHigh}
              setTemperatureThresholdHigh={setTemperatureThresholdHigh}
              temperatureScaleType={temperatureScaleType}
              setTemperatureScaleType={setTemperatureScaleType}
              enableTemperatureAlert={enableTemperatureAlert}
              setEnableTemperatureAlert={setEnableTemperatureAlert}
              temperatureRangeType={temperatureRangeType}
              setTemperatureRangeType={setTemperatureRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <PressureThresholds
              pressureThresholdLow={pressureThresholdLow}
              setPressureThresholdLow={setPressureThresholdLow}
              pressureThresholdHigh={pressureThresholdHigh}
              setPressureThresholdHigh={setPressureThresholdHigh}
              enablePressureAlert={enablePressureAlert}
              setEnablePressureAlert={setEnablePressureAlert}
              pressureRangeType={pressureRangeType}
              setPressureRangeType={setPressureRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <HumidityThresholds
              humidityThresholdLow={humidityThresholdLow}
              setHumidityThresholdLow={setHumidityThresholdLow}
              humidityThresholdHigh={humidityThresholdHigh}
              setHumidityThresholdHigh={setHumidityThresholdHigh}
              enableHumidityAlert={enableHumidityAlert}
              setEnableHumidityAlert={setEnableHumidityAlert}
              humidityRangeType={humidityRangeType}
              setHumidityRangeType={setHumidityRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <BatteryThresholds
              batteryThresholdLow={batteryThresholdLow}
              setBatteryThresholdLow={setBatteryThresholdLow}
              batteryThresholdHigh={batteryThresholdHigh}
              setBatteryThresholdHigh={setBatteryThresholdHigh}
              enableBatteryAlert={enableBatteryAlert}
              setEnableBatteryAlert={setEnableBatteryAlert}
              batteryRangeType={batteryRangeType}
              setBatteryRangeType={setBatteryRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <XAccelThresholds
              xAccelThresholdLow={xAccelThresholdLow}
              setXAccelThresholdLow={setXAccelThresholdLow}
              xAccelThresholdHigh={xAccelThresholdHigh}
              setXAccelThresholdHigh={setXAccelThresholdHigh}
              enableXAccelAlert={enableXAccelAlert}
              setEnableXAccelAlert={setEnableXAccelAlert}
              xAccelRangeType={xAccelRangeType}
              setXAccelRangeType={setXAccelRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <YAccelThresholds
              yAccelThresholdLow={yAccelThresholdLow}
              setYAccelThresholdLow={setYAccelThresholdLow}
              yAccelThresholdHigh={yAccelThresholdHigh}
              setYAccelThresholdHigh={setYAccelThresholdHigh}
              enableYAccelAlert={enableYAccelAlert}
              setEnableYAccelAlert={setEnableYAccelAlert}
              yAccelRangeType={yAccelRangeType}
              setYAccelRangeType={setYAccelRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <ZAccelThresholds
              zAccelThresholdLow={zAccelThresholdLow}
              setZAccelThresholdLow={setZAccelThresholdLow}
              zAccelThresholdHigh={zAccelThresholdHigh}
              setZAccelThresholdHigh={setZAccelThresholdHigh}
              enableZAccelAlert={enableZAccelAlert}
              setEnableZAccelAlert={setEnableZAccelAlert}
              zAccelRangeType={zAccelRangeType}
              setZAccelRangeType={setZAccelRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <LightThresholds
              lightThresholdLow={lightThresholdLow}
              setLightThresholdLow={setLightThresholdLow}
              lightThresholdHigh={lightThresholdHigh}
              setLightThresholdHigh={setLightThresholdHigh}
              enableLightAlert={enableLightAlert}
              setEnableLightAlert={setEnableLightAlert}
              lightRangeType={lightRangeType}
              setLightRangeType={setLightRangeType}
            />
          </Grid>
          <Grid className={classes.alertCard} item>
            <RSSIThresholds
              rssiThresholdLow={rssiThresholdLow}
              setRSSIThresholdLow={setRSSIThresholdLow}
              rssiThresholdHigh={rssiThresholdHigh}
              setRSSIThresholdHigh={setRSSIThresholdHigh}
              enableRSSIAlert={enableRSSIAlert}
              setEnableRSSIAlert={setEnableRSSIAlert}
              rssiRangeType={rssiRangeType}
              setRSSIRangeType={setRSSIRangeType}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GaugeThresholdDash;
