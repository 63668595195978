import React from "react";
import { Grid, IconButton, Box } from "@material-ui/core";
import { Edit, DeleteForever } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

/**
 * CardActions component
 * @param {Object} props
 * @props
 * editEvent: Function
 * deleteEvent: Function,
 */
function CardActions(props) {
  const {
    editEvent, // HandlerEvent for the Edit Button
    deleteEvent, // HandlerEvent for the Delete Button
    alignItems = "center",
    disableDelete,
    smallDevice = false,
    singleCardSensor,
  } = props;
  const useStyles = makeStyles((theme) => ({
    container: {
      [theme.breakpoints.down("xs")]: {
        transform: singleCardSensor && "translateX(-35px)",
      },
    },
    icon: {
      fontSize: smallDevice ? 25 : 30,
      color: theme.palette.type === "light" ? "#AAA" : "#FFF",
    },
  }));
  const classes = useStyles();

  return (
    <Grid className={classes.container} container item xs={12} justify="center">
      <Grid item container justify="flex-end" xs={6}>
        <Box display="flex" alignItems={alignItems}>
          <IconButton onClick={editEvent} size="medium">
            <Edit className={classes.icon} />
          </IconButton>
        </Box>
      </Grid>
      {disableDelete ? null : (
        <Grid item container justify="flex-start" xs={6}>
          <Box display="flex" alignItems={alignItems}>
            <IconButton onClick={deleteEvent}>
              <DeleteForever className={classes.icon} />
            </IconButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default CardActions;
