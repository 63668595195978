import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  yAccelRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const YAccelThresholds = (props) => {
  const theme = useTheme();

  const {
    yAccelThresholdLow,
    setYAccelThresholdLow,
    yAccelThresholdHigh,
    setYAccelThresholdHigh,
    enableYAccelAlert,
    setEnableYAccelAlert,
    yAccelRangeType,
    setYAccelRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: -50,
      label: "-50g",
    },

    {
      value: 50,
      label: "50g",
    },
  ];

  const valuetext = (value) => {
    return `${value}g`;
  };

  const handleToggleEnable = (e) => {
    setEnableYAccelAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (yAccelRangeType === 1 || yAccelRangeType === 2) {
      setYAccelThresholdHigh(v);
    } else {
      setYAccelThresholdLow(v[0]);
      setYAccelThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableYAccelAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.yAccelRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="yaccel-range-slider">
                Y-Accel
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableYAccelAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={yAccelRangeType}
                setRangeType={setYAccelRangeType}
                enabled={enableYAccelAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                aria-labelledby="yaccel-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  yAccelRangeType === 1 || yAccelRangeType === 2
                    ? yAccelThresholdHigh
                      ? yAccelThresholdHigh
                      : 50
                    : [
                        yAccelThresholdLow ? yAccelThresholdLow : 0,
                        yAccelThresholdHigh ? yAccelThresholdHigh : 100,
                      ]
                }
                track={
                  yAccelRangeType === 1 || yAccelRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                min={-50}
                max={50}
                disabled={!enableYAccelAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default YAccelThresholds;
