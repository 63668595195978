import _ from "lodash";
import React from "react";
import {
  Grid,
  Select,
  MenuItem,
  makeStyles,
  FormControl,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import SensorValueCard from "../../Generic/SensorValueCard";
import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";
import { defaultSensorProfile } from "../../common/ModalComponents/DefaultSensorProfile";

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

/**
 * Door Settings Step
 * @param {*} props
 */
export default function DoorSettingsStep(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    setDoorAlertType,
    doorAlertType,
    rangeSelError,
    setRangeSelError,
  } = props;

  const sensorProfile = defaultSensorProfile(theme);

  const doorAlertTypes = [
    {
      name: "Select alert type",
      value: "none",
      isDefault: true,
    },
    {
      name: "Alert when open",
      value: "open_alert",
    },
    {
      name: "Alert when close",
      value: "close_alert",
    },
  ];

  const handleChange = (value) => {
    if (value !== "none" && rangeSelError.rangeSelDropdown) {
      setRangeSelError({ ...rangeSelError, rangeSelDropdown: false });
    }
    setDoorAlertType(value);
  };

  return (
    <Grid item xs={12}>
      <ModalFormHeader
        header="Alert Range Settings - Door Open/Closed"
        desc="Select when you would like to receive your alert from the options below."
      />
      <Grid item xs={12}>
        <SensorValueCard
          icon={_.find(sensorProfile, { name: "door_open_close" }).icon}
          sensorName={"Door Open/Closed"}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formSelect}>
          <Select
            labelId="alert-profile-alert-type"
            id="alert-profile-alert-select"
            className={classes.select}
            inputProps={{
              name: "door",
              id: "range-type-selector",
            }}
            onChange={(e) => handleChange(e.target.value)}
            value={doorAlertType}
            error={rangeSelError.rangeSelDropdown}
          >
            {doorAlertTypes.map(({ name, value, isDefault }, index) => {
              const label = isDefault ? <em>{name}</em> : name;

              return (
                <MenuItem key={`door__alert__option__${index}`} value={value}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
