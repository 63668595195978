import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  pressureRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const PressureThresholds = (props) => {
  const theme = useTheme();

  const {
    pressureThresholdLow,
    setPressureThresholdLow,
    pressureThresholdHigh,
    setPressureThresholdHigh,
    enablePressureAlert,
    setEnablePressureAlert,
    pressureRangeType,
    setPressureRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: 0,
      label: "0hPa",
    },

    {
      value: 2000,
      label: "2000hPa",
    },
  ];

  const valuetext = (value) => {
    return `${value}hPa`;
  };

  const handleToggleEnable = (e) => {
    setEnablePressureAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (pressureRangeType === 1 || pressureRangeType === 2) {
      setPressureThresholdHigh(v);
    } else {
      setPressureThresholdLow(v[0]);
      setPressureThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enablePressureAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.pressureRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="pressure-range-slider">
                Pressure
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enablePressureAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={pressureRangeType}
                setRangeType={setPressureRangeType}
                enabled={enablePressureAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                aria-labelledby="pressure-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  pressureRangeType === 1 || pressureRangeType === 2
                    ? pressureThresholdHigh
                      ? pressureThresholdHigh
                      : 1000
                    : [
                        pressureThresholdLow ? pressureThresholdLow : 0,
                        pressureThresholdHigh ? pressureThresholdHigh : 2000,
                      ]
                }
                track={
                  pressureRangeType === 1 || pressureRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                min={0}
                max={2000}
                disabled={!enablePressureAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PressureThresholds;
