import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "./../../AppContext";

import fetchAll from "./api/fetchAll";
import GatewaySelectSkeleton from "./GatewaySelectSkeleton";

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(3, 2),
  },
  selectMenu: {
    maxHeight: 58,
  },
}));

const GatewaySelect = (props) => {
  const log = window.log('GatewaySelect');

  const { appState, setLogoutState, snack } = useContext(AppContext);
  const { returnValue, setReturnValue, width, error } = props;

  const inputLabel = React.useRef(null);

  const classes = useStyles();

  const [gatewayNames, setGatewayNames] = useState([]);

  const getGateways = async () => {
    log("Gateway_select: Inside of get gateways");
    try {
      const json = await fetchAll(
        "gateway",
        appState.auth.userInfo.companyId,
        appState.auth.token
      );

      if (json.success && json.data.gateways) {
        setGatewayNames(json.data.gateways);
      } else {
        setGatewayNames([]);
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }

      log("Gateway_select: Setting gateway names", json);
      log("Gateway_select: Finished with get gateways");
    } catch (err) {
      log("Error", err);
      snack("Network Error", "error");
    }
  };

  useEffect(() => {
    getGateways();
  }, []);

  return gatewayNames ? (
    <FormControl
      style={{ width }}
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
        Select Gateway
      </InputLabel>
      <Select
        className={classes.selectMenu}
        labelid="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={returnValue ? returnValue : ""}
        error={error}
        onChange={(e) => {
          setReturnValue(e.target.value);
        }}
        labelWidth={125}
      >
        {gatewayNames.map((item, index) => (
          <MenuItem value={item.entryId} key={index}>
            <Grid>
              <Typography>{item.gatewayName}</Typography>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <GatewaySelectSkeleton />
  );
};

export default GatewaySelect;
