import React, { useState, useContext } from "react";
import { AppContext } from "../../AppContext";

import { navigate } from "hookrouter";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";
import insertOne from "../common/api/insertOne";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: " 0 auto",
  },
  textField: {
    margin: "5px 0",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: "1rem",
  },
  formControl: {
    marginTop: 8,
    width: 210,
  },

  select: {
    padding: theme.spacing(3, 2),
  },
}));

const AddSubUser = () => {
  const log = window.log('AddSubUser');

  const { appState, setAppState, snack } = useContext(AppContext);

  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    perm: 1,
  });

  const [err, setErr] = useState({
    fname: false,
    lname: false,
    email: false,
    perm: false,
  });

  const classes = useStyles();

  const attempt = async () => {
    try {
      const data = {
        ...state,
        companyId: appState.auth.userInfo.companyId,
      };
      const json = await insertOne("users", data, appState.auth.token);

      if (json.success) {
        // success
        snack(
          "An email invitation has been sent to this users address",
          "success"
        );
        // redirect to login page (root route when not auth)
        setTimeout(() => navigate("/user/all", true), 750);
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const inputLabel = React.useRef(null);
  return (
    <Grid container>
      <Grid item className={classes.centerGrid} sm={9}>
        <Paper className={classes.root}>
          <Typography
            variant="h5"
            component="h5"
            style={{ textAlign: "center", marginBottom: 15 }}
          >
            Create New Sub User Account
          </Typography>

          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="First Name"
                value={state.fname}
                error={err.fname}
                className={classes.textField}
                onChange={(e) =>
                  setState({ ...state, fname: e.currentTarget.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Last Name"
                value={state.lname}
                error={err.lname}
                className={classes.textField}
                onChange={(e) =>
                  setState({ ...state, lname: e.currentTarget.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                error={err.email}
                label="Email Address"
                value={state.email}
                className={classes.textField}
                onChange={(e) =>
                  setState({ ...state, email: e.currentTarget.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  id="demo-simple-select-outlined-label"
                >
                  Permission Level
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  value={state.perm}
                  onChange={(e) => setState({ ...state, perm: e.target.value })}
                  labelWidth={125}
                  style={{ textAlign: "left" }}
                >
                  <MenuItem value={0}>Business Owner</MenuItem>
                  <MenuItem value={1}>Employee</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={() => attempt()}
              className={classes.button}
            >
              Add User
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddSubUser;
