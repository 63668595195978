import React from "react";
import { SvgIcon } from "@material-ui/core";

const HumiditySVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="34.992"
      height="34.992"
      viewBox="0 0 34.992 34.992"
      {...props}
    >
      <path fill="none" d="M0,0H34.992V34.992H0Z" />
      <g transform="translate(5.832 2.916)">
        <g transform="translate(5.987 5.01)">
          <path
            fill={filledcolor}
            d="M875.534,137.749a5.292,5.292,0,0,1-1.053,0,5.026,5.026,0,0,1-4.1-2.885,5.411,5.411,0,0,1-.292-3.918l-.621.927a5.417,5.417,0,0,0-.366,5.322,5.025,5.025,0,0,0,4.1,2.885,5.29,5.29,0,0,0,1.053,0,5.026,5.026,0,0,0,4.1-2.885,5.377,5.377,0,0,0,.365-.99A5.027,5.027,0,0,1,875.534,137.749Z"
            transform="translate(-864.644 -119.779)"
          />
          <path
            fill={outlinecolor}
            d="M875,147.308a9,9,0,0,1-.93,0,8.9,8.9,0,0,1-7.256-5.11,9.6,9.6,0,0,1,.647-9.425l5.677-8.481a2.271,2.271,0,0,1,3.77,0l5.677,8.481a9.6,9.6,0,0,1,.646,9.425,8.9,8.9,0,0,1-7.255,5.11A8.4,8.4,0,0,1,875,147.308Zm0-21.8-5.676,8.483a7.2,7.2,0,0,0-.478,7.187,6.567,6.567,0,0,0,5.4,3.827,7.806,7.806,0,0,0,1.454,0,6.565,6.565,0,0,0,5.428-3.827,7.2,7.2,0,0,0-.455-7.187Z"
            transform="translate(-865.879 -123.287)"
          />
        </g>
        <path
          fill={outlinecolor}
          d="M866.4,137.477a5.589,5.589,0,0,1-2.1-2.409,6.123,6.123,0,0,1,.405-6.115l4.829-7.214,3.234,4.832,1.158-1.729-2.769-4.136a1.93,1.93,0,0,0-3.206,0l-4.829,7.214a8.162,8.162,0,0,0-.551,8.018,7.574,7.574,0,0,0,3.566,3.622A11.394,11.394,0,0,1,866.4,137.477Z"
          transform="translate(-861.773 -119.851)"
        />
        <path
          fill={filledcolor}
          d="M865.715,133.21a11.344,11.344,0,0,1,.974-2.081,4.281,4.281,0,0,1-1.083-1.43,4.605,4.605,0,0,1-.248-3.333l-.529.789a4.607,4.607,0,0,0-.311,4.527A4.262,4.262,0,0,0,865.715,133.21Z"
          transform="translate(-860.723 -116.867)"
        />
      </g>
    </SvgIcon>
  );
};

export default HumiditySVG;
