import React, { useState, useContext } from "react";
import { AppContext } from "../../AppContext";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import LoginLayout from "./LoginLayout";

import { A } from "hookrouter";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: " 0 auto",
  },
  textField: {
    width: "100%",
    margin: "5px 0",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  },
  formContainer: {
    width: "80%",
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: ".5rem",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Signup = () => {
  const log = window.log("Signup");

  const { snack } = useContext(AppContext);
  const [formStep, setFormStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleClose = () => {
    setOpenBackdrop(false);
  };
  const handleToggle = () => {
    setOpenBackdrop(!openBackdrop);
  };

  const [state, setState] = useState({
    fname: "",
    lname: "",
    company: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });

  const [err, setErr] = useState({
    fname: false,
    lname: false,
    company: false,
    email: false,

    password: false,
    passwordConfirmation: false,
  });

  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const attempt = async () => {
    handleToggle();
    try {
      const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

      if (
        state.email === "" ||
        state.password === "" ||
        state.passwordConfirmation === ""
      ) {
        setErr((prevState) => {
          return {
            ...prevState,
            email: !state.email,
            password: !state.password,
            passwordConfirmation: !state.passwordConfirmation,
          };
        });
        handleClose();
        snack("Something went wrong. Please double check all fields.", "error");
        return false;
      } else if (state.password !== state.passwordConfirmation) {
        setErr((prevState) => {
          return {
            ...prevState,
            password: false,
            passwordConfirmation: false,
          };
        });
        handleClose();
        snack("Password does not match.", "error");
        return false;
      } else if (!pwRegex.exec(state.password)) {
        //Password requirements - at least 8 characters, at least 1 uppercase, at least 1 lowercase, at least 1 special character
        setErr((prevState) => {
          return {
            ...prevState,
            password: false,
            passwordConfirmation: false,
          };
        });
        handleClose();
        snack("Password requirements were not met.", "error");
        return false;
      }

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/auth/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(state),
        }
      );
      const json = await response.json();

      if (json.success) {
        handleClose();
        // success
        snack("Sign up successful. Please verify your email.", "success");
        // redirect to login page (root route when not auth)
        setFormStep(1);
      } else {
        handleClose();
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      handleClose();
      log(err);
    }
  };

  const resendEmail = () => {
    log(state);
  };

  const subtitles = [
    "Welcome, create your new admin account below.",
    "Almost done! Let's confirm your email.\nJust click on the link in the email we sent you.",
  ];

  const formContents = [
    {
      form: (
        <Grid container>
          {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="First Name"
                value={state.fname}
                error={err.fname}
                className={classes.textField}
                onChange={(e) =>
                  setState({ ...state, fname: e.currentTarget.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Last Name"
                value={state.lname}
                error={err.lname}
                className={classes.textField}
                onChange={(e) =>
                  setState({ ...state, lname: e.currentTarget.value })
                }
              />
            </Grid> */}
          {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Business Name"
                value={state.company}
                error={err.company}
                className={classes.textField}
                onChange={(e) =>
                  setState({ ...state, company: e.currentTarget.value })
                }
              />
            </Grid> */}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              error={err.email}
              label="Email Address"
              value={state.email}
              className={classes.textField}
              onChange={(e) =>
                setState({ ...state, email: e.currentTarget.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                variant="outlined"
                label="Password"
                placeholder="Password"
                value={state.password}
                name="password"
                onChange={(e) =>
                  setState({ ...state, password: e.currentTarget.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attempt();
                  }
                }}
                error={err.password}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password-confirmation">
                Password Confirmation
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-confirmation"
                className={classes.textField}
                fullWidth
                variant="outlined"
                label="Password Confirmation"
                placeholder="Password Confirmation"
                value={state.passwordConfirmation}
                name="password confirmation"
                onChange={(e) =>
                  setState({
                    ...state,
                    passwordConfirmation: e.currentTarget.value,
                  })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attempt();
                  }
                }}
                error={err.passwordConfirmation}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText id="component-error-text">
                Requirements: at least 8 characters, at least 1 uppercase, at
                least 1 lowercase, at least 1 number, at least 1 special
                character (@$!%*?&)
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      ),
      buttons: (
        <Grid container item xs={12} className={classes.buttons}>
          <A
            className={classes.button}
            href="/"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="outlined"
              className={classes.button}
              size="large"
              color="primary"
            >
              Cancel
            </Button>
          </A>
          <Button
            id="submit"
            variant="contained"
            className={classes.button}
            size="large"
            color="primary"
            onClick={() => attempt()}
          >
            Sign Up
          </Button>
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      ),
    },
    {
      form: (
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              error={err.email}
              label="Email To Confirm"
              value={state.email}
              className={classes.textField}
              onChange={(e) =>
                setState({ ...state, email: e.currentTarget.value })
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.buttons}>
            <Button
              className={classes.button}
              id="resentEmail"
              variant="outlined"
              color="primary"
              onClick={() => attempt()}
            >
              Resend Email
            </Button>
            <Backdrop
              className={classes.backdrop}
              open={openBackdrop}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </Grid>
      ),
      buttons: (
        <Grid item xs={12} className={classes.buttons}>
          <A
            className={classes.button}
            href="/"
            style={{ textDecoration: "none" }}
          >
            <Button
              id="submit"
              variant="contained"
              className={classes.button}
              size="large"
              color="primary"
            >
              Close
            </Button>
          </A>
        </Grid>
      ),
    },
  ];

  return (
    <LoginLayout subtitle={subtitles[formStep]}>
      <Grid container className={classes.formContainer}>
        {formContents[formStep].form}
        {formContents[formStep].buttons}
      </Grid>
    </LoginLayout>
  );
};

export default Signup;
