import React from "react";
import { Grid, makeStyles, LinearProgress } from "@material-ui/core";

import ModalFormHeader from "./ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  fieldPadding: {
    paddingBottom: theme.spacing(4),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Loading screen for Modal
 * @props
 * header: string
 * desc: string
 */
export default function ModalLoadingScreen(props) {
  const classes = useStyles();
  const { header, desc } = props;

  return (
    <Grid item xs={12}>
      <ModalFormHeader header={header} desc={desc} />
      <Grid
        item
        xs={12}
        style={{ marginTop: 10 }}
        className={classes.fieldPadding}
      >
        <LinearProgress />
      </Grid>
    </Grid>
  );
}
