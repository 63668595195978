import React from "react";
import { SvgIcon } from "@material-ui/core";

const DashboardIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" className="a" d="M0,0H24V24H0Z" />
        <path
          //   fill="#08b3ff"
          className="b"
          d="M6,13.778h6.222V6H6ZM6,20h6.222V15.333H6Zm7.778,0H20V12.222H13.778Zm0-14v4.667H20V6Z"
          transform="translate(-1 -1)"
        />
      </svg>
    </SvgIcon>
  );
};

export default DashboardIcon;
