import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import ResponsiveDialog from "../Generic/GenericDialog";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function EditSubUserFormModal(props) {
  const log = window.log('EditSubUserFormModal');

  const {
    appState,
    setAppState,
    setLogoutState,
    openEditUserModal,
    setOpenEditUserModal,
    snack,
  } = useContext(AppContext);
  const { getUsers, getUser, userInfo } = props;

  const classes = useStyles();

  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    phoneNo: "",
    permissions: "",
  });

  const [errState, setErrState] = useState({
    fname: false,
    lname: false,
    email: false,
  });

  //Submit USER state hook
  const [submitState, setSubmitState] = useState(false);

  useEffect(() => {
    setState({
      fname: userInfo && userInfo.fname && userInfo.fname,
      lname: userInfo && userInfo.lname && userInfo.lname,
      email: userInfo && userInfo.email && userInfo.email,
      phoneNo: userInfo && userInfo.phoneNumber && userInfo.phoneNumber,
      permissions:
        userInfo && (userInfo.perm || userInfo.perm === 0) ? userInfo.perm : "",
    });
  }, [userInfo]);

  // Submit function to api
  const handleSubmitUser = async () => {
    let foundError = false;
    const { fname, lname, email, phoneNo, permissions } = state;

    //Don't make fetch request if no changes were made
    if ( userInfo.fname === fname && userInfo.lname === lname && userInfo.email === email && userInfo.phoneNumber === phoneNo && permissions === userInfo.perm ) {
      return true;
    }

    //  This is to let mysql know if permissions/user profile have been changed
    //  mysql does not use "real" boolean values 0 = false, 1 = true
    let permChanged = 1;
    // Don't switch the flag if edit was done on user's own account
    if ( userInfo.id === appState.auth.userInfo.id ) {
      permChanged = 0;
    }

    if (!fname.length || !lname.length || !email.length ) {
      setErrState({ 
        ...errState, 
        fname: !fname.length,
        lname: !lname.length,
        email: !email.length,
      });
      foundError = true;
    }

    let formattedPhoneNum = phoneNo
      ? phoneNo
          .split("(")
          .join("")
          .split(")")
          .join("")
          .split(" ")
          .join("")
          .split("-")
          .join("")
      : "";

    if ( formattedPhoneNum.length <= 5 ) {
      formattedPhoneNum = ''
    }

    if (foundError) {
      snack("Please Fill Out All Fields", "error");
      return false;
    }

    try {
      const data = {
        fname,
        lname,
        email,
        permChanged,
        phoneNumber: formattedPhoneNum,
        id: userInfo.id,
        perm: permissions,
        companyId: userInfo.company.id
      };

      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1500);

      // Double check reseller email domain and the new user email domain matches
      if (appState?.auth?.userInfo?.resellerId) {
        const verifyResponse = await fetch(`${process.env.REACT_APP_API_URL}/reseller/verify-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': appState.auth.token,
          },
          body: JSON.stringify({
            id: appState.auth.userInfo.resellerId,
            domain: email.split('@')[1],
          }),
        });

        const json = await verifyResponse.json();

        if (!json.success) {
          json.errors.length && json.errors[0].type !== 'email' 
            ? snack(json.errors[0].msg, 'error') 
            : snack("Unable to make changes the changes. User's email domain does not match the company's email domain on the system.", 'error');
          return false;
        }
      } else {
        snack("Unable to make the changes, please re-login and try again. If the problem persists, please contact support.", 'error')
        return false;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/editUser`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        if (json.errors.length) {
          if (json.errors[0] && json.errors[0].msg) {
            snack(json.errors[0].msg, "error");
          }
        }

        setErrState({ fname: false, lname: false, email: false });

        if (
          appState.auth &&
          appState.auth.userInfo &&
          userInfo &&
          appState.auth.userInfo.id === userInfo.id
        ) {
          appState.auth.userInfo = {
            ...appState.auth.userInfo,
            email,
            fname,
            lname,
          };
          setAppState(appState);
        }

        getUsers && getUsers();
        getUser && getUser();
        return true;
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          if (error.type === "fname") {
            setErrState({ ...state, fname: true });
          }
          if (error.type === "lname") {
            setErrState({ ...state, lname: true });
          }
          if (error.type === "email") {
            setErrState({ ...state, email: true });
          }

          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  /**
   * Takes an object and sets state
   * @param {{stateKey: newval}} changeObj
   */
  const handleStateChange = (changeObj) => {
    setState({ ...state, ...changeObj });
  };

  const handleClose = () => {
    setOpenEditUserModal(false);
  };

  /**
   * controlSelect takes current permissions level
   * returns list allowed to from select menu
   */
  const controlSelect = () => {
    let disabled = false;
    const menuItems = [
      <MenuItem value={50} key="0">
        Reseller
      </MenuItem>,
      <MenuItem value={51} key="1">
        Reseller (DEMO)
      </MenuItem>,
    ];
    const menuItemsAllowed = [];
    const uP = appState.auth.userInfo.perm; // uP = userPerm

    if (uP === 50) menuItemsAllowed.push(menuItems[0], menuItems[1]);

    if (appState.auth.userInfo.id === userInfo.id) disabled = true;

    return [disabled, menuItemsAllowed];
  };

  return (
    <div>
      <ResponsiveDialog
        openState={openEditUserModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title="EDIT USER"
        stepsArray={[
          {
            label: "Enter Name and Email of the user.",
            nextFunction: handleSubmitUser,
            validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Enter Name and Email of the user.
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  {/* <Box className={classes.fontColor}>
                    Once completed the user will receive an email for them to
                    confirm the changes.
                  </Box> */}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="firstName"
                    error={errState.fname}
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    placeholder="First Name*"
                    label="First Name*"
                    value={state.fname}
                    onChange={(e) =>
                      handleStateChange({ fname: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="lastName"
                    error={errState.lname}
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    placeholder="Last Name*"
                    label="Last Name*"
                    value={state.lname}
                    onChange={(e) =>
                      handleStateChange({ lname: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    error={errState.email}
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    placeholder="Email*"
                    label="Email*"
                    value={state.email}
                    onChange={(e) =>
                      handleStateChange({ email: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    value={state.phoneNo}
                    placeholder="Phone Number"
                    label="Phone Number"
                    defaultCountry={"us"}
                    preferredCountries={["us"]}
                    disableAreaCodes={true}
                    autoFormat={true}
                    onChange={(e) =>
                      handleStateChange({ phoneNo: e })
                    }
                    countryCodeEditable={false}
                    inputProps={{
                      autoComplete: 'new-password'
                    }}
                  />
                </Grid>{" "}
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Updating User
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    MiSensors is currently updating the user.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "EDIT COMPLETE", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Success! User has been updated.
                  </Box>
                  <Box
                    className={classes.fontColor}
                    style={{ marginTop: "1rem" }}
                  >
                    Select close to return to the page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
