import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "./../../AppContext";

import fetchAll from "./api/fetchAll";
import GatewaySelectSkeleton from "./GatewaySelectSkeleton";

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   marginTop: 8,
  // },
  select: {
    padding: theme.spacing(3, 2),
  },
  selectMenu: {
    maxHeight: 58,
    backgroundColor: theme.palette.background.paper,
  },
}));

const LocationSelect = (props) => {
  const log = window.log('LocationSelect');

  const {
    appState,
    setLogoutState,
    openEditLocationModal,
    openLocationModal,
    snack,
  } = useContext(AppContext);
  const { returnValue, setReturnValue, width } = props;

  const inputLabel = React.useRef(null);

  const classes = useStyles();

  const [locationNames, setLocationNames] = useState([]);
  //   const [gatewayValue, setGatewayValue] = useState();

  const getLocations = async () => {
    log("Location_select: Inside of get locations");
    
    try {
      const json = await fetchAll(
        "location",
        appState.auth.userInfo.companyId,
        appState.auth.token
      );

      log("Location_select: Setting location names ", json);

      if (json.success) {
        setLocationNames(json.data.locations);
        return true;
      } else {
        setLocationNames([]);
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log("------: getLocations -> err", err);
      snack("Network Error", "error");
    }
  };

  useEffect(() => {
    getLocations();
  }, [openLocationModal, openEditLocationModal]);

  return (
    <>
      {locationNames ? (
        <FormControl
          style={{ width }}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
            Select Location
          </InputLabel>
          <Select
            className={classes.selectMenu}
            labelid="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={returnValue}
            onChange={(e) => {
              setReturnValue(e.target.value);
            }}
            labelWidth={125}
          >
            {locationNames.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                <Grid>
                  <Typography>{item.name ? item.name : item.street}</Typography>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <GatewaySelectSkeleton />
      )}
    </>
  );
};

export default LocationSelect;
