import React, { useState, useEffect, useContext } from "react";
// import TwosComplementBuffer from 'twos-complement-buffer'
import { Grid, Container } from "@material-ui/core";
import { A, navigate } from "hookrouter";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DashboardIcon from "../img/icons/DashboardIcon";
import LocationIcon from "@material-ui/icons/Room";
import GatewayIcon from "@material-ui/icons/Router";
import AlertIcon from "@material-ui/icons/Announcement";
import SensorIcon from "../img/icons/SensorIcon";

import { AppContext, AppProvider } from "../AppContext";

import LastAlertsList from "./Dashboard/LastAlertsList.js";
import Tile from "./Dashboard/Tile.js";
import DevicesStatusList from "./Dashboard/DevicesStatusList.js";
import MapLayout from "./common/MapLayout";
import HeaderBar from "./Generic/HeaderBar";
import LoadingSpinner from "./Generic/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  tileContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  headerContainer: {
    padding: 0,
    width: "100%",
    marginRight: 14,
    [theme.breakpoints.up("xs")]: {
      marginRight: 0,
    },
  },
}));

const Dashboard = () => {
  const log = window.log('Dashboard');
  const theme = useTheme();
  const classes = useStyles();
  const {
    appState,
    setLogoutState,
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openUserModal,
    openAlertModal,
    setOpenAddingModal,
    setContextLocationCount,
    setContextAlertCount,
    setContextGatewayCount,
    setContextSensorCount,
    alertCount,
    snack,
  } = useContext(AppContext);

  const [state, setState] = useState({
    locationCount: "-",
    gatewayCount: "-",
    sensorCount: "-",
    activeAlertsArray: [],
    resolvedAlertsArray: [],
    devicesArray: [],
    loading: true,
    networkErr: false,
  });



  const fetchAllAlertsPage = async () => {
    log("fetchAlertCounts: Inside of fetchAlertCounts");

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/alert/allPage",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await response.json();

      if (json.success) {
        setState((prevState) => {
          return { ...prevState, activeAlertsArray: json.data.alertsArray };
        });
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
      setState({ ...state, networkErr: true });
    }
  };

  const fetchCompanyCounts = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/company/get",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await response.json();
      if (json.success) {
        const {
          locationCount,
          gatewayCount,
          sensorCount,
          activeAlertCount,
          resolvedAlerts,
          offlineSensors,
        } = json.data;

        setState((prevState) => {
          return {
            ...prevState,
            locationCount,
            gatewayCount,
            sensorCount,
            activeAlertCount,
            resolvedAlertsArray: resolvedAlerts,
            devicesArray: offlineSensors,
            loading: false,
          };
        });
        setContextLocationCount(json.data.locationCount);
        setContextGatewayCount(json.data.gatewayCount);
        setContextSensorCount(json.data.sensorCount);
        setContextAlertCount(json.data.activeAlertCount);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
      setState({
        loading: false,
        networkErr: true,
      });
    }
  };

  useEffect(() => {
    fetchAllAlertsPage();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
  ]);

  useEffect(() => {
    fetchCompanyCounts();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  const openAddModal = () => {
    setOpenAddingModal(true);
  };
  return state.loading ? (
    <LoadingSpinner />
  ) : state.networkErr ? null : (
    <div>
      {/* <Grid style={{ marginTop: 4 }} container spacing={3}> */}
      {/* <Grid className={classes.headerContainer} item> */}
      <HeaderBar
        dashboardHeader={true}
        leadingIcon={
          <DashboardIcon
            style={{
              fontSize: "1.75rem",
              marginLeft: 7,
            }}
            color="primary"
          />
        }
        title="Dashboard"
        searchInput={false}
      />
      {/* <a href="misensors://device/all">Mobile Link</a> */}
      {/* </Grid> */}
      {/* Tile Entity Components */}
      <Grid style={{ padding: 5, marginLeft: 6, marginRight: 6 }} item xs={12}>
        <Grid container spacing={3}>
          <Grid
            onClick={() => navigate("/location/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Locations"
              underlineColor="blue"
              numberOfEntities={state.locationCount}
              scaleFont={false}
              icon={<LocationIcon style={{ fontSize: 55, color: "#08B3FF" }} />}
            />
          </Grid>

          <Grid
            onClick={() => navigate("/gateway/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Gateways"
              underlineColor="green"
              numberOfEntities={state.gatewayCount}
              scaleFont={false}
              icon={<GatewayIcon style={{ fontSize: 55, color: "#59C754" }} />}
            />
          </Grid>

          <Grid
            onClick={() => navigate("/device/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Sensors"
              underlineColor="lightBlue"
              numberOfEntities={state.sensorCount}
              scaleFont={false}
              icon={<SensorIcon style={{ fontSize: 55, color: "#58A3B9" }} />}
            />
          </Grid>
          <Grid
            onClick={() => navigate("/alert/all")}
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Alerts"
              underlineColor="red"
              numberOfEntities={state.activeAlertCount}
              scaleFont={false}
              icon={<AlertIcon style={{ fontSize: 55, color: "#FF6A6A" }} />}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Map component To do */}

      <Container style={{ maxWidth: "inherit", marginTop: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8}>
            <MapLayout
              loading={state.loading}
              mapCenter={{ lat: 37.376, lng: -122.034 }}
              title="locations"
            />
          </Grid>
          {/* Latest alerts list */}
          <Grid item xs={12} sm={12} md={4}>
            <LastAlertsList
              dashboardTable={true}
              title="Active Alerts"
              alerts={state.activeAlertsArray}
            />
          </Grid>
          {/* Offline Devices */}
          <Grid item xs={12} sm={12} md={6}>
            <DevicesStatusList
              title="Offline Sensors"
              devices={state.devicesArray}
            />
          </Grid>
          {/* Online Devices */}
          <Grid item xs={12} sm={12} md={6}>
            <DevicesStatusList
              title="Resolved Alerts"
              devices={state.resolvedAlertsArray}
              entity={"alerts"}
            />
            {/* </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
