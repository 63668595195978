// entities are top level UI items: Gateways, Devies, Alerts, Users, LastReport
const fetchOne = async (entity, entityId, company, token, getLatestMessage) => {
  const request = await fetch(
    `${process.env.REACT_APP_API_URL}/${entity}/get?id=${entityId}&getLatestMessage=${getLatestMessage}`,
    {
      // method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      // body: JSON.stringify({ company, entityId }),
    }
  );

  const json = await request.json();

  return json;
};

export default fetchOne;
