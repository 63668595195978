import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../AppContext";

import GaugeThresholdDash from "../common/gauge_thresholds/GaugeThresholdDash";
import AllDevices from "../Devices/AllDevices";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, TextField, Button } from "@material-ui/core";
import insertOne from "../common/api/insertOne";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: " 0 auto",
  },
  textField: {
    margin: "5px 0 30px 0",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginTop: "1rem",
  },
  formControl: {
    width: 210,
  },

  select: {
    padding: theme.spacing(3, 2),
  },
}));

const AddAlert = () => {
  const log = window.log('AddAlert');
  const { appState, snack } = useContext(AppContext);

  const classes = useStyles();

  const [alertDescription, setAlertDescription] = useState("");
  const [alertDescriptionError, setAlertDescriptionError] = useState(false);
  const [selectedSensors, setSelectedSensors] = useState([]);

  // Temperature
  const [temperatureScaleType, setTemperatureScaleType] = useState("");
  const [temperatureThresholdLow, setTemperatureThresholdLow] = useState("");
  const [temperatureThresholdHigh, setTemperatureThresholdHigh] = useState("");
  const [enableTemperatureAlert, setEnableTemperatureAlert] = useState(false);
  const [temperatureRangeType, setTemperatureRangeType] = useState(3);

  const [
    temperatureThresholdLowError,
    setTemperatureThresholdLowError,
  ] = useState(false);
  const [
    temperatureThresholdHighError,
    setTemperatureThresholdHighError,
  ] = useState(false);

  //Pressure
  const [pressureThresholdLow, setPressureThresholdLow] = useState("");
  const [pressureThresholdHigh, setPressureThresholdHigh] = useState("");
  const [enablePressureAlert, setEnablePressureAlert] = useState(false);
  const [pressureRangeType, setPressureRangeType] = useState(3);

  const [pressureThresholdLowError, setPressureThresholdLowError] = useState(
    false
  );
  const [pressureThresholdHighError, setPressureThresholdHighError] = useState(
    false
  );

  //Humidity
  const [humidityThresholdLow, setHumidityThresholdLow] = useState("");
  const [humidityThresholdHigh, setHumidityThresholdHigh] = useState("");
  const [enableHumidityAlert, setEnableHumidityAlert] = useState(false);
  const [humidityRangeType, setHumidityRangeType] = useState(3);

  const [humidityThresholdLowError, setHumidityThresholdLowError] = useState(
    false
  );
  const [humidityThresholdHighError, setHumidityThresholdHighError] = useState(
    false
  );

  //Battery
  const [batteryThresholdLow, setBatteryThresholdLow] = useState("");
  const [batteryThresholdHigh, setBatteryThresholdHigh] = useState("");
  const [enableBatteryAlert, setEnableBatteryAlert] = useState(false);
  const [batteryRangeType, setBatteryRangeType] = useState(3);

  const [batteryThresholdError, setBatteryThresholdError] = useState(false);

  //XAccel
  const [xAccelThresholdLow, setXAccelThresholdLow] = useState("");
  const [xAccelThresholdHigh, setXAccelThresholdHigh] = useState("");
  const [enableXAccelAlert, setEnableXAccelAlert] = useState(false);
  const [xAccelRangeType, setXAccelRangeType] = useState(3);

  const [xAccelThresholdError, setXAccelThresholdError] = useState(false);

  //YAccel
  const [yAccelThresholdLow, setYAccelThresholdLow] = useState("");
  const [yAccelThresholdHigh, setYAccelThresholdHigh] = useState("");
  const [enableYAccelAlert, setEnableYAccelAlert] = useState(false);
  const [yAccelRangeType, setYAccelRangeType] = useState(3);

  const [yAccelThresholdError, setYAccelThresholdError] = useState(false);

  //ZAccel
  const [zAccelThresholdLow, setZAccelThresholdLow] = useState("");
  const [zAccelThresholdHigh, setZAccelThresholdHigh] = useState("");
  const [enableZAccelAlert, setEnableZAccelAlert] = useState(false);
  const [zAccelRangeType, setZAccelRangeType] = useState(3);

  const [zAccelThresholdError, setZAccelThresholdError] = useState(false);

  //Light
  const [lightThresholdLow, setLightThresholdLow] = useState("");
  const [lightThresholdHigh, setLightThresholdHigh] = useState("");
  const [enableLightAlert, setEnableLightAlert] = useState(false);
  const [lightRangeType, setLightRangeType] = useState(3);

  const [lightThresholdError, setLightThresholdError] = useState(false);

  //RSSI
  const [rssiThresholdLow, setRSSIThresholdLow] = useState("");
  const [rssiThresholdHigh, setRSSIThresholdHigh] = useState("");
  const [enableRSSIAlert, setEnableRSSIAlert] = useState(false);
  const [rssiRangeType, setRSSIRangeType] = useState(3);

  const [rssiThresholdError, setRSSIThresholdError] = useState(false);

  const handleAddAlert = async () => {
    try {
      const data = {
        alertDescription,
        selectedSensors,
        temperatureScaleType,
        temperatureThresholdLow,
        temperatureThresholdHigh,
        enableTemperatureAlert,
        temperatureRangeType,
        pressureThresholdLow,
        pressureThresholdHigh,
        enablePressureAlert,
        pressureRangeType,
        humidityThresholdLow,
        humidityThresholdHigh,
        enableHumidityAlert,
        humidityRangeType,
        batteryThresholdLow,
        batteryThresholdHigh,
        enableBatteryAlert,
        batteryRangeType,
        xAccelThresholdLow,
        xAccelThresholdHigh,
        enableXAccelAlert,
        xAccelRangeType,
        yAccelThresholdLow,
        yAccelThresholdHigh,
        enableYAccelAlert,
        yAccelRangeType,
        zAccelThresholdLow,
        zAccelThresholdHigh,
        enableZAccelAlert,
        zAccelRangeType,
        lightThresholdLow,
        lightThresholdHigh,
        enableLightAlert,
        lightRangeType,
        rssiThresholdLow,
        rssiThresholdHigh,
        enableRSSIAlert,
        rssiRangeType,
        companyId: appState.auth.userInfo.companyId,
        fname: appState.auth.userInfo.fname,
        lname: appState.auth.userInfo.lname,
        userId: appState.auth.userInfo.id,
      };
      const json = await insertOne("alerts", data, appState.auth.token);

      if (json.success) {
        // success
        snack("Alert added successfully", "success");
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          if (error.type === "description") {
            setAlertDescriptionError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
        });
      }
    } catch (err) {
      log(err);
    }
  };

  return (
    <Grid container>
      <Grid item className={classes.centerGrid} sm={9}>
        <Paper className={classes.root}>
          <Typography
            variant="h5"
            component="h5"
            style={{ textAlign: "center", marginBottom: 15 }}
          >
            Add New Alert
          </Typography>

          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                label="Alert Name"
                value={alertDescription}
                error={alertDescriptionError}
                className={classes.textField}
                onChange={(e) => setAlertDescription(e.currentTarget.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <GaugeThresholdDash
                temperatureThresholdLow={temperatureThresholdLow}
                setTemperatureThresholdLow={setTemperatureThresholdLow}
                temperatureThresholdHigh={temperatureThresholdHigh}
                setTemperatureThresholdHigh={setTemperatureThresholdHigh}
                temperatureScaleType={temperatureScaleType}
                setTemperatureScaleType={setTemperatureScaleType}
                enableTemperatureAlert={enableTemperatureAlert}
                setEnableTemperatureAlert={setEnableTemperatureAlert}
                temperatureRangeType={temperatureRangeType}
                setTemperatureRangeType={setTemperatureRangeType}
                pressureThresholdLow={pressureThresholdLow}
                setPressureThresholdLow={setPressureThresholdLow}
                pressureThresholdHigh={pressureThresholdHigh}
                setPressureThresholdHigh={setPressureThresholdHigh}
                enablePressureAlert={enablePressureAlert}
                setEnablePressureAlert={setEnablePressureAlert}
                pressureRangeType={pressureRangeType}
                setPressureRangeType={setPressureRangeType}
                humidityThresholdLow={humidityThresholdLow}
                setHumidityThresholdLow={setHumidityThresholdLow}
                humidityThresholdHigh={humidityThresholdHigh}
                setHumidityThresholdHigh={setHumidityThresholdHigh}
                enableHumidityAlert={enableHumidityAlert}
                setEnableHumidityAlert={setEnableHumidityAlert}
                humidityRangeType={humidityRangeType}
                setHumidityRangeType={setHumidityRangeType}
                batteryThresholdLow={batteryThresholdLow}
                setBatteryThresholdLow={setBatteryThresholdLow}
                batteryThresholdHigh={batteryThresholdHigh}
                setBatteryThresholdHigh={setBatteryThresholdHigh}
                enableBatteryAlert={enableBatteryAlert}
                setEnableBatteryAlert={setEnableBatteryAlert}
                batteryRangeType={batteryRangeType}
                setBatteryRangeType={setBatteryRangeType}
                xAccelThresholdLow={xAccelThresholdLow}
                setXAccelThresholdLow={setXAccelThresholdLow}
                xAccelThresholdHigh={xAccelThresholdHigh}
                setXAccelThresholdHigh={setXAccelThresholdHigh}
                enableXAccelAlert={enableXAccelAlert}
                setEnableXAccelAlert={setEnableXAccelAlert}
                xAccelRangeType={xAccelRangeType}
                setXAccelRangeType={setXAccelRangeType}
                yAccelThresholdLow={yAccelThresholdLow}
                setYAccelThresholdLow={setYAccelThresholdLow}
                yAccelThresholdHigh={yAccelThresholdHigh}
                setYAccelThresholdHigh={setYAccelThresholdHigh}
                enableYAccelAlert={enableYAccelAlert}
                setEnableYAccelAlert={setEnableYAccelAlert}
                yAccelRangeType={yAccelRangeType}
                setYAccelRangeType={setYAccelRangeType}
                zAccelThresholdLow={zAccelThresholdLow}
                setZAccelThresholdLow={setZAccelThresholdLow}
                zAccelThresholdHigh={zAccelThresholdHigh}
                setZAccelThresholdHigh={setZAccelThresholdHigh}
                enableZAccelAlert={enableZAccelAlert}
                setEnableZAccelAlert={setEnableZAccelAlert}
                zAccelRangeType={zAccelRangeType}
                setZAccelRangeType={setZAccelRangeType}
                lightThresholdLow={lightThresholdLow}
                setLightThresholdLow={setLightThresholdLow}
                lightThresholdHigh={lightThresholdHigh}
                setLightThresholdHigh={setLightThresholdHigh}
                enableLightAlert={enableLightAlert}
                setEnableLightAlert={setEnableLightAlert}
                lightRangeType={lightRangeType}
                setLightRangeType={setLightRangeType}
                rssiThresholdLow={rssiThresholdLow}
                setRSSIThresholdLow={setRSSIThresholdLow}
                rssiThresholdHigh={rssiThresholdHigh}
                setRSSIThresholdHigh={setRSSIThresholdHigh}
                enableRSSIAlert={enableRSSIAlert}
                setEnableRSSIAlert={setEnableRSSIAlert}
                rssiRangeType={rssiRangeType}
                setRSSIRangeType={setRSSIRangeType}
              />
            </Grid>
            <Grid item xs={12}>
              <AllDevices
                enableSelectableRows={true}
                disableNavigateOnClick={true}
                selectedSensors={selectedSensors}
                setSelectedSensors={setSelectedSensors}
              />
            </Grid>
            <Button
              variant={"contained"}
              color={"primary"}
              // onClick={() => attempt()}
              className={classes.button}
              onClick={handleAddAlert}
            >
              Add Alert
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AddAlert;
