import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import CheckboxCard from "../../Generic/CheckboxCard";
import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  select: {
    backgroundColor: theme.palette.background.paper,
  },
  formSelect: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

/**
 * NotificationSettings
 * @param {*} props
 */
export default function NotificationSettings(props) {
  const classes = useStyles();

  const {
    notificationSettings,
    notificationAlertTime,
    setNotificationSettings,
    setNotificationAlertTime,
  } = props;

  const notificationTimeOptions = [
    {
      name: "Select time between notifications",
      value: "none",
      isDefault: true,
    },
    {
      name: "5 minutes",
      value: 5,
    },
    {
      name: "10 minutes",
      value: 10,
    },
    {
      name: "15 minutes",
      value: 15,
    },
    {
      name: "30 minutes",
      value: 30,
    },
    {
      name: "45 minutes",
      value: 45,
    },
    {
      name: "60 minutes",
      value: 60,
    },
  ];

  return (
    <Grid key={6} item xs={12}>
      <ModalFormHeader
        header="Notification Settings"
        desc={`Select the notificiation methods for when an alert is triggered.
          Select all that apply.`}
      />
      <Grid item container xs={12} style={{ marginTop: 20 }}>
        {notificationSettings.map(({ icon, value, title }, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <CheckboxCard
              icon={icon}
              value={value}
              title={title}
              toggleEvent={() => {
                let arrayCopy = notificationSettings.slice();
                arrayCopy[index].value = !value;
                setNotificationSettings(arrayCopy);
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        fontWeight="fontWeightLight"
        fontSize={16}
        style={{ marginTop: "60px" }}
      >
        <Box className={classes.fontColor}>
          Select the time between alert notifications.
        </Box>
      </Grid>
      {/* Select to choose the time frame between alerts */}
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.formSelect}>
          <Select
            labelId="notification-time-interval"
            id="notification-time-interval-select"
            className={classes.select}
            inputProps={{
              name: "notificationTime",
              id: "range-type-selector",
            }}
            onChange={(event) => {
              const value = event.target.value;
              setNotificationAlertTime(value);
            }}
            value={notificationAlertTime}
          >
            {notificationTimeOptions.map(
              ({ name, value, isDefault }, index) => {
                const label = isDefault ? <em>{name}</em> : name;

                return (
                  <MenuItem
                    value={value}
                    key={`notification__setting__option__${index}`}
                  >
                    {label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
