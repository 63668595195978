import React, { useState, useEffect, useContext } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "./../../AppContext";

import fetchAll from "./api/fetchAll";
import GatewaySelectSkeleton from "./GatewaySelectSkeleton";

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   marginTop: 8,
  // },
  select: {
    padding: theme.spacing(3, 2),
  },
  selectMenu: {
    maxHeight: 58,
  },
}));

const SensorSelect = (props) => {
  const log = window.log('SensorSelect');

  const { appState, setLogoutState, snack } = useContext(AppContext);
  const { returnValue, setReturnValue, width, error } = props;

  const inputLabel = React.useRef(null);

  const classes = useStyles();

  const [sensorNames, setSensorNames] = useState([]);
  //   const [gatewayValue, setGatewayValue] = useState();

  const getSensors = async () => {
    try {
      log("Sensor_select: Inside of get sensors");
      const json = await fetchAll(
        "sensor",
        appState.auth.userInfo.companyId,
        appState.auth.token
      );
      log("Sensor_select: Setting sensor names", json);
      if (json.success && json.data.sensors) {
        setSensorNames(json.data.sensors);
        log("Sensor_select: Finished with get sensors");
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      log("------: getSensors -> err", err);
      snack("Network Error", "error");
    }
  };

  useEffect(() => {
    getSensors();
  }, []);

  return (
    <>
      {sensorNames ? (
        <FormControl
          style={{ width }}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
            Select Sensor
          </InputLabel>
          <Select
            className={classes.selectMenu}
            labelid="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={returnValue}
            error={error}
            onChange={(e) => {
              setReturnValue(e.target.value);
            }}
            labelWidth={125}
          >
            {sensorNames.map((item, index) => (
              <MenuItem value={item.sensorEntryId} key={index}>
                <Grid>
                  <Typography>{item.sensorName}</Typography>
                  {/* <Typography>{item.entryId}</Typography> */}
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <GatewaySelectSkeleton />
      )}
    </>
  );
};

export default SensorSelect;
