import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { navigate } from "hookrouter";
import MUIDataTable from "mui-datatables";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import { Grid } from "@material-ui/core";
import moment from "moment";

import {
  TempGauge,
  HumidityGauge,
  PressureGauge,
  BatteryVoltageGauge,
  RSSIGauge,
  LightLuxGauge,
  Accel,
} from "../Gauges";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },

  expansionPanel: {
    padding: ".5rem 0",
    borderRadius: "5px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem"
  }
}));
const Temper = props => {
  const { value } = props;

  const [transition, setTransition] = useState(false);
  useEffect(() => {
    setTimeout(setTransition(true), 300);
  }, []);
}

const SensorMessagesAccordian = (props) => {
  const classes = useStyles();

  const [columns, setColumns] = useState([
    {
      name: "Date Received",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => value,
      },
    },
    {
      name: "Temperature",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <TempGauge
              data={value / 0.005}
              fontSize={15}
              barSize={52}
              bottomFontSpace={-9}
              bottomBarSpace={-20}
              leftBarSpace={-20}
            />
          ) : (
            "N/A"
          ),
      },
    },
    {
      name: "Humidity",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <HumidityGauge
              fontSize={15}
              direction={"row"}
              data={value / 0.0025}
            />
          ) : (
            "N/A"
          ),
      },
    },
    {
      name: "Pressure",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? (
            <PressureGauge
              fontSize={15}
              direction={"row"}
              data={value * 100 - 5000}
            />
          ) : (
            "N/A"
          ),
      },
    },
    {
      name: "Light",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          true ? <LightLuxGauge data={true} /> : "N/A",
      },
    },
    {
      name: "AccX",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? <Accel data={value} axis={"X"} fontSize={15} /> : "N/A",
      },
    },
    {
      name: "AccY",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? <Accel data={value} axis={"Y"} fontSize={15} /> : "N/A",
      },
    },
    {
      name: "AccZ",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          value ? <Accel data={value} axis={"Z"} fontSize={15} /> : "N/A",
      },
    },
    {
      name: "Battery",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          true ? (
            <BatteryVoltageGauge data={90} fontSize={15} direction={"row"} />
          ) : (
            "N/A"
          ),
      },
    },
    {
      name: "RSSI",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) =>
          true ? <RSSIGauge translateY={-10} data={3} /> : "N/A",
      },
    },
  ]);

  let { messages } = props;
  let data;
  if (messages !== undefined) {
    data = messages.map((message) => {
      return [
        moment.utc(message.dateReceived).format("MM/DD/YYYY"),
        message.temp ? Math.round(message.temp * 0.005) : null,
        message.humidity ? message.humidity * 0.0025 : null,
        message.pressure ? (message.pressure + 5000) / 100 : null,
        message.light ? message.light : null,
        message.accX ? message.accX : null,
        message.accY ? message.accY : null,
        message.accZ ? message.accZ : null,
        message.batteryVoltage ? message.batteryVoltage : null,
        message.rssi ? message.rssi : null,
      ];
    });
  }

  const options = {
    selectableRows: "none",
    responsive: "stacked",
  };

  return messages ? (
    <div>
      <ExpansionPanel className={classes.expansionPanel}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{`Sensor Messages (${messages.length})`}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.root}>
              <MUIDataTable
                columns={columns}
                data={data}
                title={
                  <Grid container>
                    <RssFeedIcon fontSize="large" color="primary" />
                    <Typography className={classes.tableTitle}>
                      Messages
                    </Typography>
                  </Grid>
                }
                options={options}
              />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  ) : null;
};
export default SensorMessagesAccordian;
