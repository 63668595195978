import React from "react";

import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rangeTypeSelect: {
    width: 150,
    height: 25,
    [theme.breakpoints.down("xs")]: { height: "auto" },
  },
}));

const RangeTypeSelector = (props) => {
  const { rangeType, setRangeType, enabled } = props;

  const [rangeTypeSelection, setRangeTypeSelection] = React.useState(rangeType);

  const classes = useStyles();

  const handleChange = (e) => {
    setRangeType(e.target.value);
    setRangeTypeSelection(e.target.value);
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.rangeTypeSelect}>
        <InputLabel id="range-type-select">Range Type</InputLabel>
        <Select
          labelId="range-type-select-label"
          id="range-type-select"
          value={rangeTypeSelection}
          onChange={handleChange}
          label="Range Type"
          disabled={!enabled}
        >
          <MenuItem value={1}>Above Value</MenuItem>
          <MenuItem value={2}>Below Value</MenuItem>
          <MenuItem value={3}>Outside of Range</MenuItem>
          <MenuItem value={4}>Inside of Range</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default RangeTypeSelector;
