import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import SingleLiveSensor from "./SingleLiveSensor";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "1rem",
  },
  expansionPanel: {
    padding: ".5rem 0",
    borderRadius: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LiveSensorAccordian = props => {
  const { macAddress, refreshTable } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.expansionPanel}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{`Real time messaging`}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.root}>
            <SingleLiveSensor
              macAddress={macAddress}
              refreshTable={refreshTable}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};
export default LiveSensorAccordian;
