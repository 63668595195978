import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { A } from "hookrouter";
import GatewayIcon from "@material-ui/icons/Router";

//Components
import HeaderBar from "../Generic/HeaderBar";
import SingleCustomerCard from "./SingleCustomerCard";
import CardActions from "../Generic/CardActions";
import TileCentered from "../Dashboard/TileCentered.js"
import Tile from "../Dashboard/Tile"
import { formatBraintreeDate } from '../helper/formatBraintreeDate';

//DCS ICONS
import SensorIcon from "../../img/icons/SensorIcon";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import BatterySVG from "../../icons/BatterySVG";
import LoadingSpinner from "../Generic/LoadingSpinner";
import AdminDashboardIcon from "../../img/icons/AdminDashboardIcon";

const useStyles = makeStyles((theme) => ({
  root: {},
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'white',
    borderRadius: 5
  },
  chipText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,

  }
}));

const SingleCustomer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    appState,
    snack,
    setLogoutState,
    setOpenEditResellerModal,
  } = useContext(AppContext);

  const { id } = props;

  const [company, setCompany] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [sensorCountByProfile, setSensorCountByProfile] = useState({
    temperature: "-",
    humidity: "-",
    pressure: "-",
    acceleration: "-",
    light: "-",
    panic: "-",
    door: "-",
    rssi: "-",
    battery: "-",
  });
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/customer">
        Customers
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const getResellers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reseller/companies?id=${appState.auth.userInfo.resellerId}&company=${id}`, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': appState.auth.token,
        },
      });

      const json = await response.json();

      if (json.success) {
        if (json.data && json.data.companies.length) {
          const company = json.data.companies[0];
          setCompany(company);
          setSensorCountByProfile({
            temperature: company.sensorCountByProfile.temperature || "0",
            humidity: company.sensorCountByProfile.humidity || "0",
            pressure: company.sensorCountByProfile.pressure || "0",
            acceleration: company.sensorCountByProfile.acceleration || "0",
            light: company.sensorCountByProfile.light || "0",
            panic: company.sensorCountByProfile.panic || "0",
            door: company.sensorCountByProfile.door || "0",
            rssi: company.sensorCountByProfile.rssi || "0",
            battery: company.sensorCountByProfile.battery || "0",
          })
        } else {
          snack("Unable to find the account. Please contact support.");
          json.errors.forEach((err) => {
            if (err.type === "token") {
              snack(err.msg, "error");
              setLogoutState(true);
            }
          });
        }
      } else {
        if (json.errors.length) {
          snack(json.errors[0].msg, "error");
        }
      }
    } catch (err) {
      snack("Something went wrong. Please contact support.", "error");
    }
    await getCustomerSubscriptionInfo();
  };

  const getCustomerSubscriptionInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reseller/company-subscription?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': appState.auth.token
        }
      });

      const json = await response.json();

      if (!json.success) {
        snack('Subscription Info not found at this moment. If the problem persists, please contact support.', 'error');
      } else {
        if (json.data) {
          setSubscriptionInfo(json.data);
        }
      }

    } catch (err) {
      snack('Network error. If the problem persits, please contact support.', 'error');
    }
    setInitialPageLoad(false);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getResellers();
  }, []);


  const handleOpenEditResellerModal = () => {
    setOpenEditResellerModal(true);
  };

  const tileComponents = [
    {
      id: 1,
      entityName: "TEMPERATURE SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.temperature,
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 2,
      entityName: "HUMIDITY SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.humidity,
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PRESSURE SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.pressure,
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "IMPACT DETECTION SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.acceleration,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "LIGHT DETECTION SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.light,
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PANIC BUTTON SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.panic,
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "DOOR OPEN/CLOSED SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.door,
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PROXIMITY ALERT SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.rssi,
      icon: (
        <ProximitySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "BATTERY SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.battery,
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
  ];

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
      <div className={classes.root}>
        {/* Generic Search  Bar */}
        <HeaderBar
          leadingIcon={
            <AdminDashboardIcon style={{ fontSize: 32 }} color="primary" />
          }
          breadcrumbs={breadcrumbs}
          title={company && company.name ? company.name : ""}
          addButton={false}
          searchInput={false}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SingleCustomerCard
              singleLocationCard={true}
              enableZero={true}
              showAvatar={false}
              name={
                company && company.name
                  ? company.name
                  : "No Customer Name"
              }
              subtitle="asd"
              subtitle2={company && company.street ? company.street : ""}
              subtitle3={
                company && (company.city || company.state || company.zip)
                  ? `${company.city}, ${company.state} ${company.zip}`
                  : ""
              }
              contactName={
                company && company.first_name
                  ? `${company.first_name} ${company.last_name}`
                  : "No Contact Name"
              }
              contactEmail={company && company.email ? company.email : ""}
              contactPhone={company && company.phone ? company.phone : ""}
              buttons={
                appState.auth.userInfo.perm === 99 && (
                  <CardActions
                    smallDevice={smallDevice}
                    editEvent={handleOpenEditResellerModal}
                    disableDelete={false}
                  />
                )
              }
              trailingItems={[
                {
                  value:
                    company && company.totalGatewayCount
                      ? company.totalGatewayCount
                      : 0,
                  title: "Gateway",
                  icon: (
                    <GatewayIcon style={{ fontSize: 24, color: "#59C754" }} />
                  ),
                },

                {
                  value:
                    company && company.totalSensorCount
                      ? company.totalSensorCount
                      : 0,
                  title: "Sensor",
                  icon: (
                    <SensorIcon
                      style={{
                        fontSize: 24,
                        color: theme.palette.primary.main,
                      }}
                    />
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TileCentered
              entityName="Subscription Type"
              cardStyle={{minHeight: 158}}
            >
              <div style={{ width: "100%" }}>
                <div style={{ height: "3vh" }}></div>
                <Box display="flex" justifyContent="center" style={{ textAlign: "center" }}>
                  <div className={classes.chip}>
                    <Typography className={classes.chipText}>
                      BASIC
                  </Typography>
                  </div>
                </Box>
                <div style={{ width: "100%", height: "2vh" }} />
                <Box style={{ textAlign: "center" }}>
                  <Typography>
                    {subscriptionInfo && subscriptionInfo.planInfo && subscriptionInfo.planInfo.sensors ? `Up to ${subscriptionInfo.planInfo.sensors} Sensors` : 'Failed to retreive sensor information'}
                  </Typography>
                </Box>
              </div>
            </TileCentered>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TileCentered
              entityName="SUBSCRIPTION RENEWAL DATE"
            >
              <div style={{ width: "100%" }}>
                <div style={{ height: "3vh" }}></div>
                <Box display="flex" justifyContent="center" style={{ textAlign: "center" }}>
                  <div className={classes.chip}>
                    <Typography className={classes.chipText}>
                      {!subscriptionInfo ? 'N/A' : subscriptionInfo.neverExpires ? formatBraintreeDate(subscriptionInfo.nextBillingDate) : formatBraintreeDate(subscriptionInfo.paidThroughDate)}
                  </Typography>
                  </div>
                </Box>
                <div style={{ width: "100%", height: "2vh" }} />
                <Box style={{ textAlign: "center" }}>
                  <Typography>
                    {subscriptionInfo && subscriptionInfo.planInfo && subscriptionInfo.neverExpires ? `Auto-Renewal` : `Expires`}
                  </Typography>
                </Box>
              </div>
            </TileCentered>
          </Grid>
          {tileComponents.map((u, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Tile
                entityName={u.entityName}
                underlineColor={u.underlineColor}
                numberOfEntities={u.numberOfEntities}
                scaleFont={true}
                icon={u.icon}
                multiple={true}
                secondEntry={u.secondEntry}
                fontSize={u.fontSize}
                dateValue={u.dateValue}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
};

export default SingleCustomer;
