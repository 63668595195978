import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  zAccelRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const ZAccelThresholds = (props) => {
  const theme = useTheme();

  const {
    zAccelThresholdLow,
    setZAccelThresholdLow,
    zAccelThresholdHigh,
    setZAccelThresholdHigh,
    enableZAccelAlert,
    setEnableZAccelAlert,
    zAccelRangeType,
    setZAccelRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: -50,
      label: "-50g",
    },

    {
      value: 50,
      label: "50g",
    },
  ];

  const valuetext = (value) => {
    return `${value}g`;
  };

  const handleToggleEnable = (e) => {
    setEnableZAccelAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (zAccelRangeType === 1 || zAccelRangeType === 2) {
      setZAccelThresholdHigh(v);
    } else {
      setZAccelThresholdLow(v[0]);
      setZAccelThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableZAccelAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.zAccelRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="zaccel-range-slider">
                Z-Accel
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableZAccelAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={zAccelRangeType}
                setRangeType={setZAccelRangeType}
                enabled={enableZAccelAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                aria-labelledby="zaccel-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  zAccelRangeType === 1 || zAccelRangeType === 2
                    ? zAccelThresholdHigh
                      ? zAccelThresholdHigh
                      : 50
                    : [
                        zAccelThresholdLow ? zAccelThresholdLow : 0,
                        zAccelThresholdHigh ? zAccelThresholdHigh : 100,
                      ]
                }
                track={
                  zAccelRangeType === 1 || zAccelRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                min={-50}
                max={50}
                disabled={!enableZAccelAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ZAccelThresholds;
