import React from "react";
import { SvgIcon } from "@material-ui/core";

const ProximitySVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="34.992"
      height="34.992"
      viewBox="0 0 34.992 34.992"
      {...props}
    >
      <path fill="none" d="M0,0H34.992V34.992H0Z" />
      <g transform="translate(2.335 3.679)">
        <path
          fill={outlinecolor}
          d="M1133.146,132.954a.506.506,0,0,0,.11.053c.068.017.137.03.208.042a18.392,18.392,0,0,1,5.436,1.713,7.384,7.384,0,0,1,2.94,2.448,3.236,3.236,0,0,1,.091,3.647,6.3,6.3,0,0,1-2.15,2.078,16.815,16.815,0,0,1-6.454,2.243,26.908,26.908,0,0,1-7.531.131,19.737,19.737,0,0,1-6.115-1.659,7.692,7.692,0,0,1-3.348-2.653,3.238,3.238,0,0,1-.059-3.7,5.824,5.824,0,0,1,1.492-1.591,13,13,0,0,1,4.5-2.1c.973-.271,1.973-.458,3.005-.693-.157-.341-.336-.689-.478-1.053a.4.4,0,0,0-.526-.273,20.325,20.325,0,0,0-6.279,2.147,9.021,9.021,0,0,0-3.314,2.9,4.57,4.57,0,0,0-.007,5.319,7.722,7.722,0,0,0,2.125,2.159,17.818,17.818,0,0,0,6.8,2.771,28.019,28.019,0,0,0,8.851.377,22.058,22.058,0,0,0,7.162-1.954,10.274,10.274,0,0,0,3.966-3.057,4.638,4.638,0,0,0,.693-4.683,6.4,6.4,0,0,0-2.036-2.617,17.53,17.53,0,0,0-8.214-3.379.347.347,0,0,0-.273.159C1133.532,132.125,1133.349,132.531,1133.146,132.954Zm1.035-7.367c0-.045-.013-.176-.02-.308a4.964,4.964,0,0,0-8.835-2.754,5.113,5.113,0,0,0-.422,5.671,47.643,47.643,0,0,1,2.7,5.452c.377.919.709,1.856,1.062,2.784.149.394.4.582.7.433a.952.952,0,0,0,.41-.514,46.171,46.171,0,0,1,3.735-8.148A4.992,4.992,0,0,0,1134.182,125.587Zm-4.958,16.068a16.642,16.642,0,0,0,3.935-.459,5.535,5.535,0,0,0,2.621-1.212,2.021,2.021,0,0,0,.5-.712,1.27,1.27,0,0,0-.588-1.473,7.245,7.245,0,0,0-3.257-1.267.312.312,0,0,0-.251.16c-.114.247-.175.52-.286.768a2.848,2.848,0,0,1-2.61,1.885,2.748,2.748,0,0,1-2.583-1.629,5.519,5.519,0,0,1-.388-.988c-.064-.2-.16-.254-.353-.2-.527.144-1.063.263-1.584.429a4.555,4.555,0,0,0-1.743.969,1.246,1.246,0,0,0-.02,2.009,4.38,4.38,0,0,0,1.737.976A15.16,15.16,0,0,0,1129.224,141.655Zm-2.475-15.971a2.462,2.462,0,1,1,2.462,2.462A2.462,2.462,0,0,1,1126.749,125.684Z"
          transform="translate(-1113.816 -120.651)"
        />
        <path
          fill={outlinecolor}
          d="M1133.146,129.6c.2-.423.386-.829.595-1.223a.347.347,0,0,1,.273-.159,17.53,17.53,0,0,1,8.214,3.379,6.4,6.4,0,0,1,2.036,2.617,4.638,4.638,0,0,1-.693,4.683,10.274,10.274,0,0,1-3.966,3.057,22.058,22.058,0,0,1-7.162,1.954,28.019,28.019,0,0,1-8.851-.377,17.818,17.818,0,0,1-6.8-2.771,7.722,7.722,0,0,1-2.125-2.159,4.57,4.57,0,0,1,.007-5.319,9.021,9.021,0,0,1,3.314-2.9,20.325,20.325,0,0,1,6.279-2.147.4.4,0,0,1,.526.273c.142.364.32.712.478,1.053-1.032.235-2.032.422-3.005.693a13,13,0,0,0-4.5,2.1,5.824,5.824,0,0,0-1.492,1.591,3.238,3.238,0,0,0,.059,3.7,7.693,7.693,0,0,0,3.348,2.653,19.737,19.737,0,0,0,6.115,1.659,26.908,26.908,0,0,0,7.531-.131,16.814,16.814,0,0,0,6.454-2.243,6.3,6.3,0,0,0,2.15-2.078,3.236,3.236,0,0,0-.091-3.646,7.384,7.384,0,0,0-2.94-2.448,18.391,18.391,0,0,0-5.436-1.713c-.071-.012-.14-.025-.208-.042A.5.5,0,0,1,1133.146,129.6Z"
          transform="translate(-1113.816 -117.294)"
        />
        <path
          fill={outlinecolor}
          d="M1130.976,125.587a5,5,0,0,1-.67,2.617,46.136,46.136,0,0,0-3.735,8.148.951.951,0,0,1-.41.514c-.3.149-.554-.039-.7-.433-.352-.929-.684-1.866-1.061-2.784a47.685,47.685,0,0,0-2.7-5.452,5.113,5.113,0,0,1,.422-5.671,4.965,4.965,0,0,1,8.835,2.754C1130.963,125.411,1130.973,125.542,1130.976,125.587Zm-2.9.1a2.015,2.015,0,0,0-2.007-2.049,2.043,2.043,0,1,0,2.007,2.049Z"
          transform="translate(-1110.609 -120.651)"
        />
        <path
          fill={filledcolor}
          d="M1126.671,136.778a15.159,15.159,0,0,1-4.873-.745,4.378,4.378,0,0,1-1.737-.976,1.246,1.246,0,0,1,.02-2.009,4.555,4.555,0,0,1,1.743-.969c.521-.166,1.057-.284,1.584-.429.192-.052.289,0,.352.2a5.556,5.556,0,0,0,.389.988,2.748,2.748,0,0,0,2.584,1.629,2.847,2.847,0,0,0,2.609-1.885c.111-.248.172-.521.286-.768a.311.311,0,0,1,.251-.16,7.246,7.246,0,0,1,3.257,1.267,1.269,1.269,0,0,1,.588,1.473,2.022,2.022,0,0,1-.5.712,5.536,5.536,0,0,1-2.621,1.212A16.64,16.64,0,0,1,1126.671,136.778Z"
          transform="translate(-1111.263 -115.775)"
        />
      </g>
    </SvgIcon>
  );
};

export default ProximitySVG;
