import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "./../../AppContext";
import { Grid } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { makeStyles } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";
import HeaderBar from "../Generic/HeaderBar";
import UserCard from "../Generic/UserCard";
import fetchAll from "../common/api/fetchAll";
import EditSubUserFormModal from "./EditSubUserFormModal";
import DeleteUserFormModal from "./DeleteUserFormModal";

import AdminDashboardIcon from '../../img/icons/AdminDashboardIcon';
import LoadingSpinner from "../Generic/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  MuiContainer: {
    root: {
      padding: "0",
    },
  },
  root: {
    // width: "100%",
  },
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
}));

/**
 * Event to add a user that is send to the search to execute
 */
const addUserEvent = () => {
  console.warn("Need to be implemented");
};

const AllSubUsers = () => {
  const log = window.log('AllSubUsers');
  const classes = useStyles();

  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenUserModal,
    setOpenEditUserModal,
    setOpenDeleteUserModal,
    setOpenAddingModal,
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    snack,
  } = useContext(AppContext);
  const [state, setState] = useState();

  const [userInfo, setUserInfo] = useState({});

  const [originalData, setOriginalData] = useState([]);
  const [filterCollumns, setColumns] = useState(["fname", "email"]);

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const getUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reseller/users`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': appState.auth.token,
        }
      });
      const json = await response.json()
      log("Looking at the users json ", json);
      if (json.success) {
        setOriginalData(json.data);
        setState(json.data.users);
        log("json.data.users:", json.data.users);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error');
    }

    setInitialPageLoad(false);
  };

  useEffect(() => {
    getUsers();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  const options = {
    onRowClick: (selectedRow) => navigate(`/user/${selectedRow[1]}`),
    selectableRows: "none",
  };

  const openDialog = () => {
    setOpenUserModal(true);
  };

  const handleClose = () => {
    setOpenUserModal(false);
  };

  const handleOpenEditUserModal = (user) => {
    log("Looking at user info ", user);
    setUserInfo(user);
    setOpenEditUserModal(true);
  };

  const handleOpenDeleteUserModal = (user) => {
    log("open delete user modal clicked");
    log("Looking at the user data\n",user);
    log("Looking at token data\n", appState.auth.userInfo);
    if (user.id === appState.auth.userInfo.id) {
      snack("A user cannot delete themselves", "error");
    } else {
      setUserInfo(user);
      setOpenDeleteUserModal(true);
    }
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/">
        Dashboard
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : state ? (
    <div className={classes.root}>
      {/* <SubUserFormModal getUsers={getUsers} /> */}
      <EditSubUserFormModal getUsers={getUsers} userInfo={userInfo} />
      <DeleteUserFormModal userInfo={userInfo} getUsers={getUsers} />
      <HeaderBar
        leadingIcon={
        <AdminDashboardIcon style={{ fontSize: 32 }} color="primary" />
      }
        // breadcrumbs={breadcrumbs}
        title="Demo Accounts"
        buttonAddTitle="ADD DEMO ACCOUNT"
        buttonAddEvent={openAddModal}
        searchCallback={(value) => {
          let filteredArray = [];
          //Iterate over every gateway object
          for (var i = 0; i < originalData["users"].length; i++) {
            let match = false;
            //Iterate over every filter condition
            for (var j = 0; j < filterCollumns.length; j++) {
              //Check for match
              if (
                originalData["users"][i][filterCollumns[j]]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                match = true;
              }
            }
            //If match add to filtered array
            if (match) {
              filteredArray.push(originalData["users"][i]);
            }
          }
          setState(filteredArray);
        }}
      />
      <Grid container spacing={2}>
        {state.map((user) => {
          if (appState.auth.userInfo.id !== user.id) {
            return (
              <Grid item xs={12} sm={6} md={6} key={user.id}>
                <UserCard
                  key={user.id}
                  id={user.id}
                  name={`${user.fname} ${user.lname}`}
                  email={user.email}
                  companyName={user.company.name}
                  perm={user.perm}
                  avatar={
                    user.profile && user.profile.profileImageLocation
                      ? user.profile.profileImageLocation
                      : ""
                  }
                  handleOpenEditUserModal={() => handleOpenEditUserModal(user)}
                  handleOpenDeleteUserModal={() =>
                    handleOpenDeleteUserModal(user)
                  }
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </div>
  ) : null;
};

export default AllSubUsers;
