import React, { useState, useContext, useEffect } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, TextField, Button, Typography } from "@material-ui/core";
import { navigate } from "hookrouter";
import insertOne from "../common/api/insertOne";
import LocationSelect from "../common/LocationSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  textField: {
    width: "75%",
  },
}));

const AddGateway = () => {
  const log = window.log('AddGateway');

  const { appState, setAppState, snack } = useContext(AppContext);
  const classes = useStyles();

  const [state, setState] = useState({
    name: "",
    gatewayMac: "",
  });

  const [locationId, setLocationId] = useState();

  const [err, setErr] = useState({
    gatewayMac: false,
  });

  const addGateway = async () => {
    try {
      const data = {
        ...state,
        name: state.name,
        locationId,
        gatewayMac: state.gatewayMac,
        companyId: appState.auth.userInfo.companyId,
      };
      const json = await insertOne("gateway", data, appState.auth.token);
      if (json.success === true) {
        snack("Gateway successfully added", "success");
        setTimeout(() => navigate("/gateway/all", true), 750);
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        //Write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const scanQRCode = async () => {
    // log("Scan QR code clicked");
    // log("cordoava test " + window.hasOwnProperty("cordova"));
    window.cordova.plugins.barcodeScanner.scan(
      function (result) {
        if (!result.cancelled) {
          if (result.format == "QR_CODE") {
            setState({
              ...state,
              gatewayMac: result.text
                .split(",")[1]
                .replace("MAC", "")
                .replace(/:\s*/g, ""),
            });
          }
        }
      },
      function (error) {
        if (!error.contains("write settings")) {
          alert("Scanning failed: " + error);
        }
      }
    );
  };

  return (
    <Grid item sm={6} style={{ margin: "0 auto" }}>
      <Paper className={classes.root}>
        <Typography
          variant="h5"
          component="h5"
          style={{ textAlign: "center", marginBottom: 15 }}
        >
          Enter Gateway ID
        </Typography>

        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {window.hasOwnProperty("cordova") ? (
              <Button
                id="scanQRCode"
                variant="contained"
                size="large"
                color="primary"
                onClick={() => scanQRCode()}
              >
                Scan QR Code
              </Button>
            ) : (
              <>
                <i>QR scanning availble on our mobile app</i>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <TextField
              variant="outlined"
              id="name"
              className={classes.textField}
              label="Gateway Name (optional)"
              onChange={(e) =>
                setState({ ...state, name: e.currentTarget.value })
              }
              value={state.name}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("gatewayMac").focus();
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <LocationSelect
              returnValue={locationId}
              setReturnValue={setLocationId}
              width={"75%"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <TextField
              variant="outlined"
              id="gatewayMac"
              className={classes.textField}
              label="Gateway ESN"
              onChange={(e) =>
                setState({ ...state, gatewayMac: e.currentTarget.value })
              }
              value={state.gatewayMac}
              error={err.gatewayMac}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("submit").focus();
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              id="submit"
              variant="contained"
              size="large"
              color="primary"
              onClick={() => addGateway()}
            >
              Register Gateway
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AddGateway;
