import React from "react";
import { SvgIcon } from "@material-ui/core";

const MotionDetectionSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="34.992"
      height="34.992"
      viewBox="0 0 34.992 34.992"
      {...props}
    >
      <path fill="none" d="M0,0H34.992V34.992H0Z" />
      <g transform="translate(1.458 2.916)">
        <rect
          fill={filledcolor}
          width="7.103"
          height="7.103"
          rx="0.991"
          transform="translate(15.717 11.833)"
        />
        <path
          fill={outlinecolor}
          d="M989.769,140.507a.927.927,0,0,0-.988.864l-.183,2.751-4.062-4.062a2.615,2.615,0,0,0,.138-.84v-8.192a2.63,2.63,0,0,0-2.627-2.627h-3.015v-5.687l2.074,1.816a.928.928,0,1,0,1.223-1.4l-3.614-3.163-.01-.007-.026-.02-.03-.024-.018-.013-.024-.016-.034-.021-.02-.011-.024-.013-.036-.017-.021-.01-.026-.011-.036-.013-.024-.009-.027-.007-.034-.01-.027-.006-.027-.006-.034-.006-.03,0h-.024l-.036,0h-.09l-.034,0H978l-.031,0-.034.006-.026.006-.027.006-.035.01-.026.007-.024.009-.036.013-.026.01-.021.011-.037.017-.024.013-.018.011-.036.021-.024.016-.017.013-.031.024-.024.02-.01.007-3.615,3.163a.928.928,0,1,0,1.223,1.4l2.076-1.816V128.4h-3.318a2.63,2.63,0,0,0-2.627,2.627v3.138h-9.572l1.816-2.074a.928.928,0,1,0-1.4-1.223l-3.163,3.614-.009.01-.02.024-.023.031-.013.018-.017.024-.021.034-.011.018-.013.026-.017.036-.01.021-.01.026-.014.036-.009.024-.007.027-.009.034-.007.027,0,.026-.007.036,0,.03,0,.024,0,.036v.09l0,.034,0,.024,0,.03.007.036,0,.026.007.027.009.036.007.026.009.024.014.036.01.026.01.021.017.037.013.024.011.018.021.036.016.023.014.018.023.031.02.024.009.01,3.163,3.615a.928.928,0,1,0,1.4-1.223l-1.816-2.076h9.572v3.2a2.63,2.63,0,0,0,2.627,2.627h8.19a2.6,2.6,0,0,0,1.3-.348l3.935,3.935-2.751.183a.928.928,0,0,0,.124,1.852l4.793-.318h.013l.031,0,.038,0,.021,0,.028-.006.04-.009.021-.006.027-.009.037-.013.023-.009.026-.011.034-.016.023-.01.026-.014.03-.017.024-.016.021-.014.028-.021.024-.017.02-.017.026-.023.024-.023.009-.007.009-.009.023-.024.023-.027.016-.018.019-.024.02-.028.016-.023.014-.023.018-.031.013-.024.011-.023.016-.036.01-.024.009-.023.013-.038.009-.026.006-.021.01-.04.006-.028,0-.023.006-.038,0-.031v-.013l.32-4.793A.927.927,0,0,0,989.769,140.507Zm-15.913.07a1.358,1.358,0,0,1-1.358-1.356v-8.192a1.358,1.358,0,0,1,1.358-1.356h8.19a1.359,1.359,0,0,1,1.358,1.356v8.192a1.359,1.359,0,0,1-1.358,1.356Z"
          transform="translate(-958.682 -119.74)"
        />
      </g>
    </SvgIcon>
  );
};

export default MotionDetectionSVG;
