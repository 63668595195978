import React from "react";
import { SvgIcon } from "@material-ui/core";

const PressureSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/SVGIcon"
      width="34.992"
      height="34.992"
      viewBox="0 0 34.992 34.992"
      {...props}
    >
      <path fill="none" d="M0,0H34.992V34.992H0Z" />
      <g transform="translate(3.576 3.575)">
        <path
          fill={outlinecolor}
          d="M911.872,136.085v-1.279a1.406,1.406,0,0,0,.025-.158,12.457,12.457,0,0,1,.307-2.241,14.22,14.22,0,0,1,5.9-8.688,14.24,14.24,0,0,1,22.219,12.907,13.453,13.453,0,0,1-.839,3.787,14.252,14.252,0,0,1-11.9,9.226c-.3.032-.605.057-.91.085h-1.192c-.057-.008-.113-.022-.17-.025a13.238,13.238,0,0,1-4-.817,14.134,14.134,0,0,1-7.891-6.982,13.913,13.913,0,0,1-1.472-4.952C911.924,136.662,911.9,136.374,911.872,136.085Zm25.855-.61a11.6,11.6,0,1,0-11.677,11.6A11.607,11.607,0,0,0,937.727,135.475Z"
          transform="translate(-911.872 -121.222)"
        />
        <path
          fill={filledcolor}
          d="M914.907,132.048h5.219a4.182,4.182,0,0,0,1.8,3.56,4.128,4.128,0,0,0,2.777.834,4.2,4.2,0,0,0,2.928-1.384,4.1,4.1,0,0,0,1.193-3.018h5.2a9.885,9.885,0,0,1-19.116.008Z"
          transform="translate(-910.219 -115.331)"
        />
        <path
          fill={outlinecolor}
          d="M918.674,127.236c.093.1.19.2.276.31q1.738,2.129,3.471,4.262a.563.563,0,0,0,.368.212,2.7,2.7,0,0,1,.125,5.329,2.706,2.706,0,0,1-3.137-2.235,2.346,2.346,0,0,1,.147-1.368,1.221,1.221,0,0,0,.059-.828c-.445-1.835-.876-3.671-1.311-5.508-.012-.051-.019-.1-.028-.154Z"
          transform="translate(-908.185 -117.947)"
        />
        <path
          fill={filledcolor}
          d="M918.69,127.108l-.99-1.752a.113.113,0,0,1,.045-.171l.66-.414a1.275,1.275,0,0,1,.091.113c.3.513.6,1.029.9,1.54a.159.159,0,0,1-.051.246C919.138,126.81,918.928,126.949,918.69,127.108Z"
          transform="translate(-908.71 -119.289)"
        />
        <path
          fill={filledcolor}
          d="M917.748,128.044c-.151.227-.3.445-.443.66a.132.132,0,0,1-.2.054q-.813-.48-1.628-.956a.408.408,0,0,1-.062-.057c.144-.224.286-.456.442-.68.022-.028.127-.028.173,0,.329.175.653.357.978.542C917.254,127.743,917.492,127.891,917.748,128.044Z"
          transform="translate(-909.94 -118.052)"
        />
        <path
          fill={filledcolor}
          d="M924.069,127.052c-.165-.1-.314-.187-.453-.281-.28-.192-.284-.2-.119-.488.266-.466.542-.927.809-1.392.062-.108.127-.13.23-.062.173.116.349.226.525.337a.124.124,0,0,1,.046.193c-.3.514-.59,1.033-.888,1.548A.754.754,0,0,1,924.069,127.052Z"
          transform="translate(-905.601 -119.278)"
        />
        <path
          fill={filledcolor}
          d="M927.565,127.756a.6.6,0,0,1-.086.079c-.542.32-1.084.639-1.628.955a.135.135,0,0,1-.128-.023c-.133-.191-.255-.389-.381-.582-.063-.1,0-.145.068-.187.365-.229.729-.459,1.1-.683.15-.09.307-.164.459-.25.08-.046.142-.046.2.036.122.192.245.382.367.573A.494.494,0,0,1,927.565,127.756Z"
          transform="translate(-904.551 -118.058)"
        />
        <path
          fill={filledcolor}
          d="M928.205,131.079h-2.088v-.925h2.088Z"
          transform="translate(-904.114 -116.358)"
        />
        <path
          fill={filledcolor}
          d="M920.8,126.172v-2.087h.927v2.087Z"
          transform="translate(-907.01 -119.663)"
        />
        <path
          fill={filledcolor}
          d="M916.82,130.15v.931h-2.085v-.931Z"
          transform="translate(-910.313 -116.36)"
        />
      </g>
    </SvgIcon>
  );
};

export default PressureSVG;
