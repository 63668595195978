import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * GenericListCard component
 * @param {Object} props
 * @props
 * Children: Array[Component]
 */
function GenericListCard(props) {
  const {
    height = "100%",
    children, //Array of children component
    sensorCard,
    singleGatewayCard,
    userCard,
    smallDevice = false,
    resellerCard = false
  } = props;

  const useStyles = makeStyles((theme) => ({
    rootCard: {
      padding: sensorCard ? "8px 0px 8px 0px" : theme.spacing(1),
      height: height,
    },
    cardContent: {
      height: height,
      marginLeft: (sensorCard || singleGatewayCard || userCard || smallDevice) ? 0 : 15,
      paddingLeft: singleGatewayCard && 10,
      // marginTop: singleGatewayCard && 15,
      paddingBottom: resellerCard && '18px !important',
    },
  }));
  const classes = useStyles();

  return (
    <Card elevation={2} className={classes.rootCard}>
      <CardContent className={classes.cardContent}>{children}</CardContent>
    </Card>
  );
}

export default GenericListCard;
