import React, { useState, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import {
  Box,
  Grid,
  makeStyles,
  LinearProgress,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import GenericDialog from "../Generic/GenericDialog";
import { navigate } from "hookrouter";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  locationSelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
}));

/**
 * Gateway Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function EditGatewayFormModal(props) {
  const log = window.log('DeleteAlertFormModal');
  const {
    appState,
    setLogoutState,
    openDeleteAlertModal,
    setOpenDeleteAlertModal,
    snack,
    getAlertCount,
  } = useContext(AppContext);

  const { alertId } = props;
  const classes = useStyles();
  //Gateway name state hooks

  //Submit gateway state hook
  const [submitState, setSubmitState] = useState(false);

  const [nameError, setNameError] = useState(false);

  //checkbox state
  const [confirmed,setConfirmed] = useState(false);

  const handleDeleteAlert = async () => {
    log("Inside of handle delete alert");
    log("Alert Delete: Inside of handle delete alert");
    
    try {
      const data = {
        userId: appState.auth.userInfo.id,
        input: confirmed,
        alertId: alertId,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/alert/delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      log("Alert delete: Looking at the json response ", json);
      
      if (json.success) {
        getAlertCount();
        setTimeout(() => setOpenDeleteAlertModal(false), 1999);
        setTimeout(() => navigate("/alert/all", true), 2000);
        return true;
      } else {
        setNameError(true);
        json.errors.forEach((error) => {
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") setLogoutState(true);
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleClose = () => {
    // setOpenEditGatewayModal(false);
    setOpenDeleteAlertModal(false);
  };

  const handleFinish = () => {
    setOpenDeleteAlertModal(false);
    navigate(`/alert/all`);
  };

  return (
    <div>
      <GenericDialog
        openState={openDeleteAlertModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        handleFinish={handleFinish}
        title="DELETE ALERT"
        stepsArray={[
          {
            label: "DELETE ALERT",
            nextFunction: handleDeleteAlert,
            validator: !submitState,
            showProgress: true,
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                  Are you sure you want to delete this alert ?
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight="fontWeightLight"
                  fontSize={16}
                  style={{ marginTop: 10 }}
                ></Grid>
                <Grid item xs={12} style={{ marginTop: 25 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confirmed}
                        onChange={(e)=>setConfirmed(e.target.checked)}
                        name="delete"
                        color="primary"
                      />
                    }
                    label="Yes"
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightBold"
                    fontSize={20}
                    className={classes.fieldPadding}
                  >
                    Deleting gateway from platform
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight="fontWeightLight"
                  fontSize={16}
                  style={{ marginTop: 10 }}
                  className={classes.fieldPadding}
                >
                  <Box>MiSensors is currently deleting your alert.</Box>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10 }}
                    className={classes.fieldPadding}
                  >
                    <LinearProgress />
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            label: "COMPLETE", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box fontWeight="fontWeightBold" fontSize={20}>
                    Success! Your Alert has been deleted.
                  </Box>
                  <Box style={{ marginTop: "1rem" }}>
                    Select finish to return to the Alerts page.
                  </Box>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
