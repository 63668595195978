import React from "react";

// libraries imports

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Box, Divider, Typography, Tooltip } from "@material-ui/core";

// Icon imports

import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";

import SensorIcon from "../../img/icons/SensorIcon";

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: theme.spacing(0, 1),
  },
  divider: {
    backgroundColor: theme.palette.type === "dark" && "#FFF",
  },
  itemContainer: {
    height: -1,
    lineHeight: 0,
    fontSize: 0,
  },
}));

export default function ManageAlertSensorCell(props) {
  const theme = useTheme();

  const { sensorIcons, numberOfAlerts, isSmall } = props;

  const classes = useStyles();
  
  const iconsDict = {
    temperature: (key) => (
      <Tooltip title="Temperature" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <TemperatureSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    humidity: (key) => (
      <Tooltip title="Humidity" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <HumiditySVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    pressure: (key) => (
      <Tooltip title="Pressure" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <PressureSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    motionDetection: (key) => (
      <Tooltip title="Motion" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <MotionDetectionSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    lightDetection: (key) => (
      <Tooltip title="Light Detection" placement="top"  key={key}>
        <div className={classes.itemContainer}>
          <LightSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    panicButton: (key) => (
      <Tooltip title="Panic Button" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <PanicSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    proximity: (key) => (
      <Tooltip title="Proximity" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <ProximitySVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
    openDoor: (key) => (
      <Tooltip title="Open Door" placement="top" key={key}>
        <div className={classes.itemContainer}>
          <DoorSVG
            className={classes.icon}
            outlinecolor={
              theme.palette.type === "light"
                ? theme.palette.iconColor.outline
                : theme.palette.iconColorDarkMode.outline
            }
            filledcolor={theme.palette.iconColorAlert.filled}
            style={{ height: 33, width: 33 }}
          />
        </div>
      </Tooltip>
    ),
  };

  const items = [];

  for (const key in sensorIcons) {
    if (sensorIcons[key]) {
      items.push(
        <Divider
          className={classes.divider}
          key={`divider__${key}`}
          orientation="vertical"
          flexItem
        />
      );
      items.push(iconsDict[key](`icon__${key}`));
    }
  }

  if (!isSmall) {
    items.push(
      <Divider
        className={classes.divider}
        key={"__divider__"}
        orientation="vertical"
        flexItem
      />
    );
  }

  items.push(
    <Tooltip title="Sensors" placement="top" key="sensors">
      <div className={classes.itemContainer}>
        <SensorIcon
          key={"__sensor__icon__"}
          className={classes.icon}
          style={{
            fontSize: 32,
            color: theme.palette.primary.main,
          }}
        />
      </div>
    </Tooltip>
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexWrap="wrap"
      alignItems="center"
      className={classes.container}
    >
      {items}
      <Tooltip title="Sensor Count" placement="top">
        <Typography
          style={{
            fontWeight: 450,
          }}
        >
          {numberOfAlerts}
        </Typography>
      </Tooltip>
    </Box>
  );
}
