import React, { useState, useEffect, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import MUIDataTable from "mui-datatables";
import { Grid, Box, Card } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";
import LocationIcon from "@material-ui/icons/Room";
import GatewayIcon from "@material-ui/icons/Router";
import AlertIcon from "@material-ui/icons/Announcement";
import SensorIcon from "../../img/icons/SensorIcon";
import HeaderBar from "../Generic/HeaderBar";
import LocationCard from "../Generic/LocationCard";
import LoadingSpinner from "../Generic/LoadingSpinner";

import LocationFormModal from "./LocationFormModal";
import GatewayFormModal from "../Gateways/GatewayFormModal";
import DeviceFormModal from "../Devices/DeviceFormModal";

import fetchAll from "../common/api/fetchAll";

const useStyles = makeStyles((theme) => ({
  root: {},
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
}));

/**
 * Event to add a location that is send to the search to execute
 */
const addLocationEvent = () => {
  console.warn("Event not implemented yet");
};

const AllLocations = () => {
  const log = window.log('AllLocations');

  const theme = useTheme();
  const classes = useStyles();

  const {
    appState,
    setAppState,
    setOpenLocationModal,
    setLogoutState,
    setOpenAddingModal,
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
    snack,
  } = useContext(AppContext);
  const [state, setState] = useState();
  const [openState, setOpenState] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filterColumns, setColumns] = useState([
    "name",
    "street",
    "city",
    "state",
    "zip",
  ]);

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const getLocations = async () => {
    try {
      const json = await fetchAll(
        "location",
        appState.auth.userInfo.companyId,
        appState.auth.token
      );
  
      if (json.success) {
        setOriginalData(json.data);
        log("json.data:", json.data);
        setState(json.data.locations);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack('Network Error', 'error')
    }

    setInitialPageLoad(false);
  };

  useEffect(() => {
    getLocations();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    alertCount,
  ]);

  const options = {
    onRowClick: (selectedRow) => {
      navigate(`/location/${selectedRow[5]}`);
    },
    selectableRows: "none",
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/">
        Dashboard
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div className={classes.root}>
      {/* <LocationFormModal getLocations={getLocations} />
        <GatewayFormModal />
        <DeviceFormModal /> */}
      {/* Generic Search  Bar */}
      <HeaderBar
        leadingIcon={<LocationIcon style={{ fontSize: 32 }} color="primary" />}
        // breadcrumbs={breadcrumbs}
        title="Locations"
        buttonAddTitle="ADD LOCATION"
        buttonAddEvent={openAddModal}
        searchCallback={(value) => {
          log("Inside of search callback ", originalData);
          let filteredArray = [];
          //Iteratate over every location object
          for (var i = 0; i < originalData["locations"].length; i++) {
            let match = false;
            log(originalData["locations"][i]);
            //Iterate over every filter condtion
            for (var j = 0; j < filterColumns.length; j++) {
              log(
                "Look " + originalData["locations"][i][filterColumns[j]]
              );
              //Check for match
              if (
                originalData["locations"][i][filterColumns[j]]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                match = true;
              }
            }
            //If match add to filtered array
            if (match) {
              filteredArray.push(originalData["locations"][i]);
            }
          }
          log("Filter ", filteredArray);
          setState(filteredArray);
        }}
      />
      <Grid container spacing={2}>
        {state
          ? state.map((location) => (
              <Grid item xs={12} sm={6} key={location.id}>
                <LocationCard
                  id={location.id}
                  title={`${location.name}`}
                  street={`${location.street}`}
                  city={`${location.city}`}
                  state={`${location.state}`}
                  zip={`${location.zip}`}
                  phone={location.phone}
                  trailingItems={[
                    {
                      value: location.alertCount,
                      icon: (
                        <AlertIcon style={{ fontSize: 28, color: "#FF6A6A" }} />
                      ),
                    },
                    {
                      value: location.gatewayCount,
                      icon: (
                        <GatewayIcon
                          style={{ fontSize: 28, color: "#59C754" }}
                        />
                      ),
                    },
                    {
                      value: location.sensorCount,
                      icon: (
                        <SensorIcon
                          style={{
                            fontSize: 28,
                            color: theme.palette.primary.main,
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  );
};

export default AllLocations;
