import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "./../../AppContext";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";

//COMPONENT
import HeaderBar from "../Generic/HeaderBar";
import DeviceCard from "./DeviceCard";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

//SVG ICONS
import SensorIcon from "../../img/icons/SensorIcon";
import SensorImage from "../../img/icons/sensor.svg";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import BatterySVG from "../../icons/BatterySVG";

//MODAL COMPONENT
// import DeviceFormModal from "./DeviceFormModal";
// import GatewayFormModal from "../Gateways/GatewayFormModal";
// import LocationFormModal from "../Locations/LocationFormModal";

//Socket IO
import socketIOClient from "socket.io-client";
import LoadingSpinner from "../Generic/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  root: {},
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  sensorCardContainer: {
    height: 155,
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AllDevices = (props) => {
  const log = window.log("AllDevices");

  const theme = useTheme();
  const {
    enableSelectableRows,
    disableNavigateOnClick,
    setSelectedSensors,
  } = props;
  const classes = useStyles();

  const {
    appState,
    setAppState,
    setOpenSensorModal,
    setLogoutState,
    setOpenAddingModal,
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    snack,
  } = useContext(AppContext);
  const [state, setState] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const timers = {};
  let sockets = [];

  const [gateways, setGateways] = useState();
  const [originalData, setOriginalData] = useState([]);
  const [filterColumns] = useState([
    "sensorName",
    "sensorId",
    // "locationName",
  ]);
  let [reload, setReload] = useState([]);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [filterValue, setFilterValue] = useState("");
  const getDevices = async () => {
    if (
      !openLocationModal &&
      !openGatewayModal &&
      !openSensorModal &&
      !openAlertModal &&
      !openUserModal
    ) {
      setOpenBackdrop(true);
    }

    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await request.json();

      if (json.success) {
        if (originalData.length === 0) {
          for (let i in json.data.sensors) {
            const timer = 300 - json.data.sensors[i].lastMessageTime;
            json.data.sensors[i].lastMessageTime =
              new Date().getTime() / 1000 - timer;
          }
        } else {
          const prevState = originalData.sensors;
          for (let i in json.data.sensors) {
            let sensor = prevState.find(
              (sensor) => sensor.sensorId === json.data.sensors[i].sensorId
            );
            if (sensor !== undefined) {
              json.data.sensors[i].lastMessageTime = sensor.lastMessageTime;
            } else {
              const timer = 300 - json.data.sensors[i].lastMessageTime;
              json.data.sensors[i].lastMessageTime =
                new Date().getTime() / 1000 - timer;
            }
          }
        }
        setOriginalData(json.data);
        let parsedProfiles = json.data.sensors.map((sensor) => {
          return {
            ...sensor,
            sensorProfile: JSON.parse(sensor.sensorProfile).SensorProfile,
          };
        });

        setState(parsedProfiles);
        setGateways(json.data.sensorGateways);

        log("Looking at the state ", parsedProfiles);
      } else {
        try {
          json.errors.forEach((err) => {
            snack(err.msg, "error");
            if (err.type === "token") setLogoutState(true);
          });
        } catch (err) {
          console.log("Error " + err);
        }
      }
    } catch (err) {
      snack(
        "Unable to grab information. Please refresh the page and try again.",
        "error"
      );
    }
    if (
      !openLocationModal &&
      !openGatewayModal &&
      !openSensorModal &&
      !openAlertModal &&
      !openUserModal
    ) {
      setOpenBackdrop(false);
    }

    setInitialPageLoad(false);
    setReload(!reload);
  };

  useEffect(() => {
    getDevices();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  useEffect(() => {
    if (state.length === 0) return;
    //Set up the live message traffic

    //For every gateway MAC create a socket
    log("Looking at the state " + state);
    for (let i = 0; i < state.length; i++) {
      log(state[i]);
      log(state[i].sensorId);
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        upgrade: false,
        transports: ["websocket"],
        query: {
          sensorMAC: state[i].sensorId.toLowerCase(),
        },
      });
      sockets.push(socket);
      log("The id is " + state[i].sensorId);
      socket.on(state[i].sensorId.toLowerCase(), (data) => {
        log("Sensor data: " + state[i].sensorId + ":" + data);
        log("There are " + state.length + " state objects");
        let newState = [];
        for (let j = 0; j < state.length; j++) {
          if (state[i].sensorId === state[j].sensorId) {
            let stateObj = state[i];
            stateObj.lastMessageTime = new Date().getTime() / 1000;
            stateObj.lastSensorMessage = data;
            newState.push(stateObj);
          } else {
            newState.push(state[j]);
          }
        }
        log("Looking at the new state ", newState);
        setState((prevState) => {
          let updatedState = [];
          for (let i = 0; i < prevState.length; i++) {
            for (let j = 0; j < newState.length; j++) {
              if (prevState[i].sensorId === newState[j].sensorId) {
                log("Setting state for " + prevState[i].sensorId);
                let stateObj = prevState[i];
                stateObj.lastSensorMessage.temperature =
                  newState[j].lastSensorMessage.temperature;
                stateObj.lastSensorMessage.humidity =
                  newState[j].lastSensorMessage.humidity;
                stateObj.lastSensorMessage.pressure =
                  newState[j].lastSensorMessage.pressure;
                stateObj.lastSensorMessage.acceleration =
                  newState[j].lastSensorMessage.acceleration;
                stateObj.lastSensorMessage.currentDoorState =
                  newState[j].lastSensorMessage.currentDoorState;
                stateObj.lastSensorMessage.currentPanicState =
                  newState[j].lastSensorMessage.currentPanicState;
                stateObj.lastSensorMessage.rssi =
                  newState[j].lastSensorMessage.rssi;
                updatedState.push(stateObj);
              }
            }
          }
          return updatedState;
        });
      });
    }

    return () => {
      for (let i = 0; i < sockets.length; i++) {
        log("Leaving page");
        sockets[i].disconnect();
      }
    };
  }, [gateways]);

  // const options = {
  //   onRowClick: (selectedRow) => navigate(`/device/${selectedRow[1]}`),
  //   selectableRows: enableSelectableRows ? enableSelectableRows : "none",
  //   selectableRowsOnClick: disableNavigateOnClick ? true : false,
  //   onRowsSelect: ([...currentRowsSelected], [...allRowsSelected]) => {
  //     let arr = [];
  //     allRowsSelected.map((row) => {
  //       arr.push(state[row.index].sensorId);
  //     });
  //     setSelectedSensors(arr);
  //   },
  // };

  // let breadcrumbs = (
  //   <div style={{ display: "inline-flex" }}>
  //     <A className={classes.breadcrumbs} href="/">
  //       Dashboard
  //     </A>
  //     <div>{"\xa0-\xa0"}</div>
  //   </div>
  // );
  const convertTempForUserPref = (value) => {
    let unitType = " °C";
    //Convert the Fahrenheit
    if (appState.auth.userInfo.themePreferences.measurement === "imperial") {
      value = value * (9 / 5) + 32;
      unitType = " °F";
    }
    value = value.toFixed(2);
    value = value + unitType;
    return value;
  };

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  const scanDevicesHandler = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("scanDevices");
    }
  };

  // const stubDevices = [
  //   {
  //     sensorName: "987458541258 blah",
  //     lastSensorMessage: {
  //       temperature: 444,
  //       humidity: 1000,
  //       pressure: 10000,
  //       acceleration: 100,
  //       light: 3000,
  //       panic: 10.0,
  //       proximity: 55.0,
  //       door: 10.0,
  //     },
  //   },
  // ];

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Generic Search  Bar */}
      <HeaderBar
        leadingIcon={<SensorIcon style={{ fontSize: 32 }} color="primary" />}
        // breadcrumbs={breadcrumbs}
        title="Sensors"
        buttonAddTitle="ADD SENSOR"
        buttonAddEvent={openAddModal}
        searchCallback={(value) => {
          setFilterValue(value);
          let filteredArray = [];
          //Iterate over every sensor object
          for (var i = 0; i < originalData["sensors"].length; i++) {
            let match = false;
            //Iterate over every filter condition
            for (var j = 0; j < filterColumns.length; j++) {
              //Check for match
              if (
                originalData["sensors"][i][filterColumns[j]]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                match = true;
              }
            }
            //If match add to filtered array
            if (match || value === "") {
              filteredArray.push(originalData["sensors"][i]);
            }
          }
          setState((prevState) => {
            let newState = [];
            for (let i = 0; i < filteredArray.length; i++) {
              newState.push(filteredArray[i]);
            }
            log("search Looking at the new State", newState);
            return newState;
          });
          // setState(filteredArray);
        }}
        buttonAux={window.ReactNativeWebView}
        buttonAuxTitle={"SCAN DEVICES"}
        buttonAuxEvent={scanDevicesHandler}
      />
      <Grid container spacing={2}>
        {state
          ? state.map((device, index) => {
              log("++++++", device.lastSensorMessage);
              return (
                <Grid
                  item
                  xs={12}
                  key={device.sensorId}
                  className={classes.sensorCardContainer}
                >
                  <DeviceCard
                    deviceData={device}
                    index={index}
                    reloadSocket={reload}
                    sensorCard={true}
                    showStatus={true}
                    lastMessageTime={device.lastMessageTime}
                    onClick={() => {
                      navigate(`/device/${device.sensorId}`);
                    }}
                    // wrap="wrap"
                    // displayDivider="none"
                    name={`${device.sensorName.toUpperCase()}`}
                    subtitle={`ID: ${device.sensorId}`}
                    subtitle2={`Location: ${device.sensorLocation}`}
                    defaultAvatar={device.sensorImageLocation ? false : true}
                    avatar={
                      device.sensorImageLocation
                        ? device.sensorImageLocation
                        : SensorImage
                    }
                    isItemsDict={true}
                    //showOnlyItems={["temperature", "humidity", "pressure"]}
                    // showOnlyItems={["TEMPERATURE"]}
                    showOnlyItems={device.sensorProfileArray}
                    batteryVolt={device.lastSensorMessage.batteryVoltage}
                    sensorProfile={device.sensorProfile}
                    trailingItems={{
                      temperature: {
                        title: "Temperature",
                        value:
                          device.lastSensorMessage.temperature ||
                          device.lastSensorMessage.temperature === 0
                            ? convertTempForUserPref(
                                device.lastSensorMessage.temperature
                              )
                            : "-",
                        icon: (
                          <TemperatureSVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      humidity: {
                        title: "Humidity",
                        value:
                          device.lastSensorMessage.humidity ||
                          device.lastSensorMessage.humidity === 0
                            ? device.lastSensorMessage.humidity.toFixed(1) +
                              " %"
                            : "-",
                        icon: (
                          <HumiditySVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      pressure: {
                        title: "Pressure",
                        value:
                          device.lastSensorMessage.pressure ||
                          device.lastSensorMessage.pressure === 0
                            ? device.lastSensorMessage.pressure.toFixed(1) +
                              " hPa"
                            : "-",
                        icon: (
                          <PressureSVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      motionDetection: {
                        title: "Motion",
                        value:
                          device.lastSensorMessage.acceleration ||
                          device.lastSensorMessage.acceleration === 0
                            ? device.lastSensorMessage.acceleration.toFixed(1)
                            : "-",
                        icon: (
                          <MotionDetectionSVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      light: {
                        title: "Light",
                        value:
                          device.lastSensorMessage.light ||
                          device.lastSensorMessage.light === 0
                            ? Number(device.lastSensorMessage.light).toFixed(
                                2
                              ) + " lux"
                            : "-",
                        icon: (
                          <LightSVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      panicButton: {
                        title: "Panic",
                        value: device.lastSensorMessage.currentPanicState
                          ? "Active"
                          : "Inactive",
                        icon: (
                          <PanicSVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      proximity: {
                        title: "Proximity",
                        value: "-",
                        icon: (
                          <ProximitySVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                      doorOpen: {
                        title: "Door",
                        value: device.lastSensorMessage.currentDoorState
                          ? "Open"
                          : "Closed",
                        icon: (
                          <DoorSVG
                            outlinecolor={
                              theme.palette.type === "light"
                                ? theme.palette.iconColor.outline
                                : theme.palette.iconColorDarkMode.outline
                            }
                            filledcolor={theme.palette.iconColor.filled}
                            style={{ height: 35, width: 35 }}
                          />
                        ),
                      },
                    }}
                  />
                </Grid>
              );
            })
          : null}
      </Grid>
    </div>
  );
};

export default AllDevices;
