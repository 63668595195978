import React, { useState, useEffect, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import MUIDataTable from "mui-datatables";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { A, navigate } from "hookrouter";
import ShareIcon from "@material-ui/icons/Share";
import GatewayIcon from "@material-ui/icons/Router";
import SensorIcon from "../../img/icons/SensorIcon";
import GatewayImage from "../../img/icons/router-24px.svg";

import HeaderBar from "../Generic/HeaderBar";
import GatewayFormModal from "./GatewayFormModal";
import LocationFormModal from "../Locations/LocationFormModal";
import DeviceFormModal from "../Devices/DeviceFormModal";
import GatewayCard from "./GatewayCard";
import LoadingSpinner from "../Generic/LoadingSpinner";
import socketIOClient from "socket.io-client";

const useStyles = makeStyles((theme) => ({
  root: {},
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  gatewayCardContainer: {
    height: 143,
    [theme.breakpoints.down("xs")]: {
      height: 245,
    },
  },
}));

const AllGateways = () => {
  const log = window.log("AllGateways");

  const classes = useStyles();

  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenGatewayModal,
    setOpenAddingModal,
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
    snack,
  } = useContext(AppContext);
  const [state, setState] = useState();
  const [openState, setOpenState] = useState(false);
  const [openLocationState, setOpenLocationState] = useState(false);
  const [openSensorState, setOpenSensorState] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filterColumns, setColumns] = useState(["gatewayMAC", "gatewayName"]);
  const [reload, setReload] = useState(true);

  const [initialPageLoad, setInitialPageLoad] = useState(true);

  let sockets = [];

  const getGateways = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/get`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await request.json();
      if (json.success) {
        setOriginalData(json.data);
        setState(json.data.gateways);
        log("json.data.gateways:", json.data.gateways);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
      log("AllGateways: Showing json ", json);
      //Set the gateway last Message count
    } catch (error) {
      snack("Network Error", "error");
    }

    setInitialPageLoad(false);
    setReload(!reload);
  };

  useEffect(() => {
    getGateways();
  }, [
    openLocationModal,
    openGatewayModal,
    openSensorModal,
    openAlertModal,
    openUserModal,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      let newState = [];
      setState((prevState) => {
        for (let i = 0; i < prevState.length; i++) {
          let stateObj = prevState[i];
          if (stateObj.lastMessageTime > 0) {
            stateObj.lastMessageTime = prevState[i].lastMessageTime - 15;
          }
          newState.push(stateObj);
        }
        // log(prevState)
        return newState;
      });
    }, 15000);

    return () => clearInterval(interval);
  }, [reload]);

  const [reloadInterval, setReloadInterval] = useState(null);
  useEffect(() => {
    // log("Use effect looking at the state ", state)
    if (state === undefined) {
      const interval = setInterval(() => {
        setReload(!reload);
      }, 2000);
      setReloadInterval(interval);
      return;
    }
    if (state.length === 0) return;
    //Clear out any existing sockets
    sockets = [];
    for (let i = 0; i < state.length; i++) {
      // log("Loop " + i + "\t", state[i]);
      // log("Building a socket for ", state[i].gatewayMAC.toLowerCase());
      const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
        query: {
          sensorMAC: state[i].gatewayMAC.toLowerCase(),
        },
      });
      sockets.push(socket);
      socket.on(state[i].gatewayMAC.toLowerCase(), (data) => {
        // log("Gateway message received ", data);
        let newState = [];
        let updateState = false;
        for (let j = 0; j < state.length; j++) {
          if (state[i].gatewayMAC === state[j].gatewayMAC) {
            // log(
            //   "Setting the gateway message count for gateway " +
            //     state[i].gatewayMAC
            // );
            let stateObj = state[i];
            //Use this as a debounce so the state hook doesn't update constantly
            if (stateObj.lastMessageTime < 100) {
              log("Setting the last message time to 300 ", stateObj);
              updateState = true;
              stateObj.lastMessageTime = 300;
            }
            newState.push(stateObj);
          } else {
            newState.push(state[j]);
          }
        }
        if (updateState) {
          log("Socket update");
          log("Looking at the state ", newState);
          setState(newState);
        }
      });
    }
    return () => {
      clearInterval(reloadInterval);
      for (let i = 0; i < sockets.length; i++) {
        log("Leaving the page");
        sockets[i].disconnect();
      }
    };
  }, [reload]);

  const options = {
    onRowClick: (selectedRow) => navigate(`/gateway/${selectedRow[1]}`),
    selectableRows: "none",
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/">
        Dashboard
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const openAddModal = () => {
    setOpenAddingModal(true);
  };

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
    <div className={classes.root}>
      {/* <GatewayFormModal getGateways={getGateways} /> */}
      {/* <LocationFormModal /> */}
      {/* <DeviceFormModal getGateways={getGateways} /> */}
      {/* Generic Search  Bar */}
      <HeaderBar
        leadingIcon={<GatewayIcon style={{ fontSize: 32 }} color="primary" />}
        // breadcrumbs={breadcrumbs}
        title="Gateways"
        buttonAddTitle="ADD GATEWAY"
        buttonAddEvent={openAddModal}
        searchCallback={(value) => {
          log("original data ", originalData);
          let filteredArray = [];
          //Iterate over every gateway object
          for (var i = 0; i < originalData["gateways"].length; i++) {
            log(originalData["gateways"][i]);
            let match = false;
            //Iterate over every filter condition
            for (var j = 0; j < filterColumns.length; j++) {
              log(
                "Looking at original data " +
                  originalData["gateways"][i][filterColumns[j]].toLowerCase()
              );
              //Check for match
              if (
                originalData["gateways"][i][filterColumns[j]]
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                match = true;
              }
            }
            //If match add to filtered array
            if (match) {
              filteredArray.push(originalData["gateways"][i]);
            }
          }
          setState(filteredArray);
        }}
      />
      <Grid container spacing={2}>
        {state
          ? state.map((gateway) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                key={gateway.gatewayMAC}
                className={classes.gatewayCardContainer}
                // style={{ height: 150 }}
              >
                <GatewayCard
                  //Justin pass in boolean here for online or offline
                  onlineStatus={true}
                  lastMessageTime={gateway.lastMessageTime}
                  gatewayCard={true}
                  enableZero={true}
                  resize={true}
                  showAvatar={true}
                  avatar={
                    gateway.gatewayImageLocation
                      ? gateway.gatewayImageLocation
                      : GatewayImage
                  }
                  defaultAvatar={gateway.gatewayImageLocation ? false : true}
                  showStatus={true}
                  onClick={() => {
                    navigate(`/gateway/${gateway.gatewayMAC}`);
                  }}
                  name={
                    smallDevice ? gateway.gatewayName : `${gateway.gatewayName}`
                  }
                  id={gateway.gatewayMAC}
                  subtitle={`ID: ${gateway.gatewayMAC}`}
                  subtitle2={
                    gateway.locationName
                      ? `Location: ${gateway.locationName}`
                      : "Location: No location provided"
                  }
                  // wrap="wrap"
                  displayDivider="block"
                  trailingItems={[
                    {
                      icon: "",
                    },
                    {
                      value: gateway.sensorCount,
                      icon: (
                        <SensorIcon color="primary" style={{ fontSize: 26 }} />
                      ),
                    },
                  ]}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  );
};

export default AllGateways;
