import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Braintree, HostedField } from "react-braintree-fields";
import grey from "@material-ui/core/colors/grey";
import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // paddingBottom: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  descriptionText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 14,
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  inputNormal: {
    height: "50px",
    marginTop: "50px",
    background: "white",
    border: "1px solid grey",
    padding: "4px",
    borderRadius: "5px",
    paddingLeft: "15px",
  },
  inputFocus: {
    height: "50px",
    marginTop: "50px",
    background: "white",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "4px",
    borderRadius: "5px",
    paddingLeft: "15px",
    width: "100%",
    color: theme.palette.type === "light" ? grey[600] : grey[400],
  },
  error: {
    borderColor: "#f44336",
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    width: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    height: `100%`,
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  contentContainer: {
    height: `100%`,
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  actionContainer: {
    paddingTop: 55,
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  cardContainer: {
    overflowX: "hidden",
    overflowY: "auto",
    height: `100%`,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const EditPayment = (props) => {
  const log = window.log('EditPayment');

  const { appState, setLogoutState, snack } = useContext(AppContext);
  const { openState, handleClose, handleSave } = props;

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const classes = useStyles();

  const [onboardObject, setOnboardObject] = useState({
    cardNumber: "",
    cardExpirationDate: "",
    cardCVC: "CVC",
    zipCode: "",
  });

  const [errorState, setErrorState] = useState({
    number: false,
    expirationDate: false,
    cvv: false,
    postalCode: false,
  });

  const [submitState, setSubmitState] = useState(false);

  const [clientToken, setClientToken] = useState("");
  const [isBtReady, setIsBtReady] = useState(false);
  const [tokenize, setTokenizeFunc] = React.useState();
  const [cardType, setCardType] = React.useState("");
  const [focusedFieldName, setFocusedField] = React.useState("");
  const numberField = React.useRef();
  const cvvField = React.useRef({ field: "CVC" });
  const postalCodeField = React.useRef();

  const [cardPlaceholder, setCardPlaceholder] = useState({
    number: "xxxx xxxx xxxx xxxx",
    expirationDate: "MM/YY",
  });

  useEffect(() => {
    let isCancelled = false;
    const getToken = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/payment/token",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: appState.auth.token,
            },
          }
        );
        const json = await response.json();
        if (json.success) {
          !isCancelled && setClientToken(json.data.token);
        } else {
          json.errors.forEach((err) => {
            snack(err.msg, "error");
            if (err.type === "token") setLogoutState(true);
          });
        }
      } catch (err) {
        snack("Failed getting token. Please try again later.", "error");
      }
    };

    const getUserInfo = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/payment/user",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: appState.auth.token,
            },
          }
        );
        const json = await response.json();
        if (json.success) {
          if (json.data && json.data.defaultCard) {
            setCardPlaceholder({
              ...cardPlaceholder,
              number: "xxxx xxxx xxxx " + json.data.defaultCard.last4,
              expirationDate:
                json.data.defaultCard.expirationMonth +
                "/" +
                json.data.defaultCard.expirationYear,
            });
          }
        } else {
          json.errors.forEach((err) => {
            snack(err.msg, "error");
            if (err.type === "token") setLogoutState(true);
          });
        }
      } catch (err) {
        log("err", err);
      }
    };

    getToken();
    getUserInfo();

    return () => {
      isCancelled = true;
    };
  }, []);

  const onFieldBlur = (field, event) => setFocusedField("");
  const onFieldFocus = (field, event) => setFocusedField(event.emittedBy);

  const onAuthorizationSuccess = () => {
    setIsBtReady(true);
    numberField.current.focus();
  };

  const onDataCollectorInstanceReady = (collector) => {
    // DO SOMETHING with Braintree collector as needed
  };

  const onCardTypeChange = ({ cards }) => {
    let cardCode = "CVV";
    if (1 === cards.length) {
      const [card] = cards;

      setCardType(card.type);

      if (card.code && card.code.name) {
        cardCode = card.code.name;
      }
    } else {
      setCardType("");
    }
  };

  const verifyPaymentMethod = async () => {
    try {
      setSubmitState(true);
      const payload = await tokenize();
      if (payload.nonce) {
        await updatePaymentMethod(payload.nonce);
      }
    } catch (err) {
      handleError(err);
      setSubmitState(false);
    }
  };

  const updatePaymentMethod = async (nonce) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/payment/update-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify({
            paymentMethod: nonce,
          }),
        }
      );

      const json = await response.json();
      if (json.success) {
        snack("Successfully updated the payment method.", "success");
        handleClose();
      } else {
        snack(json.errors[0], "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }
      setErrorState({
        number: false,
        expirationDate: false,
        cvv: false,
        postalCode: false,
      });
    } catch (err) {
      console.error(err);
    }
    setSubmitState(false);
  };

  const handleError = (error = {}) => {
    let fieldError = {
      number: false,
      expirationDate: false,
      cvv: false,
      postalCode: false,
    };
    if (error.details && error.details.invalidFieldKeys) {
      error.details.invalidFieldKeys.forEach((field) => {
        fieldError[field] = true;
      });
    } else if (
      error.message ===
        "All fields are empty. Cannot tokenize empty card fields." &&
      error.code === "HOSTED_FIELDS_FIELDS_EMPTY"
    ) {
      let fields = ["number", "expirationDate", "cvv", "postalCode"];
      fields.forEach((field) => {
        fieldError[field] = true;
      });
    }

    setErrorState((prevState) => {
      return {
        ...prevState,
        ...fieldError,
      };
    });
    snack("Something went wrong. Please double check all fields.", "error");
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      aria-labelledby="responsive-dialog-title"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Card className={classes.cardContainer}>
          <CardContent
            style={{ padding: 0, margin: "0 auto", marginTop: "16px" }}
            className={classes.formContainer}
          >
            <Grid spacing={4} container>
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography className={classes.titleText} align="center">
                  EDIT PAYMENT METHOD
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {}
                    : {
                        paddingLeft: theme.spacing(6),
                        paddingRight: theme.spacing(6),
                        paddingBottom: 0,
                      }
                }
                className={classes.formContainer}
              >
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <p className={classes.descriptionText}>
                    Edit your payment information for future billing charges and
                    subscription renewals.
                  </p>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {
                        paddingLeft: theme.spacing(8),
                        paddingRight: theme.spacing(8),
                        paddingBottom: theme.spacing(2),
                      }
                    : {
                        paddingLeft: 86,
                        paddingRight: 86,
                        paddingBottom: theme.spacing(2),
                        paddingTop: 0,
                      }
                }
                className={classes.contentContainer}
              >
                {/* <Grid item xs={12} className={classes.textFieldPadding}>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    variant="outlined"
                    label="Name on Card"
                    placeholder="Name on Card"
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Braintree
                    className={isBtReady ? "" : "disabled"}
                    authorization={clientToken}
                    onAuthorizationSuccess={onAuthorizationSuccess}
                    onDataCollectorInstanceReady={onDataCollectorInstanceReady}
                    onError={handleError}
                    onCardTypeChange={onCardTypeChange}
                    getTokenRef={(ref) => setTokenizeFunc(() => ref)}
                    styles={{
                      input: {
                        "font-size": "inherit",
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="credit-card-number">
                            Card Number
                          </InputLabel>
                          <HostedField
                            type="number"
                            className={`${
                              focusedFieldName === "number"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${errorState.number ? classes.error : ""} `}
                            placeholder={cardPlaceholder.number}
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            ref={numberField}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="expirationDate">
                            Expiration date
                          </InputLabel>
                          <HostedField
                            type="expirationDate"
                            placeholder={cardPlaceholder.expirationDate}
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            className={`${
                              focusedFieldName === "expirationDate"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${
                              errorState.expirationDate ? classes.error : ""
                            }`}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="cvv">Security Code</InputLabel>
                          <HostedField
                            type="cvv"
                            ref={cvvField}
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            placeholder="CVC/CVV"
                            className={`${
                              focusedFieldName === "cvv"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${errorState.cvv ? classes.error : ""}`}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel htmlFor="expirationDate">
                            Zip Code
                          </InputLabel>
                          <HostedField
                            type="postalCode"
                            placeholder="Zip Code"
                            ref={postalCodeField}
                            onBlur={onFieldBlur}
                            onFocus={onFieldFocus}
                            className={`${
                              focusedFieldName === "postalCode"
                                ? classes.inputFocus
                                : classes.inputNormal
                            } ${errorState.postalCode ? classes.error : ""}`}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Braintree>
                </Grid>
                {/* <Grid item xs={12} className={classes.textFieldPadding}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        label="Card Number"
                        placeholder="Card Number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={classes.textFieldPadding}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        label="MM/YY"
                        placeholder="MM/YY"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} className={classes.textFieldPadding}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        label="CVC"
                        placeholder="CVC"
                      />
                    </Grid>
  
                    <Grid item xs={12} sm={12} md={4} className={classes.textFieldPadding}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        label="Zip Code"
                        placeholder="Zip Code"
                      />
                    </Grid> */}
              </Grid>
            </Grid>
            <CardActions className={classes.actionContainer}>
              <Grid
                container
                style={{
                  paddingLeft: theme.spacing(8),
                  paddingRight: theme.spacing(8),
                  marginBottom: 36,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Button
                    className={classes.leftButton}
                    onClick={handleClose}
                    size="large"
                    variant="outlined"
                    disabled={submitState}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} container justify="flex-end">
                  <Button
                    className={classes.rightButton}
                    onClick={verifyPaymentMethod}
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={submitState}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </ClickAwayListener>
    </Dialog>
  );
};

export default EditPayment;
