import React, { useState, useContext } from "react";
import { AppContext } from "./../../AppContext";
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Grid,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import grey from "@material-ui/core/colors/grey";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ListViewIcon from "../../img/icons/ListViewIcon";

/**
 * Generic HeaderBar for the multiple modules
 * @param {Object} props
 * @props
 * leadingIcon: React component to render for the Icon
 * title: String
 * buttonAddTitle: String
 * addButton: Boolean
 * searchInput: Boolean
 * buttonAddEvent: Callback
 * searchCallback: Callback event to handle input writting
 * to implement search behavior
 */
export default function HeaderBar(props) {
  const { appState } = useContext(AppContext);
  const theme = useTheme();
  const [searchWord, setSearchWord] = useState("");

  const {
    leadingIcon,
    title,
    buttonAddTitle,
    buttonAddEvent,
    searchCallback,
    addButton = true,
    searchInput = true,
    breadcrumbs,
    dashboardHeader,
    sortButton = false, // boolean
    sortButtonEvent, //Function
    buttonAux,
    buttonAuxTitle,
    buttonAuxEvent,
  } = props;

  const useStyles = makeStyles((theme) => ({
    bar: {
      marginBottom: theme.spacing(3),
      marginTop: !searchInput && 4,
    },
    button: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0.75, 4),
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
        width: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: "40px",
        maxHeight: "40px",
        minWidth: "40px",
        minHeight: "40px",
      },
    },
    buttonContainer: {
      marginRight: dashboardHeader ? 12 : 0,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginRight: 12,
        marginLeft: 12,
      },
    },
    searchInput: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "5px",
      flexGrow: 0,
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
      },
    },
    leadingIcon: {
      marginBottom: theme.spacing(-0.5),
      marginRight: theme.spacing(1),
    },
    inputContainer: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
    title: {
      color: theme.palette.type === "light" ? "#051F34" : "#FFF",
      fontSize: 18,
      fontWeight: 600,
    },
    squareButton: {},
  }));

  const handleChange = (event) => {
    setSearchWord(event.target.value);
    searchCallback(event.target.value);
  };

  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.bar}
      alignItems="center"
      direction="row"
      justify="space-between"
    >
      <Grid item container xs={12} sm={!searchInput && 3} md={5}>
        <Box display="flex" alignItems="center">
          <Box className={classes.leadingIcon}>{leadingIcon}</Box>
          <Typography
            display="block"
            variant="h6"
            component="h6"
            className={classes.title}
          >
            {breadcrumbs}
            {title}
          </Typography>
        </Box>
        {buttonAux ? (
          <Box
            className={classes.buttonContainer}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={buttonAuxEvent}
              className={classes.button}
            >
              {/* {buttonAddTitle} */}
              {buttonAuxTitle}
            </Button>
        </Box>
        ) : null}
        
      </Grid>

      <Grid
        item
        container
        xs={12}
        sm={!searchInput && 9}
        md={7}
        alignItems="flex-end"
        className={classes.inputContainer}
      >
        {searchInput ? (
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            <TextField
              variant="outlined"
              placeholder="Search"
              value={searchWord}
              onChange={handleChange}
              className={classes.searchInput}
              margin="dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: grey[500] }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : null}
        {addButton && appState.auth.userInfo.perm !== 1 ? (
          <Box
            className={classes.buttonContainer}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={buttonAddEvent}
              className={classes.button}
            >
              {/* {buttonAddTitle} */}
              <AddCircleOutlineIcon></AddCircleOutlineIcon>
            </Button>
          </Box>
        ) : null}
        {sortButton ? (
          <Box
            className={classes.buttonContainer}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={sortButtonEvent}
              className={classes.button}
              style={{
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {/* {buttonAddTitle} */}
              <ListViewIcon style={{ color: "#ccc" }} fontSize="small">
                {" "}
              </ListViewIcon>
            </Button>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
}
