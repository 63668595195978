import React, { useContext, useState, useEffect, useRef } from "react";
import { useRoutes, A, navigate } from "hookrouter";
import localforage from "localforage";
import clsx from "clsx";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  // Container,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  // List,
  // ListItem,
  ListItemIcon,
  ListItemText,
  Grow,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  Avatar,
  Tabs,
  Tab,
  Box,
  Divider,
  SwipeableDrawer,
  Badge,
  SubHeader,
  Button,
  ListItemSecondaryAction,
} from "@material-ui/core";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "./img/MiSensorsLogo.svg";
import LogoIcon from "./img/MiSensorLogo_Icon.svg";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import GatewayIcon from "@material-ui/icons/Router";
// import DeviceIcon from "@material-ui/icons/SettingsRemote";
import SensorIcon from "./img/icons/SensorIcon";
import AlertIcon from "@material-ui/icons/Announcement";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "./img/icons/DashboardIcon";
import UserIcon from "@material-ui/icons/People";
import LocationIcon from "@material-ui/icons/Room";
import ReportsSVG from "./icons/ReportsSVG";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import fetchOne from "./components/common/api/fetchOne";

import { AppContext, AppProvider } from "./AppContext";
//Auth Import
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import UserInvitation from "./components/Auth/UserInvitation";
import SuccessSignup from "./components/Auth/SuccessSignup";
import NotFoundPage from "./components/Auth/NotFoundPage";
import NotFoundPageLoggedIn from "./components/Auth/NotFoundPageLoggedIn";

import PaymentPage from "./components/Auth/PaymentPage";
import ForgotPassword from "./components/Auth/ForgotPassword";
import SubUserPassword from "./components/Auth/SubUserPassword";
import updateOne from "./components/common/api/updateOne";
import uploadImage from "./components/common/api/uploadImage";
import ThemeDialogContent from "./components/layout/ThemeDialogContent";
import SettingsDialogContent from "./components/layout/SettingsDialogContent";
import UserSettings from "./components/Users/UserSettings";
import OnBoardModal from "./components/GeneralModals/OnBoardModal";
import UniversalAddModal from "./components/GeneralModals/UniversalAddModal";

import Dashboard from "./components/Dashboard";
// Account Settings import
import AccountSettings from "./components/Users/AccountSettings";
import AccountBilling from "./components/Users/AccountBilling";

// Gateway import
import AddGateway from "./components/Gateways/AddGateway";
import AllGateways from "./components/Gateways/AllGateways";
import SingleGateway from "./components/Gateways/SingleGateway";
// Device import
import AllDevices from "./components/Devices/AllDevices";
import SingleDevice from "./components/Devices/SingleDevice";
// Alerts import
import AddAlert from "./components/Alerts/AddAlert";
import AllAlerts from "./components/Alerts/AllAlerts";
import ManageAlert from "./components/Alerts/ManageAlert";
//  Users import
import AddSubUser from "./components/Users/AddSubUser";
import AllSubUsers from "./components/Users/AllSubUsers";
import SingleSubUser from "./components/Users/SingleSubUser";
//  Locations import
import AllLocations from "./components/Locations/AllLocations";
import SingleLocation from "./components/Locations/SingleLocation";
// Reports Import
import ReportView from "./components/Reports/ReportsView";
import ReportSelectedView from "./components/Reports/ReportsSelectedView";
import { RepeatOneSharp, FormatListNumberedRounded } from "@material-ui/icons";
import EmailIcon from "@material-ui/icons/Email";
import LocationFormModal from "./components/Locations/LocationFormModal";
import GatewayFormModal from "./components/Gateways/GatewayFormModal";
import DeviceFormModal from "./components/Devices/DeviceFormModal";
import SubUserFormModal from "./components/Users/SubUserFormModal";
import AlertsFormModal from "./components/Alerts/AlertsFormModal";

// Admin imports
import AdminDashboard from "./components/Admin/Dashboard";
import AllResellers from "./components/Admin/AllResellers";
import SingleReseller from "./components/Admin/SingleReseller";
// Resellers imports
import ResellerDashboard from "./components/Resellers/Dashboard"
import AllCustomers from "./components/Resellers/AllCustomers"
import SingleCustomer from "./components/Resellers/SingleCustomer"
import ResellerSignup from './components/Resellers/ResellerSignup';
import Transactions from './components/Resellers/Transactions';

//  List Items Import
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AdminDashboardIcon from "./img/icons/AdminDashboardIcon";

// Img imports
import appMobileIcon from "./img/app_icon_rounded.png";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";

const drawerWidth = 240;

const Content = (props) => {
  const {
    appState,
    setAppState,
    logoutState,
    alertCount,
    openSettingsModal,
    setOpenSettingsModal,
    openOnboardModal,
    setOpenOnboardModal,
    setAlertCount,
    featureCount,
    setFeatureCount,
    snack,
  } = useContext(AppContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setDrawer(true);
    setOpen(!open);
  };
  // boolean to handle breakpoint
  const isDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isDownXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [drawerState, setDrawerState] = React.useState(true);
  const {
    primaryColor,
    handlePrimaryColorChange,
    secondaryColor,
    handleSecondaryColorChange,
    backgroundColor,
    handleBackgroundColorChange,
    darkMode,
    setDarkMode,
    handleDarkModeChange,
  } = props;

  const [platformAlias, setPlatformAlias] = useState("");
  const [platformImageLocation, setPlatformImageLocation] = useState("");
  const [faviconImageLocation, setFaviconImageLocation] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);

  const [themeLoaded, setThemeLoaded] = useState(false);

  const [timeFormat, setTimeFormat] = useState("12hr");
  const [measurement, setMeasurement] = useState("metric");
  const [presetThemeChoice, setPresetThemeChoice] = useState("0");

  const [openUserMenu, setOpenUserMenu] = useState(false);
  const anchorRef = useRef(null);

  const log = window.log("Content");

  const setStateFromStorage = async () => {
    const storedState = await localforage.getItem("appState");
    if (storedState !== null) {
      setAppState(storedState);
    } else {
      setAppState({
        ...storedState,
        auth: {
          loggedIn: false,
        },
      });
    }
  };

  const returnThemeToDefault = () => {
    setPlatformAlias("");
    setPlatformImageLocation("");
    setFaviconImageLocation("");
    setPresetThemeChoice("0");
    setTimeFormat("12hr");
    setMeasurement("metric");
    handlePrimaryColorChange({
      hex: "#08b3ff",
    });
    handleSecondaryColorChange({
      hex: "#03a9f4",
    });
    handleBackgroundColorChange({
      hex: "#F4F5F5",
    });
    setDarkMode(false);
  };

  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  log("isSmallDevice: " + isSmallDevice);
  const [bottomDrawerState, setBottomDrawerstate] = React.useState(false);

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      setBottomDrawerstate(
        /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)
      );
    }
  }, [isSmallDevice]);

  const fetchTheme = async () => {
    try {
      // log("inside of fetchTheme " + appState.auth.loggedIn);
      if (appState.auth.loggedIn) {
        log("About to run a fetch");
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/company/get",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: appState.auth.token,
            },
          }
        );
        // log(process.env.REACT_APP_API_URL);
        log("Looking at company login reponse ", response);
        // window.response = response;
        // window.jsonTest = await response.json();
        // log("parsed json")

        const json = await response.json();
        // log(json);

        if (json.success) {
          if (!json.data.company[0].is_enabled) {
            setAppState({
              auth: {
                loggedIn: false,
              },
            });
            snack("Account Is Disabled", "error");

            navigate("/");
          } else {
            if (json.success) {
              const themePreferences = json.data.company[0].theme_preferences;

              if (themePreferences) {
                if (themePreferences.presetThemeChoice) {
                  setPresetThemeChoice(themePreferences.presetThemeChoice);
                }
                if (themePreferences.timeFormat) {
                  setTimeFormat(themePreferences.timeFormat);
                }
                if (themePreferences.measurement) {
                  setMeasurement(themePreferences.measurement);
                }
                if (themePreferences.platformAlias) {
                  setPlatformAlias(themePreferences.platformAlias);
                }
                if (themePreferences.platformImageLocation) {
                  setPlatformImageLocation(
                    themePreferences.platformImageLocation
                  );
                }
                if (themePreferences.faviconImageLocation) {
                  setFaviconImageLocation(
                    themePreferences.faviconImageLocation
                  );
                }
                if (themePreferences.primaryColor) {
                  handlePrimaryColorChange({
                    hex: themePreferences.primaryColor,
                  });
                }
                if (themePreferences.secondaryColor) {
                  handleSecondaryColorChange({
                    hex: themePreferences.secondaryColor,
                  });
                }
                if (themePreferences.backgroundColor) {
                  handleBackgroundColorChange({
                    hex: themePreferences.backgroundColor,
                  });
                }
                if (themePreferences.darkMode) {
                  setDarkMode(themePreferences.darkMode);
                }
              } else {
                returnThemeToDefault();
              }
            }
            setThemeLoaded(true);
          }
        } else {
          log("Json failure");
          if (json.errors.length) {
            for (const error of json.errors) {
              if (error.type === "token") {
                snack("Login Failure", "error");
                setAppState({
                  auth: {
                    loggedIn: false,
                  },
                });
                navigate("/");
                break;
              }
            }
          }
        }
      }
    } catch (err) {
      log("------: fetchTheme -> err", err);
      snack("Network Error", "error");
    }
  };
  let windowSizeSmall = useMediaQuery(theme.breakpoints.down("sm"));
  let windowSizeXSmall = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    setStateFromStorage();
  }, []);

  useEffect(() => {
    if (logoutState) logout();
  }, [logoutState]);

  useEffect(() => {
    let isCancelled = false;

    if (appState.auth.loggedIn !== false) {
      if (!isCancelled) {
        fetchTheme();
      }
    }

    // appState.auth.loggedIn ? setDrawer(true) : setDrawer(false);
    if (appState.auth.loggedIn && windowSizeSmall === true) {
      setDrawer(false);
    }
    if (appState.auth.loggedIn && windowSizeSmall === false) {
      setDrawer(false);
    }
    // log("UE 1");
    return () => {
      isCancelled = true;
    };
  }, [appState.auth.loggedIn]);

  useEffect(() => {
    if (window.location.pathname.includes("android_asset")) {
      navigate(`/`);
    }
    if (window.location.pathname === "/" && selectedPage !== 0) {
      setSelectedPage(0);
    } else if (
      window.location.pathname.includes("customer") &&
      selectedPage !== 1
    ) {
      setSelectedPage(1);
    } else if (
      window.location.pathname.includes("user") &&
      selectedPage !== 3
    ) {
      setSelectedPage(3);
    }
    // log("UE 2");
  }, [selectedPage, window.location.pathname]);

  const [drawer, setDrawer] = useState(false);

  const routeResultNonAuth = useRoutes({
    // "/signup": () => <Signup />,
    "/reseller_signup": () => <ResellerSignup />,
    "/reset": () => <ForgotPassword />,
    "/setpassword": () => <UserInvitation />,
    //root
    "/": () => <Login />,
    // "/android_asset/*": () => <Login />,
    // "*/www*": () => <Login />,
    "/*": () => <Login />,
  });
  const routeResultAuth = useRoutes({
    // User configuration
    "/current/user/configuration": () => (
      <AccountSettings
        platformImageLocation={platformImageLocation}
        setPlatformImageLocation={setPlatformImageLocation}
        faviconImageLocation={faviconImageLocation}
        setFaviconImageLocation={setFaviconImageLocation}
      />
    ),
    "/user/add": () => <AddSubUser />,
    "/user": () => <AllSubUsers />,
    "/user/:id": ({ id }) => <SingleSubUser id={id} />,
    // resellers: Allow access to users with permissiuon level = 50
    "/customer": () => <AllCustomers />,
    "/customer/:id": ({ id }) => <SingleCustomer id={id} />,
    "/transactions": () => <Transactions />,
    // root
    "/": () => <ResellerDashboard />,
    "/*": () => <NotFoundPageLoggedIn />,
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      top: 48,
    },
    appBar: {
      width: `calc(100% - 65px)`,
      marginLeft: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },

      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        width: `calc(100%)`,

      },
    },
    appBarShift: {
      marginLeft: drawerWidth,

      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },

    accountIcon: {
      marginLeft: "auto",
      marginRight: 5,
      color: theme.palette.primary.contrastText,
    },
    avatarMenuButton: {
      borderRadius: "25px",
    },
    avatarMenuButtonText: {
      borderRadius: "25px",
    },
    avatarMenuImage: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      border: `1px solid ${theme.palette.text.primary}`,
    },
    button: {
      color: theme.palette.secondary.main,
    },
    colorThemeIcon: {
      marginBottom: ".2rem",
    },
    dialogPaper: {
      minHeight: "90vh",
      maxHeight: "90vh",
    },
    "@global": {
      ".MuiDrawer-paper": {
        // width: drawerWidth,
        backgroundColor: darkMode ? theme.palette.background.default : "#FFF",
      },
      ".MuiDrawer-paperAnchorLeft": {
        width: drawerWidth,
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    drawerOpen: {
      width: appState.auth.loggedIn ? drawerWidth : 0,
      // transition: theme.transitions.create("width", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },
    drawerClose: {
      // transition: theme.transitions.create("width", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      overflowX: "hidden",
      width: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up("sm")]: {
        width: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },
      [theme.breakpoints.down("sm")]: {
        width: 0,
      },
    },
    content: {
      backgroundColor:
        theme.palette.type === "light"
          ? "#F4F5F5"
          : theme.palette.background.default,
      flexGrow: 1,
      padding: appState.auth.loggedIn ? theme.spacing(3) : theme.spacing(0),
      // transition: theme.transitions.create("margin", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      marginLeft: appState.auth.loggedIn ? theme.spacing(7) + 1 : 0,
      [theme.breakpoints.up("sm")]: {
        marginLeft: appState.auth.loggedIn ? theme.spacing(8) + 1 : 0,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        padding: appState.auth.loggedIn ? theme.spacing(1) : theme.spacing(0),
      },
    },
    contentShift: {
      // transition: theme.transitions.create("margin", {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
      marginLeft: appState.auth.loggedIn ? drawerWidth : 0,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    hide: {
      display: "none",
    },
    text: {
      color: darkMode ? theme.palette.text.primary : "#FFF",
      fontSize: 14,
    },
    labelContainer: {
      width: "auto",
      padding: 0,
    },
    iconLabelWrapper: {
      flexDirection: "row",
      justifyContent: "left",
      marginLeft: 12,
    },
    labelIconTab: {
      minHeight: 55,
      paddingLeft: 9,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    navIcon: {
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    tabText: {
      marginLeft: 20,
      marginBottom: 12,
    },
    menuItem: {
      paddingLeft: 5,
    },
    navbarMenu: {
      textDecoration: "none",
      color: theme.palette.primary.contrastText,
    },

    navTab: {
      ".labelIcon": {
        height: 55,
      },
      "&:hover": {
        backgroundColor: "#F4F5F5",
      },
    },
    removeImageButton: {
      marginLeft: "1rem",
    },
    themeSaveButton: {
      marginRight: "auto",
      color: theme.palette.secondary.main,
    },
    wrapperDiv: {
      [theme.breakpoints.down("xs")]: { 
        overflow: "auto",
       },
       paddingTop: appState.auth.loggedIn && "55px",

    },
    listItemText: {
      color: "#747474",
      fontSize: 14,
      fontFamily: "Open Sans",
      fontWeight: 500,
      lineHeight: 1.5,
    },
    listItemIcon: {
      paddingLeft: 6,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    bottomSheetButton: {
      width: 100,
    },
  }));

  const actions = [
    { icon: <GatewayIcon />, name: "Add Gateway", href: "/gateway/add" },
    { icon: <SensorIcon />, name: "Add Sensor", href: "/device/add" },
    { icon: <AlertIcon />, name: "Add Alert", href: "/alert/add" },
    { icon: <UserIcon />, name: "Add User", href: "/user/add" },
    { icon: <LocationIcon />, name: "Add Location", href: "/location/add" },
  ];

  const drawerItems = [
    { icon: <DashboardIcon />, name: "Dashboard", href: "/" },
    { icon: <UserIcon />, name: "Customers", href: "/customer" },
    { icon: <MonetizationOnOutlinedIcon />, name: "Commission Reports", href: "/transactions" },
    { icon: <AdminDashboardIcon />, name: "Users", href: "/user" },
  ];

  const classes = useStyles();
  const closeBottomSheet = () => {
    setBottomDrawerstate(false);
  };
  var fallbackToStore = function () {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      window.location.replace("https://apps.apple.com/app/id1463892769");
    }
    const android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
    if (android) {
      window.location.replace("market://details?id=com.dcs.misensors");
    }
  };
  var openApp = function () {
    window.location.replace("misensors://");
  };
  var triggerAppOpen = function () {
    openApp();
    setTimeout(fallbackToStore, 250);
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.fullList, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <img
              src={appMobileIcon}
              alt="Mobile app icon"
              width="24"
              height="24"
            />
          </ListItemIcon>
          <ListItemText primary="MISensors App" />
          <ListItemSecondaryAction>
            <Button
              onClick={triggerAppOpen}
              variant="outlined"
              color="primary"
              className={classes.bottomSheetButton}
            >
              Open
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemIcon>{<OpenInBrowserOutlinedIcon />}</ListItemIcon>
          <ListItemText primary="Browser" />
          <ListItemSecondaryAction>
            <Button
              variant="outlined"
              color="primary"
              className={classes.bottomSheetButton}
              onClick={closeBottomSheet}
            >
              Continue
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
  const [sdOpen, setSdOpen] = useState(false);
  const [value, setValue] = useState(0);

  const [openColorDialog, setOpenColorDialog] = useState(false);

  const [err, setErr] = useState({
    platformAlias: false,
  });

  const handleOpenSd = () => {
    setSdOpen(true);
  };

  const handleCloseSd = () => {
    setSdOpen(false);
  };

  const logout = async () => {
    // clear local storage token
    await setAppState({
      auth: { loggedIn: false },
    });
    // close drawer
    handleDrawerClose();
    handleCloseUserMenu();
    // set default theme settings
    returnThemeToDefault();
    setThemeLoaded(false);
    // redirect to root (will be log in page since token is now gone)
    navigate("/");
  };

  const drawerLink = (href, index) => {
    // change nav indicator
    setSelectedPage(index);
    // close drawer if mobile
    if (windowSizeSmall) {
      setDrawer(false);
    }
    // navigate to link
    navigate(href);
  };

  // Theme menu open and close functions
  const handleColorDialogOpen = () => {
    handleCloseUserMenu();
    setOpenSettingsModal(true);
  };

  const handleColorDialogClose = (e) => {
    if (typeof e === "boolean" && e) {
      //Pass
    } else {
      if (
        appState &&
        appState.auth &&
        appState.auth.userInfo &&
        appState.auth.userInfo.themePreferences
      ) {
        if (
          appState.auth.userInfo.themePreferences.primaryColor !== primaryColor
        ) {
          handlePrimaryColorChange({
            hex: appState.auth.userInfo.themePreferences.primaryColor,
          });
        }
        if (appState.auth.userInfo.themePreferences.timeFormat !== timeFormat) {
          setTimeFormat(appState.auth.userInfo.themePreferences.timeFormat);
        }
        if (
          appState.auth.userInfo.themePreferences.measurement !== measurement
        ) {
          setMeasurement({
            hex: appState.auth.userInfo.themePreferences.measurement,
          });
        }
        if (appState.auth.userInfo.themePreferences.darkMode !== darkMode) {
          setDarkMode(appState.auth.userInfo.themePreferences.darkMode);
        }
      }
    }
    setOpenSettingsModal(false);
  };

  const handleOnboardModalOpen = () => {
    setOpenOnboardModal(true);
  };

  const handleOnboardModalClose = () => {
    setOpenOnboardModal(false);
  };
  // Save theme settings
  const handleSaveThemePreferences = async () => {
    const changes = {
      primaryColor,
      darkMode,
      platformAlias,
      platformImageLocation,
      faviconImageLocation,
      timeFormat,
      measurement,
    };
    try {
      const data = {
        themePreferences: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        snack("Settings successfully updated", "success");
        let cp = { ...appState };
        cp.auth.userInfo.themePreferences = JSON.parse(
          json.data.themePreferences
        );
        log(cp);
        setAppState(cp);
        handleColorDialogClose(true);
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };
  const handleDrawerOpen = () => {
    setDrawer(true);
  };

  const handleDrawerClose = () => {
    setDrawer(false);
    setOpen(false);
  };

  const handleToggleUserMenu = () => {
    setOpenUserMenu((prevOpenUserMenu) => !prevOpenUserMenu);
  };

  const handleCloseUserMenu = (e) => {
    setOpenUserMenu(false);
  };

  function handleListKeyDown(e) {
    if (e.key === "Tab") {
      e.preventDefault();
      setOpenUserMenu(false);
    }
  }

  const handleNavigateToProfile = () => {
    navigate(`/current/user/configuration`);
    handleCloseUserMenu();
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={2}>{children}</Box>}
      </Typography>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  if (themeLoaded || appState.auth.loggedIn === false) {
    return (
      <div className={classes.wrapperDiv}>
        <AppBar
          className={clsx(classes.appBar, {
            [classes.appBarShift]: drawer,
          })}
          // position={!isDownXS ? "sticky" : 'fixed'}
          style={{
            display: appState.auth.loggedIn === false ? "none" : "",
            transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            // width: isDownSM ? "100%" : "calc(100% - 64px)",
          }}
        >
          <Toolbar style={{ minHeight: 55 }} variant="dense">
            {!drawer ? (
              <IconButton
                className={clsx(classes.menuButton, drawer)}
                disabled={!appState.auth.loggedIn}
                onClick={() => setDrawer(true)}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <IconButton
                className={clsx(classes.menuButton, drawer)}
                disabled={!appState.auth.loggedIn}
                onClick={handleDrawerClose}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <ChevronLeftIcon />
              </IconButton>
            )}

            <A className={classes.navbarMenu} href="/">
              <Typography variant="subtitle2" className={classes.text}>
                {platformAlias ? `${platformAlias}` : "MiSensors"}
              </Typography>
            </A>
            <div className={classes.accountIcon}>
              {appState.auth.loggedIn && (
                <div style={{ padding: 0 }}>
                  {appState.auth.userInfo.profile &&
                  appState.auth.userInfo.profile.profileImageLocation ? (
                    <Avatar
                      className={classes.avatarMenuImage}
                      src={appState.auth.userInfo.profile.profileImageLocation}
                    />
                  ) : (
                    <AccountCircleIcon style={{ fontSize: "2rem" }} />
                  )}
                </div>
              )}
            </div>
            {appState.auth.loggedIn && (
              <Typography
                style={{
                  fontSize: 14,
                  display: windowSizeXSmall ? "none" : "",
                  marginRight: 15,
                }}
                variant="subtitle1"
                className={classes.text}
              >
                {appState.auth.userInfo.fname} {appState.auth.userInfo.lname}
              </Typography>
            )}
            {appState.auth.loggedIn &&
            featureCount > 0 &&
            appState.auth.userInfo.perm !== 1 ? (
              <div
                style={{
                  backgroundColor: "#ffffff",
                  width: "1px",
                  height: "1.75rem",
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
            ) : null}
            {appState.auth.loggedIn && (
              <div>
                <IconButton
                  onClick={handleToggleUserMenu}
                  aria-controls={openUserMenu ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  ref={anchorRef}
                >
                  <SettingsIcon style={{ color: "white" }} />
                </IconButton>
                <Popper
                  open={openUserMenu}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  placement="top"
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseUserMenu}>
                          <MenuList
                            autoFocusItem={openUserMenu}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={handleNavigateToProfile}
                            >
                              <ListItemIcon>
                                <IconButton>
                                  <AccountBoxIcon />
                                </IconButton>
                              </ListItemIcon>
                              <ListItemText>Profile</ListItemText>
                            </MenuItem>
                            {appState.auth.loggedIn &&
                              appState.auth.userInfo.perm !== 1 && (
                                <MenuItem
                                  className={classes.menuItem}
                                  onClick={handleColorDialogOpen}
                                >
                                  <ListItemIcon>
                                    <IconButton>
                                      <SettingsIcon />
                                    </IconButton>
                                  </ListItemIcon>
                                  <ListItemText>Settings</ListItemText>
                                </MenuItem>
                              )}
                            <MenuItem
                              className={classes.menuItem}
                              onClick={logout}
                            >
                              <ListItemIcon>
                                <IconButton>
                                  <ExitToAppIcon />
                                </IconButton>
                              </ListItemIcon>
                              <ListItemText>Logout</ListItemText>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
          </Toolbar>
        </AppBar>
        {appState.auth.loggedIn && (
          <>
            <SubUserFormModal />
            <UniversalAddModal />
          </>
        )}
        {/* <OnBoardModal
          openState={openOnboardModal}
          handleClose={handleOnboardModalClose}
          featureCount={featureCount}
        /> */}
        <UserSettings
          openState={openSettingsModal}
          handleClose={handleColorDialogClose}
          handleSave={handleSaveThemePreferences}
          appState={appState}
          err={err}
          classes={classes}
          handleColorDialogClose={handleColorDialogClose}
          timeFormat={timeFormat}
          setTimeFormat={setTimeFormat}
          measurement={measurement}
          setMeasurement={setMeasurement}
          // presetThemeChoice={presetThemeChoice}
          // setPresetThemeChoice={setPresetThemeChoice}
          primaryColor={primaryColor}
          handlePrimaryColorChange={handlePrimaryColorChange}
          // secondaryColor={secondaryColor}
          // handleSecondaryColorChange={handleSecondaryColorChange}
          // backgroundColor={backgroundColor}
          // handleBackgroundColorChange={handleBackgroundColorChange}
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          handleDarkModeChange={handleDarkModeChange}
          // platformAlias={platformAlias}
          // setPlatformAlias={setPlatformAlias}
          // platformImageUploadHandler={platformImageUploadHandler}
          // faviconImageUploadHandler={faviconImageUploadHandler}
          // platformImageLocation={platformImageLocation}
          // setPlatformImageLocation={setPlatformImageLocation}
          // faviconImageLocation={faviconImageLocation}
          // setFaviconImageLocation={setFaviconImageLocation}
          handleSaveThemePreferences={handleSaveThemePreferences}
        />

        {/* <Dialog
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth={"sm"}
          open={openColorDialog}
          onClose={handleColorDialogClose}
          aria-labelledby="max-width-dialog-title"
        > */}
        {/* <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              aria-label="settings tabs"
            >
              <Tab label="Settings" {...a11yProps(0)} />
              <Tab label="Theme" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          {value === 0 && (
            <SettingsDialogContent
              classes={classes}
              handleColorDialogClose={handleColorDialogClose}
              timeFormat={timeFormat}
              setTimeFormat={setTimeFormat}
              measurement={measurement}
              setMeasurement={setMeasurement}
              handleSaveThemePreferences={handleSaveThemePreferences}
            />
          )}

          {value === 1 && (
            <ThemeDialogContent
              appState={appState}
              err={err}
              classes={classes}
              handleColorDialogClose={handleColorDialogClose}
              presetThemeChoice={presetThemeChoice}
              setPresetThemeChoice={setPresetThemeChoice}
              primaryColor={primaryColor}
              handlePrimaryColorChange={handlePrimaryColorChange}
              secondaryColor={secondaryColor}
              handleSecondaryColorChange={handleSecondaryColorChange}
              backgroundColor={backgroundColor}
              handleBackgroundColorChange={handleBackgroundColorChange}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
              handleDarkModeChange={handleDarkModeChange}
              platformAlias={platformAlias}
              setPlatformAlias={setPlatformAlias}
              platformImageUploadHandler={platformImageUploadHandler}
              faviconImageUploadHandler={faviconImageUploadHandler}
              platformImageLocation={platformImageLocation}
              setPlatformImageLocation={setPlatformImageLocation}
              faviconImageLocation={faviconImageLocation}
              setFaviconImageLocation={setFaviconImageLocation}
              handleSaveThemePreferences={handleSaveThemePreferences}
            />
          )} */}
        {/* </Dialog> */}

        <SwipeableDrawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawer,
            [classes.drawerClose]: !drawer,
          })}
          classes={{
            paper: isDownSM
              ? ""
              : clsx({
                  [classes.drawerOpen]: drawer,
                  [classes.drawerClose]: !drawer,
                }),
          }}
          variant={windowSizeSmall ? "temporary" : "permanent"}
          anchor="left"
          open={drawer}
          onOpen={() => {}}
          onClose={() => setDrawer(!drawer)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <img
            style={{
              marginLeft: drawer ? "auto" : 5,
              marginRight: "auto",
              marginTop: drawer ? 10 : 10,
              width: drawer ? "auto" : "auto",
              height: drawer ? 35 : 35,
              padding: drawer ? "6px 20px 5px 25px" : "6px 104px 5px 13px",
            }}
            src={
              drawer
                ? appState.auth.userInfo &&
                  appState.auth.userInfo.themePreferences &&
                  appState.auth.userInfo.themePreferences.platformImageLocation
                  ? appState.auth.userInfo.themePreferences
                      .platformImageLocation
                  : theme.palette.type === "light"
                  ? "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg"
                  : "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg"
                : appState.auth.userInfo &&
                  appState.auth.userInfo.themePreferences &&
                  appState.auth.userInfo.themePreferences.faviconImageLocation
                ? appState.auth.userInfo.themePreferences.faviconImageLocation
                : theme.palette.type === "light"
                ? "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon.svg"
                : "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorLogo_Icon+-+DarkMode.svg"
            }
            alt="logo"
          />

          <Divider
            style={{
              marginTop: "10px",
              marginBottom: "5px",
              marginLeft: drawer && 24,
              marginRight: drawer && 24,
              backgroundColor: "#CCC",
              height: 1,
            }}
            variant="middle"
          />
          <Tabs
            value={selectedPage ? selectedPage : 0}
            orientation="vertical"
            aria-label="nav tabs"
            indicatorColor="primary"
            TabIndicatorProps={{ style: { left: 0, width: 5, height: 55 } }}
            classes={{
              indicator: classes.indicator,
            }}
          >
            {drawerItems.map((item, index) => (
              <Tab
                key={index}
                className={classes.navTab}
                onClick={() => drawerLink(item.href, index)}
                style={{
                  backgroundColor:
                    selectedPage === index && !darkMode
                      ? "#F4F5F5"
                      : selectedPage === index && darkMode && "#424242",
                }}
                classes={{
                  wrapper: classes.iconLabelWrapper,
                  // labelContainer: classes.labelContainer,
                  labelIcon: classes.labelIconTab,
                }}
                icon={
                  <Typography
                    className={classes.navIcon}
                    style={{
                      color:
                        selectedPage === index
                          ? theme.palette.primary.main
                          : "#CCC",
                    }}
                  >
                    {item.icon}
                  </Typography>
                }
                label={
                  <Typography
                    style={{
                      color:
                        selectedPage === index
                          ? theme.palette.primary.main
                          : "",
                      textTransform: "none",
                    }}
                    className={classes.tabText}
                  >
                    {item.name}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </SwipeableDrawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawer,
          })}
        >
          {appState.auth.loggedIn ? routeResultAuth : routeResultNonAuth}
        </main>
        <div>
          <React.Fragment>
            <Drawer
              anchor={"bottom"}
              // open={bottomDrawerState}
              open={false}
              onClose={closeBottomSheet}
            >
              {list("bottom")}
            </Drawer>
          </React.Fragment>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const App = () => {
  const breakpoints = createBreakpoints({});
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // Nav
  const [primaryColor, setPrimaryColor] = useState("#08b3ff");
  // Buttons
  const [secondaryColor, setSecondaryColor] = useState("#03a9f4");
  // Background
  const [backgroundColor, setBackgroundColor] = useState("#F4F5F5");

  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  const log = window.log("App");

  useEffect(() => {
    // alert(`test ${navigator.userAgent}`)
    log("HELOOOOOOOO");
    log(navigator.userAgent);
    if (
      /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)
    ) {
      // window.location.replace("misensors://")
    }
  });

  const handlePrimaryColorChange = (color) => {
    setPrimaryColor(color.hex);
  };

  const handleSecondaryColorChange = (color) => {
    setSecondaryColor(color.hex);
  };

  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex);
  };

  const handleDarkModeChange = () => {
    setDarkMode(!darkMode);
  };
  log(darkMode);

  const theme = createMuiTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          // "*": {
          //   "scrollbar-width": "thin",
          // },
          "*::-webkit-scrollbar": {
            width: "4px",
            // height: "4px",
          },
          "*::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#CCC",
            // outline: "1px solid slategrey",
          },
        },
      },
      MuiStepIcon: {
        root: {
          "&$completed": {
            color: "#81c784",
          },
          "&$active": {
            color: primaryColor,
          },
        },
        active: {},
        completed: {},
        text: { fill: "#fff" },
      },
      MuiButton: {
        containedPrimary: {
          color: "#fff",
        },
      },
      MuiIconButton: {
        edgeStart: {
          color: "#fff !important",
        },
      },

      MuiStepLabel: {
        label: {
          "&$active": {
            color: primaryColor,
          },
          "&$completed": {
            color: "#81c784",
          },
        },
      },
      MuiCard: {
        root: {
          boxShadow: "0px 0px 6px #00000040 !important",
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: "0px 0px 6px #00000040 !important",
        },
        elevation1: {
          boxShadow: "0px 0px 6px #00000040 !important",
        },
        elevation2: {
          boxShadow: "0px 0px 6px #00000040 !important",
        },
        elevation3: {
          boxShadow: "0px 0px 6px #00000040 !important",
        },
        rounded: {
          borderRadius: "5px !important",
        },
      },
      MUIDataTableBodyCell: {
        // root: {
        //   overflow: "hidden",
        //   whiteSpace: "nowrap",
        //   textOverflow: "ellipsis",
        //   maxWidth: 200,
        // },
        // stackedCommon: {
        //   lineHeight: "0",
        // },
      },
      // MUIDataTableBody: {
      //   emptyTitle: {
      //     [breakpoints.down("sm")]: {
      //       transform: "translate(-52%,0%)",
      //     },
      //   },
      // },
      MUIDataTableHeadCell: {
        root: {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          color: darkMode ? "#FFF" : "#747474",
          fontWeight: 600,
        },
      },
      MUIDataTable: {
        responsiveBase: {
          borderTop: ".5px solid #CCC",
        },
        responsiveScrollMaxHeight: {
          borderTop: ".5px solid #CCC",
        },
      },
      MUIDataTablePagination: {
        navContainer: {
          flexWrap: "wrap",
        },
      },
      MUIDataTableJumpToPage: {
        caption: {
          [breakpoints.down("sm")]: {
            fontSize: 10,
          },
        },
        select: {
          [breakpoints.down("sm")]: {
            fontSize: 10,
          },
        },
      },
      MuiTablePagination: {
        caption: {
          [breakpoints.down("sm")]: {
            fontSize: 10,
          },
        },
        select: {
          [breakpoints.down("sm")]: {
            fontSize: 10,
          },
        },
      },
      MUIDataTableToolbar: {
        left: {
          fontSize: 14,
          color: darkMode ? "#FFF" : "#747474",
          fontWeight: 500,
          marginLeft: 12,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: 14,
          color: darkMode ? "#FFF" : "#747474",
          fontWeight: 500,
        },
      },
    },
    typography: {
      fontFamily: "Open Sans",
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      background: {
        default: darkMode ? "#303030" : "#F4F5F5",
      },
      text: {
        primary: darkMode ? "#FFF" : "#051F34",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
      },
      success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
      },
      iconColor: {
        outline: "#051f34",
        filled: "#08b3ff",
      },
      iconColorAlert: {
        outline: "#051f34",
        filled: "#ff6a6a",
      },
      iconColorDarkMode: {
        outline: "#CCCCCC",
        filled: "#08b3ff",
      },
      iconColorDarkModeAlert: {
        outline: "#CCCCCC",
        filled: "#ff6a6a",
      },
      type: darkMode ? "dark" : "light",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <AppProvider>
          <CssBaseline />
          <Content
            setDarkMode={setDarkMode}
            primaryColor={primaryColor}
            handlePrimaryColorChange={handlePrimaryColorChange}
            secondaryColor={secondaryColor}
            handleSecondaryColorChange={handleSecondaryColorChange}
            backgroundColor={backgroundColor}
            handleBackgroundColorChange={handleBackgroundColorChange}
            darkMode={darkMode}
            handleDarkModeChange={handleDarkModeChange}
          />
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
