import React from "react";
import { SvgIcon } from "@material-ui/core";

const TemperatureSVG = (props) => {
  const { outlinecolor, filledcolor } = props;
  return (
    <SvgIcon
      width="34.992"
      height="34.992"
      viewBox="0 0 34.992 34.992"
      {...props}
    >
      <path fill="none" d="M0,0H34.992V34.992H0Z" />
      <g transform="translate(8.748 2.916)">
        <path
          fill={outlinecolor}
          fillRule="evenodd"
          d="M821.851,149.015a8.462,8.462,0,0,1-4.516-15.607v-9.226a4.417,4.417,0,1,1,8.834,0v9.029a8.538,8.538,0,0,1,4.123,7.264A8.4,8.4,0,0,1,821.851,149.015Zm2.651-14.134-.2-10.209c0-1.178-1.375-2.06-2.552-2.06a2.373,2.373,0,0,0-2.455,2.06v10.307a6.245,6.245,0,0,0-3.533,5.594,6.184,6.184,0,1,0,8.736-5.692Z"
          transform="translate(-813.409 -119.764)"
        />
        <path
          fill={filledcolor}
          fillRule="evenodd"
          d="M820.648,142.72a4.608,4.608,0,0,1-4.613-4.615,4.679,4.679,0,0,1,3.043-4.319V125.15h2.944v8.637a4.537,4.537,0,0,1,3.24,4.319A4.609,4.609,0,0,1,820.648,142.72Z"
          transform="translate(-812.206 -117.297)"
        />
      </g>
    </SvgIcon>
  );
};

export default TemperatureSVG;
