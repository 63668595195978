import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: 210,
    height: 60,
    borderRadius: 5
  },
  button: {
    width: 200,
    height: 40,
    borderRadius: 5,
    marginTop: "1rem"
  }
}));

const GatewaySelectSkeleton = () => {
  const classes = useStyles();

  return (
    <div>
      <Skeleton className={classes.root} variant="rect" animation="wave" />
    </div>
  );
};

export default GatewaySelectSkeleton;
