import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Card, CardContent, Box, Divider, Tooltip } from "@material-ui/core";

// const triangleSize = 18

const useStyles = makeStyles((theme) => ({
  containerCard: {
    overflow: "visible",
    marginBottom: theme.spacing(4),
  },
  itemsContainer: {
    display: "flex",
    // overflow: "visible",

    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      overflowX: "scroll",
    },
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2, 3),
    cursor: "pointer",
  },
  unselectedIcon: {
    opacity: 0.5,
  },
  selectedIcon: {
    opacity: 1,
    position: "relative",
    // overflow: "visible",
    "&::before": {
      content: '""',
      // positon absolute and bottom -34px so it is outside the card in the bottom
      position: "absolute",
      bottom: "-34px",
      // width of the container in half minus half the width of the diamond so the triangle is centered
      left: `calc((98px / 2 ) - 18px )`,
      width: "20px",
      height: "20px",
      // skewed and rotated so it looks like a triangle
      transform: "rotate(45deg) skew(5deg, 5deg)",
      // color and shadow so it looks like the card of material ui
      backgroundColor: theme.palette.background.paper,
      boxShadow: "1.2px 1.2px 1.3px rgba(0,0,0,0.5)",
      [theme.breakpoints.down("sm")]: {
        bottom: "-10px",
      },
    },
  },
  idleIcon: {
    opacity: 1,
  },
  divider: {
    backgroundColor: theme.palette.type === "dark" && "#fff",
  },
}));

export default function IconSelector(props) {
  const {
    //className, // className of the root component
    items /** Array of Objects {

    }*/,
    selectedIndexCallback = () => {}, // Function(index) , function that recieves the index of the currently selected item
  } = props;

  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(null);

  const mapItems = [];

  const handleSelectedIndexChange = (index) => {
    if (currentIndex === index) {
      setCurrentIndex(null);
      selectedIndexCallback(null);
    } else {
      setCurrentIndex(index);
      selectedIndexCallback(index);
    }
  };

  const iconClass = (i) => {
    if (currentIndex === null) {
      return classes.idleIcon;
    } else {
      if (i === currentIndex) {
        return classes.selectedIcon;
      } else {
        return classes.unselectedIcon;
      }
    }
  };

  for (const i in items) {
    mapItems.push(
      <Tooltip title={items[i].label} placement="top" key={i}>
        <Box
          key={`icon__selector__alerts__${i}`}
          overflow="visible"
          className={`${classes.iconContainer} ${iconClass(i)}`}
          onClick={() => handleSelectedIndexChange(i)}
        >
          <Box>{items[i].icon}</Box>
          <Box>{items[i].text}</Box>
        </Box>
      </Tooltip>
    );

    if (i < items.length - 1) {
      mapItems.push(
        <Divider
          key={`${i}__alerts__divider`}
          orientation="vertical"
          flexItem
          className={classes.divider}
        />
      );
    }
  }

  return (
    <Card className={classes.containerCard}>
      <CardContent className={classes.itemsContainer}>{mapItems}</CardContent>
    </Card>
  );
}
