import React from "react";
import { SvgIcon } from "@material-ui/core";

const SensorIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47.754"
        height="47.754"
        viewBox="0 0 100 100"
      >
        <path fill="none" d="M0,0H47.754V47.754H0Z" />
        <g transform="translate(4.228 4.228)">
          <path
            className="b"
            d="M290.937,658.061H278.972a5.408,5.408,0,0,1-3.856-1.6L266.653,648a5.418,5.418,0,0,1-1.6-3.854V632.181a5.416,5.416,0,0,1,1.6-3.856l8.462-8.461a5.414,5.414,0,0,1,3.856-1.6h11.965a5.417,5.417,0,0,1,3.856,1.6l8.461,8.461a5.416,5.416,0,0,1,1.6,3.856v11.967a5.416,5.416,0,0,1-1.6,3.856l-8.461,8.46A5.41,5.41,0,0,1,290.937,658.061Zm-11.965-36.239a1.882,1.882,0,0,0-1.341.555l-8.462,8.463a1.887,1.887,0,0,0-.556,1.341v11.967a1.884,1.884,0,0,0,.556,1.34l8.461,8.463a1.889,1.889,0,0,0,1.343.554h11.965a1.883,1.883,0,0,0,1.341-.554l8.463-8.463a1.882,1.882,0,0,0,.554-1.34V632.181a1.883,1.883,0,0,0-.554-1.341l-8.463-8.463a1.882,1.882,0,0,0-1.341-.555Z"
            transform="translate(-265.056 -618.266)"
          />
          <g transform="translate(5.266 5.265)">
            <path
              className="b"
              d="M289.054,650.836h-12.12l-8.572-8.57V630.143l8.572-8.572h12.12l8.572,8.572v12.122Zm-11.874-.6h11.628l8.222-8.223V630.389l-8.222-8.222H277.18l-8.222,8.222v11.629Z"
              transform="translate(-268.362 -621.571)"
            />
          </g>
          <g transform="translate(9.951 11.559)">
            <rect
              className="b"
              width="0.496"
              height="10.22"
              transform="translate(13.345 3.638)"
            />
            <path
              className="b"
              d="M273.309,631.828l.222.445,10.09-5.058-.222-.445Z"
              transform="translate(-269.918 -624.555)"
            />
            <circle
              className="b"
              cx="0.593"
              cy="0.593"
              r="0.593"
              transform="translate(13 1.846)"
            />
            <path
              className="b"
              d="M272.651,630.8l.222-.445,9.345,4.684-.222.445Z"
              transform="translate(-270.308 -622.428)"
            />
            <circle
              className="b"
              cx="0.592"
              cy="0.592"
              r="0.592"
              transform="translate(13.001 12.995)"
            />
            <circle
              className="b"
              cx="0.593"
              cy="0.593"
              r="0.593"
              transform="translate(1.841 7.532)"
            />
            <path
              className="b"
              d="M273.614,628.948a2.439,2.439,0,1,0,2.434,2.439A2.437,2.437,0,0,0,273.614,628.948Zm0,3.527a1.087,1.087,0,1,1,1.085-1.088A1.086,1.086,0,0,1,273.614,632.475Z"
              transform="translate(-271.18 -623.263)"
            />
            <path
              className="b"
              d="M280.619,625.379a2.439,2.439,0,1,0,2.434,2.439A2.437,2.437,0,0,0,280.619,625.379Zm0,3.525a1.086,1.086,0,1,1,1.085-1.086A1.085,1.085,0,0,1,280.619,628.9Z"
              transform="translate(-267.026 -625.379)"
            />
            <path
              className="b"
              d="M280.619,632.378a2.439,2.439,0,1,0,2.434,2.439A2.436,2.436,0,0,0,280.619,632.378Zm0,3.525a1.087,1.087,0,1,1,1.088-1.087A1.087,1.087,0,0,1,280.619,635.9Z"
              transform="translate(-267.026 -621.228)"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default SensorIcon;
