import React from "react";
import {
  Card,
  Box,
  Typography,
  CardContent,
  makeStyles,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  cardPadding: {},
  title: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
    fontSize: 16,
    color: theme.palette.type === "light" ? grey[600] : "#FFF",
  },
  subTitle: {
    color: theme.palette.type === "light" ? grey[500] : "#FFF",
  },
}));

/**
 * IconButton
 * @param {Object} props
 * @props
 * icon: Componenet
 * title: String
 * subtitle: String
 * onClick: Function
 */
const IconButton = (props) => {
  const {
    icon, // Icon of the button
    title, // Title of the button
    subtitle, // Subtitle of the button
    onClick,
  } = props;

  const classes = useStyles();

  return (
    <Card onClick={onClick}>
      <CardContent style={{ cursor: onClick === undefined ? 'auto' : "pointer" }}>
        <Box display="flex" justifyContent="center" mt={2}>
          {icon}
        </Box>
        <Box justifyContent="center">
          <Typography align="center" variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box justifyContent="center" mb={1}>
          <Typography
            align="center"
            variant="subtitle1"
            className={classes.subTitle}
          >
            {subtitle}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default IconButton;
