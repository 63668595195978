import React from "react";
import { Box, IconButton } from "@material-ui/core/";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

import SnoozeIcon from "@material-ui/icons/Snooze";
import BuildIcon from "@material-ui/icons/Build";

/**
 *
 *
 * returns the bacjground color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int} theme, lightTone=700, darkTone=300
 */
const rowColor = ({ theme, index }) => {
  if (theme.palette.type === "light") {
    return index % 2 === 0 ? grey[100] : "white";
  } else {
    return index % 2 === 0 ? grey[900] : grey[800];
  }
};
/**
 * Device Container with alertStatus
 *
 * @ deprecated replaced with the components inside ActiveAlertsList
 *
 * @props
 * index: integer
 * alertName: String
 * locationName: String
 * deviceName: String
 * trailingComponent: Component
 */
function AlertContainer(props) {
  const theme = useTheme();
  const {
    index, // Index use for the color
    alertName, // Name of the alert
    locationName, // Name of the device
    deviceName, // Name of the device
    trailingComponent, // Leading component for the right of the container
    leadingIcon, // Component leadingIcon
    canSnooze, // Boolean show snooze button,
    noActiveAlerts, // If no list
  } = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundColor: rowColor({ theme, index }),
      paddingLeft: 35,
      paddingRight: 5,
      paddingTop: 10,
      paddingBottom: 10,
    },
    titleText: {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    descriptionText: {
      fontSize: 12,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    icon: {
      margin: theme.spacing(0, 0, 1.5, 0),
    },
    leadingIcon: {
      marginRight: theme.spacing(1),
    },
    trailingValue: {
      marginRight: theme.spacing(3),
    },
    snoozeIcon: {
      color: theme.palette.warning.main,
    },
  }));

  const classes = useStyles();

  return noActiveAlerts ? (
    <Box
      style={{
        backgroundColor: rowColor({ theme, index }),
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box>
          <Box className={classes.titleText}>{alertName}</Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="space-around">
          <Box className={classes.leadingIcon}>{leadingIcon}</Box>
          <Box>
            <Box className={classes.titleText}>{alertName}</Box>
            <Box className={classes.descriptionText}>
              {locationName} - {deviceName}
            </Box>
          </Box>
        </Box>
        <Box className={classes.trailingValue}>
          {!trailingComponent ? (
            <Box display="flex" alignItems="center">
              {canSnooze ? (
                <IconButton
                  className={classes.snoozeIcon}
                  aria-label="snooze_alarm"
                >
                  <SnoozeIcon />
                </IconButton>
              ) : null}
              {/* <IconButton color="primary" aria-label="configure_alarm">
                <BuildIcon />
              </IconButton> */}
            </Box>
          ) : (
            { trailingComponent }
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AlertContainer;
