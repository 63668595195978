import React, { useState, Fragment, useContext } from "react";
import { AppContext } from "./../../AppContext";

import { navigate } from "hookrouter";

// material ui imports
import {
  Box,
  IconButton,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

import grey from "@material-ui/core/colors/grey";

import MUIDataTable from "mui-datatables";

// components imports
import ResolveAlertModal from "./ResolveAlertModal";

// Icons Import
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import PrintIcon from "@material-ui/icons/Print";
// import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// import FilterListIcon from "@material-ui/icons/FilterList";

import SnoozeIcon from "@material-ui/icons/Snooze";
import BuildIcon from "@material-ui/icons/Build";

const useStyles = makeStyles((theme) => ({
  table: {
    margin: theme.spacing(4, 0),
    "& tr:nth-of-type(odd)": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor:
        theme.palette.type === "light" ? "white" : theme.palette.grey[800],
    },
  },
  descriptionText: {
    fontSize: 12,
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
    // marginTop: "25px",
  },
  icon: {
    margin: theme.spacing(0, 0, 1.5, 0),
  },
  leadingIcon: {
    marginRight: theme.spacing(1),
  },
  trailingValue: {
    marginRight: theme.spacing(3),
  },
  snoozeIcon: {
    color: theme.palette.warning.main,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  valueName: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
}));

/**
 * ActiveAlertsList for
 * the list in the dashboard
 *
 * @props
 * alerts: Array[Object]
 */
const ActiveAlertsList = (props) => {
  const log = window.log('ActiveAlertsList');
  const classes = useStyles();

  const {
    title, // String with the title of the List
    alerts, // Alerts array with the alerts objects
    device,
    icons,
    fetchAllAlertsPage,
    getAlertCount,
  } = props;

  const { appState } = useContext(AppContext);

  // const onClick = () => {
  //   navigate('/alert/all')
  // };

  const [openResolveAlert, setOpenResolveAlert] = useState(false);
  const [measurementUnit, setMeasurementUnit] = useState(" °C");
  const [measurementValue, setMeasurementValue] = useState();

  const [currentAlert, setCurrentAlert] = useState({
    info: {
      id: 0,
      alertName: "",
      locationName: "",
      deviceName: "",
      leadingIcon: <div></div>,
      minValue: 0,
      maxValue: 0,
      minRange: 0,
      maxRange: 0,
      rangeType: "-",
      lastSensorValue: 0,
      messageReceivedDate: "",
    },
    trailing: {
      canSnooze: false,
    },
    device: {
      deviceName: "",
      sensorId: "",
      location: "",
      deviceImageUrl: ""
    },
  });

  const updateAlertPage = () => {
    log("Running update alert");
    fetchAllAlertsPage();
  };

  const resolveAlertClose = () => {
    setOpenResolveAlert(false);
  };

  const convertTempForUserPref = (value) => {
    // let unitType = " °C";
    //Convert the Fahrenheit
    if (appState.auth.userInfo.themePreferences.measurement === "imperial") {
      value = value * (9 / 5) + 32;
      // unitType = " °F";
    }
    value = value.toFixed(2);
    value = parseInt(value);
    // value = value + unitType;
    return value;
  };

  const tableOptions = {
    // onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    onRowClick: (rowData, { dataIndex }) => {
      setOpenResolveAlert(true);
      //Only dealing with temperature right now
      if (alerts[dataIndex].info.measurementUnit === "°C") {
        setMeasurementUnit(" °C");
        setMeasurementValue(alerts[dataIndex].info.lastSensorValue);
        if (
          appState.auth.userInfo.themePreferences.measurement === "imperial"
        ) {
          setMeasurementUnit(" °F");
          setMeasurementValue(
            convertTempForUserPref(alerts[dataIndex].info.lastSensorValue)
          );
        }
      } else {
        setMeasurementValue(parseInt(alerts[dataIndex].info.lastSensorValue));
        setMeasurementUnit(alerts[dataIndex].info.measurementUnit);
      }
      setCurrentAlert(alerts[dataIndex]);
      log("Looking at alert ", alerts[dataIndex]);
    },
    selectableRows: "none",
    print: false,
    filter: false,
    download: false,
    jumpToPage: true,
    viewColumns: false,
    responsive: "standard",
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      currentRow.forEach((col) => {
        if (
          (col.alertName &&
            col.alertName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (col.deviceName &&
            col.deviceName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (col.locationName &&
            col.locationName.toLowerCase().includes(searchQuery.toLowerCase()))
        ) {
          isFound = true;
        }
      });

      return isFound;
    },
  };

  const columns = [
    {
      name: "info",
      label: "Active Alerts",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              style={{ cursor: "pointer", marginLeft: 20 }}
              display="flex"
              key={`alert_active_list_info__${tableMeta.rowIndex}`}
            >
              <Box className={classes.leadingIcon}>
                {icons[value.leadingIcon]}
                {/* {`${tableMeta.rowIndex}`} */}
              </Box>
              <Box>
                <Box className={classes.valueName}>{value.alertName}</Box>
                <Box className={classes.descriptionText}>
                  {value.locationName} - {value.deviceName}
                </Box>
              </Box>
            </Box>
          );
        },

        customHeadRender: (columnMeta) => (
          <th
            key={`alerts__manager__col__${columnMeta.index}`}
            style={{
              // borderBottom: "1px solid rgba(224, 224, 224, 1)",
              // backgroundColor: theme.palette.background.paper,
              display: "none",
            }}
          />
        ),
      },
    },
    {
      name: "trailing",
      label: " ",

      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              className={classes.trailingValue}
              display="flex"
              justifyContent="flex-end"
              key={`trailing__row__${tableMeta.rowIndex}`}
            >
              {!value.trailingComponent ? (
                <Box display="flex" alignItems="center">
                  {value.canSnooze ? (
                    <IconButton
                      className={classes.snoozeIcon}
                      aria-label="snooze_alarm"
                    >
                      <SnoozeIcon />
                    </IconButton>
                  ) : null}
                  <IconButton color="primary" aria-label="configure_alarm">
                    <BuildIcon />
                  </IconButton>
                </Box>
              ) : (
                value.trailingComponent
              )}
            </Box>
          );
        },
        customHeadRender: (columnMeta) => (
          <th
            key={`alerts__manager__col__${columnMeta.index}`}
            style={{
              // borderBottom: "1px solid rgba(224, 224, 224, 1)",
              // backgroundColor: theme.palette.background.paper,
              display: "none",
            }}
          />
        ),
      },
    },
  ];

  return (
    <Fragment>
      <MUIDataTable
        className={classes.table}
        options={tableOptions}
        data={alerts}
        columns={columns}
        title={
          <Grid container>
            <Typography className={classes.titleText}>{title}</Typography>
          </Grid>
        }
      />
      <ResolveAlertModal
        messageReceivedDate={currentAlert.info.messageReceivedDate}
        getAlertCount={getAlertCount}
        openState={openResolveAlert}
        alertIcon={icons[currentAlert.info.leadingIcon]}
        alertName={currentAlert.info.alertName}
        device={currentAlert.device}
        handleClose={resolveAlertClose}
        minValue={parseInt(currentAlert.info.minValue)}
        maxValue={parseInt(currentAlert.info.maxValue)}
        minRange={parseInt(currentAlert.info.minRange)}
        maxRange={parseInt(currentAlert.info.maxRange)}
        rangeType={currentAlert.info.rangeType}
        sensorValue={parseInt(measurementValue)}
        currentAlert={currentAlert}
        updateAlertPage={updateAlertPage}
        measurementUnit={measurementUnit}
        alertType={currentAlert.info.type}
      />
    </Fragment>
  );
};

export default ActiveAlertsList;
