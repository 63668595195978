import React, { useState, useContext } from "react";

import { navigate } from "hookrouter";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, TextField, Button } from "@material-ui/core";

import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    marginTop: 50,
  },
  centerGrid: {
    margin: " 0 auto",
  },
  textField: {
    margin: "5px 0",
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    width: 200,
  },
  formControl: {
    marginTop: 8,
    minWidth: 200,
    width: "80%",
  },
  select: {
    padding: theme.spacing(3, 2),
  },
}));

const SubUserPassword = () => {
  const { setLogoutState, snack } = useContext(AppContext);
  const classes = useStyles();
  const url = new URL(window.location);
  const token = url.searchParams.get("token");

  const [state, setState] = useState({
    pw1: "",
    pw2: "",
  });

  const [err, setErr] = useState({
    pw1: "",
    pw2: "",
  });

  const attempt = async () => {
    try {
      const emailSend = await fetch(
        process.env.REACT_APP_API_URL + "/user/add-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(state),
        }
      );

      const json = await emailSend.json();

      if (json.success) {
        snack("Your password has been updated", "success");
        setTimeout(() => navigate("/", true), 750);
      } else {
        json.errors.forEach((error) => {
          setErr({ [error.type]: true });
          snack(error.msg, "error");
          if (error.type === "token") {
            snack(error.msg, "error");
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  return (
    <Grid container>
      {
        //redirect to login page if no token param is set
        token ? (
          <Grid item className={classes.centerGrid} sm={6}>
            <Paper className={classes.root}>
              <Typography
                variant="h5"
                component="h5"
                style={{ textAlign: "center", marginBottom: 15 }}
              >
                Create New Sub User Account
              </Typography>

              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Enter Password"
                    value={state.pw1}
                    error={err.pw1}
                    type="password"
                    className={classes.textField}
                    onChange={(e) =>
                      setState({ ...state, pw1: e.currentTarget.value })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Re-Enter Password"
                    value={state.pw2}
                    error={err.pw2}
                    type="password"
                    className={classes.textField}
                    onChange={(e) =>
                      setState({ ...state, pw2: e.currentTarget.value })
                    }
                  />
                </Grid>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => attempt()}
                >
                  Add User
                </Button>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          navigate("/")
        )
      }
    </Grid>
  );
};

export default SubUserPassword;
