import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Box } from "@material-ui/core";

import logo from "../../img/MiSensorsLogo.svg";

import grey from "@material-ui/core/colors/grey";
/**
 *  103px is the height of the navbar and the padding of the container
 * , so it is fully centered in the screen with half of it 51.5
 */

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    height: "100%",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    height: "100vh",
    width: "100vw",
  },
  pageWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15%",
    height: "100%",
    width: "100%",
  },
  logo: {
    width: "230px",
  },
  subtitle: {
    color: theme.palette.type === "light" ? grey[600] : grey[400],
    width: "80%",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
}));

export default function LoggedInLayout(props) {
  const theme = useTheme();
  const { subtitle, children } = props;

  const classes = useStyles();

  return (
    <Box className={classes.pageWrapper}>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={8}
        className={classes.rightContainer}
      >
        <Box>
          <img
            className={classes.logo}
            src={
              theme.palette.type === "light"
                ? "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo.svg"
                : "https://misensors-images.s3.amazonaws.com/App+Image+Resources/MiSensorsLogo+-+DarkMode.svg"
            }
            alt="logo"
          />
        </Box>
        <Box display="flex" justifyContent="center" style={{ width: "100%" }}>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </Box>
        {children}
      </Grid>
    </Box>
  );
}
