import React, { useContext } from "react";
import { AppContext } from "./../../AppContext";
import { Grid, Avatar, Typography, Box, IconButton } from "@material-ui/core";
import CardActions from "../Generic/CardActions";
import GenericListCard from "../Generic/GenericListCard";
import { makeStyles } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import grey from "@material-ui/core/colors/grey";
import UserImage from "../../img/icons/single-user.svg";

/**
 * SingleUserCard
 * @param {Object} props
 * @props
 * id: Integer
 * name: String
 * avatar: String
 * email: String
 * companyName: String
 * phoneNumber: String
 * permissionLevel: String
 * handleEdit: Function
 * handleEditImage: Function
 */
function SingleUserCard(props) {
  const log = window.log('SingleUserCard');

  const { appState } = useContext(AppContext);

  const {
    id, // Id of the user,
    avatar, // Link to the image
    name, // Name of the user
    email, // Email of the user
    phoneNumber, // PhoneNumber of the user
    companyName, //Company of the user
    permissionLevel, // Permission level of the user
    handleEditImage, // Handle edit image function
    handleOpenEditUserModal, // Handle edit user information
    handleOpenDeleteUserModal,
    userSettingsPage, // If in logged in user's settings page
    disableDelete,
  } = props;

  const useStyles = makeStyles((theme) => ({
    rootCard: {
      paddingLeft: 20,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    avatarLarge: {
      width: "256px",
      height: "256px",
      border: "1px solid #CCC",
      backgroundColor: !avatar && "#F4F5F5",
    },
    avatarImage: {
      objectFit: !avatar && "none",
      // transform: defaultAvatar && gatewayCard && "translateY(-5px)",
    },
    title: {
      paddingTop: theme.spacing(1),
      fontWeight: "bold",
      fontSize: 16,
      color: theme.palette.type === "light" ? grey[600] : "#fff",
    },
    subTitle: {
      color: theme.palette.type === "light" ? grey[500] : "#fff",
    },
  }));

  const classes = useStyles();

  /**
   * Event to delete the user
   */
  const deleteUser = () => {
    log("Need to implement");
  };

  const perm = (level) => {
    switch (level) {
      case 50:
        return "Reseller";
      case 51:
        return "Reseller (DEMO)";
      default:
        return "error with permissions function";
    }
  };

  const determineEdit = () => {
    let canEdit = true;
    const perm = permissionLevel;

    if ((perm || perm === 0) && appState.auth.userInfo.id !== id) {
      const userPerm = appState.auth.userInfo.perm;

      if (userPerm === 1) canEdit = false;
      if (userPerm === 0 && perm === 99) canEdit = false;
      if (userPerm === 2 && (perm === 99 || perm === 0)) canEdit = false;
    }
    return canEdit;
  };

  return (
    <GenericListCard>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Box display="flex" justifyContent="center">
            <Avatar
              classes={{ img: classes.avatarImage }}
              alt="avatar"
              src={avatar ? avatar : UserImage}
              className={classes.avatarLarge}
            />
          </Box>
          {(determineEdit() || userSettingsPage) && (
            <Box
              display="flex"
              justifyContent="center"
              style={{
                marginTop: -25,
                backgroundColor: grey,
                transform: "translateY(10px)",
              }}
            >
              <IconButton
                style={{ color: "#ffffff", backgroundColor: grey[400] }}
                size="small"
                onClick={handleEditImage}
              >
                <Edit></Edit>
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid item container xs={12} sm={12} md={6}>
          <Grid
            item
            container
            direction="column"
            justify="center"
            xs={12}
            md={10}
          >
            <Typography className={classes.title} gutterBottom>
              {name}
            </Typography>
            <Typography className={classes.subTitle} gutterBottom>
              {email}
            </Typography>
            <Typography className={classes.subTitle} gutterBottom>
              {companyName}
            </Typography>
            <Typography className={classes.subTitle} gutterBottom>
              {phoneNumber}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={2} alignItems="stretch">
            {determineEdit() && (
              <CardActions
                editEvent={handleOpenEditUserModal}
                deleteEvent={handleOpenDeleteUserModal}
                disableDelete={disableDelete}
                alignItems="start"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </GenericListCard>
  );
}

export default SingleUserCard;
