import React, { useState, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import {
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ResponsiveDialog from "../Generic/GenericDialog";
import ImageButton from "../Generic/ImageButton";
import marker from "../../img/place-24px.svg";
import gateway from "../../img/router-24px.svg";
import LocationForm from "../common/LocationForm";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(3),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  imageButtonSize: {
    width: 150,
    [theme.breakpoints.down("sm")]: {
      width: 140,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 150,
      marginBottom: 10,
    },
  },
}));

/**
 * Location Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function LocationFormModal(props) {
  const log = window.log('LocationFormModal');

  const {
    appState,
    setAppState,
    setlogoutState,
    openLocationModal,
    setOpenLocationModal,
    setOpenGatewayModal,
    setFeatureCount,
    snack,
  } = useContext(AppContext);
  const { getLocations } = props;

  const classes = useStyles();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [street, setStreet] = useState("");
  const [streetError, setStreetError] = useState(false);

  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);

  const [state, setState] = useState("");
  const [stateError, setStateError] = useState(false);

  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);

  const [submitState, setSubmitState] = useState(false);

  const setDefaultObject = () => {
    setName("");
    setStreet("");
    setCity("");
    setState("");
    setZipcode("");
  };

  // Marks Add location as complete for onboard
  const handleLocationFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addLocation: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter((item) => item === false)
          .length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], "error");
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setlogoutState(true);
        });
      }
    } catch (err) {
      log(err);
    }
  };
  // Submit function to api
  const handleSubmitLocation = async () => {
    let foundError = false;
    if (street.length === 0) {
      setStreetError(true);
      foundError = true;
    }
    if (city.length === 0) {
      setCityError(true);
      foundError = true;
    }
    if (state.length === 0) {
      setStateError(true);
      foundError = true;
    }
    if (zipcode.length === 0) {
      setZipcodeError(true);
      foundError = true;
    }
    if (foundError) {
      snack("Please Fill Out All Fields", "error");
      return false;
    }
    if (state.length !== 2) {
      snack("Please Use Two Letter State Code", "error");
      return false;
    }

    try {
      let correctedStreet = street;
      if (street.includes("#")) {
        correctedStreet = correctedStreet.split("#").join("%23");
      }

      const response = await fetch(
        `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${process.env.REACT_APP_HERE_API_KEY}&searchtext=${correctedStreet}+${city}+${state}+${zipcode}`
      );
      const json = await response.json();

      if (json.Response.View.length === 0) {
        snack("Not a valid address", "error");

        return false;
      } else {
        log("added location", json);
        let lat =
          json.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        let lng =
          json.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        try {
          const data = {
            street,
            city,
            state,
            zipcode,
            name,
            primary: false,
            lat,
            lng,
          };

          // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

          // await delay(1500);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/location/add`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: appState.auth.token,
              },
              body: JSON.stringify(data),
            }
          );
          const json = await response.json();

          if (json.success) {
            setStreetError(false);
            setCityError(false);
            setStateError(false);
            setZipcodeError(false);
            getLocations && getLocations();
            setDefaultObject();
            handleLocationFeatureComplete();
            return true;
          } else {
            let errorObj = {};
            json.errors.forEach((error) => {
              if (error.type === "street") {
                setStreetError(true);
              }
              if (error.type === "city") {
                setCityError(true);
              }
              if (error.type === "state") {
                setStateError(true);
              }
              if (error.type === "zipcode") {
                setZipcodeError(true);
              }
              //add error to err object
              errorObj = { ...errorObj, [error.type]: true };
              //err alert
              snack(error.msg, "error");
              if (error.type === "token") setlogoutState(true);
            });
            return false;
          }
        } catch (err) {
          log(err);
          snack("Network Error", "error");
          return false;
        }
      }
    } catch (error) {
      log(error);
      snack("Network Error", "error");
      return false;
    }
  };

  const handleCheckName = async () => {
    log("Looking at name " + name);
    log("Looking at token " + appState.auth.token);
    //Check that name doesn't already exist in the database
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/location/checkLocationName?locationName=${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await request.json();
      if (!json.success) {
        setNameError(true);
        let errorObj = {};
        json.errors.forEach((error) => {
          //Add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") setlogoutState(true);
        });
      } else {
        setNameError(false);
        return json.success;
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  const handleRestart = async () => {
    handleClose();
    setTimeout(() => {
      setOpenLocationModal(true);
    }, 1000);
  };

  const handleClose = () => {
    setOpenLocationModal(false);
    setDefaultObject();
  };

  //Open the Gateway Form Modal
  const openGatewayModal = () => {
    setOpenLocationModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenGatewayModal(true);
    }, 500);
  };

  return (
    <div>
      <ResponsiveDialog
        openState={openLocationModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title="ADD A NEW LOCATION"
        stepsArray={[
          {
            label: "LOCATION NAME",
            nextFunction: handleCheckName,
            showProgress: false,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Give your location a name
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    Giving your location a name will you help you locate and
                    manage all of your gateways and devices.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    error={nameError}
                    value={name}
                    className={classes.textField}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Location Name"
                    label="Location Name"
                  />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "ADDRESS",
            nextFunction: handleSubmitLocation,
            validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Enter the address of your location
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    Enter the Street, City, State and Zipcode information for
                    your location below.
                  </Box>
                </Grid>
                <LocationForm
                  street={street}
                  setStreet={setStreet}
                  streetError={streetError}
                  city={city}
                  setCity={setCity}
                  cityError={cityError}
                  state={state}
                  setState={setState}
                  stateError={stateError}
                  zipcode={zipcode}
                  setZipcode={setZipcode}
                  zipcodeError={zipcodeError}
                  classes={classes}
                />
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Adding Location into Platform
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    MiSensors is currently configuring your location into the
                    platform. This should take less than a minute.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ),
          },
          {
            label: "COMPLETE", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Success! Your location has been added.
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    You may now add additional locations or start adding
                    gateways by selecting the options below.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid justify="space-around" container display="flex">
                    <Grid className={classes.imageButtonSize} item>
                      <ImageButton
                        onClick={() => openGatewayModal()}
                        image={gateway}
                        action="Continue"
                        text="ADD GATEWAY"
                      />
                    </Grid>
                    <Grid className={classes.imageButtonSize} item>
                      <ImageButton
                        onClick={() => handleRestart()}
                        image={marker}
                        action="ADD"
                        text="ANOTHER LOCATION"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
