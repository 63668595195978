import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  ClickAwayListener,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { ca } from "date-fns/esm/locale";
import grey from "@material-ui/core/colors/grey";
import ImageButton from "../Generic/ImageButton";
import DecoratedImageButton from "../Generic/DecoratedImageButton";

import sensor from "../../img/settings_remote-24px.svg";
import { AppContext } from "../../AppContext";

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    height: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  cardText: {
    textAlign: "center",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  cardValueText: {
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  cardContainer: {
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
    overflowX: "hidden",
    overflowY: "auto",
    height: `100%`,
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  // textField: {
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: "5px",
  // },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const ChangeSubscription = (props) => {
  const log = window.log('ChangeSubscription');

  const { setLogoutState, snack } = useContext(AppContext);

  const {
    openState,
    handleClose,
    currentSubscription,
    reload,
    token,
    listOfPlans,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const classes = useStyles();
  const [submitState, setSubmitState] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);

  const rgbColorToOpacity = (color, opacity = 0.3) => {
    return color.replace("rgb", "rgba").replace(")", `, ${opacity})`);
  };

  const handlePlanChange = async (plan) => {
    setSelectedPlan(plan);

    let proratedPlan = null;
    if (!currentSubscription.proratedPricePlans) return;
    for (const proratedPricePlan of currentSubscription.proratedPricePlans) {
      if (proratedPricePlan.id === plan.id) {
        proratedPlan = proratedPricePlan.proratedPriceInfo;
        break;
      }
    }
    if (!proratedPlan) return;
    setTotalPrice(proratedPlan.proratedPrice);
  };

  const handleSubmit = async () => {
    if (currentSubscription.numOfSensors > selectedPlan.sensors) {
      snack(
        "Please delete your sensors in order to downgrade from your current plan",
        "error"
      );
      return;
    }

    try {
      setSubmitState(true);
      //Submit subscription update request
      const updateSubscriptionResponse = await fetch(
        process.env.REACT_APP_API_URL + "/payment/upgrade-plan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            newPlanId: selectedPlan.id,
          }),
        }
      );

      const json = await updateSubscriptionResponse.json();
      if (json.success) {
        snack("Successfully updated your subscription", "success");
        setSelectedPlan({});
        reload();
        handleClose();
      } else {
        snack(json.errors[0], "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }
    } catch (error) {
      log(error);
      snack("Network Error", "error");
    }
    setSubmitState(false);
  };

  const renderTotalPrice = () => {
    let proratedPlan = null;
    if (!currentSubscription.proratedPricePlans) return;
    for (const plan of currentSubscription.proratedPricePlans) {
      if (plan.id === selectedPlan.id) {
        proratedPlan = plan.proratedPriceInfo;
        break;
      }
    }

    if (proratedPlan) {
      return (
        <>
          <Grid item xs={6}>
            <p>
              Estimated Prorated Amount (
              {new Date(proratedPlan.from).toLocaleDateString()} -{" "}
              {new Date(proratedPlan.to).toLocaleDateString()})
            </p>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <p style={{ fontWeight: "bold" }}>
              $
              {selectedPlan.price
                ? proratedPlan.proratedPrice.toFixed(2)
                : "0.00"}
            </p>
          </Grid>
        </>
      );
    }
  };

  const buttonsArray = [
    {
      onClick: () => {
        handlePlanChange(listOfPlans[0]);
      },
      id: listOfPlans[0].id,
      image: sensor,
      action: "Up to 6",
      text: "SENSORS",
      selected: selectedPlan.id === listOfPlans[0].id ? true : false,
    },
    {
      onClick: () => {
        handlePlanChange(listOfPlans[1]);
      },
      id: listOfPlans[1].id,
      image: sensor,
      action: "Up to 12",
      text: "SENSORS",
      selected: selectedPlan.id === listOfPlans[1].id ? true : false,
    },
    {
      onClick: () => {
        handlePlanChange(listOfPlans[2]);
      },
      id: listOfPlans[2].id,
      image: sensor,
      action: "Up to 25",
      text: "SENSORS",
      selected: selectedPlan.id === listOfPlans[2].id ? true : false,
    },
    {
      onClick: () => {
        handlePlanChange(listOfPlans[3]);
      },
      id: listOfPlans[3].id,
      image: sensor,
      action: "Up to 50",
      text: "SENSORS",
      selected: selectedPlan.id === listOfPlans[3].id ? true : false,
    },
  ];

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={openState}
      aria-labelledby="responsive-dialog-title"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Card className={classes.cardContainer}>
          <CardContent className={classes.formContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography className={classes.titleText} align="center">
                  EDIT ACCOUNT INFORMATION
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {}
                    : {
                        paddingLeft: theme.spacing(6),
                        paddingRight: theme.spacing(6),
                      }
                }
                className={classes.formContainer}
              >
                <Grid item xs={12}>
                  <p
                    className={classes.cardText}
                    style={{ fontWeight: "bold" }}
                  >
                    Buy More, Save More
                  </p>
                  <p className={classes.cardText}>
                    {currentSubscription &&
                    currentSubscription.planInfo &&
                    currentSubscription.planInfo.sensors
                      ? `Your current plan allows you to add up to ${currentSubscription.planInfo.sensors} sensors.`
                      : ""}{" "}
                    Select a plan below to upgrade your MiSensors account.
                  </p>
                </Grid>
                <Grid item xs={12} container justify="center">
                  {buttonsArray.map((button) => {
                    return (
                      <Grid
                        key={button.id}
                        item
                        xs={12}
                        sm={3}
                        container
                        justify="center"
                        alignItems="flex-end"
                      >
                        <DecoratedImageButton
                          onClick={button.onClick}
                          image={button.image}
                          action={button.action}
                          text={button.text}
                          title={
                            currentSubscription.planInfo &&
                            currentSubscription.planInfo.id === button.id
                              ? "Current Subscription"
                              : currentSubscription.pendingSubscription &&
                                currentSubscription.pendingSubscription
                                  .planId === button.id
                              ? `Pending Plan (${currentSubscription.nextBillingDate})`
                              : ""
                          }
                          titleColor={
                            currentSubscription.pendingSubscription &&
                            currentSubscription.pendingSubscription.planId ===
                              button.id
                              ? "#0B9EDE"
                              : "#59C754"
                          }
                          border={
                            (currentSubscription.planInfo &&
                              currentSubscription.planInfo.id === button.id) ||
                            (currentSubscription.pendingSubscription &&
                              currentSubscription.pendingSubscription.planId ===
                                button.id)
                          }
                          color={
                            button.selected
                              ? rgbColorToOpacity(
                                  theme.palette.primary.light,
                                  0.3
                                )
                              : theme.palette.background.paper
                          }
                          disabled={
                            (currentSubscription.planInfo &&
                              currentSubscription.planInfo.id === button.id) ||
                            (currentSubscription.pendingSubscription &&
                              currentSubscription.pendingSubscription.planId ===
                                button.id)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.cardValueText}
                  >
                    <p style={{ fontWeight: "bold" }}>ORDER SUMMARY</p>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    container
                    className={classes.cardValueText}
                  >
                    <Grid item xs={6}>
                      <p>{selectedPlan.description || ""}</p>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <p style={{ fontWeight: "bold" }}>
                        $
                        {selectedPlan.price
                          ? selectedPlan.price.toFixed(2)
                          : "0.00"}
                      </p>
                    </Grid>
                    <Grid item xs={12} container>
                      <Divider
                        variant="middle"
                        style={{ width: "100%", margin: "0 auto" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p style={{ fontWeight: "bold" }}>Subtotal</p>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <p style={{ fontWeight: "bold" }}>
                        $
                        {selectedPlan.price
                          ? selectedPlan.price.toFixed(2)
                          : "0.00"}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>Sales Tax (0%)</p>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <p style={{ fontWeight: "bold" }}>$0.00</p>
                    </Grid>
                    <Grid item xs={12} container>
                      <Divider
                        variant="middle"
                        style={{
                          width: "100%",
                          margin: "0 auto",
                          marginBottom: "1em",
                        }}
                      />
                    </Grid>
                    {renderTotalPrice()}
                    <Grid item xs={6}>
                      <p style={{ fontWeight: "bold" }}>Total</p>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                      <p style={{ fontWeight: "bold" }}>
                        ${totalPrice.toFixed(2)}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <CardActions className={classes.actionContainer}>
              <Grid
                container
                style={{
                  paddingLeft: theme.spacing(8),
                  paddingRight: theme.spacing(8),
                  marginBottom: theme.spacing(4),
                }}
                spacing={1}
              >
                <Grid item xs={12} sm={5}>
                  <Button
                    className={classes.leftButton}
                    onClick={handleClose}
                    size="medium"
                    variant="outlined"
                    fullWidth={true}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={2}></Grid>
                <Grid item xs={12} sm={5} container justify="flex-end">
                  <Button
                    className={classes.rightButton}
                    onClick={handleSubmit}
                    disabled={
                      (currentSubscription.planInfo &&
                        currentSubscription.planInfo.id === selectedPlan.id) ||
                      submitState ||
                      !selectedPlan.id
                    }
                    size="medium"
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                  >
                    COMPLETE YOUR PURCHASE
                  </Button>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <p className={classes.cardText}>
                    When you hit the button above your payment method on file
                    will be billed the total shown.
                  </p>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </ClickAwayListener>
    </Dialog>
  );
};

export default ChangeSubscription;
