import React from "react";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";

import ModalFormHeader from "../../common/ModalComponents/ModalFormHeader";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
}));

/**
 * Enter ID and Name form
 * @param {Object} props
 * @props
 * idInfo: { MAC: "", name: "", desc: "" }
 * infoCallback: ()={void};
 * sensorMACError: bool
 * isEdit: bool
 */
export default function SensorIdAndName(props) {
  const classes = useStyles();
  const { idInfo, infoCallback, sensorMACError, isEdit } = props;

  const handleChange = (key, value) => {
    infoCallback({ ...idInfo, [key]: value });
  };

  const handleQRCode = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("enableQR");
    }
  };

  return (
    <Grid container item xs={12} spacing={2}>
      <ModalFormHeader
        header={
          isEdit
            ? "Edit Sensor ID and edit your sensor a name"
            : "Enter Sensor ID and give your sensor a name"
        }
        desc="Enter the sensor ID for your device and give your sensor a name to be able to search and identify it easily."
      />
      <Grid item xs={12}>
        <TextField
          disabled={isEdit}
          className={classes.textField}
          value={idInfo.MAC}
          error={sensorMACError}
          fullWidth
          variant="outlined"
          label="Sensor ID"
          placeholder="Sensor ID"
          onChange={(e) => handleChange("MAC", e.target.value.toUpperCase())}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.textField}
          fullWidth
          variant="outlined"
          value={idInfo.name}
          label="Sensor Name"
          placeholder="Sensor Name"
          onChange={(e) => handleChange("name", e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.textField}
          fullWidth
          variant="outlined"
          value={idInfo.desc}
          label="Additional Comments (Optional)"
          placeholder="Additional Comments (Optional)"
          onChange={(e) => handleChange("desc", e.target.value)}
        />
      </Grid>
      {window.ReactNativeWebView && (
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleQRCode}>
            Scan QR
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
