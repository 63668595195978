import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

//MATERIAL UI ICONS
import LocationIcon from "@material-ui/icons/Room";
import GatewayIcon from "@material-ui/icons/Router";

//DCS ICONS
import SensorIcon from "../../img/icons/SensorIcon";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import BatterySVG from "../../icons/BatterySVG";
import LoadingSpinner from "../Generic/LoadingSpinner";
import AdminDashboardIcon from "../../img/icons/AdminDashboardIcon";
import CustomersIcon from "../../img/icons/CustomersIcon";

//Components
import { AppContext } from "../../AppContext";
import Tile from "../Dashboard/Tile.js";
import TileCentered from "../Dashboard/TileCentered.js"
import HeaderBar from "../Generic/HeaderBar";
import BarGraphContainer from './BarGraphContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
  tileContainer: {
    // "&:hover": {
    //   cursor: "pointer",
    // },
  },
  headerContainer: {
    padding: 0,
    width: "100%",
    marginRight: 14,
    [theme.breakpoints.up("xs")]: {
      marginRight: 0,
    },
  },
  cardPrincipalText: {
    textAlign: 'right',
    color: '#747474',
    opacity: 1,
    marginTop: 22,
    font: 'normal normal 600 45px/61px Open Sans;', 
  },
  cardSecondaryText: {
    font: 'normal normal 600 15px/20px Open Sans;',
    color: theme.palette.primary.main,
    opacity: 1
  }
}));

const ResellerDashboard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { appState, setLogoutState, snack } = useContext(
    AppContext
  );

  const [resellerInfo, setResellerInfo] = useState({});
  const [locationCount, setLocationCount] = useState("-");
  const [resellerCount, setResellerCount] = useState("-");
  const [gatewayCount, setGatewayCount] = useState("-");
  const [sensorCount, setSensorCount] = useState("-");
  const [sensorCountByProfile, setSensorCountByProfile] = useState({
    temperature: "-",
    humidity: "-",
    pressure: "-",
    acceleration: "-",
    light: "-",
    panic: "-",
    door: "-",
    rssi: "-",
    battery: "-",
  });
  const [forecastData, setForecastData] = useState([]);

  const [loading, setLoading] = useState(true);

  const getDashboardInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reseller/dashboard?id=${appState.auth.userInfo.resellerId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: appState.auth.token,
        },
      });

      const json = await response.json();
      if (json.success) {
        const sensorCountByType = json.data.sensorCountByType;
        setResellerInfo(json.data.resellerInfo);
        setLocationCount(json.data.totalLocationCount);
        setGatewayCount(json.data.totalGatewayCount);
        setSensorCount(json.data.totalSensorCount);
        setResellerCount(json.data.totalResellerCount);
        setSensorCountByProfile({
          ...sensorCountByProfile,
          temperature: sensorCountByType.temperature,
          humidity: sensorCountByType.humidity,
          pressure: sensorCountByType.pressure,
          acceleration: sensorCountByType.acceleration,
          light: sensorCountByType.light,
          panic: sensorCountByType.panic,
          door: sensorCountByType.door,
          rssi: sensorCountByType.rssi,
          battery: sensorCountByType.battery,
        });
      } else {
        snack("Failed to retrieve system wide information.", "error");
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
    setLoading(false);
  };

  const [originalData, setOriginalData ] = useState({});

  const getForecastData = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/reseller/forecast?id=${appState.auth.userInfo.resellerId}`,
        {
          headers: {
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await response.json();
      if (json.success) {
        setOriginalData(json.data);
        setForecastData(json.data.forecast);
      } else {
        snack("Failed to retrieve forecasted data.", "error");
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  useEffect(() => {
    getDashboardInfo();
    getForecastData();
  }, []);

  const tileComponents = [
    {
      id: 1,
      entityName: "TEMPERATURE SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.temperature,
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 2,
      entityName: "HUMIDITY SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.humidity,
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PRESSURE SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.pressure,
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "IMPACT DETECTION SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.acceleration,
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "LIGHT DETECTION SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.light,
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PANIC BUTTON SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.panic,
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "DOOR OPEN/CLOSED SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.door,
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PROXIMITY ALERT SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.rssi,
      icon: (
        <ProximitySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "BATTERY SENSORS",
      underlineColor: "blue",
      numberOfEntities: sensorCountByProfile.battery,
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
  ];
  return !loading ? (
    <div>
      {/* <Grid style={{ marginTop: 4 }} container spacing={3}> */}
      {/* <Grid className={classes.headerContainer} item> */}
      <HeaderBar
        dashboardHeader={true}
        leadingIcon={
          <AdminDashboardIcon
            style={{ fontSize: "1.75rem", marginLeft: 7 }}
            color="primary"
          />
        }
        title="Reseller - Dashboard"
        addButton={false}
        searchInput={false}
      />
      {/* </Grid> */}
      {/* Tile Entity Components */}
      <Grid style={{ padding: 5, marginLeft: 6, marginRight: 6 }} item xs={12}>
        <Grid container spacing={3}>
          <Grid
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Customers"
              underlineColor="blue"
              numberOfEntities={resellerCount}
              scaleFont={false}
              icon={
                <CustomersIcon
                  style={{ fontSize: 55, color: "#FF6A6A" }}
                />
              }
            />
          </Grid>
          <Grid
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Locations"
              underlineColor="blue"
              numberOfEntities={locationCount}
              scaleFont={false}
              icon={<LocationIcon style={{ fontSize: 55, color: "#08B3FF" }} />}
            />
          </Grid>

          <Grid
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Gateways"
              underlineColor="green"
              numberOfEntities={gatewayCount}
              scaleFont={false}
              icon={<GatewayIcon style={{ fontSize: 55, color: "#59C754" }} />}
            />
          </Grid>

          <Grid
            className={classes.tileContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <Tile
              entityName="Sensors"
              underlineColor="lightBlue"
              numberOfEntities={sensorCount}
              scaleFont={false}
              icon={<SensorIcon style={{ fontSize: 55, color: "#58A3B9" }} />}
            />
          </Grid>
          {/* Comissions cards */}
          <Grid container item xs={12} md={3}>
            <Grid item xs={12}>
              <TileCentered
                entityName="Upgrade Commission Payout"
                entitySubtitle={resellerInfo.upgrade_commission_rate ? Number(resellerInfo.upgrade_commission_rate) + "%" : "-"}
                cardStyle={{minHeight: "225px", maxHeight: "35vh", textAlign: 'center'}}
              >
                <Box style={{ width: "100%" }}>
                  <Box display="flex" justifyContent="center" style={{ textAlign: "center", width: "100%" }}>
                    <Typography fontWeight="fontWeightMedium" className={classes.cardPrincipalText}>
                      ${originalData?.current?.totalProrated && resellerInfo.upgrade_commission_rate ? calculateCommission(originalData.current.totalProrated, resellerInfo.upgrade_commission_rate) : 0}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" style={{ textAlign: "center", width: "100%" }}>
                    <Typography fontWeight="fontWeightMedium" className={classes.cardSecondaryText}>
                      THIS MONTH
                      </Typography>
                  </Box>
                </Box>

              </TileCentered>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 12, marginBottom: 12 }}></Grid>
            <Grid item xs={12}>
              <TileCentered
                entityName="Renewal Commission Payout"
                entitySubtitle={resellerInfo.renewal_commission_rate ? Number(resellerInfo.renewal_commission_rate) + "%" : "-"}
                cardStyle={{minHeight: "225px", maxHeight: "35vh", textAlign: 'center'}}
              >
                <Box style={{ width: "100%" }}>
                  <Box display="flex" justifyContent="center" style={{ textAlign: "center", width: "100%" }}>
                    <Typography fontWeight="fontWeightMedium" className={classes.cardPrincipalText}>
                      ${originalData?.current?.totalCompletedAnnual && resellerInfo.upgrade_commission_rate ? calculateCommission(originalData.current.totalCompletedAnnual, resellerInfo.renewal_commission_rate) : 0}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" style={{ textAlign: "center", width: "100%" }}>
                    <Typography fontWeight="fontWeightMedium" className={classes.cardSecondaryText}>
                      THIS MONTH
                      </Typography>
                  </Box>
                </Box>

              </TileCentered>
            </Grid>
          </Grid>
          {/* Forecast Bar Graph*/}
          <BarGraphContainer data={originalData} sellerInfo={resellerInfo}/>
          {tileComponents.map((u, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Tile
                entityName={u.entityName}
                underlineColor={u.underlineColor}
                numberOfEntities={u.numberOfEntities}
                scaleFont={true}
                icon={u.icon}
                multiple={true}
                secondEntry={u.secondEntry}
                fontSize={u.fontSize}
                dateValue={u.dateValue}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  ) : (
      <LoadingSpinner />
    );
};

const calculateCommission = (total, percentage) => {
  const formatPercentage = Number(percentage) / 100;
  return (Number(total) * formatPercentage).toFixed(2);
}

export default ResellerDashboard;