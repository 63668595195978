import React, { useState, useEffect, useContext, useCallback } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Card,
  CardContent,
  Backdrop,
  Button,
  Grid,
  Typography,
  Snackbar,
  Avatar,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { makeStyles } from "@material-ui/core/styles";
import fetchOne from "../common/api/fetchOne";
import uploadImage from "../common/api/uploadImage";
import EditableTextField from "../common/EditableTextField";
import { setQueryParams } from "hookrouter";
import DeleteDialog from "../common/DeleteDialog";
import deleteOne from "../common/api/deleteOne";
import { navigate, A } from "hookrouter";
import GroupIcon from "@material-ui/icons/Group";
import CircularProgress from "@material-ui/core/CircularProgress";
import SingleUserCard from "./SingleUserCard";
import MUIDataTable from "mui-datatables";
import Breadcrumb from "../Generic/BreadCrumb";

import EditUserImageDialog from "./EditUserImageDialog";
import EditSubUserFormModal from "./EditSubUserFormModal";
import DeleteUserFormModal from "./DeleteUserFormModal";
import NotFoundPageLoggedIn from "../Auth/NotFoundPageLoggedIn";
import NotFoundPage from "../Auth/NotFoundPage";
import LoadingSpinner from "../Generic/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    width: theme.spacing(40),
    height: theme.spacing(40),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
    border: `2px solid ${theme.palette.text.primary}`,
  },
  gridContainer: {
    marginTop: "5%",
  },
  imageDialogContainer: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  removeImageButton: {
    marginLeft: "auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem",
      marginLeft: 0,
    },
  },
  backButton: {
    color: theme.palette.secondary.main,
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "1rem",
    width: "6rem",
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: "1rem",
    marginLeft: "auto",
  },
  card: {
    marginTop: "5%",
    paddingLeft: "1rem",
  },
  cardContent: {
    minHeight: "50vh",
  },
  editAvatarIcon: {
    width: 33,
    height: 33,
    padding: 4,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "25px",
  },
  profileImageButton: {
    border: "none",
    width: 33,
    height: 33,
    padding: 0,
    margin: 0,
    borderRadius: "25px",
  },
  profileImageSaveButton: {
    marginRight: "auto",
    marginTop: "1rem",
  },
  titleName: {
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
    margin: "auto",
    color: theme.palette.text,
  },
  text: {
    color: theme.palette.text,
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  nested: {
    maxWidth: 275,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SingleSubUser = (props) => {
  const log = window.log('SingleSubUser');

  const classes = useStyles();
  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenEditUserModal,
    setOpenDeleteUserModal,
    snack,
  } = useContext(AppContext);

  const [state, setState] = useState({});

  const { id } = props;

  const [editForm, setEditForm] = useState(false);
  const [editFname, setEditFname] = useState("");
  const [editLname, setEditLname] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editPhoneNumber, setEditPhoneNumber] = useState("");
  const [emailError, setEmailError] = useState();
  const [firstNameError, setFirstNameError] = useState();

  const [imgErr, setImgErr] = useState(false);

  // const [upImg, setUpImg] = useState();
  // const [imgRef, setImgRef] = useState(null);
  // const [crop, setCrop] = useState({ unit: "%", aspect: 1 / 1 });
  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();
  // const [previewUrl, setPreviewUrl] = useState();
  const [profileImageLocation, setProfileImageLocation] = useState("");
  const [openProfileImageDialog, setOpenProfileImageDialog] = useState("");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userImageModalState, setUserImageModalState] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const userImageOpenModal = () => {
    setUserImageModalState(true);
  };

  const userImageCloseModal = () => {
    setUserImageModalState(false);
  };

  const userImageSaveModal = () => {
    setUserImageModalState(false);
  };

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reseller/users?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': appState.auth.token,
        }
      });
      const json = await response.json()

      log("JSON", json);
      if (json.success) {
        setState(json.data.users[0]);
        if (
          json.data.users[0].profile &&
          json.data.users[0].profile.profileImageLocation
        ) {
          setProfileImageLocation(
            json.data.users[0].profile.profileImageLocation
          );
        }
      } else {
        setState(null);
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const onEditClick = () => {
    // Set input values and change to edit form
    setEditForm(!editForm);
    setEditFname(state.fname);
    setEditLname(state.lname);
    setEditEmail(state.email);
    setEditPhoneNumber(state.phoneNumber);
  };

  const updateOne = async (entity, data, token) => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/${entity}/editUser`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await request.json();
      return json;
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  const onSubmitClick = async () => {
    try {
      const data = {
        ...state,
        fname: editFname,
        lname: editLname,
        email: editEmail,
        phoneNumber: editPhoneNumber,
        profile: JSON.stringify(appState.auth.userInfo.profile),
        companyId: appState.auth.userInfo.companyId,
      };

      const json = await updateOne("user", data, appState.auth.token);
      if (json.success) {
        snack("User information successfully updated", "success");
        getUser();
        setEditForm(!editForm);
        setEmailError(false);
        setFirstNameError(false);
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          if (error.type === "email") {
            setEmailError(true);
          }
          if (error.type === "fName") {
            setFirstNameError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
        });
      }
    } catch (err) {
      log(err);
    }
  };

  const onDeleteClick = async () => {
    setOpenDeleteDialog(true);
  };

  const deleteEntity = async () => {
    const data = {
      ...state,
      companyId: appState.auth.userInfo.companyId,
    };
    const json = await deleteOne("users", data, appState.auth.token);

    if (json.success) {
      snack("User successfully removed from the system", "success");
      setTimeout(() => navigate("/user", true), 750);
    } else {
      let errorObj = {};
      json.errors.forEach((error) => {
        errorObj = { ...errorObj, [error.type]: true };
        snack(error.msg, "error");
      });
    }
  };

  const userAuthButtons = (
    <Grid container>
      {editForm ? (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onSubmitClick()}
        >
          Submit
        </Button>
      ) : (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => handleOpenEditUserModal()}
        >
          Edit
        </Button>
      )}
      <Button
        disabled={appState.auth.userInfo.perm === 1}
        className={classes.deleteButtons}
        variant="outlined"
        onClick={() => handleOpenDeleteUserModal()}
      >
        Delete
      </Button>
    </Grid>
  );

  const singleFileUploadHandler = async (e) => {
    setOpenBackdrop(true);
    try {
      const renameText = `userImage-${appState.auth.userInfo.id}-${Date.now()}`;
      const data = new FormData();

      const folderDestination = "Profile Image";
      if (file) {
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            snack(response.error, "error");
          }
          setOpenBackdrop(false);
        } else {
          // Success
          setProfileImageLocation(response.location);
          snack("Image successfully uploaded", "success");
          handleSaveProfileImage(response.location);
        }
      } else if (imgFile) {
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        if (response.error) {
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            // If not the given file type
            snack(response.error, "error");
          }
          setOpenBackdrop(false);
        } else {
          // Success
          setProfileImageLocation(response.location);
          handleSaveProfileImage(response.location);
        }
      } else if (profileImageLocation) {
        //PASS
        setOpenBackdrop(false);
      } else {
        handleSaveProfileImage("");

        snack("Image Removed", "success");
      }
    } catch (error) {
      log(error);
      setOpenBackdrop(false);
    }
  };

  const handleProfileImageDialogOpen = () => {
    setOpenProfileImageDialog(true);
  };

  const handleProfileImageDialogClose = () => {
    setOpenProfileImageDialog(false);
  };

  const handleRemoveProfileImageLocation = () => {
    setFile(false);
    setImgFile(null);
    setProfileImageLocation("");
  };

  // Save Profile Image
  const handleSaveProfileImage = async (location) => {
    const changes = {
      profileImageLocation: location,
    };

    try {
      const data = {
        ...state,
        profile: JSON.stringify(changes),
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/user/editUserImage",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await response.json();

      if (json.success) {
        // snack("Image successfully updated", "success");
        if (appState.auth.userInfo.id === state.id) {
          let cp = { ...appState };
          cp.auth.userInfo.profile = JSON.parse(json.data.profile);
          setAppState(cp);
        }
        getUser();
        handleProfileImageDialogClose();
      } else {
        //handle errs
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
    setOpenBackdrop(false);
  };

  const handleRemoveImg = () => {
    handleSaveProfileImage("");
  };

  const handleOpenEditUserModal = () => {
    setOpenEditUserModal(true);
  };

  const handleOpenDeleteUserModal = () => {
    if (state.id === appState.auth.userInfo.id) {
      snack("A user cannot delete themselves", "error");
    } else {
      setOpenDeleteUserModal(true);
    }
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/user">
        Demo Accounts
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  return state ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <>
        <EditSubUserFormModal getUser={getUser} userInfo={state} />
        <DeleteUserFormModal userInfo={state} />
        <EditUserImageDialog
          openState={userImageModalState}
          handleClose={userImageCloseModal}
          handleSave={singleFileUploadHandler}
          setImgLocation={setProfileImageLocation}
          handleRemoveImg={handleRemoveProfileImageLocation}
          imgLocation={profileImageLocation}
          setImgErr={setImgErr}
          setFile={setFile}
          setImgFile={setImgFile}
        />
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {state ? (
          <div>
            <Grid item className={classes.title}>
              <Breadcrumb
                breadcrumbs={breadcrumbs}
                leadingIcon={
                  <GroupIcon style={{ fontSize: 32 }} color="primary" />
                }
                title={`${state.fname} ${state.lname}`}
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SingleUserCard
                  id={state.id}
                  name={`${state.fname || ""} ${state.lname || ""}`}
                  email={state.email || ""}
                  companyName={state.companyName || ""}
                  phoneNumber={state.phoneNumber || ""}
                  permissionLevel={state.perm}
                  userSettingsPage={false}
                  avatar={profileImageLocation}
                  handleEditImage={userImageOpenModal}
                  handleOpenEditUserModal={handleOpenEditUserModal}
                  handleOpenDeleteUserModal={handleOpenDeleteUserModal}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <MUIDataTable
                  title={"LATEST ACTIVITY"}
                  data={data}
                  columns={columns}
                  options={{
                    search: false,
                    selectableRows: false,
                    sort: false,
                    sortFilterList: false,
                    viewColumns: false,
                    filter: false,
                  }}
                />
              </Grid> */}
            </Grid>
          </div>
        ) : (
          <NotFoundPageLoggedIn />
        )}
      </>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default SingleSubUser;
