import React, { useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

import { Slider, Tooltip, withWidth, Typography } from "@material-ui/core";
import ValueLabel from "@material-ui/core/Slider/ValueLabel";

import grey from "@material-ui/core/colors/grey";

const isSmall = (_width) => {
  switch (_width) {
    case "sm":
      return true;
    case "xs":
      return true;
    default:
      return false;
  }
};

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1.5, 0),
    border: `1px solid ${grey[500]}`,
    overflow: "visible",
    padding: theme.spacing(1.5),
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  slider: {
    width: "90%",
  },
}));

const StyledValueLabel = withStyles({
  offset: {
    top: -28,
    left: (props) =>
      props.index === 0 ? "calc(-50% + -30px)" : "calc(-50% + 12px)",
  },
  circle: {
    fontSize: "14px",
    backgroundColor: "#0B9EDE",
    transform: (props) =>
      props.index === 0 ? "rotate(-90deg)" : "rotate(0deg)",
  },
  label: {
    transform: (props) =>
      props.index === 0 ? "rotate(90deg)" : "rotate(0deg)",
  },
  tooltip: {
    fontSize: "14px",
  },
})(ValueLabel);

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "14px",
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const classes = useTooltipStyles();

  return (
    <Tooltip
      arrow
      open={open}
      enterTouchDelay={0}
      placement={props.index === 0 ? "top" : "bottom"}
      title={value}
      classes={classes}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const SliderTile = function (props) {
  const log = window.log("SliderTile");

  const classes = useStyles();
  const { modalOpenState } = useContext(AppContext);
  let {
    isRangeType = false, // boolean, if it is a single value or a range
    values, // number, one element Ex: 15 , or array of two numbers Ex: [15,20]
    unitMeasure = "%", // String, to specify the unit , example "°F"
    minimumValue = 0, // number, minimum value of the range
    maximumValue = 100, // number, maximun value of the range
    onChange, // Function (event, newValue)
    drawer, // if drawer is open
    width, // Breakpoints for width
    type, // Type of range
  } = props;
  log("Slider tile props");
  log(props);

  const valueTextFormat = (value) => {
    return (
      <Typography style={{ color: "white" }}>
        {value}
        {unitMeasure}
      </Typography>
    );
  };

  const isInverted = () => {
    if ( !type || type === 'Below Value' || type === 'Inside of Range' ) {
      return "normal"
    } 
    return "inverted"
  }

  const marks = [
    {
      value: minimumValue,
      label: valueTextFormat(minimumValue),
    },
    {
      value: maximumValue,
      label: valueTextFormat(maximumValue),
    },
  ];
  if (!isRangeType) {
    values = values[0];
  }
  const defaultValue = isRangeType
    ? [minimumValue, maximumValue / 2]
    : minimumValue;

  log("Looking at the default value " + defaultValue);
  return (
    <Slider
      className={classes.slider}
      ValueLabelComponent={ValueLabelComponent}
      defaultValue={defaultValue}
      valueLabelFormat={valueTextFormat}
      value={values}
      onChange={(event, newValue) => {
        if (onChange) {
          onChange(newValue);
        }
      }}
      disabled
      min={minimumValue}
      max={maximumValue}
      valueLabelDisplay={
        modalOpenState() || (drawer && isSmall(width)) ? "off" : "on"
      }
      aria-labelledby="range-slider"
      getAriaValueText={valueTextFormat}
      marks={marks}
      track={isInverted()}
    />
  );
};

export default withWidth()(SliderTile);
