import React, { useState, useEffect, useContext } from "react";
import MUIDataTable from "mui-datatables";
import { Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";

//MATERIAL UI ICONS
import GatewayIcon from "@material-ui/icons/Router";

//DCS ICONS
import SensorIcon from "../../img/icons/SensorIcon";
import CustomersIcon from "../../img/icons/CustomersIcon";
import AdminDashboardIcon from "../../img/icons/AdminDashboardIcon";

//COMPONENTS
import HeaderBar from "../Generic/HeaderBar";
import CustomerCard from "./CustomerCard";
import LoadingSpinner from "../Generic/LoadingSpinner";
import { AppContext } from "./../../AppContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
}));

const AllCustomers = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { appState, setLogoutState, snack } = useContext(AppContext);

  const [state, setState] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [listView, setlistView] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/reseller">
        Reseller
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const options = {
    onRowClick: (selectedRow) => {
      navigate(`/customer/${selectedRow[0]}`);
    },
    responsive: "standard",
    selectableRows: "none",
    search: false,
  };

  const filterColumns = ["name", "street", "city", "state", "zip"];

  const columns = [
    {
      name: "company_id",
      options: {
        viewColumns: false,
        display: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "totalGatewayCount",
      label: "Gateways",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "totalSensorCount",
      label: "Sensors",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const handleSortChange = () => {
    setlistView(!listView);
  };

  const getCompanies = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/reseller/companies?id=${appState.auth.userInfo.resellerId}`,
        {
          headers: {
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await response.json();
      if (json.success) {
        const updatedCompanies = json.data.companies.filter(company => company.company_id !== appState.auth.userInfo.companyId );
        setState(updatedCompanies);
        setOriginalData(updatedCompanies);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return initialPageLoad ? (
    <LoadingSpinner />
  ) : (
      <div className={classes.root}>
        {/* Generic Search  Bar */}
        <HeaderBar
          leadingIcon={
            <AdminDashboardIcon style={{ fontSize: 32 }} color="primary" />
          }
          title="Customers"
          addButton={false}
          searchCallback={(value) => {
            let filteredArray = [];
            //Iteratate over every location object
            for (var i = 0; i < originalData.length; i++) {
              let match = false;
              //Iterate over every filter condtion
              for (var j = 0; j < filterColumns.length; j++) {
                //Check for match
                if ((originalData[i][filterColumns[j]] + "").toLowerCase().includes(value.toLowerCase())) {
                  match = true;
                  break;
                }
              }
              //If match add to filtered array
              if (match) {
                filteredArray.push(originalData[i]);
              }
            }
            setState(filteredArray);
          }}
          sortButton={true}
          sortButtonEvent={handleSortChange}
        />
        <Grid container spacing={2}>
          {state ? (
            listView ? (
              <Grid xs={12}>
                <MUIDataTable
                  title={"Customers"}
                  data={state}
                  columns={columns}
                  options={options}
                />
              </Grid>
            ) : (
                state.map((item) => (
                  <Grid item xs={12} sm={6} key={item.id}>
                    <CustomerCard
                      id={item.company_id}
                      title={`${item.name}`}
                      street={`${item.street}`}
                      city={`${item.city}`}
                      state={`${item.state}`}
                      zip={`${item.zip}`}
                      phone={item.phone}
                      trailingItems={[
                        {
                          value: item.totalGatewayCount,
                          title: "Gateway",
                          icon: (
                            <GatewayIcon
                              style={{ fontSize: 28, color: "#59C754" }}
                            />
                          ),
                        },
                        {
                          value: item.totalSensorCount,
                          title: "Sensor",
                          icon: (
                            <SensorIcon
                              style={{
                                fontSize: 28,
                                color: theme.palette.primary.main,
                              }}
                            />
                          ),
                        },
                      ]}
                    />
                  </Grid>
                ))
              )
          ) : null}
        </Grid>
      </div>
    );
};

export default AllCustomers;
