import React from "react";
import { Grid, Box, Card, Typography, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import grey from "@material-ui/core/colors/grey";

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

/**
 * Tile component for
 * dashboard top area
 *
 * @props
 * numberOfEntities: integer
 * entityName: String
 * icon: React Component
 * underlineColor: String (Color)
 */
export default function Tile(props) {
  const {
    entityName, // string , title of the tile
    entitySubtitle, // string subtitle of the tile
    underlineColor, // underline Color
    numberOfEntities, // number of entities to be shown
    icon, // icon to render
    scaleFont, // scale font to fit long text
    multiple, // add 2 rows of text,
    secondEntry, //second row message
    fontSize,
    dateValue,
    children,
    cardStyle,
  } = props;

  const useStyles = makeStyles((theme) => ({
    card: {
      padding: "24px 36px 25px 32px",
      // margin: theme.spacing(1, 1),
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      maxHeight: "25vh",
    },
    titleContainer: {
      margin: theme.spacing(0, 0, 1, 0),
    },
    cardTitle: {
      textTransform: "uppercase",
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.type === "light" ? "#747474" : "#FFF",
    },
    cardSubtitle: {
      textTransform: "uppercase",
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.primary.main,
    },
    underLine: {
      backgroundColor: theme.palette.primary.main,
      height: "1px",
      width: "5rem",
    },
    icon: {
      margin: theme.spacing(0, 0, 0, 0),
    },
    numberStyles: {
      color: textColor({ theme }),
      whiteSpace: "nowrap",
      fontSize: 53,
      [theme.breakpoints.down("lg")]: scaleFont && {
        fontSize: dateValue ? 37 : 53,
      },
      [theme.breakpoints.down("1442")]: scaleFont && {
        fontSize: dateValue && "2.5vw",
      },
      [theme.breakpoints.down("1239")]: scaleFont && {
        fontSize: dateValue && "2.2vw",
      },
      [theme.breakpoints.down("md")]: scaleFont && {
        fontSize: !dateValue && "3.4vw",
      },
      [theme.breakpoints.down("sm")]: scaleFont && {
        fontSize: dateValue ? "3.7vw" : 30,
      },
      [theme.breakpoints.down("xs")]: scaleFont && {
        fontSize: dateValue ? 22 : 30,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.card} style={{...cardStyle}}>
      <div className={classes.titleContainer}>
        <Box fontWeight="fontWeightMedium" display="flex" justifyContent="center">
          <Typography className={classes.cardTitle}>{entityName}</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box className={classes.underLine} display="flex" justifyContent="center"/>
        </Box>
        <Box fontWeight="fontWeightMedium" display="flex" justifyContent="center">
          <Typography className={classes.cardSubtitle}>{entitySubtitle}</Typography>
        </Box>
      </div>
      <Grid
        container
        display="flex"
        justify="space-between"
        alignItems={multiple ? "center" : "flex-end"}
        direction="row"
        style={{ flexWrap: "nowrap" }}
      >
        {children}
      </Grid>
    </Card>
  );
}
