import React from "react";
import {
  Box,
  Card,
  Button,
  CardContent,
  CardHeader,
  CardActions,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const buildList = (data, itemBuilder, emptyBuilder) => {
  if (data.length === 0) {
    // if list empty return the element to show when empty
    return emptyBuilder();
  }

  // reducing array to 20 elements
  const reducedArray = data.slice(0, 20);
  // item build is used in map to build the array of components to show
  const result = reducedArray.map(itemBuilder);

  return result;
};

/**
 * Generic List for
 * the list in the dashboard
 *
 * @props
 * title: String
 * actionTitle: String
 * onClick: Function
 * children: Components (Color)
 * action: Components
 * showActions: Boolean
 */
function GenericList(props) {
  const theme = useTheme();
  const {
    title, // Title of the list
    actionTitle, // Title for the action in the bottom the list
    onClick, // Function for the action in the bottom of the list
    children, // Children components
    action, // Action for the CardHeader
    showActions = true,
    dashboardTable,
    /// props for limiting the amount of elements in the table
    useItemBuilder = false, // if true the table will render using item builder and will not use the children
    data = [], // list of elements to render
    itemBuilder = (item, index) => {}, // function that returns the component of an item in the list given the item and the index
    emptyBuilder = () => {}, //function that returns the component to render if the list is empty
  } = props;

  const useStyles = makeStyles((theme) => ({
    cardContainer: {
      borderRadius: 10,
      height: dashboardTable ? 670 : 393,
    },
    cardContents: {
      overflowY: "auto",
      height: dashboardTable ? 670 : 272,
      maxHeight: dashboardTable ? 550 : 272,
    },
    cardHeader: {
      boxShadow: "0 1px 2px 0px rgba(0, 0, 0, .1)",
      paddingLeft: 20,
      borderBottom: ".5px solid #CCC",
    },
    headerTitle: {
      fontSize: 14,
      fontWeight: 600,
      color:
        theme.palette.type === "light"
          ? theme.palette.grey[600]
          : theme.palette.text.primary,
    },
    margin: {
      margin: theme.spacing(1),
      transform: "translateY(-13px)",
    },
  }));

  const classes = useStyles();

  return (
    <Card elevation={2} className={classes.cardContainer}>
      {/* Header  */}
      {title !== undefined ? (
        <Box
          fontWeight="fontWeightMedium"
          fontSize={16}
          className={classes.cardHeader}
          children
        >
          <CardHeader
            className={classes.headerTitle}
            title={title.toUpperCase()}
            disableTypography={true}
            action={action}
          />
        </Box>
      ) : (
        <div></div>
      )}

      <CardContent className={classes.cardContents} style={{ padding: 0 }}>
        {useItemBuilder ? buildList(data, itemBuilder, emptyBuilder) : children}
      </CardContent>
      {showActions ? (
        <CardActions
          style={{
            justifyContent: "center",
            borderTop: ".5px solid #CCC",

            marginTop: 15,
          }}
        >
          <Button
            onClick={onClick}
            size="large"
            className={classes.margin}
            color="primary"
          >
            {actionTitle}
          </Button>
        </CardActions>
      ) : null}
    </Card>
  );
}

export default GenericList;
