import React, { useEffect, useContext, useState } from "react";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import moment from "moment-timezone";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { A } from "hookrouter";
import Breadcrumb from "../Generic/BreadCrumb";
import { AppContext } from "../../AppContext";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  table: {
    margin: theme.spacing(2, 0),
    "& tr:nth-of-type(odd)": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor:
        theme.palette.type === "light" ? "white" : theme.palette.grey[800],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 600,
  },
}));

const Transactions = (props) => {
  const log = window.log("Transactions");
  const classes = useStyles();

  const { appState, snack } = useContext(AppContext);

  const [transactions, setTransactions] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(true);

  /**
   * table options
   */
  const tableOptions = {
    selectableRows: "none",
    print: false,
    download: true,
    jumpToPage: true,
    responsive: "scrollMaxHeight",
    downloadOptions: {
      filename: `misensors_transaction_report.csv`,
      separator: ",",
    },
  };

  const tableColumns = [
    {
      name: "date",
      label: "Date",
    },
    {
      name: "name",
      label: "Company Name",
    },
    {
      name: "type",
      label: "Subscription Type",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "commission",
      label: "Commission",
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "address",
      label: "Address",
    },
    {
      name: "transStatus",
      label: "Trans Status",
    },
    {
      name: "transDate",
      label: "Trans Date",
    },
  ];

  useEffect(() => {
    getAllTransactions();
  }, []);

  const getResellerInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reseller/dashboard?id=${appState.auth.userInfo.resellerId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: appState.auth.token,
        },
      });

      const json = await response.json();
      if (json.success) {
        return json.data.resellerInfo;
      } else {
        snack("Failed to retrieve system wide information.", "error");
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token"){
            snack('Failed to retrieve commission information', 'error');
          };
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  }

  const getAllTransactions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reseller/transactions?id=${appState?.auth?.userInfo?.resellerId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await response.json();
      const resellerInfo = await getResellerInfo()
      let upRate;//upgrade rate
      let reRate;//renewal rate
  
      if(resellerInfo.upgrade_commission_rate){
        upRate = parseInt(resellerInfo.upgrade_commission_rate) / 100;
      } else {
        upRate = 1;
      }
  
      if(resellerInfo.renewal_commission_rate){
        reRate = parseInt(resellerInfo.renewal_commission_rate) / 100;
      } else {
        reRate = 1;
      }

      console.log(resellerInfo)
      if (json.success && json.data.transactions) {
        if (!json.data.transactions.length) {
          snack("No Transactions Found.", "warning");
        } else {
          const formattedTransaction = json.data.transactions.map((item) => {
            const { date, amount, customer, statusHistory, type } = item;
            let commission = type === 'annual' ? amount * reRate : amount * upRate
            return {
              date: formatTime(
                date,
                appState?.auth?.userInfo?.themePreferences?.timeFormat
              ),
              name: customer.company,
              type,
              amount: `$${amount}`,
              commission: `$${commission.toFixed(2)}`,
              email: customer.email || "",
              phoneNumber:
                customer.phoneNumber && customer.phoneNumber.length < 5
                  ? ""
                  : customer.phoneNumber,
              address: `${customer.street}, ${customer.city}, ${customer.state} ${customer.zip}`,
              transDate:
                formatTime(
                  statusHistory.timestamp,
                  appState?.auth?.userInfo?.themePreferences?.timeFormat
                ) || "",
              transStatus: formatStatus(statusHistory.status),
            };
          });
          setTransactions(formattedTransaction);
        }
      } else {
        snack("Unable to get transactions. Please try again.");
      }
    } catch (err) {
      log("Error --> ", err);
      snack("Network Error", "error");
    }

    setOpenBackdrop(false);
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ marginTop: 10 }}>
        <Breadcrumb
          leadingIcon={
            <InsertChartIcon style={{ fontSize: 32 }} color="primary" />
          }
          title={`Transaction Report`}
        />
      </div>
      <Grid style={{ marginTop: "1rem" }} container>
        <Grid item xs={12}>
          {!openBackdrop ? (
            <MUIDataTable
              className={classes.table}
              options={tableOptions}
              data={transactions}
              columns={tableColumns}
              title={
                <Grid container>
                  <Typography style={{ fontWeight: "bold" }}>
                    TRANSACTION REPORTS
                  </Typography>
                </Grid>
              }
            />
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Transactions;

/**
 *
 * @param {string} status - Transactions status
 * @return {string} returns a formatted string of the status [Failed | Successful | Status Not Found]
 */
const formatStatus = (status) => {
  switch (status) {
    case "Pending":
      return "Pending";
    case "authorization_expired":
    case "settlement_declined":
    case "failed":
    case "gateway_rejected":
    case "voided":
    case "processor_declined":
      return "Failed";
    case "authorized":
    case "authorizing":
    case "settlement_pending":
    case "settlement_confirmed":
    case "settled":
    case "submitted_for_settlement":
      return "Successful";
    default:
      return "Status Not Found";
  }
};

/**
 *
 * @param {Date} date
 * @param {('12hr'|'24hr')} timeFormat
 * @return {string} Formatted Date - MM/DD/YYYY kk:mm:ss | ''
 */
const formatTime = (date, timeFormat) => {
  if (!date) return "";
  let dateFormat = "MM/DD/YYYY";
  if (timeFormat === "12hr") {
    dateFormat += " hh:mm a";
  } else {
    dateFormat += "HH:mm";
  }

  return moment(date).format(dateFormat);
};
