import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  xAccelRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const XAccelThresholds = (props) => {
  const theme = useTheme();

  const {
    xAccelThresholdLow,
    setXAccelThresholdLow,
    xAccelThresholdHigh,
    setXAccelThresholdHigh,
    enableXAccelAlert,
    setEnableXAccelAlert,
    xAccelRangeType,
    setXAccelRangeType,
  } = props;
  const classes = useStyles();

  const Marks = [
    {
      value: -50,
      label: "-50g",
    },

    {
      value: 50,
      label: "50g",
    },
  ];

  const valuetext = (value) => {
    return `${value}g`;
  };

  const handleToggleEnable = (e) => {
    setEnableXAccelAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (xAccelRangeType === 1 || xAccelRangeType === 2) {
      setXAccelThresholdHigh(v);
    } else {
      setXAccelThresholdLow(v[0]);
      setXAccelThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableXAccelAlert ? null : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.xAccelRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography className={classes.title} id="xaccel-range-slider">
                X-Accel
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableXAccelAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={xAccelRangeType}
                setRangeType={setXAccelRangeType}
                enabled={enableXAccelAlert}
              />
            </Grid>
            <Grid className={classes.slider} item>
              <Slider
                aria-labelledby="xaccel-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  xAccelRangeType === 1 || xAccelRangeType === 2
                    ? xAccelThresholdHigh
                      ? xAccelThresholdHigh
                      : 50
                    : [
                        xAccelThresholdLow ? xAccelThresholdLow : 0,
                        xAccelThresholdHigh ? xAccelThresholdHigh : 100,
                      ]
                }
                track={
                  xAccelRangeType === 1 || xAccelRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={Marks}
                min={-50}
                max={50}
                disabled={!enableXAccelAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default XAccelThresholds;
