import React, { useState, useEffect } from "react";
import { Badge, makeStyles, withStyles } from "@material-ui/core";

import SideBatterySVG from "../../../icons/SideBatterySVG";

const useStyles = makeStyles((theme) => ({
  statusCircle: {
    right: "0%",
    bottom: "49%",
  },
}));
/**
 * Device Card
 * @param {Object} props
 * @props
 * batteryVolt: number
 * batteryAllowed: bool
 * lastMessageTime: timestamp,
 */
export default function DeviceStatusIcon(props) {
  const {
    batteryVolt, // Voltage of the battery
    batteryAllowed,
    lastMessageTime,
    children,
  } = props;

  const [messageTimeOk, setMessageTimeOk] = useState();

  useEffect(() => {
    const currTime = new Date();
    const messageTime = currTime.getTime() - lastMessageTime * 1000;
    setMessageTimeOk(messageTime > 0 && messageTime <= 300000);
  }, [lastMessageTime]);

  const classes = useStyles();

  const StyledBadge = withStyles((theme) => ({
    anchorOriginBottomRightCircle: "", // To remove error from console
    badge: {
      width: 16,
      height: 16,
      borderRadius: "50%",
      backgroundColor: messageTimeOk ? "#59C754" : "#FF6A6A",
      color: messageTimeOk ? "#59C754" : "#FF6A6A",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": ((batteryAllowed &&
        batteryVolt &&
        batteryVolt > 2.76 &&
        messageTimeOk) ||
        !batteryAllowed) && {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": messageTimeOk && {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <StyledBadge
      classes={{
        anchorOriginBottomRightCircle: classes.statusCircle,
      }}
      overlap="circle"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant={
        batteryAllowed && batteryVolt && batteryVolt <= 2.76 && messageTimeOk
          ? "standard"
          : "dot"
      }
      badgeContent={
        batteryAllowed &&
        batteryVolt &&
        batteryVolt <= 2.76 && <SideBatterySVG />
      }
    >
      {children}
    </StyledBadge>
  );
}
