import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5
  }
}));

const SkeletonForm = ({styles}) => {
  const classes = useStyles();
  return (
    <div style={{...styles}}>
      <Skeleton className={classes.root} variant="rect" animation="wave" />
    </div>
  );
};

export default SkeletonForm;
