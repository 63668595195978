import React, { useEffect, useContext, useState } from "react";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { useTheme } from "@material-ui/core/styles";
import GatewaySelect from "../common/GatewaySelect";
import LocationSelect from "../common/LocationSelect";
import SensorSelect from "../common/SensorSelect";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";
import moment from "moment-timezone";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import MUIDataTable from "mui-datatables";
import { A } from "hookrouter";
import Breadcrumb from "../Generic/BreadCrumb";

import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Temperature from "temperature-js";
import { AppContext, AppProvider } from "../../AppContext";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};
const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3, 4.5, 2, 4),
    // margin: theme.spacing(1, 1),
    borderRadius: "5px",
  },
  titleContainer: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  cardTitle: {
    textTransform: "uppercase",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  underLine: {
    backgroundColor: theme.palette.primary.light,
    height: "2px",
    width: "3rem",
  },
  icon: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  numberStyles: {
    color: textColor({ theme }),
    whiteSpace: "nowrap",
    fontSize: 55,
  },
  typography: {
    textAlign: "center",
    font: "Semibold 12px/25px Open Sans",
    letterSpacing: "0px",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
    opacity: "1",
    marginBottom: 6,
    marginTop: 6,
  },
  table: {
    margin: theme.spacing(4, 0),
    "& tr:nth-of-type(odd)": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
    },
    "& tr:nth-of-type(even)": {
      backgroundColor:
        theme.palette.type === "light" ? "white" : theme.palette.grey[800],
    },
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ReportSelectedView = (props) => {
  const log = window.log('ReportSelectedView');

  const theme = useTheme();

  const { category } = props;
  let pageTitle = category.replace(/-/g, " ");
  if (pageTitle === "Door Detection") {
    pageTitle = "Open/Closed Door";
  }
  const classes = useStyles();
  const [locationId, setLocationId] = React.useState("");
  const [locationSelectionError, setLocationSelectionError] = React.useState(
    false
  );

  const [gatewayId, setGatewayId] = React.useState("");
  const [gatewaySelectionError, setGatewaySelectionError] = React.useState(
    false
  );

  const [sensorId, setSensorId] = React.useState("");
  const [sensorSelectionError, setSensorSelectionError] = React.useState(false);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date().setHours(new Date().getHours() - 6)
  ); //Set 6 hours prior to current date
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const [reportGenerated, setReportGenerated] = React.useState(false);
  const [crosshairValues, setCrosshairValues] = React.useState([]);

  const { appState, setLogoutState, snack } = useContext(AppContext);

  const [dataLine, setDataLine] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [barGraphKeys, setBarGraphKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [markers, setMarker] = useState([]);

  const [highestValue, setHighestValue] = useState(null);
  const [lowestValue, setLowestValue] = useState(null);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const isABooleanType =
    pageTitle.toUpperCase() === "DOOR DETECTION" ||
    pageTitle.toUpperCase() === "PANIC BUTTON";

  /**
   * table options
   */
  const tableOptions = {
    // onRowClick: (selectedRow) => navigate(`/alert/${selectedRow[1]}`),
    selectableRows: "none",
    print: false,
    download: true,
    jumpToPage: true,
    responsive: "scrollMaxHeight",
    onDownload: (buildHead, buildBody, columns, data) => {
      const degreeSymbol = "\u00B0";
      const minusSymbol = "\u2212";
      let body = [];
      for (let row of data) {
        //Temperature
        let temp = row.data[4];
        const tempMeasurement = temp.slice(-1);
        let tempWithoutMeasurement = temp.slice(0, temp.length - 2);
        let formattedTemp = "";
        if (tempWithoutMeasurement.slice(0, 1) === "-") {
          formattedTemp =
            minusSymbol +
            tempWithoutMeasurement.slice(1) +
            degreeSymbol +
            tempMeasurement;
        } else {
          formattedTemp =
            tempWithoutMeasurement + degreeSymbol + tempMeasurement;
        }

        //Humidity
        let humidity = row.data[5];
        let formattedHumidity = "";
        if (humidity.slice(0, 1) === "-") {
          formattedHumidity = minusSymbol + humidity.slice(1);
        } else {
          formattedHumidity = humidity;
        }
        //Pressure
        let pressure = row.data[6];
        let formattedPressure = "";
        if (pressure.slice(0, 1) === "-") {
          formattedPressure = minusSymbol + pressure.slice(1);
        } else {
          formattedPressure = pressure;
        }
        //Acceleration
        let acceleration = row.data[7];
        let formattedAcceleration = "";
        if (acceleration.slice(0, 1) === "-") {
          formattedAcceleration = minusSymbol + acceleration.slice(1);
        } else {
          formattedAcceleration = acceleration;
        }
        //Light
        //Proximity
        let proximity = row.data[10];
        let formattedProximity = "";
        if (proximity.slice(0, 1) === "-") {
          formattedProximity = minusSymbol + proximity.slice(1);
        } else {
          formattedProximity = proximity;
        }
        //Battery
        let battery = row.data[11];
        let formattedBattery = "";
        if (battery.slice(0, 1) === "-") {
          formattedBattery = minusSymbol + battery.slice(1);
        } else {
          formattedBattery = battery;
        }

        row.data[4] = formattedTemp;
        row.data[5] = formattedHumidity;
        row.data[6] = formattedPressure;
        row.data[7] = formattedAcceleration;
        row.data[10] = formattedProximity;
        row.data[11] = formattedBattery;
        body.push(row);
      }

      return "\uFEFF" + buildHead(columns) + buildBody(body);
    },
    downloadOptions: {
      filename: `misensors_report_${category}.csv`,
      separator: ",",
    },
  };

  const tableColumns = [
    {
      name: "date",
      label: "Date",
    },
    {
      name: "location",
      label: "Location",
    },
    {
      name: "gateway",
      label: "Gateway",
    },
    {
      name: "sensor",
      label: "Sensor",
    },
    {
      name: "temperature",
      label: "Temperature",
    },
    {
      name: "humidity",
      label: "Humidity",
    },
    {
      name: "pressure",
      label: "Pressure",
    },
    {
      name: "acceleration",
      label: "Acceleration",
    },
    {
      name: "light",
      label: "Light",
    },
    {
      name: "panic",
      label: "Panic",
    },
    {
      name: "door",
      label: "Door",
    },
    {
      name: "proximity",
      label: "Proximity",
    },
    {
      name: "battery",
      label: "Battery",
    },
  ];

  const handleGenerateReport = () => {
    if (!sensorId) {
      // setGatewaySelectionError(!gatewayId);
      setSensorSelectionError(!sensorId);
      snack(`Please double check highlighted fields.`, "error");
    } else {
      switch (pageTitle.toUpperCase()) {
        case "TEMPERATURE":
          fetchData("temperature");
          break;
        case "HUMIDITY":
          fetchData("humidity");
          break;
        case "PRESSURE":
          fetchData("pressure");
          break;
        case "MOTION DETECTION":
          fetchData("acceleration");
          break;
        case "LIGHT DETECTION":
          fetchData("light");
          break;
        case "PANIC BUTTON":
          fetchData("panic");
          break;
        case "OPEN/CLOSED DOOR":
          fetchData("door");
          break;
        case "PROXIMITY SENSOR":
          fetchData("proximity");
          break;
        case "BATTERY":
          fetchData("battery");
          break;
        default:
          log("Report Not Available");
      }
      // setGatewaySelectionError(!gatewayId);
      setSensorSelectionError(!sensorId);
    }
  };

  const fetchData = async (measurementType) => {
    log("fetching data...", measurementType);
    try {
      setOpenBackdrop(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/report/data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            locationId,
            gatewayId,
            sensorId,
          }),
        }
      );

      const json = await response.json();
      if (json.success && json.data.result) {
        if (!json.data.result.length) {
          //NO DATA FOUND
          setOpenBackdrop(false);
          return snack("No data found", "error");
        }
        let tableRowData = [];
        let barGraphData = {};
        let barGraphSensorKey = [];
        let graphData = {};
        let graphDataId = "";
        let lowAlertMarker = {
          value: null,
          alertName: "",
        };
        let highAlertMarker = {
          value: null,
          alertName: "",
        };
        let highValue = null;
        let lowValue = null;

        for (let data of json.data.result) {
          let {
            locationName,
            gatewayName,
            sensorName,
            sensorId,
            sensorMAC,
            dateReceived,
            temperature,
            humidity,
            pressure,
            acceleration,
            accelerationX,
            accelerationY,
            accelerationZ,
            light,
            doorPanicAlert,
            doorPanicSource,
            currentDoorState,
            currentPanicState,
            batteryVoltage,
            sensorAlertCondition,
            rssi,
          } = data;

          if (!graphData[sensorId]) {
            graphData[sensorId] = {
              id: sensorName,
              data: [],
            };
          }

          let parsedSensorAlertCondition = null;
          if (sensorAlertCondition) {
            parsedSensorAlertCondition = JSON.parse(sensorAlertCondition);
          }

          dateReceived = moment(dateReceived).format("YYYY-MM-DD HH:mm");

          if (
            appState.auth.userInfo &&
            appState.auth.userInfo.themePreferences &&
            appState.auth.userInfo.themePreferences.measurement === "imperial"
          ) {
            temperature = Number(temperature) * (9 / 5) + 32;
          } else {
            temperature = Number(temperature);
          }
          humidity = Number(humidity);
          pressure = Number(pressure);
          acceleration = Number(acceleration);
          accelerationX = Number(accelerationX);
          accelerationY = Number(accelerationY);
          accelerationZ = Number(accelerationZ);
          light = Number(light);
          batteryVoltage = Number(batteryVoltage);
          rssi = Number(rssi);

          if (measurementType === "temperature") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Temperature";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: temperature.toFixed(1),
              });
            }

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.temperature
            ) {
              const rangeType =
                parsedSensorAlertCondition.temperature.temperatureRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const tempAlertHigh =
                appState.auth.userInfo &&
                appState.auth.userInfo.themePreferences &&
                appState.auth.userInfo.themePreferences.measurement ===
                  "imperial"
                  ? Number(
                      parsedSensorAlertCondition.temperature
                        .temperatureThresholdHigh
                    ) *
                      (9 / 5) +
                    32
                  : Number(
                      parsedSensorAlertCondition.temperature
                        .temperatureThresholdHigh
                    );
              const tempAlertLow =
                appState.auth.userInfo &&
                appState.auth.userInfo.themePreferences &&
                appState.auth.userInfo.themePreferences.measurement ===
                  "imperial"
                  ? Number(
                      parsedSensorAlertCondition.temperature
                        .temperatureThresholdLow
                    ) *
                      (9 / 5) +
                    32
                  : Number(
                      parsedSensorAlertCondition.temperature
                        .temperatureThresholdLow
                    );
              const highLowRange = getHighLowRange(
                tempAlertLow,
                tempAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;

              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (temperature > highValue) {
                highValue = temperature;
              }

              if (temperature < lowValue) {
                lowValue = temperature;
              }
            } else {
              //If no alert conditions, get highest/lowest value from sensor data
              if (highValue === null || lowValue === null) {
                highValue = temperature;
                lowValue = temperature;
              }
              if (temperature > highValue) {
                highValue = temperature;
              }
              if (temperature < lowValue) {
                lowValue = temperature;
              }
            }
          } else if (measurementType === "humidity") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Humidity";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: humidity.toFixed(1),
              });
            }
            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.humidity
            ) {
              const rangeType =
                parsedSensorAlertCondition.humidity.humidityRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const humidityAlertHigh = Number(
                parsedSensorAlertCondition.humidity.humidityThresholdHigh
              );
              const humidityAlertLow = Number(
                parsedSensorAlertCondition.humidity.humidityThresholdLow
              );
              const highLowRange = getHighLowRange(
                humidityAlertLow,
                humidityAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;

              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (humidity > highValue) {
                highValue = humidity;
              }

              if (humidity < lowValue) {
                lowValue = humidity;
              }
            } else {
              //If no alert conditions, get highest/lowest value from sensor data
              if (highValue === null || lowValue === null) {
                highValue = humidity;
                lowValue = humidity;
              }
              if (humidity > highValue) {
                highValue = humidity;
              }
              if (humidity < lowValue) {
                lowValue = humidity;
              }
            }
          } else if (measurementType === "pressure") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Pressure";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: pressure.toFixed(1),
              });
            }

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.pressure
            ) {
              const rangeType =
                parsedSensorAlertCondition.pressure.pressureRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const pressureAlertHigh = Number(
                parsedSensorAlertCondition.pressure.pressureThresholdHigh
              );
              const pressureAlertLow = Number(
                parsedSensorAlertCondition.pressure.pressureThresholdLow
              );
              const highLowRange = getHighLowRange(
                pressureAlertLow,
                pressureAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;
              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (pressure > highValue) {
                highValue = pressure;
              }

              if (pressure < lowValue) {
                lowValue = pressure;
              }
            } else {
              if (highValue === null || lowValue === null) {
                highValue = pressure;
                lowValue = pressure;
              }
              if (pressure > highValue) {
                highValue = pressure;
              }
              if (pressure < lowValue) {
                lowValue = pressure;
              }
            }
          } else if (measurementType === "acceleration") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Acceleration";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: acceleration.toFixed(1),
              });
            }

            if (highValue === null || lowValue === null) {
              highValue = acceleration;
              lowValue = acceleration;
            }
            if (acceleration > highValue) {
              highValue = acceleration;
            }
            if (acceleration < lowValue) {
              lowValue = acceleration;
            }
          } else if (measurementType === "light") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Light";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: light.toFixed(1),
              });
            }

            //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
            if (
              parsedSensorAlertCondition &&
              parsedSensorAlertCondition.light
            ) {
              const rangeType =
                parsedSensorAlertCondition.light.lightRangeType;
              const alertName = parsedSensorAlertCondition.alertName;
              const lightAlertHigh = Number(
                parsedSensorAlertCondition.light.lightThresholdHigh
              );
              const lightAlertLow = Number(
                parsedSensorAlertCondition.light.lightThresholdLow
              );
              const highLowRange = getHighLowRange(
                lightAlertLow,
                lightAlertHigh,
                rangeType,
                lowAlertMarker,
                highAlertMarker,
                alertName
              );
              highAlertMarker = highLowRange.highAlertMarker;
              lowAlertMarker = highLowRange.lowAlertMarker;
              //Get highest/lowest value between alert and sensor data
              if (lowValue === null && highValue === null) {
                highValue = highAlertMarker.value;
                lowValue = lowAlertMarker.value;
              }

              if (light > highValue) {
                highValue = light;
              }

              if (light < lowValue) {
                lowValue = light;
              }
            } else {
              if (highValue === null || lowValue === null) {
                highValue = light;
                lowValue = light;
              }
              if (light > highValue) {
                highValue = light;
              }
              if (light < lowValue) {
                lowValue = light;
              }
            }
          } else if (measurementType === "panic") {
            if (doorPanicSource == 5 && doorPanicAlert == 2) {
              graphDataId = "Panic";

              if (!barGraphData[dateReceived]) {
                barGraphData[dateReceived] = {};
                barGraphData[dateReceived][sensorMAC] = 1;
              } else {
                barGraphData[dateReceived][sensorMAC] = 1;
              }
            }
          } else if (measurementType === "door") {
            if (doorPanicSource == 4) {
              if (doorPanicAlert) {
                graphDataId = "Door";

                if (!barGraphData[dateReceived]) {
                  barGraphData[dateReceived] = {};
                  barGraphData[dateReceived][sensorMAC] = 1;
                } else {
                  barGraphData[dateReceived][sensorMAC] = 1;
                }
              }
            }
          } else if (measurementType === "proximity") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Proximity";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: rssi.toFixed(1),
              });
            }

            if (highValue === null || lowValue === null) {
              highValue = rssi;
              lowValue = rssi;
            }
            if (rssi > highValue) {
              highValue = rssi;
            }
            if (rssi < lowValue) {
              lowValue = rssi;
            }
          } else if (measurementType === "battery") {
            if (doorPanicSource === "0" || doorPanicAlert === "0") {
              graphDataId = "Battery";
              graphData[sensorId].data.push({
                x: dateReceived,
                y: batteryVoltage.toFixed(2),
              });
            }

            if (highValue === null || lowValue === null) {
              highValue = batteryVoltage;
              lowValue = batteryVoltage;
            }
            if (batteryVoltage > highValue) {
              highValue = batteryVoltage;
            }
            if (batteryVoltage < lowValue) {
              lowValue = batteryVoltage;
            }
          }

          //Save keys for bar graph chart
          if (measurementType === "door" || measurementType === "panic") {
            if (!barGraphSensorKey.includes(sensorMAC)) {
              barGraphSensorKey.push(sensorMAC);
            }
          }

          tableRowData.push({
            date:
              appState.auth.userInfo &&
              appState.auth.userInfo.themePreferences &&
              appState.auth.userInfo.themePreferences.timeFormat === "12hr"
                ? (dateReceived = moment(dateReceived).format(
                    "YYYY-MM-DD hh:mm a"
                  ))
                : (dateReceived = moment(dateReceived).format(
                    "YYYY-MM-DD kk:mm"
                  )),
            location: locationName,
            gateway: gatewayName,
            sensor: sensorName,
            temperature:
              appState.auth.userInfo &&
              appState.auth.userInfo.themePreferences &&
              appState.auth.userInfo.themePreferences.measurement === "imperial"
                ? temperature.toFixed(1) + " °F"
                : temperature.toFixed(1) + " °C",
            humidity: Number(humidity).toFixed(1) + "%",
            pressure: Number(pressure).toFixed(1) + " hPa",
            acceleration: Number(acceleration).toFixed(1) + "g",
            light: Number(light).toFixed(2) + " lux",
            panic: currentPanicState ? "Active" : "Inactive",
            door: currentDoorState ? "Open" : "Closed",
            battery: Number(batteryVoltage).toFixed(2) + "V",
            proximity: Number(rssi).toFixed(1) + " dBm",
          });
          //End of loop
        }

        if (measurementType === "door" || measurementType === "panic") {
          let formattedBarGraphData = [];
          for (const date in barGraphData) {
            let preFormattedData = {};
            preFormattedData.date = date;
            for (const sensor in barGraphData[date]) {
              preFormattedData[sensor] = 1;
            }
            formattedBarGraphData.push(preFormattedData);
          }
          setBarGraphKeys(barGraphSensorKey);
          setDataBar(formattedBarGraphData);
        } else {
          let formattedGraphData = [];
          for (const sensor in graphData) {
            formattedGraphData.push(graphData[sensor]);
          }
          setDataLine(formattedGraphData);
        }

        let markerList = [];
        if (
          highAlertMarker.value &&
          lowAlertMarker.value &&
          highAlertMarker.value === lowAlertMarker.value
        ) {
          const markerContainer = marker(
            highAlertMarker.value,
            // highAlertMarker.alertName
            `Alert Setting`
          );
          markerList.push(markerContainer);
        } else {
          if (lowAlertMarker.value) {
            const markerContainer = marker(
              lowAlertMarker.value,
              // lowAlertMarker.alertName
              `Alert Setting`
            );
            markerList.push(markerContainer);
          }

          if (highAlertMarker.value) {
            const markerContainer = marker(
              highAlertMarker.value,
              // highAlertMarker.alertName,
              `Alert Setting`
            );
            markerList.push(markerContainer);
          }
        }

        setHighestValue(Number(highValue));
        setLowestValue(Number(lowValue));
        setTableData(tableRowData);
        setMarker(markerList);
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      log("---", err);
      snack('Network Error', 'error');
    }
    setReportGenerated(true);
    setOpenBackdrop(false);
  };

  const getHighLowRange = (
    alertLow,
    alertHigh,
    rangeType,
    lowAlertMarker,
    highAlertMarker,
    alertName
  ) => {
    //Range type 1: above value, 2: below value, 3: outside range, 4: inside range
    if (rangeType === 1 || rangeType === 2) {
      if (highAlertMarker.value === null) {
        highAlertMarker.value = alertHigh;
        highAlertMarker.alertName = alertName;
        lowAlertMarker.value = alertHigh;
        lowAlertMarker.alertName = alertName;
      } else if (alertHigh > highAlertMarker.value) {
        highAlertMarker.value = alertHigh;
        highAlertMarker.alertName = alertName;
      } else if (alertHigh < lowAlertMarker.value) {
        lowAlertMarker.value = alertHigh;
        lowAlertMarker.alertName = alertName;
      }
    } else if (rangeType === 3 || rangeType === 4) {
      //High range
      if (highAlertMarker.value === null || alertHigh > highAlertMarker.value) {
        highAlertMarker.value = alertHigh;
        highAlertMarker.alertName = alertName;
      }
      //Low range
      if (lowAlertMarker.value === null || alertLow < lowAlertMarker.value) {
        lowAlertMarker.value = alertLow;
        lowAlertMarker.alertName = alertName;
      }
    }

    return {
      highAlertMarker,
      lowAlertMarker,
    };
  };

  useEffect(() => {
    // handleGenerateReport();
  }, [locationId, gatewayId, sensorId]);

  useEffect(() => {
    if (!tableData.length && (!dataLine.length || !dataBar.length)) return;
    if (
      appState.auth.userInfo &&
      appState.auth.userInfo.themePreferences &&
      appState.auth.userInfo.themePreferences.measurement
    ) {
      handleGenerateReport();
    }
  }, [appState]);

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/reports/all">
        Reports
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ marginTop: 10 }}>
        <Breadcrumb
          breadcrumbs={breadcrumbs}
          leadingIcon={
            <InsertChartIcon style={{ fontSize: 32 }} color="primary" />
          }
          title={`${pageTitle} Report`}
        />
      </div>
      <Grid style={{ marginTop: "1rem" }} container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {/* Header */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box fontWeight="fontWeightBold" className={classes.typography}>
                  {pageTitle.toUpperCase()} REPORT
                </Box>
                <Box className={classes.underLine} />
              </Box>
              {/* Selectors */}
              <Box>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Grid item xs={12} sm={4} lg={2}>
                    <LocationSelect
                      returnValue={locationId}
                      setReturnValue={setLocationId}
                      width={"100%"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={2}>
                    <GatewaySelect
                      error={gatewaySelectionError}
                      returnValue={gatewayId}
                      setReturnValue={setGatewayId}
                      width={"100%"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} lg={2}>
                    <SensorSelect
                      error={sensorSelectionError}
                      returnValue={sensorId}
                      setReturnValue={setSensorId}
                      width={"100%"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <DateTimePicker
                        variant="outlined"
                        label="Start Date/Time"
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}
                        disableFuture={true}
                        inputVariant="outlined"
                      /> */}
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        ampm={
                          appState &&
                          appState.auth &&
                          appState.auth.userInfo &&
                          appState.auth.userInfo.themePreferences &&
                          appState.auth.userInfo.themePreferences.timeFormat ===
                            "12hr"
                            ? true
                            : false
                        }
                        label="Start Date/Time"
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}
                        onError={log}
                        disableFuture={true}
                        // minDate={new Date("2018-01-01T00:00")}
                        //Remove 'a' for 24hr
                        format={
                          appState &&
                          appState.auth &&
                          appState.auth.userInfo &&
                          appState.auth.userInfo.themePreferences &&
                          appState.auth.userInfo.themePreferences.timeFormat ===
                            "12hr"
                            ? "yyyy/MM/dd hh:mm a"
                            : "yyyy/MM/dd kk:mm"
                        }
                        // format="yyyy/MM/dd hh:mm a"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* <DateTimePicker
                        label="End Date/Time"
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedEndDate}
                        onChange={setSelectedEndDate}
                        disableFuture={true}
                        inputVariant="outlined"
                      /> */}
                      <KeyboardDateTimePicker
                        inputVariant="outlined"
                        ampm={
                          appState &&
                          appState.auth &&
                          appState.auth.userInfo &&
                          appState.auth.userInfo.themePreferences &&
                          appState.auth.userInfo.themePreferences.timeFormat ===
                            "12hr"
                            ? true
                            : false
                        }
                        label="End Date/Time"
                        autoOk={true}
                        fullWidth={true}
                        className={classes.textField}
                        value={selectedEndDate}
                        onChange={setSelectedEndDate}
                        onError={log}
                        disableFuture={true}
                        // minDate={new Date("2018-01-01T00:00")}
                        //Remove 'a' for 24hr
                        // format="yyyy/MM/dd HH:mm"
                        format={
                          appState &&
                          appState.auth &&
                          appState.auth.userInfo &&
                          appState.auth.userInfo.themePreferences &&
                          appState.auth.userInfo.themePreferences.timeFormat ===
                            "12hr"
                            ? "yyyy/MM/dd hh:mm a"
                            : "yyyy/MM/dd kk:mm"
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Box>
              {/* Button */}
              <Box display="flex" justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleGenerateReport}
                >
                  Generate Report
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {reportGenerated && isABooleanType && (
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ height: "50vh" }}>
                <ResponsiveBar
                  data={dataBar}
                  keys={barGraphKeys}
                  groupMode="grouped"
                  indexBy="date"
                  margin={{ top: 50, right: 60, bottom: 90, left: 60 }}
                  padding={0.3}
                  colors={theme.palette.primary.main}
                  borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  labelFormat={(v) => ""}
                  tooltipFormat={(value) => "Active"}
                  xScale={{
                    type: "time",
                    format: "%Y-%m-%d %H:%M",
                    useUTC: false,
                    precision: "minute",
                  }}
                  xFormat="time:%Y-%m-%d %H:%M"
                  axisBottom={{
                    format: (d) => moment(d).format("MMM D, kk:mm"),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: "Date/Time",
                    legendPosition: "middle",
                    legendOffset: 70,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Activations",
                    legendPosition: "middle",
                    legendOffset: -40,
                    format: (value) =>
                      value === 1 ? "Active" : value === 0 ? "Inactive" : "",
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  enableArea={false}
                  useMesh={false}
                  defs={[
                    linearGradientDef("gradientA", [
                      { offset: 0, color: "inherit" },
                      { offset: 100, color: "inherit", opacity: 0.3 },
                    ]),
                  ]}
                  fill={[{ match: "*", id: "gradientA" }]}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: 95,
                      itemsSpacing: 30,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.75,
                      symbolSize: 8,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                      itemTextColor:
                        appState &&
                        appState.auth &&
                        appState.auth.userInfo &&
                        appState.auth.userInfo.themePreferences &&
                        appState.auth.userInfo.themePreferences.darkMode
                          ? "white"
                          : "black",
                    },
                  ]}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                  theme={
                    appState &&
                    appState.auth &&
                    appState.auth.userInfo &&
                    appState.auth.userInfo.themePreferences &&
                    appState.auth.userInfo.themePreferences.darkMode && {
                      axis: {
                        ticks: {
                          text: {
                            fill: "white",
                          },
                        },
                        legend: {
                          text: {
                            fill: "white",
                          },
                        },
                      },
                      tooltip: {
                        container: {
                          color: "black",
                        },
                      },
                    }
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        {reportGenerated && !isABooleanType && (
          <Grid item xs={12}>
            <Card>
              <CardContent style={{ height: "50vh" }}>
                <ResponsiveLine
                  data={dataLine}
                  markers={markers}
                  margin={{ top: 50, right: 85, bottom: 90, left: 60 }}
                  xScale={{
                    type: "time",
                    format: "%Y-%m-%d %H:%M",
                    useUTC: false,
                    precision: "minute",
                  }}
                  xFormat="time:%Y-%m-%d %H:%M"
                  yScale={{
                    type: "linear",
                    min: lowestValue,
                    max: highestValue,
                    stacked: false,
                    reverse: false,
                  }}
                  curve="linear"
                  axisTop={null}
                  axisRight={null}
                  areaBaselineValue={lowestValue}
                  itemTextColor={"#fffff"}
                  axisBottom={{
                    format: "%b %d",
                    tickRotation: -45,
                    tickValues: "every day",
                    legend: "Date/Time",
                    legendOffset: 50,
                    legendPosition: "middle",
                  }}
                  axisLeft={{
                    orient: "left",
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: pageTitle,
                    legendOffset: -45,
                    legendPosition: "middle",
                  }}
                  colors={theme.palette.primary.main}
                  lineWidth={6}
                  pointSize={10}
                  pointColor={"#fff"}
                  pointBorderWidth={3}
                  pointBorderColor={{ from: "serieColor", modifiers: [] }}
                  pointLabel={function (e) {
                    return e.x + ": " + e.y;
                  }}
                  pointLabelYOffset={-12}
                  enableArea={true}
                  useMesh={true}
                  enableSlices="x"
                  sliceTooltip={(val) => {
                    const { slice } = val;
                    return (
                      <div
                        style={{
                          color: "black",
                          backgroundColor: "white",
                          padding: 10,
                          boxShadow: "2px 2px #e1e1e1",
                        }}
                      >
                        {slice.points
                          .filter((p) => p.value !== null)
                          .map((p) => [
                            <div key={p.serieId}>
                              <Chip key={p.serieId} color={p.serieColor} />
                              <div>Date: {p.data.xFormatted}</div>
                              <div>Value: {p.data.yFormatted}</div>
                            </div>,
                          ])}
                      </div>
                    );
                  }}
                  defs={[
                    linearGradientDef("gradientA", [
                      { offset: 0, color: "inherit" },
                      { offset: 100, color: "inherit", opacity: 0 },
                    ]),
                  ]}
                  fill={[{ match: "*", id: "gradientA" }]}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: 90,
                      itemsSpacing: 30,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemDirection: "left-to-right",
                      itemOpacity: 0.75,
                      symbolSize: 8,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                      itemTextColor:
                        appState &&
                        appState.auth &&
                        appState.auth.userInfo &&
                        appState.auth.userInfo.themePreferences &&
                        appState.auth.userInfo.themePreferences.darkMode
                          ? "white"
                          : "black",
                    },
                  ]}
                  labelTextColor="black"
                  theme={
                    appState &&
                    appState.auth &&
                    appState.auth.userInfo &&
                    appState.auth.userInfo.themePreferences &&
                    appState.auth.userInfo.themePreferences.darkMode && {
                      axis: {
                        ticks: {
                          text: {
                            fill: "white",
                          },
                        },
                        legend: {
                          text: {
                            fill: "white",
                          },
                        },
                      },
                    }
                  }
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        {reportGenerated && (
          <Grid item xs={12}>
            <MUIDataTable
              className={classes.table}
              options={tableOptions}
              data={tableData}
              columns={tableColumns}
              title={
                <Grid container>
                  <Typography style={{ fontWeight: "bold" }}>
                    {pageTitle.toUpperCase()} REPORTS - DATE RANGE
                  </Typography>
                </Grid>
              }
            ></MUIDataTable>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

const TileComponent = (props) => {
  const {
    title, // Title of the tile
    subtitle, // Subtitle of the tile
    color, // Color for the underline
    icon, // Icon in the card
  } = props;

  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box fontWeight="fontWeightBold" className={classes.typography}>
            {title}
          </Box>
          <Box
            className={classes.underLine}
            style={{ backgroundColor: color }}
          />
          <Box fontWeight="fontWeightBold" className={classes.typography}>
            {subtitle}
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          {icon}
        </Box>
      </CardContent>
    </Card>
  );
};
export default ReportSelectedView;

const marker = (value, name) => {
  return {
    axis: "y",
    value: value,
    lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
    legend: name,
    legendPosition: "right",
    textStyle: {
      fill: "red",
      fontSize: 12,
    },
  };
};

const Chip = ({ color }) => (
  <span
    style={{
      display: "block",
      width: "12px",
      height: "12px",
      background: color,
    }}
  />
);
