import React, { useState, useEffect, useContext } from "react";
import { AppContext, AppProvider } from "./../../AppContext";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Badge,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  IconButton,
  TextField,
  Container,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { navigate, A } from "hookrouter";
import _ from "lodash";

import { fetchOne, fetchAll, deleteOne } from "../common/api";
import uploadImage from "../common/api/uploadImage";
import EditableTextField from "../common/EditableTextField";
import { setQueryParams } from "hookrouter";
import GatewaySelect from "../common/GatewaySelect";
import LiveSensorAccordian from "../common/LiveSensorAccordian";
import SensorMessagesAccordian from "../common/SensorMessagesAccordian";
import Breadcrumb from "../Generic/BreadCrumb";
import LastAlertsList from "../Dashboard/LastAlertsList";
import DeleteDialog from "../common/DeleteDialog";
import DeleteDeviceFormModal from "./DeleteDeviceFormModal";
import SensorIcon from "../../img/icons/SensorIcon";
import Tile from "../Dashboard/Tile.js";
import SingleCardDevice from "./SingleCardDevice";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import BatterySVG from "../../icons/BatterySVG";
import NotFoundPageLoggedIn from "../Auth/NotFoundPageLoggedIn";
import GatewayFormModal from "../Gateways/GatewayFormModal";
import LocationFormModal from "../Locations/LocationFormModal";
import EditSensorImageDialog from "./EditSensorImageDialog";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoadingSpinner from "../Generic/LoadingSpinner";

import socketIOClient from "socket.io-client";
import LastUpdated from "./LastUpdated";
import moment from "moment-timezone";

import DeviceFormModal from "./DeviceFormModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  gridContainer: {
    // margin: "auto",
    marginTop: "5%",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(30),
      height: theme.spacing(30),
    },
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: 0,
  },
  editAvatarIcon: {
    width: 33,
    height: 33,
    padding: 4,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "25px",
  },
  deviceImageButton: {
    border: "none",
    width: 33,
    height: 33,
    padding: 0,
    margin: 0,
    borderRadius: "25px",
  },
  deviceImageSaveButton: {
    marginRight: "auto",
    marginTop: "1rem",
  },
  backButton: {
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
    color: theme.palette.secondary.main,
  },
  buttons: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "1rem",
    width: "6rem",
  },
  deleteButtons: {
    color: theme.palette.secondary.light,
    marginTop: "1rem",
    marginLeft: "auto",
  },
  gatewayButton: {
    backgroundColor: theme.palette.secondary.main,
    marginTop: "1rem",
  },
  card: {
    marginTop: "5%",
    paddingLeft: "1rem",
    marginBottom: "1rem",
  },
  cardContent: {
    minHeight: "50vh",
  },
  titleName: {
    textAlign: "center",
    color: theme.palette.text,
    fontWeight: "bold",
    alignSelf: "center",
    margin: "auto",
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  text: {
    color: theme.palette.text,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  nested: {
    maxWidth: 275,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SingleDevice = (props) => {
  const log = window.log("SingleDevice");

  const theme = useTheme();
  const classes = useStyles();
  const {
    appState,
    setLogoutState,
    setOpenDeleteSensorModal,
    setOpenSensorModal,
    alertCount,
    snack,
  } = useContext(AppContext);

  const [state, setState] = useState({});
  const [err, setErr] = useState();

  const [editForm, setEditForm] = useState(false);
  const [editName, setEditName] = useState("");

  const [selectedGateway, setSelectedGateway] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [imgErr, setImgErr] = useState(false);
  const [file, setFile] = useState();
  const [imgFile, setImgFile] = useState();

  const [sensorImageModalState, setSensorImageModalState] = useState(false);

  const [sensorImageLocation, setSensorImageLocation] = useState("");
  const [openImageDialog, setOpenDeviceImageDialog] = useState("");
  const [sensorName, setSensorName] = useState("-");
  const [editSensorName, setEditSensorName] = useState("-");
  const [locationName, setLocationName] = useState("-");
  const [alertsArray, setAlertsArray] = useState([]);
  const [socketIoGateway, setSocketIoGateway] = useState("");

  const { id } = props;

  const [sensorData, setSensorData] = useState({});
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);

  const getDevice = async () => {
    setOpenBackdrop(true);
    try {
      const json = await fetchOne(
        "sensor",
        id,
        appState.auth.userInfo.companyId,
        appState.auth.token
      );

      // log("JSONYY", json);
      if (json.success) {
        if (json.data && json.data.sensors && json.data.sensors.length) {
          const sensorInfo = json.data.sensors[0];
          setSensorName(sensorInfo.sensorName);
          setEditSensorName(sensorInfo.sensorName);
          setLocationName(sensorInfo.sensorLocation);
          setAlertsArray(sensorInfo.sensorAlerts);

          if (sensorInfo.sensorProfile) {
            let parsedState = {
              ...sensorInfo,
              sensorProfile: JSON.parse(sensorInfo.sensorProfile).SensorProfile,
            };
            // log("Looking at the parsed state ", parsedState);
            setState(parsedState);
            setSocketIoGateway(parsedState.gatewayId);
            // log("Looking at the last sensor message ", sensorInfo.lastSensorMessage);
            // log(parsedState);
          } else {
            setState(sensorInfo);
            setSocketIoGateway(sensorInfo.gatewayId);
            // log(sensorInfo);
          }

          if (sensorInfo.sensorImageLocation) {
            setSensorImageLocation(sensorInfo.sensorImageLocation);
          }

          const sensorMessages = await getSensorMessages(id);
          if (
            !sensorMessages.success ||
            (sensorMessages.success && !sensorMessages.data.length)
          ) {
            setSensorData({});
          } else {
            const message = sensorMessages.data[0];

            setSensorData(message);
            if (
              message &&
              message.gatewayMACMessageReceivedFrom &&
              message.gatewayMACMessageReceivedFrom !== sensorInfo.gatewayId
            ) {
              setSocketIoGateway(message.gatewayMACMessageReceivedFrom);
              // NOTE May add this snack back in later
              // snack(
              //   `Last message received from Gateway ID: ${message.gatewayMACMessageReceivedFrom}`,
              //   "error"
              // );
            }
          }
        } else {
          snack(
            "Unable to retreive sensor information. Please refresh the page and try again."
          );
        }
      } else {
        setState(null);
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (error) {
      log(error);
    }
    setOpenBackdrop(false);
    setInitialPageLoad(false);
  };

  useEffect(() => {
    getDevice();
  }, []);

  useEffect(() => {
    let isUnmounting = false;
    if (state && Object.keys(state).length === 0) return;
    if (!state || !state.gatewayId) return;
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL, {
      upgrade: false,
      transports: ["websocket"],
      query: {
        sensorMAC: props.id.toLowerCase(),
      },
    });

    connectSocket(socket, isUnmounting);

    return () => {
      log("---LEAVING PAGE---");
      isUnmounting = true;
      socket.disconnect();
    };
  }, [socketIoGateway]);
  //
  const connectSocket = (socket, isUnmounting) => {
    // log("The id is " + id);
    socket.on(props.id.toLowerCase(), (data) => {
      if (!data.success) {
        snack(data.error, "error");
        return socket.disconnect();
      }
      //Door Panic Source - 4: Door, 5: Panic
      //Door Panic Alert - 2: Door Open / Panic Active, 3: Transition To Close / Panic Deactivating
      !isUnmounting &&
        setSensorData((prevState) => {
          let lastActiveDoorOpen = prevState.lastActiveDoorOpen;
          let lastActiveDoorClose = prevState.lastActiveDoorClose;
          let lastActivePanic = prevState.lastActivePanic;

          if (data.doorPanicSource === 4) {
            if (data.doorPanicAlert === 2) {
              lastActiveDoorOpen = new Date().toISOString();
            } else if (data.doorPanicAlert === 3) {
              lastActiveDoorClose = new Date().toISOString();
            }
          } else if (data.doorPanicSource === 5 && data.doorPanicAlert === 2) {
            lastActivePanic = new Date().toISOString();
          }

          return {
            ...prevState,
            acceleration: data.acceleration,
            acceleration_x: data.accelX,
            acceleration_y: data.accelY,
            acceleration_z: data.accelZ,
            battery_voltage: data.batteryVoltage,
            humidity: data.humidity,
            pressure: data.pressure,
            rssi: data.rssi,
            light: data.light,
            temperature: data.temperature,
            currentDoorState: data.currentDoorState,
            currentPanicState: data.currentPanicState,
            lastActiveDoorOpen,
            lastActiveDoorClose,
            lastActivePanic,
            socket: true,
          };
        });
    });
  };

  const getSensorMessages = async (sensorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/message/get?sensorMAC=${sensorId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );
      const json = await response.json();
      if (!json.success) {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
      return json;
    } catch (error) {
      log(error);
    }
  };

  //Fetch active alerts
  const getActiveAlerts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/sensor/get?id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await response.json();

      if (json.success) {
        if (json.data && json.data.sensors.length) {
          const sensorInfo = json.data.sensors[0];
          setAlertsArray(sensorInfo.sensorAlerts);
        }
      } else {
        throw new Error("Request failed");
      }
    } catch (err) {
      log("Unable to update active alert list");
    }
  };

  useEffect(() => {
    getActiveAlerts();
  }, [alertCount]);

  const onEditClick = () => {
    // Set input values and change to edit form
    setEditForm(!editForm);
    setEditName(state.name);
    setSelectedGateway(state.gatewayEntryId);
  };

  const updateSensorImage = async (entity, data, token) => {
    log("Inside of the updateOne function");
    log("Looking at the data ", data);
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/${entity}/editImage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await request.json();
      log("Looking at the response ", json);
      return json;
    } catch (error) {
      log(error);
    }
    log("Do nothing");
  };

  const onSubmitClick = async () => {
    // Submit changes to API and revert to plain form
    setEditForm(!editForm);

    try {
      const data = {
        ...state,
        name: editName,
        gatewayEntryId: selectedGateway,
        companyId: appState.auth.userInfo.companyId,
      };
      log("Calling update sensor image 2");
      const json = await updateSensorImage("sensor", data, appState.auth.token);
      if (json.success) {
        snack("Device information successfully updated", "success");
        getDevice();
      } else {
        //handle errs
        let errorObj = {};
        json.errors.forEach((error) => {
          // add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          // err alert
          snack(error.msg, "error");
        });
        // write new object at top level per hook rules
        setErr(errorObj);
      }
    } catch (err) {
      log(err);
    }
  };

  const onDeleteClick = async () => {
    setOpenDeleteDialog(true);
  };

  const deleteEntity = async () => {
    log("Delete entity called");
    const data = {
      ...state,
      sensorEntryId: state.sensorEntryId,
      sensorId: state.sensorId,
      companyId: appState.auth.userInfo.companyId,
    };

    const json = await deleteOne("device", data, appState.auth.token);

    if (json.success) {
      snack("Device successfully removed from the system", "success");
      setTimeout(() => navigate("/device/all", true), 750);
    } else {
      let errorObj = {};
      json.errors.forEach((error) => {
        errorObj = { ...errorObj, [error.type]: true };
        snack(error.msg, "error");
      });
      setErr(errorObj);
    }
  };

  const userAuthButtons = (
    <Grid container>
      {editForm ? (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onSubmitClick()}
        >
          Submit
        </Button>
      ) : (
        <Button
          disabled={appState.auth.userInfo.perm === 1}
          className={classes.buttons}
          variant={"contained"}
          color={"secondary"}
          onClick={() => onEditClick()}
        >
          Edit
        </Button>
      )}
      <Button
        disabled={appState.auth.userInfo.perm === 1}
        className={classes.deleteButtons}
        variant="outlined"
        onClick={() => onDeleteClick()}
      >
        Delete
      </Button>
    </Grid>
  );

  const singleFileUploadHandler = async (e) => {
    log("Inside of the single file upload handler");
    try {
      const renameText = `gateway-${state.gatewayId}sensor-${
        state.sensorId
      }-${Date.now()}`;
      const data = new FormData();

      const folderDestination = "Sensor Image";
      log("Looking at the file " + file);

      if (file) {
        log("File control structure 1");
        const response = await uploadImage(
          file,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        log("Looking at response ", response);
        if (response.error) {
          log("File control structure 2");
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            log("File control structure 3");
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          log("File control structure 4");
          // Success
          setSensorImageLocation(response.location);
          handleSaveSensorImage(response.location);
        }
      } else if (imgFile) {
        log("File control structure 5");
        const response = await uploadImage(
          imgFile,
          data,
          renameText,
          appState.auth.token,
          folderDestination
        );
        log("Looking at the response ", response);
        if (response.error) {
          log("File control structure 6");
          if ("LIMIT_FILE_SIZE" === response.error.code) {
            snack("Max size: 2MB", "error");
          } else {
            log("File control structure 7");
            // If not the given file type
            snack(response.error, "error");
          }
        } else {
          log("File control structure 8");
          // Success
          setSensorImageLocation(response.location);
          handleSaveSensorImage(response.location);
        }
      } else if (sensorImageLocation) {
        //PASS
      } else {
        //No image detected
        // log("File control structure 9");
        handleSaveSensorImage("");
        // snack("Image Removed", "success");
      }
    } catch (error) {
      log("File control structure 1");
      log(error);
    }
  };

  // Save Device Image
  const handleSaveSensorImage = async (location) => {
    log("Save clicked");
    let imgLocation = location;
    try {
      const data = {
        ...state,
        name: state.sensorName,
        sensorImageLocation: imgLocation,
        companyId: appState.auth.userInfo.companyId,
        userId: appState.auth.userInfo.id,
      };
      log("Looking at data ", data);
      log("Calling update sensor image 1");
      const json = await updateSensorImage("sensor", data, appState.auth.token);
      log("Looking at the response ", json);
      if (json.success) {
        log(json);
        snack("Image successfully updated", "success");
        getDevice();
        sensorImageCloseModal();
      } else {
        //handle errs
        log(json);
        setImgErr(true);
      }
    } catch (err) {
      log(err);
    }
  };

  const convertTempForUserPref = (value) => {
    let unitType = " °C";
    //Convert the Fahrenheit
    if (appState.auth.userInfo.themePreferences.measurement === "imperial") {
      value = value * (9 / 5) + 32;
      unitType = " °F";
    }
    value = value.toFixed(2);
    value = value + unitType;
    return value;
  };

  const tileComponents = [
    {
      id: 1,
      entityName: "TEMPERATURE",
      underlineColor: "blue",
      enabled:
        state && state.sensorProfile ? state.sensorProfile[0].value : false,
      numberOfEntities:
        sensorData.temperature || sensorData.temperature === 0
          ? convertTempForUserPref(sensorData.temperature)
          : "",
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 2,
      entityName: "HUMIDITY",
      underlineColor: "blue",
      enabled:
        state && state.sensorProfile ? state.sensorProfile[6].value : false,
      numberOfEntities:
        sensorData.humidity || sensorData.humidity === 0
          ? sensorData.humidity.toFixed(1) + "%"
          : "",
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PRESSURE",
      underlineColor: "blue",
      enabled:
        state && state.sensorProfile ? state.sensorProfile[2].value : false,
      numberOfEntities:
        sensorData.pressure || sensorData.pressure === 0
          ? sensorData.pressure.toFixed(1) + " hPa"
          : "",
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "MOTION DETECTION",
      underlineColor: "blue",
      enabled:
        state && state.sensorProfile ? state.sensorProfile[1].value : false,
      numberOfEntities:
        sensorData.acceleration || sensorData.acceleration === 0
          ? sensorData.acceleration.toFixed(1)
          : "",
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "LIGHT DETECTION",
      underlineColor: "blue",
      numberOfEntities:
        sensorData.light || sensorData.light === 0
          ? Number(sensorData.light).toFixed(2) + " lux"
          : "",
      enabled:
        state && state.sensorProfile ? state.sensorProfile[4].value : false,
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      // dateValue: true,
      entityName: "PANIC BUTTON",
      underlineColor: "blue",
      enabled:
        state && state.sensorProfile ? state.sensorProfile[3].value : false,
      numberOfEntities:
        //ACTIVE/INACTIVE
        sensorData && sensorData.currentPanicState ? "Active" : "Inactive",

      //TIMESTAMP
      // sensorData && sensorData.lastActivePanic
      //   ? `${moment(sensorData.lastActivePanic).format("MM/DD/YY LT")}`
      //   : "",
      // fontSize: 24,
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "PROXIMITY ALERT",
      underlineColor: "blue",
      numberOfEntities: "",
      enabled: false,
      icon: (
        <ProximitySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      // dateValue: true,
      entityName: "DOOR OPEN/CLOSED",
      underlineColor: "blue",
      numberOfEntities:
        // OPEN/CLOSED
        sensorData && sensorData.currentDoorState ? "Open" : "Closed",
      //TIMESTAMP
      //   sensorData && sensorData.lastActiveDoorOpen
      //     ? `Open: ${moment(sensorData.lastActiveDoorOpen).format(
      //         "MM/DD/YY LT"
      //       )}`
      //     : "",
      // secondEntry:
      //   sensorData && sensorData.lastActiveDoorClose
      //     ? `Close: ${moment(sensorData.lastActiveDoorClose).format(
      //         "MM/DD/YY LT"
      //       )}`
      //     : "",
      // fontSize: 16,
      enabled:
        state && state.sensorProfile ? state.sensorProfile[5].value : false,
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
    {
      id: 1,
      entityName: "BATTERY",
      underlineColor: "blue",
      numberOfEntities:
        sensorData.battery_voltage && `${sensorData.battery_voltage}v`,
      enabled:
        state?.sensorProfile && state.sensorProfile[7]
          ? state.sensorProfile[7].value
          : false,
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          volts={sensorData.battery_voltage}
          style={{ height: 55, width: 55 }}
        />
      ),
    },
  ];
  // for (let i = 0; i < 5; i++) {
  //   alertsArray.push({
  //     id: i,
  //     alertName: "Open Door Alert",
  //     locationName: "Location Name",
  //     deviceName: "Device Name",
  //   });
  // }

  const handleEditSensor = () => {
    log("edit sensor action");
    setOpenSensorModal(true);
  };

  const handleDeleteSensor = () => {
    log("delete sensor action");
    setOpenDeleteSensorModal(true);
  };

  const handleEditSensorImage = () => {
    setSensorImageModalState(true);
  };

  const handleRemoveSensorImageLocation = () => {
    setFile(false);
    setImgFile(null);
    setSensorImageLocation("");
  };

  const sensorImageCloseModal = () => {
    setSensorImageModalState(false);
  };
  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/device/all">
        Sensors
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  const battery = _.find(state.sensorProfile, { type: "Battery" });
  const batteryAllowed = battery ? battery.value : false;
  return state ? (
    initialPageLoad ? (
      <LoadingSpinner />
    ) : (
      <div>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DeleteDeviceFormModal state={state} sensorId={id} />
        <DeviceFormModal state={state} getDevice={getDevice} isEdit={true} />
        <LocationFormModal />
        <GatewayFormModal />
        <EditSensorImageDialog
          openState={sensorImageModalState}
          handleClose={sensorImageCloseModal}
          handleSave={singleFileUploadHandler}
          setImgLocation={setSensorImageLocation}
          handleRemoveImg={handleRemoveSensorImageLocation}
          imgLocation={sensorImageLocation}
          setImgErr={setImgErr}
          setFile={setFile}
          setImgFile={setImgFile}
        />
        {state ? (
          <div>
            <Grid
              container
              className={classes.title}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item>
                <Breadcrumb
                  breadcrumbs={breadcrumbs}
                  leadingIcon={
                    <SensorIcon style={{ fontSize: 32 }} color="primary" />
                  }
                  title={`${sensorName} - ${locationName}`}
                />
              </Grid>
              <Grid item>
                <LastUpdated liveData={sensorData} />
              </Grid>
            </Grid>
            <Container style={{ maxWidth: "inherit", marginTop: 10 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <SingleCardDevice
                    name={state.sensorName}
                    sensorId={state.sensorId}
                    gatewayName={state.gatewayId}
                    avatar={state.sensorImageLocation}
                    handleEditSensor={handleEditSensor}
                    handleEditSensorImage={handleEditSensorImage}
                    handleDeleteSensor={handleDeleteSensor}
                    batteryVolt={sensorData.battery_voltage}
                    batteryAllowed={batteryAllowed}
                    lastMessageTime={
                      state.lastSensorMessage.message_received_date
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <LastAlertsList title="Active Alerts" alerts={alertsArray} />
                </Grid>
                {tileComponents.map(
                  (u, index) =>
                    u.enabled && (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Tile
                          entityName={u.entityName}
                          underlineColor={u.underlineColor}
                          numberOfEntities={u.numberOfEntities}
                          scaleFont={true}
                          icon={u.icon}
                          multiple={true}
                          secondEntry={u.secondEntry}
                          fontSize={u.fontSize}
                          dateValue={u.dateValue}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            </Container>
          </div>
        ) : (
          <NotFoundPageLoggedIn />
        )}
      </div>
    )
  ) : (
    <NotFoundPageLoggedIn />
  );
};

export default SingleDevice;
