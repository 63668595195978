import React, { useState, useContext } from "react";
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import ImageButton from "../Generic/ImageButton";
import DecoratedImageButton from "../Generic/DecoratedImageButton";

import { AppContext } from "../../AppContext";

import sensor from "../../img/settings_remote-24px.svg";

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.paper,
  },
  cardText: {
    textAlign: "center",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  cardValueText: {
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  // textField: {
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: "5px",
  // },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const RenewSubscription = (props) => {
  const log = window.log('RenewSubscription');

  const { snack } = useContext(AppContext);
  const {
    openState = false,
    handleClose,
    handleSave,
    setLogoutState,
    userInfo,
    setUserInfo,
    companyInfo,
    setCompanyInfo,
    appState,
  } = props;

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = useState(false);
  const [maxWidth, setMaxWidth] = useState("md");
  const classes = useStyles();

  const handleSubmit = async () => {
    let foundError = false;

    if (foundError) {
      snack("Please Fill Out All Fields", "error");
      return false;
    }

    try {
      const response = await fetch();
      const json = await response.json();

      if (json.Response.View.length === 0) {
        snack("Not a valid address", "error");

        return false;
      } else {
        let lat =
          json.Response.View[0].Result[0].Location.DisplayPosition.Latitude;
        let lng =
          json.Response.View[0].Result[0].Location.DisplayPosition.Longitude;
        try {
          const data = {
            lat,
            lng,
            userId: appState.auth.userInfo.id,
          };

          const delay = (ms) => new Promise((res) => setTimeout(res, ms));

          await delay(1500);

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/company/editAccountInfo`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: appState.auth.token,
              },
              body: JSON.stringify(data),
            }
          );
          const json = await response.json();

          if (json.success) {
            snack("Account Information Updated", "success");
            handleSave();
          } else {
            let errorObj = {};
            json.errors.forEach((error) => {
              //add error to err object
              errorObj = { ...errorObj, [error.type]: true };
              //err alert
              snack(error.msg, "error");
              if (error.type === "token") setLogoutState(true);
            });
          }
        } catch (err) {
          log(err);
          snack("Network Error", "error");
        }
      }
    } catch (error) {
      log(error);
      snack("Network Error", "error");
    }
  };
  const buttonsArray = [
    {
      onClick: () => {},
      image: sensor,
      action: " 1-5 ",
      text: "SENSORS",
      selected: true,
    },
    {
      onClick: () => {},
      image: sensor,
      action: " 6-10",
      text: "SENSORS",
      selected: false,
    },
    {
      onClick: () => {},
      image: sensor,
      action: "11-15",
      text: "SENSORS",
      selected: false,
    },
    {
      onClick: () => {},
      image: sensor,
      action: "16-20",
      text: "SENSORS",
      selected: false,
    },
  ];
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={openState}
      aria-labelledby="responsive-dialog-title"
    >
      <Card style={{ overflow: "auto" }}>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography align="center">RENEW SUBSCRIPTION</Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {}
                  : {
                      paddingLeft: theme.spacing(6),
                      paddingRight: theme.spacing(6),
                    }
              }
              className={classes.formContainer}
            >
              <Grid item xs={12}>
                <p className={classes.cardText} style={{ fontWeight: "bold" }}>
                  Renew Subscription
                </p>
                <p className={classes.cardText}>
                  Renew Subscription Lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </p>
              </Grid>
              <Grid item xs={12} container justify="center">
                {buttonsArray.map((button, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={3}
                      container
                      justify="center"
                      alignItems="flex-end"
                    >
                      <DecoratedImageButton
                        onClick={button.onClick}
                        image={button.image}
                        action={button.action}
                        text={button.text}
                        title={
                          button.selected
                            ? "Current Subscription Suspended"
                            : ""
                        }
                        titleColor={"#FF6A6A"}
                        border={button.selected}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={12} container className={classes.cardValueText}>
                  <p style={{ fontWeight: "bold" }}>ORDER SUMMARY</p>
                </Grid>

                <Grid item xs={12} container className={classes.cardValueText}>
                  <Grid item xs={6}>
                    <p>6-10 Sensor License Upgrade</p>
                  </Grid>
                  <Grid item xs={6} container justify="flex-end">
                    <p style={{ fontWeight: "bold" }}>$999.99</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider
                      variant="middle"
                      style={{ width: "100%", margin: "0 auto" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p style={{ fontWeight: "bold" }}>Subtotal</p>
                  </Grid>
                  <Grid item xs={6} container justify="flex-end">
                    <p style={{ fontWeight: "bold" }}>$999.99</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p>Sales Tax (0%)</p>
                  </Grid>
                  <Grid item xs={6} container justify="flex-end">
                    <p style={{ fontWeight: "bold" }}>$0.00</p>
                  </Grid>
                  <Grid item xs={12} container>
                    <Divider
                      variant="middle"
                      style={{
                        width: "100%",
                        margin: "0 auto",
                        marginBottom: "1em",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} container justify="flex-end">
                    <Button
                      className={classes.rightButton}
                      onClick={handleSubmit}
                      size="small"
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                    >
                      Update Payment Info
                    </Button>
                  </Grid>
                  <Grid item xs={8} container justify="flex-end">
                    <p style={{ fontWeight: "bold" }}>Total: $999.99</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.formContainer}>
          <Grid
            container
            style={{
              paddingLeft: theme.spacing(8),
              paddingRight: theme.spacing(8),
              marginBottom: theme.spacing(4),
            }}
            spacing={1}
          >
            <Grid item xs={12} sm={5}>
              <Button
                className={classes.leftButton}
                onClick={handleClose}
                size="medium"
                variant="outlined"
                fullWidth={true}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={5} container justify="flex-end">
              <Button
                className={classes.rightButton}
                onClick={handleSubmit}
                size="medium"
                variant="contained"
                color="primary"
                fullWidth={true}
              >
                COMPLETE YOUR PURCHASE
              </Button>
            </Grid>
            <Grid item xs={12} container justify="center">
              <p className={classes.cardText}>
                When you hit the button above your payment method on file will
                be billed the total shown.
              </p>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default RenewSubscription;
