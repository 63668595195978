import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
} from "@material-ui/core";
import { AppContext } from "../../AppContext";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import ImageButton from "../Generic/ImageButton";

import sensor from "../../img/settings_remote-24px.svg";
import router from "../../img/router-24px.svg";
import location from "../../img/place-24px.svg";
import alert from "../../img/announcement-24px - blue.svg";
import user from "../../img/people-24px.svg";
/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  rightButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    height: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  cardText: {
    textAlign: "center",
    color: textColor({ theme, lightTone: 500, darkTone: 400 }),
  },
  cardValueText: {
    color: textColor({ theme, lightTone: 500, darkTone: 400 }),
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  cardContainer: {
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.paper,
    overflowX: "hidden",
    overflowY: "auto",
    height: `100%`,
  },
  // textField: {
  //   backgroundColor: theme.palette.background.paper,
  //   borderRadius: "5px",
  // },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const UniversalAddModal = (props) => {
  const {
    appState,
    setAppState,
    openAddingModal,
    setOpenAddingModal,
    setOpenLocationModal,
    setOpenGatewayModal,
    setOpenSensorModal,
    setOpenUserModal,
    setOpenAlertModal,
  } = useContext(AppContext);
  const {
    openState = false,
    handleClose,
    handleSave,
    userInfo,
    setUserInfo,
    companyInfo,
    setCompanyInfo,
  } = props;

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const classes = useStyles();

  const buttonsArray = [
    {
      onClick: () => {
        closeModal();
        setOpenUserModal(true);
      },
      image: user,
      action: "ADD",
      text: "DEMO ACCOUNT",
    },
  ];

  const closeModal = () => {
    setOpenAddingModal(false);
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      open={openAddingModal}
      aria-labelledby="responsive-dialog-title"
    >
      <ClickAwayListener onClickAway={closeModal}>
        <Card className={classes.cardContainer}>
          <CardContent className={classes.formContainer}>
            <Grid container spacing={4}>
              <Grid className={classes.titleContainer} item xs={12}>
                <Typography className={classes.titleText} align="center">
                  ADD FEATURE
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {}
                    : {
                        paddingLeft: theme.spacing(6),
                        paddingRight: theme.spacing(6),
                      }
                }
                className={classes.formContainer}
              >
                <Grid item xs={12}>
                  <p className={classes.cardText}>
                    Select an item below to add to your account:
                  </p>
                </Grid>
                <Grid item xs={12} container justify="space-evenly">
                  {buttonsArray.map((button, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={4}
                        md={2}
                        container
                        justify="space-evenly"
                        alignItems="flex-end"
                        style={{ margin: 5 }}
                      >
                        <ImageButton
                          onClick={button.onClick}
                          image={button.image}
                          action={button.action}
                          text={button.text}
                          title={
                            button.completed ? "Completed" : "Not Completed"
                          }
                          titleColor={button.completed ? "#59C754" : "#FF6A6A"}
                          border={button.completed}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <CardActions className={classes.actionContainer}>
              <Grid
                container
                style={{
                  paddingLeft: theme.spacing(8),
                  paddingRight: theme.spacing(8),
                  marginBottom: theme.spacing(4),
                  marginTop: theme.spacing(5),
                }}
                spacing={1}
              >
                <Grid item xs={12} sm={12} container justify="center">
                  <Button
                    className={classes.leftButton}
                    onClick={closeModal}
                    size="medium"
                    variant="outlined"
                    color="primary"
                    fullWidth={false}
                  >
                    CANCEL
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </ClickAwayListener>
    </Dialog>
  );
};

export default UniversalAddModal;
