import React from "react";
import {
  Box,
  Divider,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { AppContext } from "../../AppContext";

function IconElement(props) {
  const {
    iconClass,
    iconComponent,
    valueClass,
    valueText,
    valueTitle,
    enableZero,
    iconElementClass,
  } = props;

  let createWhitespace = false;
  if (valueText || (enableZero && valueText === 0)) {
    if (valueText.length <= 0) {
      createWhitespace = true;
    }
  } else {
    createWhitespace = true;
  }
  return (
    <Tooltip title={valueTitle || ""} placement="top">
      <Box
        display="flex"
        alignContent="center"
        justifyContent="center"
        flexDirection="column"
        className={iconClass}
      >
        <Box
          className={iconElementClass}
          display="flex"
          justifyContent="center"
        >
          {iconComponent}
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography
            className={valueClass}
            style={{
              whiteSpace: "nowrap",
              paddingTop: createWhitespace ? null : 4,
            }}
          >
            {createWhitespace ? (
              <div style={{ marginTop: 45 }}></div>
            ) : (
              valueText
            )}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}

/**
 * Component that show horizontal list of
 * Icons and info for the cards components
 * @param {Object} props
 *
 * @props
 * items: Object Array [{ icon: React Component , value: String }]
 */
export default function CardInfoIconsMobile(props) {
  const log = window.log('CardInfoIconsMobile');

  const {
    items, // array or dictionary of items
    isBold, // boolean if the value to show is in bold
    wrapItems,
    isDict = false, // boolean if the array of items is a dictionary
    showOnlyItems = [],
    displayDivider,
    buttons,
    enableZero, // boolean - show 0 value text
    gatewayCard,
    sensorCard,
    singleGatewayCard,
    singleAlertCard,
    locationCard,
    singleLocationCard,
  } = props;
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: theme.spacing(1, 0),
      justifyContent: "flex-end",
      maxWidth: sensorCard && "100%",

      [theme.breakpoints.down("sm")]: {
        justifyContent:
          gatewayCard || singleLocationCard ? "flex-end" : "center",
        flexWrap: wrapItems,
        maxWidth: sensorCard && "100%",
        // position: "absolute",
        // left: "50%",
        // top: "50%",
        transform: sensorCard && "translate(-39%, 20%)",
        // transform: sensorCard && `translateX(-170px)`,
      },
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        transform: sensorCard && "translate(0)",
        flexWrap: sensorCard && "wrap",
        maxWidth: sensorCard && "100%",
      },
    },
    divider: {
      backgroundColor: theme.palette.type === "light" ? "#CCC" : "#FFF",
      height: gatewayCard ? 65 : 70,
      // marginRight: gatewayCard && !singleGatewayCard && 20,
      [theme.breakpoints.down("sm")]: {
        display: displayDivider,
        marginRight: sensorCard ? 8 : (gatewayCard || singleLocationCard) && 2,
        marginLeft: sensorCard && 8,
      },
      [theme.breakpoints.down("xs")]: {
        display: (sensorCard || singleGatewayCard || singleAlertCard) && "none",
        // transform: singleLocationCard && "translate(-58px, 98px)",
      },
      [theme.breakpoints.down("1130")]: {
        display: gatewayCard && "block",
      },
      [theme.breakpoints.down("960")]: {
        display: gatewayCard && "block !important",
      },
      [theme.breakpoints.down("460")]: {
        display: gatewayCard && "block !important",
      },
      [theme.breakpoints.down("380")]: {
        // transform: singleLocationCard && "translate(-31px, 98px)",
      },
    },
    buttonDivider: {
      height: gatewayCard ? 65 : singleLocationCard && 70,
      marginRight:
        (singleGatewayCard || singleLocationCard || singleAlertCard) && 30,
      marginBottom:
        singleGatewayCard || singleLocationCard ? 24 : singleAlertCard && 2,
      backgroundColor: theme.palette.type === "light" ? "#CCC" : "#FFF",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    buttonContainer: {
      marginBottom: 1,
    },
    buttons: {
      transform:
        (singleGatewayCard || singleLocationCard) && "translateY(-14px)",
      // [theme.breakpoints.down("sm")]: {
      //   position: "absolute",
      //   right: 11,
      //   top: 161,
      // },
      [theme.breakpoints.down("xs")]: {
        transform: singleAlertCard
          ? "translate(1%, -52%)"
          : singleGatewayCard
          ? "translate(1%, -32%)"
          : singleLocationCard && "translate(-140%, -52%)",
      },
      [theme.breakpoints.down("490")]: {
        transform: singleGatewayCard
          ? "translate(-72%, 168%)"
          : singleAlertCard && "translate(-72%, 204%)",
      },
      [theme.breakpoints.down("380")]: {
        transform: singleLocationCard && "translate(-181%, -52%)",
      },
      [theme.breakpoints.down("320")]: {
        transform: singleAlertCard && "translate(-100%, 204%)",
      },
    },
    iconContainer: {
      // margin: theme.spacing(0.5, 1.5),
      width: "105px",
      minWidth: sensorCard && "105px",
      [theme.breakpoints.down("1310")]: {
        minWidth: "90px",
      },
      [theme.breakpoints.down("1180")]: {
        // width: 105,
        minWidth: sensorCard && "85px",
      },
      [theme.breakpoints.down("1160")]: {
        // width: 105,
        minWidth: locationCard && "60px",
      },
      [theme.breakpoints.down("1135")]: {
        // width: 105,
        minWidth: sensorCard && "80px",
      },
      [theme.breakpoints.down("1090")]: {
        width: sensorCard && 105,
        minWidth: sensorCard && "inherit",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: sensorCard && "10vw",
      },

      // transform:
      //   singleGatewayCard || singleLocationCard
      //     ? "translateY(-8px)"
      //     : singleAlertCard && "translateY(3px)",

      // [theme.breakpoints.down("xs")]: {
      //   transform: singleGatewayCard
      //     ? "translate(-96%, 113%)"
      //     : singleLocationCard
      //     ? "translate(-68%, 141%)"
      //     : singleAlertCard && "translate(-93%, 138%)",
      // },
      // [theme.breakpoints.down("490")]: {
      //   transform: singleGatewayCard
      //     ? "translate(-147%, 105%)"
      //     : singleAlertCard && "translate(-147%, 147%)",
      // },
      // [theme.breakpoints.down("380")]: {
      //   transform: singleLocationCard && "translate(-35%, 142%)",
      // },
    },
    valueText: {
      fontSize: sensorCard ? 18 : 20,
      letterSpacing: sensorCard && -1,
      fontWeight: isBold ? 600 : "",
      marginTop: sensorCard ? -2 : singleAlertCard ? 0 : locationCard ? 0 : 10,
      color: theme.palette.type === "light" ? grey[700] : grey[300],
      transform: (gatewayCard || singleLocationCard) && "translateY(-8px)",
    },
    icon: {
      transform: (gatewayCard || singleLocationCard) && "translateY(4px)",
    },
    trailingContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: (singleGatewayCard || gatewayCard) && 25,
      },
    },
  }));
  const classes = useStyles();

  const result = []; // array of objects that will render
  let index = 0;
  let arrayLength;

  if (isDict) {
    arrayLength = Object.keys(items).length;
  } else {
    arrayLength = items.length;
  }

  log("CardInfoIconsMobile: " + Object.keys(items));
  log(isDict);
  log(items);
  if (isDict && showOnlyItems.length > 0) {
    for (const key of showOnlyItems) {
      result.push(
        <IconElement
          key={key}
          iconElementClass={classes.icon}
          iconClass={classes.iconContainer}
          iconComponent={items[key].icon}
          valueClass={classes.valueText}
          valueText={items[key].value}
          valueTitle={items[key].title}
          enableZero={enableZero}
          gatewayCard={gatewayCard}
        />
      );

      if (index++ < showOnlyItems.length - 1) {
        result.push(
          <Divider
            className={classes.divider}
            key={index}
            orientation="vertical"
            flexItem
          />
        );
      }
    }
  } else {
    for (const i in items) {
      result.push(
        <IconElement
          gatewayCard={gatewayCard}
          key={i}
          iconElementClass={classes.icon}
          iconClass={classes.iconContainer}
          iconComponent={items[i].icon}
          valueClass={classes.valueText}
          valueText={items[i].value}
          enableZero={enableZero}
        />
      );

      if (index++ < arrayLength - 1) {
        result.push(
          <Divider
            className={classes.divider}
            key={`diveider-${index}`}
            orientation="vertical"
            flexItem
          />
        );
      }
    }
  }

  return (
    <Box>
      {buttons}
      <Box
        className={classes.trailingContainer}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        {result}
      </Box>
    </Box>
  );
}
