import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { A } from "hookrouter";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import LoginLayout from "./LoginLayout";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  formContainer: {
    width: "80%",
  },
  linkForgotPassword: {
    color: theme.palette.primary.light,
    padding: theme.spacing(1),
    textDecoration: "none",
    fontSize: 16,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  button: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: ".5rem",
    },
  },
}));

const Login = () => {
  const {
    appState,
    setAppState,
    setLogoutState,
    snack,
    setFeatureCount,
  } = useContext(AppContext);

  const [state, setState] = useState({
    email: "",
    pw: "",
  });

  const [err, setErr] = useState({
    email: false,
    pw: false,
  });

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  const attemptLogin = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/reseller/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: state.email,
            pw: state.pw,
          }),
        }
      );

      const json = await response.json();

      if (json.success) {
        const { token, userInfo } = json.data;

        if (json.data.userInfo.is_enabled === 0) {
          snack("Account Is Disabled", "error");
        } else {
          if (rememberMe) {
            localStorage.setItem("email", state.email);
          } else {
            if (localStorage.getItem("email")) {
              localStorage.removeItem("email");
            }
          }

          //  ensure the logout state is false
          //  if user was logged in previously and was kicked out due to token error
          setLogoutState(false);
          setAppState({
            ...appState,
            auth: {
              loggedIn: true,
              token,
              userInfo,
            },
          });
        }
      } else {
        setErr({ [json.errors[0].type]: true });
        snack(json.errors[0].msg, "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      snack(
        "Unable to make your request at this time. Please try again later.",
        "error"
      );
    }
  };

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setRememberMe(true);
      setState({ ...state, email });
    }
  }, []);

  return (
    <LoginLayout subtitle="Welcome back! Please login into your reseller account.">
      <Box className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="email"
              className={classes.textField}
              label="Email Address"
              onChange={(e) =>
                setState({ ...state, email: e.currentTarget.value })
              }
              value={state.email}
              error={err.email}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("submit").focus();
                  attemptLogin();
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FormControl className={classes.textField} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                className={classes.textField}
                fullWidth
                variant="outlined"
                label="Password"
                placeholder="Password"
                value={state.pw}
                name="password"
                onChange={(e) =>
                  setState({ ...state, pw: e.currentTarget.value })
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("submit").focus();
                    attemptLogin();
                  }
                }}
                error={err.pw}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {/* <TextField
              variant="outlined"
              type="password"
              id="password"
              className={classes.textField}
              label="Password"
              onChange={(e) =>
                setState({ ...state, pw: e.currentTarget.value })
              }
              value={state.pw}
              error={err.pw}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("submit").focus();
                  attemptLogin();
                }
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={rememberMe}
                  onChange={(event) => {
                    setRememberMe(!rememberMe);
                  }}
                />
              }
              label="Remember me"
              labelPlacement="end"
            />
            <A href="/reset" className={classes.linkForgotPassword}>
              Forgot your password?
            </A>
          </Grid>
          <Grid container item xs={12} className={classes.buttons}>
            <Button
              id="submit"
              variant="contained"
              className={classes.button}
              size="large"
              color="primary"
              onClick={() => attemptLogin()}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LoginLayout>
  );
};

export default Login;
