import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Card, CardContent } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { navigate } from "hookrouter";
import MUIDataTable from "mui-datatables";
import DeviceIcon from "@material-ui/icons/SettingsRemote";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  expansionPanel: {
    padding: ".5rem 0",
    borderRadius: "5px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  tableTitle: {
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1.5rem"
  }
}));

const DevicesAccordian = props => {
  const log = window.log('DevicesAccordian');

  const [state, setState] = useState();
  const [columns, setColumns] = useState([
    { name: "name", label: "Name" },
    { name: "sensorId", label: "Sensor ID" },
    { name: "gatewayId", label: "Gateway ID" }
  ]);
  let { devices } = props;
  if (devices !== undefined) {
    devices.map(device => {
      log("Device ", device);
    });
  } else {
    log("No devices");
  }

  const classes = useStyles();

  const options = {
    onRowClick: selectedRow => navigate(`/device/${selectedRow[1]}`),
    selectableRows: "none"
  };

  return devices ? (
    <div>
      <ExpansionPanel className={classes.expansionPanel}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{`Gateway Devices (${devices.length})`}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.root}>
            <MUIDataTable
              columns={columns}
              data={devices}
              title={
                <Grid container>
                  <DeviceIcon fontSize="large" color="primary" />
                  <Typography className={classes.tableTitle}>
                    DEVICES
                  </Typography>
                </Grid>
              }
              options={options}
            />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  ) : null;
};
export default DevicesAccordian;
