import React, { useState, useContext } from "react";
import clsx from "clsx";
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  OutlinedInput,
  ClickAwayListener,
  FormHelperText,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    width: "100%",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    width: 100,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    height: `100%`,
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  titleContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  titleText: {
    fontWeight: "bold",
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  cardContainer: {
    overflowX: "hidden",
    overflowY: "auto",
    height: `100%`,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const EditPassword = (props) => {
  const log = window.log('EditPassword');

  const { setLogoutState, snack } = useContext(AppContext);
  const { openState, handleClose, appState } = props;

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = useState(false);
  const [maxWidth, setMaxWidth] = useState("sm");
  const classes = useStyles();
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    currentPassword: "",
  });
  const [passwordError, setPasswordError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    setValues({ ...values, [event.target.name]: event.target.value });
    log(values);
  };

  const updateOne = async (entity, data, token) => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/${entity}/change-password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify(data),
        }
      );

      const json = await request.json();
      if (json.success) {
        return json;
      } else {
        json.errors.forEach((err) => {
          snack(err.msg, "error");
          if (err.type === "token") setLogoutState(true);
        });
      }
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  const onSubmitClick = async () => {
    try {
      const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      setIsSubmitting(true);
      let error = false;
      let errorMsg = "";
      if (
        values.currentPassword === "" ||
        values.password === "" ||
        values.confirmPassword === ""
      ) {
        error = true;
        errorMsg = "Something went wrong. Please double check all fields.";
      } else if (values.password !== values.confirmPassword) {
        error = true;
        errorMsg = "Password does not match.";
      } else if (!pwRegex.exec(values.password)) {
        error = true;
        errorMsg = "Password requirements were not met.";
      }

      if (error) {
        setIsSubmitting(false);
        setPasswordError(true);
        return snack(errorMsg, "error");
      }

      const data = {
        currentPW: values.currentPassword,
        pw1: values.password,
        pw2: values.confirmPassword,
      };

      const json = await updateOne("user", data, appState.auth.token);
      if (json.success) {
        snack("User information successfully updated", "success");
        setPasswordError(false);
        //Set password edit form back to blank fields
        setValues({
          password: "",
          confirmPassword: "",
          currentPassword: "",
        });
        handleClose();
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          if (error.type === "pw") {
            setPasswordError(true);
          }
          if (error.type === "system") {
            setPasswordError(true);
          }
          if (error.type === "currentPW") {
            setPasswordError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
        });
      }
    } catch (err) {
      log(err);
    }
    setIsSubmitting(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      aria-labelledby="responsive-dialog-title"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Card className={classes.cardContainer}>
          <CardContent className={classes.formContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography className={classes.titleText} align="center">
                  EDIT PASSWORD
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={
                  fullScreen
                    ? {}
                    : {
                        paddingLeft: theme.spacing(6),
                        paddingRight: theme.spacing(6),
                        paddingTop: theme.spacing(6),
                      }
                }
                className={classes.formContainer}
              >
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-currentpassword">
                      Current Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-currentpassword"
                      className={classes.textField}
                      fullWidth
                      variant="outlined"
                      label="Current Password"
                      placeholder="Current Password"
                      value={values.currentPassword}
                      name="currentPassword"
                      onChange={(e) => handleChange(e)}
                      error={passwordError}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-newpassword">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-newpassword"
                      className={classes.textField}
                      fullWidth
                      variant="outlined"
                      label="New Password"
                      placeholder="New Password"
                      value={values.password}
                      name="password"
                      onChange={(e) => handleChange(e)}
                      error={passwordError}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.textFieldPadding}>
                  <FormControl className={classes.textField} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-confirmpassword">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-confirmpassword"
                      className={classes.textField}
                      fullWidth
                      variant="outlined"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      value={values.confirmPassword}
                      name="confirmPassword"
                      onChange={(e) => handleChange(e)}
                      error={passwordError}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormHelperText>
                    Requirements: at least 8 characters, at least 1 uppercase, at
                    least 1 lowercase, at least 1 number, at least 1 special character (@$!%*?&)
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <CardActions className={classes.actionContainer}>
              <Grid
                container
                style={{
                  paddingLeft: theme.spacing(8),
                  paddingRight: theme.spacing(8),
                  marginBottom: theme.spacing(4),
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Button
                    className={classes.leftButton}
                    onClick={handleClose}
                    size="large"
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} container justify="flex-end">
                  <Button
                    className={classes.rightButton}
                    onClick={onSubmitClick}
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </CardContent>
        </Card>
      </ClickAwayListener>
    </Dialog>
  );
};

export default EditPassword;
