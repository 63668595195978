import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  descriptionText: {
    fontSize: 16,
    color: "#747474",
  },
  inputNormal: {
    height: "50px",
    marginTop: "50px",
    background: "white",
    border: "1px solid grey",
    padding: "4px",
    borderRadius: "5px",
    paddingLeft: "15px",
  },
  inputFocus: {
    height: "50px",
    marginTop: "50px",
    background: "white",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "4px",
    borderRadius: "5px",
    paddingLeft: "15px",
    width: "100%",
    color: theme.palette.type === "light" ? grey[600] : grey[400],
  },
  error: {
    borderColor: "#f44336",
  },
  leftButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  rightButton: {
    backgroundColor: "#FF6A6A",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "1rem",
    },
  },
  formContainer: {
    height: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? "#EBEBEB"
        : theme.palette.background.default,
  },
  titleText: {
    fontWeight: "bold",
    color: "#FF6A6A",
  },
  titleContainer: {
    backgroundColor: theme.palette.background.default,
  },
  cardContainer: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "100%",
    backgroundColor:
      theme.palette.type === "light"
        ? "#EBEBEB"
        : theme.palette.background.default,
  },
  tableContainer: {
    marginBottom: "1rem",
  },
  actionContainer: {
    paddingTop: theme.spacing(4),
    backgroundColor:
      theme.palette.type === "light"
        ? "#EBEBEB"
        : theme.palette.background.default,
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const ChangeCompanyLogos = (props) => {
  const log = window.log('ChangeCompanyLogos');
  const {
    openState,
    handleCancel,
    handleContinue,
    selectedSensors,
    sensorList,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = useState(false);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [alertsArr, setAlertsArr] = useState([]);

  const classes = useStyles();

  const alertTableColumns = [
    {
      name: "sensorId",
      label: "ID",
    },
    {
      name: "sensorName",
      label: "Sensor Name",
    },
    {
      name: "sensorLocation",
      label: "Location",
    },
  ];

  useEffect(() => {
    const getSensors = async () => {
      var millisecondsToWait = 2000;
      log("Start");
      let tempSensorArr = await setTimeout(function () {
        // Whatever you want to do after the wait
        //Grab the selected sensors out of the sensors list form the array
        let tempSensorArr = selectedSensors.map((index) => {
          return sensorList[index];
        });
        return tempSensorArr;
      }, millisecondsToWait);
      log("Finish");
      log("Looking at the sensorList ", sensorList);
      log("Looking at selected sensors ", selectedSensors);

      log(
        "Looking at the temp sensor array ", tempSensorArr);
      log("Use effect called ", tempSensorArr);
      let tempArr = [];
      for (let sensorIndex in tempSensorArr) {
        log("Looping over a sensor ", tempSensorArr[sensorIndex]);
        let tempObj = {};
        tempObj.sensorId = tempSensorArr[sensorIndex].sensorId;
        tempObj.sensorName = tempSensorArr[sensorIndex].sensorName;
        tempObj.sensorLocation = tempSensorArr[sensorIndex].sensorLocation;
        tempArr.push(tempObj);
      }

      setAlertsArr(tempArr);
      log("Showing the temp array ", tempArr);
      log(alertsArr);
    };
    getSensors();
  }, [openState]);

  const activeAlertTableOptions = {
    rowsPerPage: 6,
    rowsPerPageOptions: [],
    selectableRowsOnClick: false,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    selectToolbarPlacement: "none",
    selectableRows: "none",
    rowHover: false,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => null,
    fixedHeader: false,
  };

  const handleContinueClick = () => {
    handleContinue();
  };

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      aria-labelledby="responsive-dialog-title"
    >
      <Card className={classes.cardContainer}>
        <CardContent className={classes.formContainer}>
          <Grid container spacing={4}>
            <Grid className={classes.titleContainer} item xs={12}>
              <Typography className={classes.titleText} align="center">
                WARNING ON ALERT MOFIFICATION
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {}
                  : {
                      paddingLeft: theme.spacing(6),
                      paddingRight: theme.spacing(6),
                      paddingBottom: 0,
                    }
              }
              className={classes.formContainer}
            >
              <Grid item xs={12} className={classes.textFieldPadding}>
                <p className={classes.descriptionText}>
                  Editing your alert settings will acknowledge all of the
                  following paired active alerts.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={
                fullScreen
                  ? {
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(2),
                      paddingBottom: theme.spacing(2),
                    }
                  : {
                      paddingLeft: theme.spacing(8),
                      paddingRight: theme.spacing(8),
                      paddingBottom: theme.spacing(2),
                      paddingTop: 0,
                    }
              }
              className={classes.formContainer}
              spacing={1}
            >
              <Grid className={classes.tableContainer} item xs={12}>
                <MUIDataTable
                  title={
                    <Grid container>
                      <Typography style={{ fontWeight: "bold" }}>
                        ACTIVE ALERTS
                      </Typography>
                    </Grid>
                  }
                  options={{
                    ...activeAlertTableOptions,
                    textLabels: {
                      body: {
                        noMatch: "No matching active alerts",
                      },
                    },
                  }}
                  columns={alertTableColumns}
                  data={alertsArr}
                />
              </Grid>
            </Grid>
          </Grid>
          <CardActions className={classes.actionContainer}>
            <Grid
              container
              style={{
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
                marginBottom: theme.spacing(4),
              }}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  className={classes.leftButton}
                  onClick={handleCancel}
                  size="large"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} container justify="flex-end">
                <Button
                  className={classes.rightButton}
                  size="large"
                  variant="contained"
                  onClick={handleContinueClick}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default ChangeCompanyLogos;
