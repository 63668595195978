import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
}));

/**
 * Header for modal forms
 * @param {Object} props
 * @props
 * header: string
 * desc: string | undefined
 */
export default function ModalFormHeader(props) {
  const classes = useStyles();
  const { header, desc } = props;

  return [
    <Grid item xs={12} key="form_title">
      <Box
        className={classes.fontColor}
        fontWeight="fontWeightBold"
        fontSize={20}
      >
        {header}
      </Box>
    </Grid>,

    <Grid
      item
      xs={12}
      fontWeight="fontWeightLight"
      fontSize={16}
      style={{ marginTop: 10 }}
      key="form_desc"
    >
      <Box className={classes.fontColor}>{desc}</Box>
    </Grid>,
  ];
}
