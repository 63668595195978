import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  ClickAwayListener,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ColorPicker from "../Generic/ColorPicker";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  gatewaySelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
  },
  textFieldPadding: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  formControlLabel: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  size: {
    width: 50,
    height: 50,
  },
  sizeIcon: {
    fontSize: 40,
  },
  formControl: {
    width: "60%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  titleText: {
    fontWeight: "bold",
    color: theme.palette.type === "light" ? "#747474" : "#FFF",
  },
  formContainer: {
    backgroundColor:
      theme.palette.type === "light"
        ? "#F4F5F5"
        : theme.palette.background.default,
  },
  labelText: {
    color: "#747474",
  },
}));

/**
 * Account Settings Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Function
 * handleSave: Function
 */
const EditUserSettings = (props) => {
  const {
    openState,
    handleClose,
    handleSave,
    darkMode,
    handleDarkModeChange,
    timeFormat,
    setTimeFormat,
    measurement,
    setMeasurement,
    primaryColor,
    handlePrimaryColorChange,
    handleSaveThemePreferences,
  } = props;

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const classes = useStyles();

  const [twelveHour, setTwelveHour] = React.useState(true);
  const [twentyFourHour, setTwentyFourHour] = React.useState(false);
  const [metric, setMetric] = React.useState(true);
  const [imperial, setImperial] = React.useState(false);

  const handle12HourCheck = () => {
    if (twentyFourHour) {
      setTwentyFourHour(false);
      setTwelveHour(!twelveHour);
      setTimeFormat("12hr");
    }
  };

  const handle24HourCheck = () => {
    if (twelveHour) {
      setTwelveHour(false);
      setTwentyFourHour(!twentyFourHour);
      setTimeFormat("24hr");
    }
  };

  const handleMetricCheck = () => {
    if (imperial) {
      setImperial(false);
      setMetric(!metric);
      setMeasurement("metric");
    }
  };

  const handleImperialCheck = () => {
    if (metric) {
      setMetric(false);
      setImperial(!imperial);
      setMeasurement("imperial");
    }
  };

  useEffect(() => {
    if (measurement === "metric") {
      setMetric(true);
      setImperial(false);
    }
    if (measurement === "imperial") {
      setMetric(false);
      setImperial(true);
    }
    if (timeFormat === "12hr") {
      setTwelveHour(true);
      setTwentyFourHour(false);
    }
    if (timeFormat === "24hr") {
      setTwelveHour(false);
      setTwentyFourHour(true);
    }
  }, [timeFormat, measurement]);

  return (
    <Dialog
      fullScreen={fullScreen} // commented temporarily because it breaks the layout
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openState}
      aria-labelledby="responsive-dialog-title"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Card style={{ overflow: "auto" }}>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography className={classes.titleText} align="center">
                  SETTINGS
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{
                  paddingLeft: theme.spacing(6),
                  paddingRight: theme.spacing(6),
                }}
                className={classes.formContainer}
              >
                {/* <Grid item xs={12} container justify="center">
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel className={classes.formControlLabel}>
                      Time Format
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.size}
                            onChange={handle12HourCheck}
                            name="12hour"
                            checked={twelveHour}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.sizeIcon}
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.sizeIcon} />
                            }
                            value="checkedI"
                          />
                        }
                        label={
                          <Typography className={classes.labelText}>
                            12 Hour
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.size}
                            onChange={handle24HourCheck}
                            name="24hour"
                            checked={twentyFourHour}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.sizeIcon}
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.sizeIcon} />
                            }
                            value="checkedII"
                          />
                        }
                        label={
                          <Typography className={classes.labelText}>
                            24 Hour
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  style={{ marginTop: 24 }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel className={classes.formControlLabel}>
                      Measuring Units
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.size}
                            onChange={handleMetricCheck}
                            name="metric"
                            checked={metric}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.sizeIcon}
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.sizeIcon} />
                            }
                            value="checkedI"
                          />
                        }
                        label={
                          <Typography className={classes.labelText}>
                            Metric
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.size}
                            onChange={handleImperialCheck}
                            name="imperial"
                            checked={imperial}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.sizeIcon}
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.sizeIcon} />
                            }
                            value="checkedII"
                          />
                        }
                        label={
                          <Typography className={classes.labelText}>
                            Imperial
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  style={{ marginTop: 24 }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel className={classes.formControlLabel}>
                      Theme Accent Color
                    </FormLabel>
                    <FormGroup>
                      <ColorPicker
                        primaryColor={primaryColor}
                        handlePrimaryColorChange={handlePrimaryColorChange}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="center"
                  style={{ marginTop: 24 }}
                >
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormLabel className={classes.formControlLabel}>
                      Dark Theme
                    </FormLabel>
                    <FormGroup>
                      <Switch
                        checked={darkMode}
                        onChange={handleDarkModeChange}
                        value="darkMode"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.formContainer}>
            <Grid
              container
              style={{
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
                marginBottom: theme.spacing(4),
              }}
            >
              <Grid item xs={6}>
                <Button
                  color="primary"
                  onClick={handleClose}
                  size="large"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Button
                  onClick={handleSave}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </ClickAwayListener>
    </Dialog>
  );
};

export default EditUserSettings;
