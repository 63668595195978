import React, { useState, useContext } from "react";
import { AppContext } from "./../../AppContext";
import {
  Button,
  Box,
  Grid,
  TextField,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import GenericDialog from "../Generic/GenericDialog";
import ImageButton from "../Generic/ImageButton";
import gateway from "../../img/router-24px.svg";
import sensor from "../../img/settings_remote-24px.svg";

import LocationSelect from "../common/LocationSelect";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  locationSelect: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
  },
  fieldPadding: {
    paddingBottom: theme.spacing(4),
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  fontColor: {
    color:
      theme.palette.type === "light"
        ? theme.palette.grey[600]
        : theme.palette.text.primary,
  },
  imageButtonSize: {
    width: 150,
    [theme.breakpoints.down("sm")]: {
      width: 140,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 150,
      marginBottom: 10,
    },
  },
}));

/**
 * Gateway Form Modal
 * @param {Object} props
 * @props
 * openState: Boolean
 * handleClose: Functions
 */
export default function GatewayFormModal(props) {
  const log = window.log("GatewayFormModal");

  const {
    appState,
    setAppState,
    setLogoutState,
    setOpenGatewayModal,
    openGatewayModal,
    setOpenSensorModal,
    setOpenLocationModal,
    setFeatureCount,
    snack,
  } = useContext(AppContext);
  const { getGateways } = props;
  const classes = useStyles();
  //Gateway name state hooks
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  //Gateway MAC state hooks
  const [gatewayMac, setGatewayMac] = useState("");
  const [gatewayMacError, setGatewayMacError] = useState(false);
  //Location ID state hook
  const [locationId, setLocationId] = useState();
  //Submit gateway state hook
  const [submitState, setSubmitState] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // const handleCheckNameValue = () => {
  //   if (name) {
  //     return true;
  //   } else {
  //     setNameError(true);
  //     snack("Required Field", "error");
  //     return false;
  //   }
  // };

  const setDefaultObject = () => {
    setName("");
    setGatewayMac("");
    setLocationId(undefined);
  };

  const handleChangeGatewayMac = (e) => {
    setGatewayMac(e.target.value.toUpperCase());
  };

  // Marks Add Gateway as complete for onboard
  const handleGatewayFeatureComplete = async () => {
    let changes = {
      ...appState.auth.userInfo.features,
      addGateway: true,
    };

    try {
      const data = {
        themePreferences: appState.auth.userInfo.themePreferences
          ? JSON.stringify(appState.auth.userInfo.themePreferences)
          : null,
        features: JSON.stringify(changes),
        companyId: appState.auth.userInfo.companyId,
      };
      const response = await fetch(
        process.env.REACT_APP_API_URL + `/company/edit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      if (json.success) {
        let cp = { ...appState };
        cp.auth.userInfo.features = JSON.parse(json.data.features);
        setAppState(cp);
        let features = JSON.parse(json.data.features);
        let count = Object.values(features).filter((item) => item === false)
          .length;
        if (!features.finishLater) {
          count = count - 1;
        }
        setFeatureCount(count);
      } else {
        snack(json.errors[0], "error");
        json.errors.forEach((err) => {
          if (err.type === "token") {
            snack(err.msg, "error");
            setLogoutState(true);
          }
        });
      }
    } catch (err) {
      log(err);
    }
  };

  const handleSubmitNameAndId = async () => {
    try {
      const request = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/checkGatewayMAC?gatewayMac=${gatewayMac}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
        }
      );

      const json = await request.json();

      if (!json.success) {
        let errorObj = {};
        json.errors.forEach((error) => {
          errorObj = { ...errorObj, [error.type]: true };
          snack(error.msg, "error");
          setGatewayMacError(true);

          if (error.type === "token") {
            setLogoutState(true);
          }
        });
      } else setGatewayMacError(false);

      return json.success;
    } catch (err) {
      snack("Network Error", "error");
    }
  };

  // Justin This function will now handle location submit step
  const handleSubmitGateway = async () => {
    log("Gateway_Submit: Inside of handle gateway");

    try {
      const data = {
        gatewayMAC: gatewayMac,
        name,
        locationId,
        userId: appState.auth.userInfo.id,
      };

      //Delay for asthetics
      // const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      // await delay(1500);

      log("Gateway_Submit: About to make the web request");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/gateway/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: appState.auth.token,
          },
          body: JSON.stringify(data),
        }
      );
      const json = await response.json();

      log("Gateway_Submit: Looking at the json response ", json);

      if (json.success) {
        setNameError(false);
        setGatewayMacError(false);

        getGateways && getGateways();
        setDefaultObject();
        handleGatewayFeatureComplete();
        return true;
      } else {
        let errorObj = {};
        json.errors.forEach((error) => {
          if (error.type === "gatewayMac") {
            setGatewayMacError(true);
          }
          //add error to err object
          errorObj = { ...errorObj, [error.type]: true };
          //err alert
          snack(error.msg, "error");
          if (error.type === "token") {
            snack(error.msg, "error");
            setLogoutState(true);
          }
        });
        return false;
      }
    } catch (err) {
      log(err);
      return false;
    }
  };

  const handleRestart = () => {
    setOpenGatewayModal(false);
    setTimeout(() => {
      setOpenGatewayModal(true);
    }, 500);
  };

  const handleCloseAndOpenSensorState = () => {
    setOpenGatewayModal(false);
    setDefaultObject();
    setTimeout(() => {
      setOpenSensorModal(true);
    }, 500);
  };

  const handleClose = () => {
    setOpenGatewayModal(false);
    setDefaultObject();
  };

  if (window.ReactNativeWebView) {
    window.addEventListener("message", (e) => {
      log(e.origin);
      if (e.data.includes("MIGATEWAY")) {
        let result = e.data
          .split(",")[1]
          .replace("MIGATEWAY", "")
          .replace(/:\s*/g, "");
        setGatewayMac(result);
      }
    });
  }

  const handleQRCode = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("enableQR");
    }
  };

  return (
    <div>
      <GenericDialog
        openState={openGatewayModal}
        handleClose={handleClose}
        handleCancel={handleClose}
        title="ADD A NEW GATEWAY"
        stepsArray={[
          {
            label: "GATEWAY ID & NAME",
            nextFunction: handleSubmitNameAndId,
            showProgress: false,
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Enter Gateway ID and give your gateway a name
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight="fontWeightLight"
                  fontSize={16}
                  style={{ marginTop: 10 }}
                >
                  <Box className={classes.fontColor}>
                    Enter your gateway ID. Giving your gateway a name will help
                    you locate and manage all of your gateways and devices.
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 25 }}>
                  <TextField
                    className={classes.textField}
                    value={gatewayMac}
                    error={gatewayMacError}
                    fullWidth
                    variant="outlined"
                    placeholder="Gateway ID"
                    label="Gateway ID"
                    onChange={(e) => handleChangeGatewayMac(e)}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 25 }}>
                  <TextField
                    className={classes.textField}
                    error={nameError}
                    fullWidth
                    name="name"
                    value={name}
                    variant="outlined"
                    placeholder="Gateway Name"
                    label="Gateway Name"
                    onChange={(e) => handleNameChange(e)}
                  />
                </Grid>
                {window.ReactNativeWebView && (
                  <Grid item xs={12} style={{ marginTop: 25 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleQRCode}
                    >
                      Scan QR
                    </Button>
                  </Grid>
                )}
              </Grid>
            ),
          },
          {
            label: "SELECT LOCATION",
            nextFunction: handleSubmitGateway,
            validator: !submitState,
            showProgress: true,
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Select the Location of your gateway
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    Select the location where you have installed your gateway.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => setOpenLocationModal(true)}
                    variant="outlined"
                    color="primary"
                  >
                    NO LOCATION CREATED? ADD A NEW LOCATION HERE
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <LocationSelect
                    returnValue={locationId}
                    setReturnValue={setLocationId}
                    width={"100%"}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={gatewayMac}
                    error={gatewayMacError}
                    fullWidth
                    variant="outlined"
                    placeholder="Gateway ID"
                    label="Gateway ID"
                    onChange={(e) => handleChangeGatewayMac(e)}
                  />
                </Grid> */}
              </Grid>
            ),
          },
          {
            label: "LOADING",
            child: (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightBold"
                    fontSize={20}
                    className={classes.fieldPadding}
                  >
                    Adding gateway into platform
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontWeight="fontWeightLight"
                  fontSize={16}
                  style={{ marginTop: 10 }}
                  className={classes.fieldPadding}
                >
                  <Box className={classes.fontColor}>
                    MiSensors is currently configuring your gateway into the
                    platform. This should take less than a minute.
                  </Box>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10 }}
                    className={classes.fieldPadding}
                  >
                    <LinearProgress />
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
          {
            label: "COMPLETE", // Because is the finish
            child: (
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Box
                    className={classes.fontColor}
                    fontWeight="fontWeightBold"
                    fontSize={20}
                  >
                    Success! Your Gateway has been added.
                  </Box>
                </Grid>
                <Grid item xs={12} fontWeight="fontWeightLight" fontSize={16}>
                  <Box className={classes.fontColor}>
                    You may now add additional gateways or start adding sensors
                    by selecting the options below.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="space-around" display="flex">
                    <Grid className={classes.imageButtonSize} item>
                      <ImageButton
                        onClick={handleCloseAndOpenSensorState}
                        image={sensor}
                        action="CONTINUE"
                        text="ADD SENSOR"
                      />
                    </Grid>
                    <Grid className={classes.imageButtonSize} item>
                      <ImageButton
                        onClick={() => handleRestart()}
                        image={gateway}
                        action="ADD"
                        text="ANOTHER GATEWAY"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ),
          },
        ]}
      />
    </div>
  );
}
