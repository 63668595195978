import React, { useState, useEffect } from "react";

import GenericList from "./GenericList";
import AlertContainer from "./AlertContainer";
import { navigate } from "hookrouter";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TemperatureSVG from "../../icons/TemperatureSVG";
import DoorSVG from "../../icons/DoorSVG";
import PanicSVG from "../../icons/PanicSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import LightSVG from "../../icons/LightSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import PressureSVG from "../../icons/PressureSVG";
import ProximitySVG from "../../icons/ProximitySVG";

const useStyles = makeStyles((theme) => ({
  alertContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

/**
 * LastAlertsList for
 * the list in the dashboard
 *
 * @props
 * alerts: Array[Object]
 */
const LastAlertsList = (props) => {
  const log = window.log('LastAlertsList');
  const classes = useStyles();
  const theme = useTheme();
  const [alertsArr, setAlertsArr] = useState([]);
  let icons = [
    <TemperatureSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <HumiditySVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <PressureSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <MotionDetectionSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <LightSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <PanicSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <ProximitySVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
    <DoorSVG
      outlinecolor={
        theme.palette.type === "light"
          ? theme.palette.iconColor.outline
          : theme.palette.iconColorDarkMode.outline
      }
      filledcolor={theme.palette.iconColorAlert.filled}
      style={{ height: 38, width: 38 }}
    />,
  ];

  const {
    alerts, // Alerts array with the alerts objects
    title,
    dashboardTable,
  } = props;
  // log("Looking at alerts ", alerts);
  const onClick = () => {
    navigate("/alert/all");
  };

  useEffect(() => {
    if (alerts[0] && alerts[0].info) {
      setAlertsArr(alerts);
    } else {
      setAlertsArr(alerts);
    }
  }, [alerts]);

  return alerts[0] && alerts[0].info ? (
    <GenericList
      title={title}
      actionTitle="View All Alerts"
      onClick={onClick}
      data={alertsArr}
      dashboardTable={dashboardTable}
      emptyBuilder={() => {
        return (
          <div key={0} className={classes.alertContainer}>
            <AlertContainer
              key={0}
              index={0}
              alertName={
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  No Active Alerts
                </div>
              }
              locationName={null}
              noActiveAlerts={true}
            />
          </div>
        );
      }}
      itemBuilder={(u, index) => (
        <div
          key={`last__alert__list__${index}`}
          className={classes.alertContainer}
          onClick={onClick}
        >
          <AlertContainer
            leadingIcon={icons[u.info.leadingIcon]}
            key={u.info.id}
            index={index}
            alertName={u.info.alertName}
            locationName={u.info.locationName}
            deviceName={u.info.deviceName}
            canSnooze={u.trailing.canSnooze}
          />
        </div>
      )}
      useItemBuilder={true}
    />
  ) : (
    <GenericList
      title={title}
      actionTitle="View All Alerts"
      onClick={onClick}
      data={alertsArr}
      dashboardTable={dashboardTable}
      emptyBuilder={() => {
        return (
          <div key={0} className={classes.alertContainer}>
            <AlertContainer
              key={0}
              index={0}
              alertName={
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  No Active Alerts
                </div>
              }
              locationName={null}
              noActiveAlerts={true}
            />
          </div>
        );
      }}
      itemBuilder={(u, index) => (
        <div
          key={`last__alert__list__${index}`}
          className={classes.alertContainer}
          onClick={onClick}
        >
          <AlertContainer
            leadingIcon={icons[0]}
            key={u.id}
            index={index}
            alertName={u.alertName}
            locationName={u.locationName}
            deviceName={u.deviceName}
          />
        </div>
      )}
      useItemBuilder={true}
    />
  );
};

export default LastAlertsList;
