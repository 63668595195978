import React from "react";
import RangeTypeSelector from "./RangeTypeSelector";

import {
  Grid,
  Typography,
  Slider,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: "5rem",
  },
  temperatureRange: {
    width: 220,
    [theme.breakpoints.down("xs")]: { width: "auto" },
  },
  title: {
    fontWeight: 500,
  },
}));

const TemperatureThresholds = (props) => {
  const theme = useTheme();

  const {
    temperatureThresholdLow,
    setTemperatureThresholdLow,
    temperatureThresholdHigh,
    setTemperatureThresholdHigh,
    temperatureScaleType,
    enableTemperatureAlert,
    setEnableTemperatureAlert,
    temperatureRangeType,
    setTemperatureRangeType,
  } = props;
  const classes = useStyles();

  const celsiusMarks = [
    {
      value: -163,
      label: "-163°C",
    },

    {
      value: 128,
      label: "128°C",
    },
  ];

  const fahrenheitMarks = [
    {
      value: -263,
      label: "-263°F",
    },

    {
      value: 263,
      label: "263°F",
    },
  ];

  const valuetext = (value) => {
    return `${value}°C`;
  };

  const handleToggleEnable = (e) => {
    setEnableTemperatureAlert(e.target.checked);
  };

  const handleOnChange = (e, v) => {
    if (temperatureRangeType === 1 || temperatureRangeType === 2) {
      setTemperatureThresholdHigh(v);
    } else {
      setTemperatureThresholdLow(v[0]);
      setTemperatureThresholdHigh(v[1]);
    }
  };

  return (
    <Card
      elevation={3}
      style={{
        padding: "30px",
        backgroundColor: !enableTemperatureAlert
          ? null
          : theme.palette.grey[500],
      }}
    >
      <Grid item className={classes.temperatureRange}>
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Grid item>
              <Typography
                className={classes.title}
                id="temperature-range-slider"
              >
                Temperature
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableTemperatureAlert}
                    onChange={handleToggleEnable}
                    name="enable"
                    color="primary"
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item>
              <RangeTypeSelector
                rangeType={temperatureRangeType}
                setRangeType={setTemperatureRangeType}
                enabled={enableTemperatureAlert}
              />
            </Grid>
            <Grid item className={classes.slider}>
              <Slider
                aria-labelledby="temperature-range-slider"
                getAriaValueText={valuetext}
                onChange={handleOnChange}
                value={
                  temperatureScaleType === "celsius"
                    ? [
                        temperatureThresholdLow ? temperatureThresholdLow : 0,
                        temperatureThresholdHigh
                          ? temperatureThresholdHigh
                          : 100,
                      ]
                    : temperatureRangeType === 1 || temperatureRangeType === 2
                    ? temperatureThresholdHigh
                      ? temperatureThresholdHigh
                      : 0
                    : [
                        temperatureThresholdLow ? temperatureThresholdLow : 32,
                        temperatureThresholdHigh
                          ? temperatureThresholdHigh
                          : 212,
                      ]
                }
                track={
                  temperatureRangeType === 1 || temperatureRangeType === 3
                    ? "inverted"
                    : "normal"
                }
                valueLabelDisplay="on"
                marks={
                  temperatureScaleType === "celsius"
                    ? celsiusMarks
                    : fahrenheitMarks
                }
                min={temperatureScaleType === "celsius" ? -163 : -263}
                max={temperatureScaleType === "celsius" ? 128 : 263}
                disabled={!enableTemperatureAlert}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TemperatureThresholds;
