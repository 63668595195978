import React from "react";
import HeaderBar from "../Generic/HeaderBar";
import InsertChartIcon from "@material-ui/icons/InsertChart";

import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
// Icons import
import TemperatureSVG from "../../icons/TemperatureSVG";
import HumiditySVG from "../../icons/HumiditySVG";
import PressureSVG from "../../icons/PressureSVG";
import MotionDetectionSVG from "../../icons/MotionDetectionSVG";
import LightSVG from "../../icons/LightSVG";
import PanicSVG from "../../icons/PanicSVG";
import ProximitySVG from "../../icons/ProximitySVG";
import DoorSVG from "../../icons/DoorSVG";
import BatterySVG from "../../icons/BatterySVG";
import { navigate, A } from "hookrouter";
import Temperature from "temperature-js";

/**
 * returns the typography color based in the
 * theme dark mode and the tone given for the colors
 * @param {Object, int, int} theme, lightTone=700, darkTone=300
 */
const textColor = ({ theme, lightTone = 700, darkTone = 300 }) => {
  if (theme.palette.type === "light") {
    return grey[lightTone];
  } else {
    return grey[darkTone];
  }
};
const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3, 4.5, 2, 4),
    // margin: theme.spacing(1, 1),
    borderRadius: "5px",
  },
  titleContainer: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  cardTitle: {
    textTransform: "uppercase",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
  },
  underLine: {
    backgroundColor: theme.palette.primary.light,
    height: "2px",
    width: "3rem",
  },
  icon: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  numberStyles: {
    color: textColor({ theme }),
    whiteSpace: "nowrap",
    fontSize: 55,
  },
  typography: {
    textAlign: "center",
    font: "Semibold 12px/25px Open Sans",
    letterSpacing: "0px",
    color: textColor({ theme, lightTone: 500, darkTone: "#FFF" }),
    opacity: "1",
    marginBottom: 6,
    marginTop: 6,
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 600,
  },
}));

const ReportView = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const optionsArray = [
    {
      title: "Temperature",
      navigationString: "Temperature",
      subtitle: "Click Here",
      icon: (
        <TemperatureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      title: "Humidity",
      navigationString: "Humidity",
      subtitle: "Click Here",
      icon: (
        <HumiditySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      title: "Pressure",
      navigationString: "Pressure",
      subtitle: "Click Here",
      icon: (
        <PressureSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      title: "Motion Detection",
      navigationString: "Motion-Detection",
      subtitle: "Click Here",
      icon: (
        <MotionDetectionSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      title: "Light Detection",
      navigationString: "Light-Detection",
      subtitle: "Click Here",
      icon: (
        <LightSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },

    {
      title: "Panic Button",
      navigationString: "Panic-Button",
      subtitle: "Click Here",
      icon: (
        <PanicSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      title: "Open/Closed Door",
      navigationString: "Door-Detection",
      subtitle: "Click Here",
      icon: (
        <DoorSVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      disable: true,
      title: "Proximity Sensor",
      navigationString: "Proximity-Sensor",
      subtitle: "Click Here",
      icon: (
        <ProximitySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          // filledcolor={theme.palette.iconColor.filled}
          filledcolor="lightgrey"
          style={{ height: 75, width: 75 }}
        />
      ),
    },
    {
      disable: true,
      title: "Battery",
      navigationString: "Battery",
      subtitle: "Click Here",
      icon: (
        <BatterySVG
          outlinecolor={
            theme.palette.type === "light"
              ? theme.palette.iconColor.outline
              : theme.palette.iconColorDarkMode.outline
          }
          filledcolor="lightgrey"
          // filledcolor={theme.palette.iconColor.filled}
          style={{ height: 75, width: 75 }}
        />
      ),
    },
  ];

  const navigateToReportsSelectedView = (name) => {
    navigate(`/reports/${name}`);
  };

  let breadcrumbs = (
    <div style={{ display: "inline-flex" }}>
      <A className={classes.breadcrumbs} href="/">
        Dashboard
      </A>
      <div>{"\xa0-\xa0"}</div>
    </div>
  );

  return (
    <React.Fragment>
      <div style={{ marginTop: 10 }}>
        <HeaderBar
          leadingIcon={
            <InsertChartIcon style={{ fontSize: 32 }} color="primary" />
          }
          // breadcrumbs={breadcrumbs}
          title="Reports"
          addButton={false}
          searchInput={false}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box fontWeight="fontWeightBold" className={classes.typography}>
                  EASY REPORTS
                </Box>
                <Box className={classes.underLine} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {optionsArray.map((option) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={option.title}>
              <TileComponent
                key={option.title}
                title={option.title}
                navigationString={option.navigationString}
                subtitle={option.subtitle}
                icon={option.icon}
                onClick={navigateToReportsSelectedView}
                disable={option.disable}
              />
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

const TileComponent = (props) => {
  const {
    title, // Title of the tile
    navigationString, // String for URL link
    subtitle, // Subtitle of the tile
    color, // Color for the underline
    icon, // Icon in the card
    onClick, // Fimctopm for OnClick
    disable,
  } = props;

  const classes = useStyles();

  const onClickHandler = () => {
    onClick(navigationString);
  };
  return (
    <Card
      onClick={disable ? undefined : onClickHandler}
      style={{
        cursor: !disable && "pointer",
        backgroundColor: disable && "lightgrey",
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Box fontWeight="fontWeightBold" className={classes.typography}>
            {title}
          </Box>
          <Box
            className={classes.underLine}
            style={{ backgroundColor: color }}
          />
          <Box fontWeight="fontWeightBold" className={classes.typography}>
            {subtitle}
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          {icon}
        </Box>
      </CardContent>
    </Card>
  );
};
export default ReportView;
